<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4">
                <h1 id="pageTitle" class="page-title text-primary text-uppercase">Tasks: Usend Problem Orders</h1>
                <div class="d-flex justify-content-center mt-4">
                    <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="data !== null && data.length > 0" class="mb-5">
                    <div class="table-responsive">
                        <table class="table table-borderless caption-top">
                            <caption><span id="ordersFound" class="fw-bold">{{ data.length }}</span> orders</caption>
                            <thead class="bg-light">
                            <tr>
                                <th># Order</th>
                                <th>Date/Status</th>
                                <th>Amount Sent</th>
                                <th>Amount Received</th>
                                <th width="25%">Sender</th>
                                <th width="25%">Recipient</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in data" :key="item.orderId">
                                <td>
                                    <router-link :to="{ name: 'Order Detail', params: { orderId: item.orderId } }" class="fw-bold">{{ item.orderId }}</router-link>
                                </td>
                                <td>
                                    <div>{{ item.date }}</div>
                                    <span class="badge rounded-3" :style="{'background-color': item.statusColor}">{{ item.status }}</span>
                                </td>
                                <td>
                                    {{ item.amountSent }}
                                </td>
                                <td>
                                    {{ item.amountReceived }} {{ item.amountReceivedCurrency }}
                                </td>
                                <td>
                                    {{ item.senderInformation }}
                                </td>
                                <td>
                                    {{ item.recipientInformation }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else>
                    <div class="alert alert-danger" v-if="error !== null">
                    {{ (error.data.Message ? error.data.Message : error.data.title) }} <span class="fw-bold">({{ error.status }})</span>
                    </div>
                    <div id="noOrderFound" class="alert alert-warning" v-show="data !== null && data.length === 0">No order found.</div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/Main";

export default {
    name: "UsendProblemOrders",
    components: {
        Layout,
    },
    data() {
        return {
            data: null,
            error: null,
            loading: false,
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            this.data = null;
            axios
                .get('/niloagent/UsendCustomerServiceTask/UsendProblemOrdersList')
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
}
</script>

<style lang="scss" scoped>
a {
    text-decoration: none;
}
table tr td {
    white-space: pre-line;
}
</style>