<template>
    <div class="input-group input-group-sm">
        <div class="input-group-text">
            <input class="form-check-input m-0" type="checkbox" v-model="item.show" value="true" />
            <label class="ms-2 form-label mb-0">{{ item.friendlyName }}</label>
        </div>

        <input type="text" class="form-control" v-model="item.filter1" :placeholder="placeholderValue1" v-if="!isDate" />
        <input type="text" class="form-control" v-model="item.filter2" :placeholder="placeholderValue2" v-show="show2Fields" />

        <input type="text" class="form-control" :id="item.id + '_1'" :placeholder="placeholderValue1" v-if="isDate" :value="item.filter1" />
        <input type="text" class="form-control" :id="item.id + '_2'" :placeholder="placeholderValue2" v-if="isDate" :value="item.filter2" />

        <button type="button" class="btn btn-light border" @click="remove">
            <i class="far fa-trash-alt"></i>
        </button>
    </div>
</template>

<script>
import { Datepicker } from 'vanillajs-datepicker';

export default {
    name: 'CustomReportFieldGrid',
    props: {
        item: Object,
    },
    data() {
        return {
            date1: null,
            date2: null,
        };
    },
    mounted() {
        this.init();
    },
    updated() {
        this.init();
    },
    methods: {
        remove() {
            this.$emit('remove');
        },
        init() {
            if (this.date1 && this.date2) {
                this.date1.destroy();
                this.date2.destroy();
            }

            if (this.isDate) {
                const elem1 = document.getElementById(String(this.item.id).concat('_' + 1));
                const elem2 = document.getElementById(String(this.item.id).concat('_' + 2));

                this.date1 = new Datepicker(elem1);
                this.date2 = new Datepicker(elem2);

                elem1.addEventListener('changeDate', () => {
                    this.item.filter1 = this.date1.getDate('mm/dd/yyyy');
                    this.item.filter2 = this.date2.getDate('mm/dd/yyyy');
                });

                elem2.addEventListener('changeDate', () => {
                    this.item.filter1 = this.date1.getDate('mm/dd/yyyy');
                    this.item.filter2 = this.date2.getDate('mm/dd/yyyy');
                });
            }
        },
    },
    computed: {
        isDate() {
            return this.item.dataType === 'D';
        },
        has2Fields() {
            return ['D', 'C', 'N'].includes(this.item.dataType);
        },
        show2Fields() {
            return !this.isDate && this.has2Fields;
        },
        placeholderValue1() {
            switch (this.item.dataType) {
                case 'D':
                    return 'mm/dd/yyyy';
                case 'M':
                    return 'mm/yy';
                case 'N':
                    return 'min';
                default:
                    return 'value';
            }
        },
        placeholderValue2() {
            switch (this.item.dataType) {
                case 'D':
                    return 'mm/dd/yyyy';
                case 'M':
                    return 'mm/yy';
                case 'N':
                    return 'max';
                default:
                    return 'value';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
button {
    font-size: 0;
    svg {
        margin: 0;
    }
}
.form-control {
    max-width: 100px;
}
.input-group-text {
    label {
        cursor: grab;
    }
}
</style>
