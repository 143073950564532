<template>
    <div class="modal" id="modal-log" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Order log</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <loading :show="loading" />
                    <div class="table-responsive" v-show="!loading">
                        <table class="table table-borderless">
                            <thead class="bg-light">
                                <tr class="">
                                    <th class="text-center rounded-start">Agent</th>
                                    <th class="text-center">Date/time</th>
                                    <th>Action</th>
                                    <th>Results</th>
                                    <th class="rounded-end">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.id">
                                    <td class="text-center agent">
                                        <span class="fw-bold d-block">{{ item.agentName }}</span>
                                        ({{ item.agentId }})
                                    </td>
                                    <td class="text-center">
                                        {{ item.dateTime }}
                                    </td>
                                    <td>
                                        {{ item.action }}
                                    </td>
                                    <td>
                                        {{ item.results }}
                                    </td>
                                    <td v-html="sanitizeData(item.remarks)"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import Loading from '@/components/Loading';
import DOMPurify from 'dompurify';

export default {
    name: 'Log',
    components: {
        Loading,
    },
    props: {
        orderId: {
            type: Number,
        },
    },
    data() {
        return {
            modal: null,
            data: null,
            loading: false,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-log'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`/niloagent/order/${this.orderId}/note/list`)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        show() {
            this.data = null;
            this.modal.show();
            this.load();
        },
        sanitizeData(item) {
            return DOMPurify.sanitize(item);
        },
    },
};
</script>

<style lang="scss" scoped>
.agent {
    font-size: 0.8rem;
}
</style>
