<template>
    <layout>
        <div class="container py-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Daily Activity</h1>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label for="fromDate" class="form-label">Start Date</label>
                                    <div class="input-group">
                                        <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                        <input type="text" autocomplete="off" class="form-control datePicker datePicker-input" id="startDate" />
                                    </div>
                                    <span v-if="errors.startDate.error" class="text-danger">{{ errors.startDate.message }} </span>
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="toDate" class="form-label">End Date</label>
                                    <div class="input-group">
                                        <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                        <input type="text" autocomplete="off" class="form-control datePicker datePicker-input" id="endDate" />
                                    </div>
                                    <span v-if="errors.endDate.error" class="text-danger">{{ errors.endDate.message }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <label class="form-label">Agent</label>
                                    <v-select :options="agents" :clearable="true" :reduce="(item) => item.id" label="value" v-model="form.agentId"></v-select>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label class="form-label">B2B</label>
                                    <select class="form-select" v-model="form.b2B" v-if="options">
                                        <option v-for="item in options.b2B" :key="item.id" :value="item.id">{{ item.value }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label class="form-label">Business Model</label>
                                    <select class="form-select" v-model="form.businessModel" v-if="options">
                                        <option v-for="item in options.businessModels" :key="item.id" :value="item.id">{{ item.value }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label class="form-label">Sender Payment type</label>
                                    <select class="form-select" v-model="form.senderPayMethod">
                                        <option value="ALL">All</option>
                                        <option value="CASH">CASH</option>
                                        <option value="CASHIERS CHECK">CASHIERS CHECK</option>
                                        <option value="CREDIT_CARD">CREDIT_CARD</option>
                                        <option value="DEBIT_CARD">DEBIT_CARD</option>
                                        <option value="I_CHECK">I_CHECK</option>
                                        <option value="REFERRAL">REFERRAL</option>
                                        <option value="TED">TED</option>
                                        <option value="WALLET">WALLET</option>
                                        <option value="WIRE_TRANSFER">WIRE_TRANSFER</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mb-3">
                                    <label class="form-label">Destination Country</label>
                                    <select class="form-select" v-model="form.destinationCountry" v-if="options">
                                        <option v-for="item in options.destinationCountries" :key="item.id" :value="item.id">{{ item.value }}</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <div class="form-check mb-3 mb-md-0">
                                        <input class="form-check-input" v-model="form.displayPartnerReferenceField" type="checkbox" id="checkDisplayPartnerReferenceField" />
                                        <label class="form-check-label" for="checkDisplayPartnerReferenceField">Display partner reference field</label>
                                    </div>
                                    <div class="form-check mb-3 mb-md-0">
                                        <input class="form-check-input" v-model="form.cancelledOnly" type="checkbox" id="checkCancelledOnly" />
                                        <label class="form-check-label" for="checkCancelledOnly">Cancelled orders only</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="mb-3">
                                <label class="form-label">Source Country</label>
                                <select class="form-select" v-model="form.countryId" @change="loadAgents" v-if="options">
                                    <option v-for="item in options.sourceCountries" :key="item.id" :value="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                            <div v-show="form.countryId == 1">
                                <label class="form-label">Select States</label>
                                <div class="card select-states">
                                    <div class="card-body">
                                        <div class="form-check">
                                            <input class="form-check-input" v-model="checkAllStates" type="checkbox" id="checkAllSelectedStates" />
                                            <label class="form-check-label" for="checkAllSelectedStates">All</label>
                                        </div>
                                        <hr />
                                        <ul v-if="options" class="list-unstyled">
                                            <li v-for="item in options.selectStates" :key="item.id">
                                                <div class="form-check">
                                                    <input class="form-check-input" v-model="form.selectedStates" :value="item.id" type="checkbox" id="checkSelectedStates" />
                                                    <label class="form-check-label" for="checkSelectedStates">{{ item.value }}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="exportData" class="btn btn-primary text-uppercase" :disabled="loading"><i class="fas fa-download"></i> <span v-show="!loading">Export</span><span v-show="loading">Waiting...</span></button>
            <loading :show="loading" />
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';
import { Datepicker } from 'vanillajs-datepicker';

const USA = 1;
const HTTP_OK = 200;
const HTTP_NO_CONTENT = 204;

export default {
    name: 'DailyActivity',
    components: {
        Layout,
        Loading,
    },
    data() {
        return {
            form: {
                startDate: null,
                endDate: null,
                agentId: null,
                b2B: 'ALL',
                businessModel: 'ALL',
                senderPayMethod: 'ALL',
                destinationCountry: 0,
                displayPartnerReferenceField: false,
                selectedStates: [],
                productOrigin: 'US',
                cancelledOnly: false,
                countryId: USA,
            },
            options: null,
            loading: false,
            heifImage: null,
            agents: [],
            checkAllStates: false,
            errors: {
                startDate: { error: false, message: '' },
                endDate: { error: false, message: '' },
            },
        };
    },
    async mounted() {
        this.form.startDate = new Datepicker(document.getElementById('startDate'));
        this.form.endDate = new Datepicker(document.getElementById('endDate'));
        await this.loadOptions();
        this.loadAgents();
        this.checkAllStates = true;
    },
    methods: {
        loadAgents() {
            this.form.agentId = null;
            this.agents = [
                {
                    id: -1,
                    value: 'Loading...',
                },
            ];

            axios
                .get('/niloagent/accounting/dailyactivityagents', {
                    params: {
                        countryId: this.form.countryId,
                    },
                })
                .then((response) => {
                    this.agents = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        async loadOptions() {
            try {
                const response = await axios.get('/niloagent/accounting/dailyactivityoptions');

                if (response.status === HTTP_OK) {
                    this.options = response.data;
                }
            } catch (error) {
                this.$toast.error(error.response.data.Message);
            }
        },
        exportData() {
            this.loading = true;
            if (this.validateForm()) {
                let params = this.getParams();
                axios
                    .post(`niloagent/accounting/dailyactivityexcel`, params, {
                        responseType: 'blob',
                    })
                    .then((response) => {
                        if (response.status == HTTP_NO_CONTENT) {
                            this.$toast.error('There are no records available for the selected filter.');
                        } else {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', 'daily-activity-report.xlsx');
                            document.body.appendChild(link);
                            link.click();
                        }
                    })
                    .catch(async (error) => {
                        let responseError = JSON.parse(await error.response.data.text());
                        this.$toast.error(responseError.Message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        getParams() {
            return {
                listStates: this.form.countryId == USA ? this.form.selectedStates : null,
                agent: this.form.agentId,
                cbpartner: this.form.displayPartnerReferenceField,
                startDate: new Date(this.form.startDate.getDate('yyyy-mm-dd')).toISOString().replace('Z', ''),
                endDate: new Date(this.form.endDate.getDate('yyyy-mm-dd')).toISOString().replace('00:00:00.000', '23:59:59.999').replace('Z', ''),
                destinationCountry: this.form.destinationCountry,
                b2b: this.form.b2B == 'ALL' ? null : this.form.b2B,
                agentType: this.form.businessModel == 'ALL' ? null : this.form.businessModel,
                sourceCountry: this.form.countryId,
                senderPayMethod: this.form.senderPayMethod == 'ALL' ? null : this.form.senderPayMethod,
                productOrigin: this.form.productOrigin,
                cancelledOnly: this.form.cancelledOnly,
            };
        },
        validateForm() {
            let valid = true;
            let startDate = this.form.startDate.getDate('yyyy-mm-dd');
            let endDate = this.form.endDate.getDate('yyyy-mm-dd');

            this.errors.startDate.error = false;
            this.errors.endDate.error = false;
            document.getElementById('startDate').classList.remove('border-danger');
            document.getElementById('endDate').classList.remove('border-danger');

            if (startDate == null) {
                document.getElementById('startDate').classList.add('border-danger');
                valid = false;
                this.errors.startDate.error = true;
                this.errors.startDate.message = 'Start date is required';
            }
            if (endDate == null) {
                document.getElementById('endDate').classList.add('border-danger');
                valid = false;
                this.errors.endDate.error = true;
                this.errors.endDate.message = 'End date is required';
            }
            if (startDate > endDate) {
                document.getElementById('startDate').classList.add('border-danger');
                document.getElementById('endDate').classList.add('border-danger');
                this.errors.startDate.error = true;
                this.errors.startDate.message = 'Start date must be less than end date';
                this.errors.endDate.error = true;
                this.errors.endDate.message = 'End date must be greater than start date';
                valid = false;
            }
            if (valid) {
                let diff = Math.abs(new Date(endDate) - new Date(startDate));
                let days = diff / (1000 * 60 * 60 * 24);
                if (days > 90) {
                    document.getElementById('startDate').classList.add('border-danger');
                    document.getElementById('endDate').classList.add('border-danger');
                    this.errors.startDate.error = true;
                    this.errors.startDate.message = 'The difference between the start date and the end date must be less than 90 days';
                    this.errors.endDate.error = true;
                    this.errors.endDate.message = 'The difference between the start date and the end date must be less than 90 days';
                    valid = false;
                }
            }
            if (!valid) {
                this.loading = false;
            }
            return valid;
        },
    },
    watch: {
        checkAllStates(value) {
            if (value) {
                this.form.selectedStates = this.options.selectStates.map((item) => {
                    return item.id;
                });
            } else {
                this.form.selectedStates = [];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.datePicker {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}
.select-states {
    max-height: 185px;
    overflow: auto;
}
</style>
