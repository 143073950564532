<template>
    <layout navigation-label="Additional Information">
        <template v-slot:content>
            <form autocomplete="off">
                <h1 class="page-title text-primary mb-4 text-uppercase" id="pageTitle">Additional Information</h1>
                <div class="row">
                    <div class="col mb-3">
                        <label for="senderOccupation" class="form-label">Sender occupation</label>
                        <input type="text" v-model="info.senderOccupation" class="form-control" id="senderOccupation" placeholder="" />
                    </div>
                    <div class="col mb-3">
                        <label for="sourceOfFunds" class="form-label">Source of funds</label>
                        <div v-if="!trangloOperation">
                            <input type="text" v-model="info.sourceOfFunds" class="form-control" id="sourceOfFunds" placeholder="" name="sourceOfFunds" />
                        </div>
                        <div v-else>
                            <select v-model="info.sourceOfFunds" class="form-select" id="sourceOfFunds" name="sourceOfFundsselect">
                                <option value="">- Select -</option>
                                <option v-for="item in fundOfSourceOptions" :value="item.id + ' - ' + item.value" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <label for="senderRelationshipToRecipient" class="form-label">Sender relationship to recipient</label>
                        <div v-if="!trangloOperation">
                            <input type="text" v-model="info.senderRelationshipToRecipient" class="form-control" id="senderRelationshipToRecipient" name="senderRelationshipToRecipient" placeholder="" />
                        </div>
                        <div v-else>
                            <select v-model="info.senderRelationshipToRecipient" @change="onChangeSenderRelationshipToRecipient" class="form-select" id="senderRelationshipToRecipient" name="senderRelationshipToRecipientselect">
                                <option value="">- Select -</option>
                                <option v-for="item in relationshipOptions" :value="item.id + ' - ' + item.value" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col mb-3">
                        <label for="payMethod" class="form-label d-flex">Pay method <label class="mandatory-mark">*</label> <span class="text-muted ms-auto">(received from sender)</span></label>
                        <select v-model="info.payMethod" class="form-select" id="payMethod" @change="onChangeSenderPayMethod">
                            <option value="0">- Select -</option>
                            <option v-for="item in payMethods" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>
                <div class="row">
                    <div class="col mb-3">
                        <label for="purposeOfTransaction" class="form-label">Purpose of transaction</label>
                        <div v-if="!trangloOperation">
                            <input type="text" v-model="info.purposeOfTransaction" class="form-control" id="purposeOfTransaction" name="purposeOfTransaction" placeholder="" />
                        </div>
                        <div v-else>
                            <select v-model="info.purposeOfTransaction" class="form-select" id="purposeOfTransaction" name="purposeOfTransactionselect">
                                <option value="">- Select -</option>
                                <option v-for="item in purposeOptions" :value="item.id + ' - ' + item.value" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col mb-3">
                        <label for="messageToRecipient" class="form-label">Message to recipient</label>
                        <input type="text" v-model="info.messageToRecipient" class="form-control" id="messageToRecipient" placeholder="" />
                    </div>
                </div>
            </form>

            <router-link to="/order/create/payment" class="btn btn-outline-primary text-uppercase mt-4 me-3"><i class="fas fa-arrow-left me-2"></i> Back</router-link>
            <button name="btnAdditionalNext" id="btnAdditionalNext" type="button" @click="next" class="btn btn-primary text-uppercase mt-4" :disabled="validating">{{ validating || loading ? 'Wait...' : !hasSummary ? 'Next' : 'Save & Go Summary' }}<i class="fas fa-arrow-right ms-2"></i></button>
        </template>
    </layout>
</template>

<script>
import axios from 'axios';
import layout from '@/views/layouts/CreateOrder';

export default {
    name: 'AdditionalInformation',
    components: {
        layout,
    },
    data() {
        return {
            info: {
                payMethod: 'CASH',
                sourceOfFunds: '',
                senderOccupation: '',
                messageToRecipient: '',
                purposeOfTransaction: '',
                senderRelationshipToRecipient: '',
            },
            payMethods: [],
            loading: false,
            validating: false,
            trangloOperation: false,
            fundOfSourceOptions: [],
            purposeOptions: [],
            relationshipOptions: [],
        };
    },
    mounted() {
        this.load();
        this.loadPayMethods();
        if (this.order.additionalInformation != null) this.info = this.order.additionalInformation;
        else {
            this.info.senderOccupation = this.order.sender.occupation;
        }
    },
    methods: {
        loadPayMethods() {
            axios
                .get('/niloagent/createorder/filter/additional/paymethodfromsender')
                .then((response) => {
                    this.payMethods = response.data;
                })
                .catch((error) => {
                    this.payMethods = null;
                    this.$toast.error(`${error.response.data.Message}`);
                });
        },
        onChangeSenderPayMethod() {
            if (this.info.payMethod === 'DEBIT_CARD') {
                alert('For Debit Card Transactions a 0.20% fee of the total amount will be charged');
                this.$store.state.simpleOrder.paymentInformation.debitCardSurcharge = this.$store.state.simpleOrder.paymentInformation.amountSent * 0.002;

                this.$store.commit('order/setDebitCardSurcharge', this.$store.state.simpleOrder.paymentInformation.debitCardSurcharge);
            } else {
                this.$store.commit('order/setDebitCardSurcharge', 0);
            }
        },
        load() {
            axios
                .get('/niloagent/createorder/loadAdditionalInformation', {
                    params: {
                        senderId: this.order.sender.clientId,
                        recipientId: this.order.recipient.clientId,
                        paymethodId: this.order.paymentInformation.paymentMethodId,
                    },
                })
                .then((response) => {
                    if (response.data.senderRecipient && this.info.senderRelationshipToRecipient.length <= 0) this.info.senderRelationshipToRecipient = response.data.senderRecipient.relationship;

                    this.fundOfSourceOptions = response.data.fundOfSourceOptions;
                    this.purposeOptions = response.data.purposeOptions;
                    this.relationshipOptions = response.data.relationshipOptions;
                    this.trangloOperation = response.data.trangloOperation;
                })
                .catch((error) => {
                    this.currencies = null;
                    this.$toast.error(error.response.data.Message);
                });
        },
        async validate() {
            this.validating = true;
            return axios
                .post('/niloagent/createorder/validate/additionalinformation', {
                    orderId: 0,
                    orderReferenceNumber: this.order.orderReferenceNumber,
                    sender: this.order.sender,
                    recipient: this.order.recipient,
                    paymentInformation: this.order.paymentInformation,
                    additionalInformation: this.info,
                })
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    return false;
                })
                .finally(() => {
                    this.validating = false;
                });
        },
        async next() {
            if (await this.validate()) {
                this.$store.commit('order/setAdditionalInformation', this.info);
                await this.$router.push({ name: 'Order Summary' });
            }
        },
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        hasSummary() {
            return this.$store.getters['order/hasSummary'];
        },
    },
};
</script>

<style scoped></style>
