<template>
    <layout>
        <div class="container pt-4 d-print-none mb-5">
            <h1 class="page-title text-primary mb-5 text-uppercase">Custom Report</h1>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Show Max</label>
                            <input type="number" v-model="max" class="form-control" />
                        </div>
                        <div class="col-md-4 mb-3">
                            <label class="form-label">Sort Order</label>
                            <select class="form-select" v-model="sort" value="A" selected>
                                <option value="A">Ascending</option>
                                <option value="D">Descending</option>
                            </select>
                        </div>
                    </div>
                    <label class="form-label">Fields</label>
                    <div class="border-gray p-3 pb-2 rounded d-flex">
                        <div class="grid-fields">
                            <ul class="list-unstyled list-inline m-0">
                                <li class="list-inline-item mb-2" @drop="drop($event, index)" @dragend.prevent @dragover.prevent @dragenter.prevent v-for="(item, index) in grid" :key="item.order" :dataId="item.id" draggable @dragstart="startDrag($event, index)">
                                    <custom-report-field-grid :item="item" @remove="remove(index)"></custom-report-field-grid>
                                </li>
                            </ul>
                        </div>
                        <div class="ms-auto" v-if="fields">
                            <div class="dropdown">
                                <button type="button" class="btn btn-light fs-5 px-3 py-1 border-0 dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" :disabled="fields.length === 0">+</button>
                                <ul class="dropdown-menu dropdown-menu-end" :class="{ 'd-none': fields.length === 0 }">
                                    <li v-for="(item, index) in fieldsOrderByName" :key="index">
                                        <a href="#" @click="add(index)" class="dropdown-item">{{ item.friendlyName }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3 d-flex">
                <button type="button" @click="display" class="btn btn-sm btn-primary text-uppercase" :disabled="loading"><i class="fas fa-search"></i> {{ loading ? 'Wait...' : 'Display' }}</button>
                <div class="ms-auto">
                    <button type="button" @click="clear" class="btn btn-sm btn-outline-primary text-uppercase">Clear</button>
                    <!-- <button type="button" @click="exportExcel" class="btn btn-sm btn-primary text-uppercase ms-md-2" :disabled="loading">{{ loading ? 'Wait...' : 'Export' }}</button> -->
                </div>
            </div>
            <div class="py-4" v-if="data != null">
                <div class="card mb-3">
                    <div class="card-body">
                        <div class="mb-1 fw-light"><span class="text-uppercase fw-bold text-dark">Report Date:</span> {{ data.reportDate }}</div>
                        <div class="fw-light"><span class="text-uppercase fw-bold text-dark">Filter:</span> {{ data.filterDescription }}</div>
                    </div>
                </div>
                <div class="py-4" v-if="data.items.length > 0">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th v-for="(item, index) in columnsTable" :key="index">{{ item.friendlyName }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, indexItem) in data.items" :key="indexItem">
                                <td v-for="(column, indexColumn) in columnsTable" :key="indexColumn">
                                    {{ item[column.name] }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-show="data.items.length > 0 && showFooterTable" class="fw-bold">
                            <tr>
                                <td v-for="(column, indexColumn) in columnsTable" :key="indexColumn">
                                    {{ totalColumn(column.name) in data ? data[totalColumn(column.name)] : '' }}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="alert alert-warning text-center container" v-else>
                    <h4 class="fw-semibold">There are no transactions in this report</h4>
                    <span class="fst-italic">(Please modify your search criteria and try again)</span>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import CustomReportFieldGrid from '@/components/CustomReportFieldGrid';

export default {
    name: 'CustomReport',
    components: {
        Layout,
        CustomReportFieldGrid,
    },
    data() {
        return {
            grid: [],
            fields: null,
            data: null,
            sort: 'A',
            max: 500,
            loading: false,
        };
    },
    mounted() {
        this.getFields();
    },
    methods: {
        display() {
            this.loading = true;
            axios
                .post(`niloagent/accounting/customreport?topOption=${this.max}&sortOption=${this.sort}`, this.grid)
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        exportExcel() {
            this.loading = true;
            axios
                .post(`niloagent/accounting/customreportexcel?topOption=${this.max}&sortOption=${this.sort}`, this.grid, {
                    responseType: 'blob',
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'custom-report.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.log(error);
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        clear() {
            this.grid = [];
            this.data = null;
            this.getFields();
        },
        add(index) {
            const item = this.fields[index];
            item.selected = true;
            this.grid.push(item);
            this.fields.splice(index, 1);
            this.reOrderIndex();
        },
        remove(index) {
            const item = this.grid[index];
            item.selected = false;
            this.fields.push(item);
            this.grid.splice(index, 1);
            this.reOrderIndex();
        },
        startDrag(event, index) {
            event.dataTransfer.setData('from', index);
            event.dataTransfer.dropEffect = 'move';
            event.dataTransfer.effectAllowed = 'move';
        },
        drop(event, to) {
            const from = event.dataTransfer.getData('from');
            this.grid.splice(to, 0, this.grid.splice(from, 1)[0]);
            this.reOrderIndex();
        },
        getFields() {
            axios
                .get('niloagent/accounting/customreportfieldsoptions')
                .then((response) => {
                    this.fields = response.data;
                    this.init();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {});
        },
        init() {
            const field = this.fields.find((el) => el.selected === true);
            const index = this.fields.indexOf(field);

            this.grid.push(field);
            this.fields.splice(index, 1);

            this.reOrderIndex();
        },
        totalColumn(value) {
            return 'total_' + value;
        },
        reOrderIndex() {
            this.grid.forEach((value, index) => {
                value.order = index;
            });
        },
    },
    computed: {
        columnsTable() {
            return this.grid.filter((el) => {
                return el.show;
            });
        },
        showFooterTable() {
            const elements = this.grid.filter((el) => {
                return el.show && (el.dataType === 'C' || el.dataType === 'N');
            });

            return elements.length > 0;
        },
        fieldsOrderByName() {
            const fields = this.fields;
            fields.sort((a, b) => {
                let fa = a.friendlyName.toLowerCase(),
                    fb = b.friendlyName.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });

            return fields;
        },
    },
};
</script>

<style lang="scss" scoped>
.rounded {
    border-width: 1px;
    border-style: dashed;
}

button.dropdown-toggle {
    &:after {
        content: none;
    }
}
</style>
