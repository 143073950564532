import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home';
import Login from '@/views/Login';
import RemessaPremiada from '@/views/forms/RemessaPremiada';
import PageNotFound from '@/views/errors/404';
import Disclosure from '@/views/user/Disclosure';
import newPassword from '@/views/user/NewPassword';
import Contact from '@/views/Contact';

import formsRoutes from './forms';
import tasksRoutes from './tasks';
import topupRoutes from './topup';
import ordersRoutes from './order';
import complianceRoutes from './compliance';
import accountingRoutes from './accounting';
import administrationRoutes from './administration';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/remessapremiada',
        name: 'RemessaPremiada',
        component: RemessaPremiada,
    },
    {
        path: '/disclosure',
        name: 'Disclosure',
        component: Disclosure,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/new-password',
        name: 'New Password',
        component: newPassword,
        beforeEnter: (to, from, next) => {
            if (!router.app.$store.state.auth.changePassword) next({ name: 'Home' });
            else next();
        },
    },
    ...ordersRoutes,
    ...topupRoutes,
    ...tasksRoutes,
    ...administrationRoutes,
    ...accountingRoutes,
    ...formsRoutes,
    ...complianceRoutes,
    { path: '*', component: PageNotFound },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    console.log(to.name);
    if (to.name === 'Credit Debit Card Payment') {
        next();
    } else if (to.name === 'Receipt-Qr-Code-Upload') {
        localStorage.clear();
        next();
    } else {
        store.dispatch('auth/authenticate').then((isAuthenticated) => {
            if (isAuthenticated) {
                var remessaPremiada = localStorage.getItem('showRemessaPremiada');

                if (remessaPremiada === 'true' || remessaPremiada == null) {
                    localStorage.setItem('showRemessaPremiada', 'false');
                    if (to.name !== 'RemessaPremiada') {
                        router.push({ name: 'RemessaPremiada' });
                    } else next();

                    return false;
                }

                if (store.getters['auth/changePassword']) {
                    if (to.name !== 'New Password') {
                        router.push({ name: 'New Password' });
                    } else next();

                    return false;
                }
                if (store.getters['agent/showDisclosure']) {
                    if (to.name !== 'Disclosure') {
                        router.push({ name: 'Disclosure' });
                    } else next();

                    return false;
                }

                // if (store.getters['showComplianceAML']) {
                //     if (to.name !== 'Compliance AML') {
                //         router.push({ name: 'Compliance AML' });
                //     } else next();

                //     return false;
                // }
                // if (store.getters['showComplianceSigns']) {
                //     if (to.name !== 'ComplianceSigns') {
                //         router.push({ name: 'ComplianceSigns' });
                //     } else next();

                //     return false;
                // }
                next();
            } else if (to.name !== 'Login') {
                next({ name: 'Login', query: { redirect: to.path } });
            } else next();
        });
    }
});

export default router;
