<template>
    <div class="container" v-if="data">
        <div class="text-center border border-dark py-3">
            <img alt="Banco Rendimento" src="@/assets/img/banco-rendimento.png" class="logo-rendimento" />
        </div>
        <div class="text-center border border-top-0 border-dark py-3">
            <h5 class="mb-0">DOCUMENTO DE CRÉDITO</h5>
        </div>

        <div class="row d-flex">
            <div class="col-4 pe-0 ">
                <div class="border border-dark border-top-0 border-end-0 py-2 ps-2 h-100">
                    <label class="fw-bold">DATA:</label>
                    <div class="fs-5">{{ data.datePaid }}</div>
                </div>
            </div>
            <div class="col-4 px-0">
                <div class="border border-dark border-top-0 border py-2 ps-2 h-100">
                    <label class="fw-bold">RECIBO N&ordm;:</label>
                    <div class="fs-5">{{ data.reciept }}</div>
                </div>
            </div>
            <div class="col-4 ps-0">
                <div class="border-end border-dark border-bottom py-2 ps-2 h-100">
                    <label class="fw-bold">VALOR:</label>
                    <div class="fs-5">{{ data.amountReceived }}</div>
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="col-4 pe-0">
                <div class="border border-dark border-top-0 border-end-0 py-2 ps-2 h-100">
                    <label class="fw-bold">DADOS DO REMETENTE/CNPJ:</label>
                    <div class="fs-6">
                        BANCO RENDIMENTO S.A<br />
                        68.900.810/0001-38
                    </div>
                </div>
            </div>
            <div class="col-3 px-0">
                <div class="border border-dark border-top-0 border py-2 ps-2 h-100">
                    <label class="fw-bold">Nº BANCO:</label>
                    <div class="fs-6">633-5</div>
                </div>
            </div>
            <div class="col-3 ps-0 pe-0">
                <div class="border-end border-dark border-bottom py-2 ps-2 h-100">
                    <label class="fw-bold">AGÊNCIA N&ordm;:</label>
                    <div class="fs-6">0001</div>
                </div>
            </div>
            <div class="col-2 ps-0">
                <div class="border-end border-dark border-bottom py-2 ps-2 h-100">
                    <label class="fw-bold">CONTA N&ordm;:</label>
                    <div class="fs-6">0001</div>
                </div>
            </div>
        </div>

        <div class="text-center border border-dark border-top-0 py-3">
            <h5 class="mb-0">DADOS DO DESTINATÁRIO</h5>
        </div>

        <div class="row d-flex">
            <div class="col-4 pe-0">
                <div class="border border-dark border-top-0 border-end-0 py-2 ps-2 h-100">
                    <label class="fw-bold">NOME DO BANCO:</label>
                    <div class="fs-6">{{ data.beneficiaryBankName }}</div>
                </div>
            </div>
            <div class="col-3 px-0">
                <div class="border border-dark border-top-0 border py-2 ps-2 h-100">
                    <label class="fw-bold">Nº BANCO:</label>
                    <div class="fs-6">{{ data.beneficiaryBranch }}</div>
                </div>
            </div>
            <div class="col-3 ps-0 pe-0">
                <div class="border-end border-dark border-bottom py-2 ps-2 h-100">
                    <label class="fw-bold">AGÊNCIA N&ordm;:</label>
                    <div class="fs-6">{{ data.beneficiaryBranch }}</div>
                </div>
            </div>
            <div class="col-2 ps-0">
                <div class="border-end border-dark border-bottom py-2 ps-2 h-100">
                    <label class="fw-bold">CONTA N&ordm;:</label>
                    <div class="fs-6">{{ data.beneficiaryAccount }}</div>
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="col-4 pe-0 ">
                <div class="border border-dark border-top-0 border-end-0 py-2 ps-2 h-100">
                    <label class="fw-bold">FAVORECIDO / CNPJ / CPF:</label>
                    <div class="fs-6">
                        {{ data.beneficiary }}<br/>
                        {{ data.beneficiaryCpfOrCnpj }}
                    </div>
                </div>
            </div>
            <div class="col-4 px-0">
                <div class="border border-dark border-top-0 border py-2 ps-2 h-100">
                    <label class="fw-bold">N&ordm; ORDEM:</label>
                    <div class="fs-6">{{ data.orderId }}</div>
                </div>
            </div>
            <div class="col-4 ps-0">
                <div class="border-end border-dark border-bottom py-2 ps-2 h-100">
                    <label class="fw-bold">FINALIDADE:</label>
                    <div class="fs-6">{{ data.purpose }}</div>
                </div>
            </div>
        </div>
        <div class="mt-2 small">
            <p class="mb-1">*A transferência de crédito através de DOC só se realiza na mesma praça ou entre praças integrantes de um mesmo sistema de Compensação Local ou Regional.</p>
            <p>*O Banco não será responsável pela demora ou não cumprimento da transferência por erro de preenchimento / informações incorretas.</p>
        </div>
        <div class="text-center d-print-none mt-3">
            <input type="button" @click="print" class="btn btn-outline-dark" value="PRINT" />
        </div>
    </div>
</template>

<script>
export default {
    name: "PaymentReceiptRendimento",
    props: {
        data: Object
    },
    methods: {
        print() {
            window.print();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>