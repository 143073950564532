<template>
    <div class="modal" id="modal-history" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">
                        History for
                        <span class="text-capitalize">{{ typeClient }}</span>
                        {{ cpf ? `CPF: ${recipientCpf}` : `#${clientId}` }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <loading :show="loading" />
                    <div class="table-responsive" v-show="!loading">
                        <table class="table table-borderless">
                            <thead class="bg-light">
                                <tr class="">
                                    <th class="text-center rounded-start">Order #</th>
                                    <th class="text-center">Date</th>
                                    <th>Status</th>
                                    <th>Amount</th>
                                    <th>Sender</th>
                                    <th class="rounded-end">Recipient</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.orderId">
                                    <td class="text-center">
                                        <button type="button" @click="openOrder(item.orderId)" class="btn btn-link fw-bolder text-decoration-none text-black-50">{{ item.orderId }}</button>
                                    </td>
                                    <td class="text-center">
                                        {{ item.createTime }}
                                    </td>
                                    <td>
                                        <span class="badge rounded-3" :style="{ 'background-color': item.statusColor }">{{ item.status }}</span>
                                    </td>
                                    <td>
                                        {{ item.amountSent }}
                                    </td>
                                    <td>
                                        {{ item.senderName }} (No.{{ item.senderId }})<br />
                                        {{ item.senderAddress }}
                                    </td>
                                    <td>
                                        {{ item.recipientName }} (No.{{ item.recipientId }})<br />
                                        {{ item.recipientAddress }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import Loading from '@/components/Loading';

export default {
    name: 'ClientHistory',
    components: {
        Loading,
    },
    props: {
        senderId: {
            type: String,
        },
        recipientId: {
            type: String,
        },
        recipientCpf: {
            type: String,
        },
    },
    data() {
        return {
            data: null,
            cpf: false,
            modal: null,
            loading: false,
            typeClient: null,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-history'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(this.url)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        loadByCPF() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`/niloagent/client/recipientcpf/${this.recipientCpf}/orderhistorybycpf`)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        show(type) {
            this.typeClient = type;
            this.data = null;
            this.modal.show();
            this.load();
        },
        showByCPF(type) {
            this.typeClient = type;
            this.cpf = true;
            this.data = null;
            this.modal.show();
            this.loadByCPF();
        },
        openOrder(orderId) {
            this.$router.push({ name: 'Order Detail', params: { orderId: orderId } });
            this.modal.hide();
        },
    },
    computed: {
        url() {
            return `/niloagent/client/${this.typeClient}/${this.clientId}/orderhistory`;
        },
        clientId() {
            return this.typeClient === 'sender' ? this.senderId : this.recipientId;
        },
    },
};
</script>

<style scoped></style>
