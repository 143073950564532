<template>
    <div class="modal" id="modal-existing-similiar-client" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Client Already Exists</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-6">
                    <div class="row">
                        <div class="col-md-6">
                            <h3 class="fs-6 fw-bold mb-3">Existing Record:</h3>
                            <ul class="m-0 list-unstyled" >
                                <li class="mb-2"><label class="fw-bold">Customer Number: </label> {{clientCompare.existingClientId}}</li>
                                <li class="mb-2"><label class="fw-bold">Name: </label> {{clientCompare.existingFullName}}</li>
                                <li class="mb-2"><label class="fw-bold">Address: </label> {{clientCompare.existingAddress}}</li>
                                <li class="mb-2"><label class="fw-bold">City: </label> {{clientCompare.existingCityStateZip}}</li>
                                <li class="mb-2"><label class="fw-bold">Country: </label> {{clientCompare.existingCountry}}</li>
                                <li class="mb-2"><label class="fw-bold">Phone: </label> {{clientCompare.existingPhone}}</li>
                                <li class="mb-2"><label class="fw-bold">Birthday: </label> {{clientCompare.existingBirthDay}}</li>
                            </ul>
                        </div>
                        <div class="col-md-6">
                            <h3 class="fs-6 fw-bold mb-3">New Information:</h3>
                            <ul class="m-0 list-unstyled" >
                                <li class="mb-2"><label class="fw-bold"/></li>
                                <li class="mb-2"><label class="fw-bold">Name: </label> {{clientCompare.newClientFullName}}</li>
                                <li class="mb-2"><label class="fw-bold">Address: </label> {{clientCompare.newClientAddress}}</li>
                                <li class="mb-2"><label class="fw-bold">City: </label> {{clientCompare.newClientCityStateZip}}</li>
                                <li class="mb-2"><label class="fw-bold">Country: </label> {{clientCompare.newClientCountry}}</li>
                                <li class="mb-2"><label class="fw-bold">Phone: </label> {{clientCompare.newClientPhone}}</li>
                                <li class="mb-2"><label class="fw-bold">Birthday: </label> {{clientCompare.newClientBirthDay}}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="modal-footer d-flex">
                    <label class="fw-bold">Action to take:</label>
                    <select class="form-select w-50" id="clientAction" v-model="clientAction"  >
                        <option value="RETRIEVE" selected>Retrieve Customer Data</option>
                        <option value="UPDATE" >Update Customer Information</option>
                        <option value="CREATENEW" >Create a new record</option>
                    </select>
                    <button type="button" class="btn btn-primary ms-auto text-uppercase" @click="save">NEXT</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    name: "ExistingSimiliarClient",
    data() {
        return {
            clientAction: 'RETRIEVE',
            modal: null,
            clientCompare:{}
        }
    },
    mounted () {
        this.modal = new Modal(document.getElementById('modal-existing-similiar-client'));
    },
    methods: {
        show(clientCompareData) {
            console.log('modal');
            console.log(clientCompareData);
            this.clientCompare = clientCompareData;
            //this.confirmationText = text;
            this.modal.show();
        },
        save() {
            if(this.clientAction == "CREATENEW")
                this.$emit('confirmed', this.clientAction, null);
            else
                this.$emit('confirmed', this.clientAction, this.clientCompare.existingClientId);
            this.modal.hide();
        }
    }
}
</script>

<style scoped>

</style>