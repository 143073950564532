<template>
    <div class="modal fade" id="webcam-modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-md modal-fullscreen-lg-down">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-3">
                    <h5 class="modal-title">Take a picture</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body web-camera-container px-4 pt-0 pb-3">
                    <loading :show="isCameraOpen && isLoading"></loading>

                    <div class="row">
                        <div class="col-lg-12">
                            <div v-if="isCameraOpen" v-show="!isLoading" :class="{ flash: isShotPhoto }">
                                <div class="camera-shutter" :class="{ flash: isShotPhoto }"></div>
                                <video v-show="!isPhotoTaken" ref="camera" :width="450" :height="337.5" autoplay></video>
                                <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 mt-2 mb-1 px-2">
                            <div class="row px-2" v-if="isCameraOpen && !isPhotoTaken && !isLoading">
                                <button class="btn btn-sm btn-primary text-uppercase" @click="takePhoto">
                                    <i class="fas fa-camera button-icon"></i>
                                    <span>Take picture</span>
                                </button>
                            </div>
                            <div class="row px-2" v-if="isPhotoTaken && isCameraOpen">
                                <div class="col-lg-6 px-1 justify-content-center">
                                    <button class="btn btn-sm btn-primary text-uppercase upload-receipt-button" @click="confirmReceiptUpload">
                                        <i class="fas fa-check button-icon"></i>
                                        <span>Confirm</span>
                                    </button>
                                </div>
                                <div class="col-lg-6 px-1">
                                    <button class="btn btn-sm btn-primary text-uppercase upload-receipt-button picture-button" @click="resetPicture">
                                        <i class="fas fa-sync-alt button-icon"></i>
                                        <span>Try again</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
import Loading from '@/components/Loading';

export default {
    name: 'FindSender',
    components: {
        Loading,
    },
    props: {
        uploadedReceiptImageId: Number,
    },
    data() {
        return {
            modal: null,
            isCameraOpen: false,
            isPhotoTaken: false,
            isShotPhoto: false,
            isLoading: false,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('webcam-modal'));
        document.getElementById('webcam-modal').addEventListener('hidden.bs.modal', this.close);
    },
    methods: {
        show() {
            this.openCamera();
            this.modal.show();
        },
        close() {
            this.closeCamera();
            this.modal.hide();
        },
        openCamera() {
            if (!this.isCameraOpen) {
                this.isCameraOpen = true;
                this.createCameraElement();
            }
        },
        closeCamera() {
            if (this.isCameraOpen) {
                this.isCameraOpen = false;
                this.isPhotoTaken = false;
                this.isShotPhoto = false;
                this.stopCameraStream();
            }
        },
        createCameraElement() {
            this.isLoading = true;

            const constraints = (window.constraints = {
                audio: false,
                video: true,
            });

            navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;
                })
                .catch(() => {
                    this.isLoading = false;
                    this.$toast.error("May the browser didn't support or there is some errors.");
                });
        },
        stopCameraStream() {
            let tracks = this.$refs.camera.srcObject.getTracks();
            tracks.forEach((track) => {
                track.stop();
            });
        },
        takePhoto() {
            if (!this.isPhotoTaken) {
                this.isShotPhoto = true;

                const FLASH_TIMEOUT = 50;

                setTimeout(() => {
                    this.isShotPhoto = false;
                }, FLASH_TIMEOUT);
            }

            this.isPhotoTaken = !this.isPhotoTaken;

            const context = this.$refs.canvas.getContext('2d');
            context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
        },
        confirmReceiptUpload() {
            var receiptImageURL = document.getElementById('photoTaken').toDataURL('image/jpeg');
            this.$emit('onReceiptUploadConfirmation', receiptImageURL);
            this.close();
        },
        resetPicture() {
            this.isPhotoTaken = null;
        },
    },
};
</script>

<style lang="scss" scoped>
.button-icon {
    font-size: 1.1em;
    margin-right: 12px;
}

.web-camera-container {
    min-height: 375px;
}

.camera-shutter {
    opacity: 0;
    width: 450px;
    height: 337.5px;
    background-color: #fff;
    position: absolute;

    &.flash {
        opacity: 1;
    }
}

.upload-receipt-button {
    width: 100%;
    margin-top: 8px;
    bottom: 0;
}
</style>
