<template>
    <div class="modal" id="modal-cash-pickup-locations" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Locations</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-show="!loading" v-if="!data || data === null || data.length === 0">
                        <p>There is no location available for the service you selected. Please choose another service or contact the Inter Commercial team for more details.</p>
                    </div>
                    <div v-else class="table-responsive" v-show="!loading">
                        <input type="text" id="btnLocationToSearch" v-model="textFilter" @keyup="filter" class="form-control mb-3" placeholder="Type a location to search" />
                        <table class="table table-borderless">
                            <thead class="bg-light">
                                <tr class="">
                                    <th class="rounded-start">Location</th>
                                    <th>Address</th>
                                    <th>City</th>
                                    <th class="rounded-end">State</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in dataFiltered" :key="item.locationId" @click="select(index)" id="trLocationFound">
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.address }}
                                    </td>
                                    <td>
                                        {{ item.city }}
                                    </td>
                                    <td>
                                        {{ item.state }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
    name: 'CashPickupLocations',
    props: {
        payMethodId: {
            type: Number,
        },
        currencyOptionId: {
            type: Number,
        },
    },
    data() {
        return {
            modal: null,
            data: null,
            dataFiltered: null,
            loading: false,
            textFilter: '',
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-cash-pickup-locations'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get('/niloagent/createorder/payment/list/locations', {
                        params: {
                            payMethodId: this.payMethodId,
                            currencyOptionId: this.currencyOptionId,
                        },
                    })
                    .then((response) => {
                        this.data = response.data;
                        this.dataFiltered = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        select(index) {
            this.$emit('selected', this.dataFiltered[index]);
            this.modal.hide();
        },
        show() {
            this.data = null;
            this.textFilter = '';
            this.modal.show();
            this.load();
        },
        filter() {
            if (this.textFilter.length > 3) {
                this.dataFiltered = this.data.filter((item) => item.name.toLowerCase().includes(this.textFilter.toLowerCase()));
            } else {
                this.dataFiltered = this.data;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

tbody {
    tr:hover {
        background-color: lighten($primary, 40);
        cursor: pointer;
    }
}
</style>
