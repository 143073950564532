<template>
    <div class="modal" id="modal-confirm-order" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Confirm Order</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <h3 class="fs-6 fw-bold mb-3">By pressing the CONFIRM ORDER button, you acknowledge the following:</h3>
                    <ul class="m-0 list-unstyled">
                        <li class="mb-2">1. That we have complied with all rules and regulations of Inter&Co.</li>
                        <li class="mb-2">2. That we are in full compliance with all terms and provisions of the Money Transmitter Agent Agreement.</li>
                        <li class="mb-2">3. That we have followed all the know your customer and anti-money laundering procedures established by Inter&Co and by Law.</li>
                        <li class="mb-2">4. That we shall remit all funds due to Inter&Co as determined by our Contract.</li>
                    </ul>
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" id="confirmOrder" class="btn btn-primary text-uppercase" @click="confirm">Confirm Order</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase" @click="close">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    name: 'ConfirmOrder',
    data() {
        return {
            modal: null,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-confirm-order'));
    },
    methods: {
        confirm() {
            this.$emit('confirmed');
            this.modal.hide();
        },
        show() {
            this.modal.show();
        },
        close() {
            this.modal.hide();
        },
    },
};
</script>

<style scoped></style>
