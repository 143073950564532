<template>
    <div class="modal" id="modal-confirm-order" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 px-4">
                    <h5 class="modal-title">Confirm Order</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="card mb-2 mx-4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <h2 class="text-primary fs-6 fw-bold text-uppercase">Sender #{{ this.order.sender.clientId }}</h2>
                                </div>
                                <h3 class="fs-6 mb-3">{{ this.order.sender.fullName }}</h3>

                                <div class="row">
                                    <div class="col-md-2">
                                        <div><label class="fw-bold">Phone:</label></div>
                                    </div>

                                    <div class="col-md-10">
                                        <div>{{ this.order.sender.phone }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="d-flex">
                                    <h2 class="text-primary fs-6 fw-bold text-uppercase">RECIPIENT #{{ this.order.recipient.clientId }}</h2>
                                </div>
                                <h3 class="fs-6 mb-3">{{ this.order.recipient.fullName }}</h3>

                                <div class="row">
                                    <div class="row" v-show="this.order.recipient.cpfOrCnpj">
                                        <div class="col-md-3">
                                            <div><label class="fw-bold">CPF/CNPJ:</label></div>
                                        </div>

                                        <div class="col-md-9">
                                            <div>{{ this.order.recipient.cpfOrCnpj }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div><label class="fw-bold">Address:</label></div>
                                        </div>

                                        <div class="col-md-9">
                                            <div>{{ this.order.recipient.fullAddress }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-6">
                                <div class="d-flex">
                                    <h2 class="text-primary fs-6 fw-bold text-uppercase">Amount to be Collected from Sender</h2>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Payment Method:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ this.order.additionalInformation.payMethod == '0' ? '' : this.order.additionalInformation.payMethod }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Net Amount:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.amountSent).toFixed(2) }}</div>
                                    </div>
                                </div>
                                <div class="row" v-show="this.order.paymentInformation.debitCardSurcharge > 0">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Debit card surcharge:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.debitCardSurcharge).toFixed(2) }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Percent Fee:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.percentFee).toFixed(2) }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Flat Fee:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.flatFee).toFixed(2) }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Other Fees:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.otherFees).toFixed(2) }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Total Fees:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.totalFees).toFixed(2) }}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Total Collected:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.totalCollected).toFixed(2) }}</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <div class="d-flex">
                                    <h2 class="text-primary fs-6 fw-bold text-uppercase">Recipient Payment information</h2>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Service:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ this.order.paymentInformation.paymentMethodName }}</div>
                                    </div>
                                </div>

                                <div v-show="showBankDeposit">
                                    <div class="row" v-show="this.order.paymentInformation.bankName">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Bank Name:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.bankName }}</div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="this.order.paymentInformation.abaOrNo">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Bank ABA No:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.abaOrNo }}</div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="this.order.paymentInformation.branchNo">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Branch:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.branchNo }}</div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="this.order.paymentInformation.accountNumber">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Account No:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.accountNumber }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="showPIXInformation">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Pix Type Key:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.pixTypeId }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Pix Key:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.pixId }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="showBoletoInformation">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Boleto No.:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.boletoNumber }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="showCashInformation">
                                    <div class="row" v-show="this.order.paymentInformation.payLocationName">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Cash Pickup Location:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.payLocationName }}</div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="this.order.paymentInformation.recipientDocumentType">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Recipient Document Type:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.recipientDocumentType }}</div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="this.order.paymentInformation.recipientDocumentNumber">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Recipient Document Number:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.recipientDocumentNumber }}</div>
                                        </div>
                                    </div>
                                    <div class="row" v-show="this.order.paymentInformation.recipientDocumentExpiration">
                                        <div class="col-md-6">
                                            <div><label class="fw-bold">Recipient Document Expiration:</label></div>
                                        </div>
                                        <div class="col-md-6">
                                            <div>{{ this.order.paymentInformation.recipientDocumentExpiration }}</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-3">
                                    <div class="col-md-6">
                                        <div><label class="fw-bold">Exchange rate:</label></div>
                                        <div><label class="fw-bold">Amount to be paid:</label></div>
                                        <div><label class="fw-bold">Currency of payment:</label></div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>{{ parseFloat(this.order.paymentInformation.exchangeRate).toFixed(2) }}</div>
                                        <div class="fw-bold text-primary">${{ parseFloat(this.order.paymentInformation.amountReceived).toFixed(2) }}</div>
                                        <div>{{ this.order.paymentInformation.currencyDescription }} ({{ this.order.paymentInformation.currencyIsoCode }})</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-body px-4">
                    <h3 class="fs-6 fw-bold mb-3">By pressing the CONFIRM ORDER button, you acknowledge the following:</h3>
                    <ul class="m-0 list-unstyled">
                        <li class="mb-2">1. That we have complied with all rules and regulations of Inter&Co.</li>
                        <li class="mb-2">2. That we are in full compliance with all terms and provisions of the Money Transmitter Agent Agreement.</li>
                        <li class="mb-2">3. That we have followed all the know your customer and anti-money laundering procedures established by Inter&Co and by Law.</li>
                        <li class="mb-2">4. That we shall remit all funds due to Inter&Co as determined by our Contract.</li>
                    </ul>
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" class="btn btn-primary text-uppercase" v-show="!sending" @click="confirmOrder">Confirm Order</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase" v-show="!sending" @click="saveOrder">Save and finish later</button>
                    <button type="button" class="btn btn-sm btn-primary px-4" v-show="sending" :disabled="sending">Wait...</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
    name: 'ConfirmOrder',
    data() {
        return {
            data: null,
            modal: null,
            sending: false,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-confirm-order'));
    },
    methods: {
        confirmOrder() {
            this.$emit('confirm');
            this.sending = true;
            // this.modal.hide();
        },
        saveOrder() {
            this.$emit('save');
            this.sending = true;
            // this.modal.hide();
        },
        show() {
            this.modal.show();
        },
        close() {
            this.modal.hide();
            this.sending = false;
        },
    },
    computed: {
        order() {
            return this.$store.state.simpleOrder;
        },
        showBankDeposit() {
            return this.order.paymentInformation.paymentMethodName ?? this.order.paymentInformation.paymentMethodName.toUpper().includes('BANK DEPOSIT');
        },
        showPIXInformation() {
            return this.order.paymentInformation.paymentMethodId == 42;
        },
        showBoletoInformation() {
            return this.order.paymentInformation.paymentMethodId == 179;
        },
        showCashInformation() {
            return this.order.paymentInformation.paymentMethodName ?? this.order.paymentInformation.paymentMethodName.toUpper().includes('CASH');
        },
    },
};
</script>

<style scoped></style>
