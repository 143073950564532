<template>
    <div class="bg-login">
        <div class="gradient">
            <div class="container px-4 p-sm-0">
                <div class="row justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-10">
                        <div class="text-center mt-5">
                            <img src="@/assets/img/logo-white-inter.png" class="logo w-100 pb-5" alt="Pontual Money" />
                        </div>
                        <div class="card">
                            <div class="card-body p-4">
                                <div class="">
                                    <div class="alert alert-warning text-center">
                                        <p>Please change you <span class="fw-bold">password</span>.</p>
                                        It must be different from the one previously used and contain <span class="fw-bold">at least 6 characters and/or numbers</span>.
                                    </div>
                                    <div class="alert alert-danger" v-show="hasError">
                                        {{ error }}
                                    </div>
                                    <div class="row justify-content-center mt-4">
                                        <div class="col-xl-8 col-md-12 d-flex flex-column justify-content-end">
                                            <div class="mb-2">
                                                <label for="username" class="form-label">Current Password</label>
                                                <input type="password" v-model="currentPassword" class="form-control" :disabled="sending" />
                                            </div>

                                            <div class="mb-2">
                                                <label for="username" class="form-label">New Password</label>
                                                <input type="password" v-model="newPassword" class="form-control" :disabled="sending" />
                                            </div>

                                            <div class="mb-2">
                                                <label for="password" class="form-label">Confirm Password</label>
                                                <input type="password" v-model="confirmPassword" class="form-control" :disabled="sending" />
                                            </div>

                                            <button class="btn btn-primary text-uppercase mt-4 align-middle" @click="changePassword" :disabled="sending">
                                                {{ sending ? 'Wait...' : 'Change Password' }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer text-center border-0">AMS Test</div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="position-fixed w-100 px-4 py-4 d-none d-sm-block">
                <div class="row align-items-center">
                    <div class="col-lg-9 text-md-start text-center">
                        <p class="fw-bold mb-1">Pontual MoneyTransfer Agent Interface</p>
                        <p class="mb-3 mb-md-0">This Computer System is property of Inter. It is for authorized use only. Unauthorized or improper use of this system is forbidden and may result in civil and criminal prosecution.</p>
                    </div>
                    <div class="col-lg-3 text-md-end text-center">PontualMoney © 2022 - All rights reserved</div>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'NewPassword',
    data() {
        return {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            sending: false,
            error: null,
        };
    },
    methods: {
        async changePassword() {
            this.sending = true;
            try {
                const response = await axios.post('/niloagent/home/updatepassword', {
                    currentPassword: this.currentPassword,
                    newPassword: this.newPassword,
                    confirmationPassword: this.confirmPassword,
                });

                if (response.status === 200) {
                    this.$store.commit('auth/setChangePassword', false);
                    await this.$router.push({ name: 'Home' });
                }
            } catch (error) {
                this.error = error.response.data.Message;
            }
            this.sending = false;
        },
    },
    computed: {
        hasError() {
            return this.error;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

.card {
    margin-bottom: 100px;
}

svg {
    margin-right: 5px;
}

.logo {
    max-width: 168px;
}
.gradient {
    background-color: $primary;
    min-height: 100vh;
}

footer {
    bottom: 0;
    font-size: 10px;
    background-color: $light;
}
</style>
