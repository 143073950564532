<template>
    <span v-if="balance">
        <a class="nav-link dropdown-toggle text-uppercase p-0" href="#" data-bs-toggle="dropdown">
            <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" aria-label="Basic example" :style="{ width: percent + '%' }" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{ percent }}%</div>
            </div>
            <div class="text-end mt-1 fw-bold"><span class="ms-2">${{ balance.coBalanceFormated }} USD</span></div>
        </a>
        <div class="dropdown-menu dropdown-menu-end p-3">
            <div class="d-flex">
                <div class="text-gray">Balance:</div>
                <div class="ms-auto fw-bold text-primary">${{ balance.coBalanceFormated }}</div>
            </div>
            <hr class="my-2">
            <div class="d-flex">
                <div class="text-gray">Operational Limit:</div>
                <div class="ms-auto fw-bold text-primary">${{ balance.coCreditLimitFormated }}</div>
            </div>
            <hr class="my-2">
            <div class="d-flex">
                <div class="text-gray">Available Operational Limit:</div>
                <div class="ms-auto fw-bold text-primary">${{ balance.coCreditAvailableFormated }}</div>
            </div>
            <hr class="my-2">
            <button type="button" @click="refresh" class="btn btn-sm btn-light w-100" :disabled="loading"><i class="fas fa-sync-alt"></i> {{ loading ? 'Wait...' : 'Refresh' }}</button>
        </div>
    </span>
</template>

<script>

export default {
    name: "Balance",
    methods: {
        async refresh(e) {
            e.stopPropagation();
            await this.$store.dispatch('getAccountingBalance');
        }
    },
    computed: {
        loading() {
            return this.$store.state.balance.loading;
        },
        balance() {
            return this.$store.state.balance.data;
        },
        percent() {
            return this.balance ? Math.round((-1 * this.balance.coBalance / this.balance.coCreditLimit) * 100) : 0;
        }
    }
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
    width: 320px;
}
.dropdown-toggle::after {
    content: none;
}
.progress {
    width:150px;
    height: .85rem;
    font-size: .7rem;
}
</style>