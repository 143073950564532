<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4">
                <h1 id="pageTitle" class="page-title text-primary text-uppercase">Tasks: Payment Hold</h1>
                <div class="d-flex justify-content-center mt-4">
                    <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="data !== null && data.length > 0" class="mb-5">
                    <div class="table-responsive">
                        <table class="table table-borderless caption-top">
                            <caption>
                                <span class="fw-bold">{{ data.length }}</span>
                                payments
                            </caption>
                            <thead class="bg-light">
                                <tr>
                                    <th class="text-center rounded-start"><input type="checkbox" class="form-check-input m-0" v-model="checkAll" @click="toggleCheckbox" value="true" /></th>
                                    <th>Payment ID</th>
                                    <th>Date Received</th>
                                    <th>Company</th>
                                    <th>Person</th>
                                    <th>Amount</th>
                                    <th>Form of Payment</th>
                                    <th>Bank Account</th>
                                    <th>Agent Reference</th>
                                    <th>Image</th>
                                    <th>Date Posted</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.paymentId">
                                    <td class="text-center"><input class="form-check-input m-0" type="checkbox" v-model="selected" name="check" :value="item.paymentId" /></td>
                                    <td>
                                        {{ item.paymentId }}
                                    </td>
                                    <td>
                                        {{ item.dateReceived }}
                                    </td>
                                    <td>
                                        {{ item.company }}
                                    </td>
                                    <td>
                                        {{ item.person }}
                                    </td>
                                    <td>
                                        {{ item.amount }}
                                    </td>
                                    <td>
                                        {{ item.formOfPayment }}
                                    </td>
                                    <td>
                                        {{ item.bankAccount }}
                                    </td>
                                    <td>
                                        {{ item.agentReference }}
                                    </td>
                                    <td>
                                        <a :href="item.image" target="_blank">
                                            <i class="fas fa-file"></i>
                                        </a>
                                    </td>
                                    <td>
                                        <input type="text" v-model="item.datePosted" class="form-control form-control-sm" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else id="noOrderFound" class="alert alert-warning" v-show="data !== null && data.length === 0">No order found.</div>
            </div>
            <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                <div class="container text-end d-flex">
                    <div class="me-auto d-inline-flex">
                        <button type="button" @click="reject" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3" :disabled="saving">Reject</button>
                        <button type="button" @click="approve" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3" :disabled="saving">Approve</button>
                    </div>
                </div>
            </footer>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';

export default {
    name: 'Payment',
    components: {
        Layout,
    },
    data() {
        return {
            data: null,
            loading: false,
            selected: [],
            checkAll: false,
            saving: false,
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            this.data = null;
            this.selected = [];
            axios
                .get('/niloagent/FinancialTask/PaymentApprovalList')
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    this.data = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        approve() {
            this.saving = true;
            axios
                .post('/niloagent/FinancialTask/approvepaymenthold', {
                    ordersToBeApproved: this.selectedValues,
                })
                .then((response) => {
                    this.$toast.success(response.data);
                    this.load();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        reject() {
            this.saving = true;

            console.log('3. ', this.selectedValues);

            axios
                .post('/niloagent/FinancialTask/rejectpaymenthold', {
                    ordersToBeApproved: this.selectedValues,
                })
                .then((response) => {
                    this.$toast.success(response.data);
                    this.load();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        toggleCheckbox() {
            if (!this.checkAll) {
                this.selected = this.data.map((el) => el.orderId);
            } else {
                this.selected = [];
            }
        },
    },
    computed: {
        selectedValues() {
            return this.selected.map((value) => {
                let selectedItem = undefined;
                this.data.forEach((item) => {
                    if (item.paymentId === value) {
                        selectedItem = item;
                    }
                });

                if (selectedItem) {
                    let datePosted = selectedItem.datePosted;
                    let paymentId = selectedItem.paymentId;
                    return {
                        paymentId,
                        datePosted,
                    };
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
footer {
    z-index: 100;
}
table tr td {
    white-space: pre-line;
}
</style>
