<template>
    <div class="card border-0 shadow position-relative">
        <div class="card-body">
            <div class="input-group input-group-lg mb-3">
                <input type="number" v-model="paymentAmount" @blur="getRates" class="form-control" />
                <span class="input-group-text">USD</span>
            </div>
            <select-country :data="countries" v-model="country" @change="getRates" class="form-select-lg mb-3"></select-country>
            <div class="d-flex">
                <span class="fw-bold">Exchange rate</span>
                <span class="ms-auto" v-if="data">USD {{ data.exchangeRate }}</span>
            </div>
            <div class="d-flex mt-2">
                <span class="fw-bold">Estimated Fee</span>
                <span class="ms-auto" v-if="data">USD {{ data.totalFees }}</span>
            </div>
            <hr />
            <h4 class="text-center text-primary" v-if="data && !loading">{{ data.currencyIsoCode }} {{ data.amountPaid }}</h4>
            <loading :show="loading"></loading>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import SelectCountry from '@/components/SelectCountry';

export default {
    name: 'Calculator',
    components: {
        Loading,
        SelectCountry,
    },
    data() {
        return {
            country: 0,
            paymentAmount: 1,
            exchangeRate: 0,
            tax: 0,
            loading: false,
            data: null,
        };
    },
    async mounted() {
        await this.loadCountries();
        this.country = 42;
        this.getRates();
    },
    methods: {
        async loadCountries() {
            try {
                if (this.countries.length === 0) {
                    const response = await axios.get('/niloagent/createorder/filter/destinycountries');

                    if (response.status === 200 && response.data.length > 0) {
                        this.$store.commit('setCalculatorCountries', response.data);
                    }
                }
            } catch (error) {
                this.$toast.error(`country: ${error.response.data.Message}`);
            }
        },
        getRates() {
            if (this.country > 0) {
                this.loading = true;
                axios
                    .get(`/niloagent/prereceipt/getrates?country=${this.country}&amount=${this.paymentAmount}`)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.data = null;
                        this.$toast.error(error.response.data.Message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
        total() {
            return this.paymentAmount * this.data.exchangeRate;
        },
        countries() {
            return this.$store.state.calculator.countries;
        },
    },
};
</script>

<style lang="scss" scoped></style>
