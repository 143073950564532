<template>
    <layout>
        <nav class="navbar navbar-expand navbar-dark bg-primary small">
            <div class="container">
                <img src="@/assets/img/logo-white-inter.png" class="logo w-100" alt="Pontual Money" />
            </div>
        </nav>

        <div class="container pb-4 mt-4" v-if="sent">
            <div v-show="sent" class="text-center">
                <div class="alert alert-primary">Go back to the system and click on the “CONFIRM UPLOAD” button to receive the payment receipt you just sent</div>
            </div>
        </div>
        <div class="container" v-if="!sent">
            <h1 class="page-title text-primary text-uppercase mt-3 mx-3">Agent Receipt Upload</h1>

            <div class="row small-row justify-content-center mt-3 mx-1">
                <div class="col-lg-4">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-12 mb-2">
                                    <div class="mt-2">
                                        <span class="form-label section-label">Receipt image</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body image-card">
                            <div class="row mt-2">
                                <div class="col-md-12">
                                    <img class="no-image-icon" src="@/assets/img/no-image-icon.svg" alt="No image was uploaded" v-if="!capturedImage" />
                                    <img class="receipt-image" :src="capturedImage" alt="Captured Image" v-if="capturedImage" />
                                </div>
                            </div>
                            <div class="row position-absolute fixed-bottom mb-3 mx-1 d-flex justify-content-center">
                                <div class="row">
                                    <div class="col-lg-6 px-1">
                                        <button class="btn btn-sm btn-primary text-uppercase upload-receipt-button" @click="openGallery">
                                            <i class="fas fa-upload button-icon"></i>
                                            <span>Browse</span>
                                        </button>
                                    </div>
                                    <div class="col-lg-6 px-1">
                                        <button class="btn btn-sm btn-primary text-uppercase upload-receipt-button picture-button" @click="openCamera">
                                            <i class="fas fa-camera button-icon"></i>
                                            <span>Take a picture</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row position-absolute fixed-bottom mb-5 mx-3 d-flex justify-content-center">
                <button type="button" @click="send" class="btn btn-sm btn-primary text-uppercase mt-4" :disabled="!capturedImage || sending">{{ sending ? 'Wait...' : 'Send Receipt' }}</button>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Main';
import axios from 'axios';

export default {
    name: 'ReceiptQrCodeUpload',
    components: {
        Layout,
    },
    props: {
        token: {
            type: String,
        },
    },
    data() {
        return {
            sending: false,
            sent: false,
            capturedImage: null,
            file: null,
        };
    },
    mounted() {},
    methods: {
        send() {
            this.sending = true;

            if (this.file == null) {
                this.sending = false;
                alert('Please take a picture of the receipt or upload an image.');
            } else {
                const formData = new FormData();
                formData.append('file', this.file);

                axios
                    .post('/auth/payment/uploadreceipt', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        params: {
                            fileName: this.token,
                        },
                    })
                    .then(() => {
                        this.sending = false;
                        this.sent = true;
                        this.capturedImage = null;
                        this.file = null;
                    })
                    .catch(() => {
                        this.sending = false;
                        alert('Error uploading receipt.');
                    });
            }
        },
        openCamera() {
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.type = 'file';
            input.accept = 'image/*,.heif,.heic';
            input.capture = 'camera';
            input.addEventListener('change', (event) => {
                this.file = event.target.files[0];
                this.capturedImage = URL.createObjectURL(this.file);
            });
            input.click();
            document.body.removeChild(input);
        },
        openGallery() {
            const input = document.createElement('input');
            document.body.appendChild(input);
            input.type = 'file';
            input.accept = 'image/*';
            input.addEventListener('change', (event) => {
                this.file = event.target.files[0];
                this.capturedImage = URL.createObjectURL(this.file);
            });
            input.click();
            document.body.removeChild(input);
        },
    },
};
</script>

<style scoped>
.image-card {
    height: 60vh;
}

.receipt-image {
    max-width: 95%;
    max-height: 450px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(0, 0, 0, 0.5);
}

.no-image-icon {
    width: 90%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    filter: invert(74%) sepia(9%) saturate(0%) hue-rotate(185deg) brightness(98%) contrast(97%);
}

.take-a-picture-button {
    width: 93%;
    bottom: 0;
}

.upload-text {
    margin-top: 5px;
}

.upload-receipt-button {
    width: 100%;
    margin-top: 8px;
    bottom: 0;
}
</style>
