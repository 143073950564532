import { render, staticRenderFns } from "./UpdateRate.vue?vue&type=template&id=092af004&scoped=true&"
import script from "./UpdateRate.vue?vue&type=script&lang=js&"
export * from "./UpdateRate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092af004",
  null
  
)

export default component.exports