<template>
    <span class="avatar bg-white rounded-circle text-primary"><slot></slot></span>
</template>

<script>
export default {
    name: "Avatar"
}
</script>

<style scoped>
.avatar {
    height: 35px;
    width: 35px;
    font-size: 14px;
    line-height: 24px;
    font-weight: bolder;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    font-style: normal;
}
</style>