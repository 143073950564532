<template>
    <layout>
        <div class="container pb-4">
            <h1 class="page-title text-primary mb-4 text-uppercase">Upload Accounting Files</h1>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label for="fileTypeId" class="form-label">File Type</label>
                            <select class="form-select" v-model="fileTypeId" id="fileTypeId">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in filetypes" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>                        
                    </div>
                </div>
                <div class="card-body border-top">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <label for="companyAccount" class="form-label">Company Account</label>
                            <select class="form-select" v-model="companyAccountId" id="companyAccount">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in companyAccounts" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="card-body border-top">
                    <div class="row">                        
                        <div class="col-md-4 mb-3">
                            <label class="form-label">File Location</label>
                        </div>
                        <div class="flex-column justify-content-end">
                            <div class="btn-group">
                                <button type="button" class="btn btn-outline-primary rounded"><i class="fas fa-upload"></i> Choose File</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" @click="send" class="btn btn-sm btn-primary text-uppercase" :disabled="sending">{{ sending ? 'Wait...' : 'Upload' }}</button>
        </div>
    </layout>
</template>

<script>
import axios from "axios"
import Layout from "@/views/layouts/Main"

export default {
    name: "UploadPayments",
    components: {
        Layout,
    },
    data() {
        return {
            fileTypeId: 0,
            companyAccountId: 0,
            data: null,
            sending: false,
            filetypes: [],
            companyAccounts: []
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            axios
                .get('/niloagent/accounting/uploadpaymentsoptions')
                .then((response) => {
                    this.filetypes = response.data.fileType;
                    this.companyAccounts = response.data.companyAccounts;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        send() {
            this.sending = true;
        }
    }
}
</script>

<style scoped>

</style>