import axios from 'axios';

const SESSION_STORAGE_KEY = 'orderPendingDocs';
const SESSION_STORAGE_TTL_KEY = 'orderPendingDocsTTL';
const MILLISECONDS_TO_HOUR = 1000 * 60 * 60

export async function getOrderPendingDocs() {
    if (sessionStorage.getItem(SESSION_STORAGE_KEY) && !isExpired()) {
        return JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY));
    }

    const orderPendingDocs = await requestOrderPendingDocs();
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(orderPendingDocs));
    sessionStorage.setItem(SESSION_STORAGE_TTL_KEY, new Date().getTime() + 1 * MILLISECONDS_TO_HOUR);
    return orderPendingDocs;
}

export function refreshOrderPendingDocs() {
    sessionStorage.removeItem(SESSION_STORAGE_KEY);
    sessionStorage.removeItem(SESSION_STORAGE_TTL_KEY);
}

function isExpired() {
    return new Date().getTime() > sessionStorage.getItem(SESSION_STORAGE_TTL_KEY);
}

async function requestOrderPendingDocs() {
    try {
        const response = await axios.get(`/niloagent/TaskController/ComplianceHold`);
        return response.data.length;
    } catch {
        return 0;
    }
}