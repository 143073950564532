<template>
    <div class="modal" id="modal-pix-keys" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Pix Keys</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <loading :show="showLoading"></loading>
                    <div class="alert alert-warning" v-show="hasNoPixKey || (data && !data.length)">No record was found</div>
                    <div class="table-responsive" v-show="!hasNoPixKey && data && !loading && data.length > 0">
                        <table class="table table-borderless">
                            <thead class="bg-light">
                                <tr class="">
                                    <th class="rounded-start">Key</th>
                                    <th class="rounded">Type</th>
                                    <th class="rounded-end">Added on</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in data" :key="index" @click="select(index)" id="pixKey">
                                    <td width="33%">
                                        {{ item.pixId }}
                                    </td>
                                    <td width="33%">
                                        {{ item.pixTypeDescription }}
                                    </td>
                                    <td width="33%">
                                        {{ item.updatedOn }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import Loading from '@/components/Loading';

export default {
    name: 'PixKeys',
    props: {
        recipientId: {
            type: Number,
        },
        hasNoPixKey: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Loading,
    },
    data() {
        return {
            modal: null,
            data: null,
            loading: false,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-pix-keys'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`/niloagent/client/${this.recipientId}/pix-keys`)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        select(index) {
            this.$emit('selected', this.data[index]);
            this.modal.hide();
        },
        show() {
            this.data = null;
            this.modal.show();
            !this.hasNoPixKey && this.load();
        },
    },
    computed: {
        showLoading: function () {
            return this.loading && !this.hasNoPixKey;
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

tbody {
    tr:hover {
        background-color: lighten($primary, 40);
        cursor: pointer;
    }
}
</style>
