<template>
    <div class="card">
        <div class="card-header fs-6 fw-bold text-black-50">
            Financial
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnFinancialHoldList1'" to="/tasks/financial-list" class="text-decoration-none">Financial Hold</router-link></div>
            <button :name="'btnFinancialHoldCount1'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getFinancialHoldCount"><i class="far fa-eye m-0"></i><span v-show="showFinancialHoldCount" class="ms-2">{{ financialHoldCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnUsendHoldList'" to="/tasks/usend-list" class="text-decoration-none">USEND Hold</router-link></div>
            <button :name="'btnUsendHoldCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getUsendHoldCount"><i class="far fa-eye m-0"></i><span v-show="showUsendHoldCount" class="ms-2">{{ usendHoldCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex">
            <div class="my-2"><router-link :name="'btnAgentPaymentApprovalList'" to="/tasks/payment-list" class="text-decoration-none">Agent Payment Approval</router-link></div>
            <button :name="'btnAgentPaymentApprovalCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getAgentPaymentApprovalCount"><i class="far fa-eye m-0"></i><span v-show="showAgentPaymentApprovalCount" class="ms-2">{{ agentPaymentApprovalCount }}</span></button>
        </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "FinancialCard",
    data() {
        return {
            financialHoldCount: 0,
            showFinancialHoldCount: false,

            usendHoldCount: 0,
            showUsendHoldCount: false,

            agentPaymentApprovalCount: 0,
            showAgentPaymentApprovalCount: false,
        }
    },
    methods: {
        getFinancialHoldCount() {
            axios
                .get('/niloagent/ComplianceTask/FinanceHoldCount')
                .then(response => {
                    this.financialHoldCount = response.data;
                    this.showFinancialHoldCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        getUsendHoldCount() {
            axios
                .get('/niloagent/ComplianceTask/UsendHoldCount')
                .then(response => {
                    this.usendHoldCount = response.data;
                    this.showUsendHoldCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        getAgentPaymentApprovalCount() {
            axios
                .get('/niloagent/FinancialTask/paymentholdcount')
                .then(response => {
                    this.agentPaymentApprovalCount = response.data;
                    this.showAgentPaymentApprovalCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                });
        },
    }
}
</script>

<style scoped>

</style>