<template>
    <layout :show-navbar-links="false">
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-4 text-uppercase">Disclosure & Acknowledgment</h1>
            <div class="row justify-content-center mt-5">
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body p-md-5">
                            <p class="mb-4">By clicking on the <span class="fst-italic text-secondary">checkbox</span>, I acknowledge:</p>
                            <div class="form-check mb-5">
                                <input class="form-check-input me-3" v-model="checkAMLComplianceProgram" type="checkbox" id="checkAMLComplianceProgram" />
                                <label class="form-check-label d-md-table-cell" for="checkAMLComplianceProgram"> Receiving from <span class="fw-bolder">Inter</span> the most recent and updated version of the <span class="fw-bold">AML Compliance Program - Agents Guide</span> available under "Compliance Docs" button in the system. </label>
                            </div>
                            <p class="mb-4">By clicking on the <span class="fst-italic text-secondary">checkbox</span>, I acknowledge the following:</p>
                            <div class="form-check">
                                <input class="form-check-input me-3" v-model="checkPMT023" type="checkbox" id="checkPMT-023" />
                                <label class="form-check-label d-md-table-cell" for="checkPMT-023"> I read, understood and will comply with the updated policies and procedures stated in <span class="fw-bold">Circular PMT-023</span> sent by email from <span class="fw-bolder">Inter</span>. </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center text-center mt-3 mt-md-5">
                <div class="col-md-3">
                    <button type="button" @click="submit" class="btn btn-primary text-uppercase w-100" :disabled="disableSubmit">{{ sending ? 'Wait...' : 'Submit' }}</button>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';

export default {
    name: 'Disclosure',
    components: {
        Layout,
    },
    data() {
        return {
            sending: false,
            checkPMT023: false,
            checkAMLComplianceProgram: false,
        };
    },
    methods: {
        async submit() {
            try {
                this.sending = true;
                const response = await axios.post('/niloagent/home/acceptdisclosure');

                if (response.status === 200) {
                    this.updateAgent();
                }
            } catch (error) {
                console.log(error);
                this.sending = false;
            }
        },
        async updateAgent() {
            try {
                const response = await axios.get('/niloagent/home/agent');

                if (response.status === 200 && response.data) {
                    this.sending = false;
                    this.$store.commit('agent/setAgent', response.data);
                    this.$router.push({ name: 'Home' });
                }
            } catch (error) {
                console.log(error);
                this.sending = false;
            }
        },
    },
    computed: {
        disableSubmit() {
            return !(this.checkPMT023 && this.checkAMLComplianceProgram) || this.sending;
        },
    },
};
</script>

<style scoped></style>
