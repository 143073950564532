<template>
    <layout navigation-label="Recipient">
        <template v-slot:content>
            <h1 class="page-title text-primary mb-4 text-uppercase">Find Recipient</h1>
            <form autocomplete="off">
                <div class="row justify-content-center mb-3">
                    <div class="col-md-4 mb-2">
                        <label for="fullName" class="form-label">Full Name (First and Last Name)</label>
                        <input autocomplete="none" type="text" v-model="fullName" @keyup.enter="load" class="form-control" placeholder="" />
                    </div>
                    <div class="col-md-4 mb-2">
                        <label for="companyName" class="form-label">Company Name</label>
                        <input autocomplete="none" type="text" v-model="companyName" @keyup.enter="load" class="form-control" placeholder="" />
                    </div>
                    <div class="col-md-2 mb-2">
                        <label for="senderTelephone" class="form-label">Beneficiary Phone</label>
                        <input autocomplete="none" type="text" v-model="telephone" @keyup.enter="load" class="form-control" placeholder="" />
                    </div>
                    <div class="col-md-2 mb-2">
                        <label for="clientId" class="form-label">Customer Number</label>
                        <input autocomplete="none" type="text" v-model="clientId" @keyup.enter="load" class="form-control" id="clientId" placeholder="" />
                    </div>
                </div>
            </form>
            <div class="d-flex">
                <button type="button" @click="load" class="btn btn-primary btn-sm text-uppercase" :disabled="loading" id="findRecipient"><i class="fas fa-search"></i><span v-show="!loading">Find Recipient</span><span v-show="loading">Waiting...</span></button>
                <button type="button" @click="clearFields" class="btn btn-outline-primary btn-sm bg-white text-uppercase ms-3">Clear all</button>
            </div>
            <loading :show="loading" />
            <div class="alert alert-danger mt-4" v-if="error != null">
                {{ error.Message ? error.Message : error.title }}
                <span class="fw-bold" v-show="error.status">({{ error.status }})</span>
            </div>
            <div class="alert alert-warning mt-4" v-show="data !== null && data.length === 0">No Beneficiary Was Found using the criteria specified.</div>
            <div v-if="data !== null && data.length > 0">
                <div class="divider"></div>
                <h1 class="page-title text-primary mb-4 text-uppercase" id="resultsFound">Results Found</h1>
                <div class="table-responsive">
                    <table class="table table-borderless table-responsive">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Client</th>
                                <th scope="col">Doc number</th>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Address</th>
                                <th scope="col" width="30%">Bank Information</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.clientId">
                                <td><input autocomplete="none" class="form-check-input border-primary" name="radio" v-model="selectedId" type="radio" :value="item.clientId" id="foundItem" /></td>
                                <td>{{ item.clientId }}</td>
                                <td>{{ item.cpfOrCnpj }}</td>
                                <td>
                                    {{ item.fullName }}
                                    <br />
                                    <b>{{ item.company }}</b>
                                </td>
                                <td>{{ item.phone }}</td>
                                <td>
                                    <div>{{ item.address }}</div>
                                </td>
                                <td width="30%">
                                    <div v-if="item.bankAccounts.length > 0" class="bank-account">
                                        <p class="mb-1">{{ item.bankAccounts[0].bankName }}</p>
                                        <span v-show="item.bankAccounts[0].branchNo"><span class="fw-bold">Agency:</span> {{ item.bankAccounts[0].branchNo }}</span> /
                                        <span v-show="item.bankAccounts[0].accountNumber"><span class="fw-bold">Account NO:</span> {{ item.bankAccounts[0].accountNumber }}</span>
                                        <div v-if="item.bankAccounts.length > 1" class="mt-1">
                                            <button type="button" @click="openBankAccountsModal(item.clientId)" class="btn btn-sm btn-link text-primary fw-bold p-0">show more ({{ item.bankAccounts.length }})</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <router-link to="/order/create/sender" class="btn btn-outline-primary text-uppercase mt-4 me-3"><i class="fas fa-arrow-left me-2"></i> Back</router-link>
            <button type="button" id="btnNext" class="btn btn-primary text-uppercase mt-4" @click="next" :disabled="!recipientSelected">Next <i class="fas fa-arrow-right ms-2"></i></button>
            <modal-bank-accounts ref="modalBankAccounts"></modal-bank-accounts>
        </template>
        <template v-slot:footer>
            <router-link to="/order/create/recipient" :name="'btnNewRecipient'" class="btn btn-primary btn-sm"><i class="fas fa-plus-circle"></i> New Recipient</router-link>
        </template>
    </layout>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import Layout from '@/views/layouts/CreateOrder';
import ModalBankAccounts from '@/views/order/modals/BankAccounts';

export default {
    name: 'FindRecipient',
    components: {
        Layout,
        Loading,
        ModalBankAccounts,
    },
    data() {
        return {
            data: null,
            fullName: '',
            clientId: '',
            telephone: '',
            companyName: '',
            selectedId: null,
            loading: false,
            error: null,
            pagination: {
                pageNumber: 1,
            },
        };
    },
    mounted() {
        const senderId = this.$store.state.order.sender.clientId;
        this.selectedId = this.order.recipient ? this.order.recipient.clientId : null;
        this.load(senderId);
    },
    methods: {
        load(senderId = 0) {
            this.loading = true;
            this.error = null;
            this.data = null;

            const url = senderId > 0 ? `/niloagent/createorder/find/sender/${senderId}/recipients` : '/niloagent/createorder/find/recipient';

            axios
                .get(url, {
                    params: {
                        ClientId: this.clientId,
                        CompanyName: this.companyName,
                        FullName: this.fullName,
                        SdPhone1: this.telephone,
                        pageNumber: this.pagination.pageNumber,
                    },
                })
                .then((response) => {
                    this.data = response.data.result;
                    this.pagination = (({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }) => ({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }))(response.data);
                })
                .catch((error) => {
                    this.data = null;
                    this.error = error.response.data;
                })
                .finally(() => {
                    this.loading = false;
                    if (this.data !== null) this.scrollUp();
                });
        },
        next() {
            this.$router.push({ name: 'Recipient', params: { recipientId: this.selectedId } });
        },
        scrollUp() {
            document.getElementById('resultsFound').scrollIntoView();
        },
        scrollDown() {
            document.getElementById('btnNext').scrollIntoView();
        },
        clearFields() {
            this.fullName = '';
            this.clientId = '';
            this.telephone = '';
            this.companyName = '';
            this.senderId = 0;
        },
        openBankAccountsModal(recipientId) {
            this.$refs.modalBankAccounts.$props.recipientId = recipientId;
            this.$refs.modalBankAccounts.show();
        },
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        recipientSelected() {
            return this.selectedId > 0 || this.order.recipient !== null;
        },
    },
    watch: {
        selectedId(value) {
            if (this.order.recipient && value !== this.order.recipient.clientId) this.$store.commit('order/setPaymentInformation', null);

            if (value > 0) this.scrollDown();
        },
    },
};
</script>

<style lang="scss" scoped>
@import './src/assets/scss/variables';

.bank-account {
    button {
        font-size: 0.7rem;
    }
}
</style>
