<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Compliance Forms</h1>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="mb-4 col-3">
                            <a :href="pdfCustomerIdentificationProgramNotice_EnglishLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Customer Identification Program Notice - English</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfCustomerIdentificationProgramNotice_PortugueseLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> Customer Identification Program Notice - Portuguese</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfCustomerAssistancePolicy_EnglishLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> Customer Assistance Policy - English</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfCustomerAssistancePolicy_PortugueseLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> Customer Assistance Policy - Portuguese</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfPrivacyPolicy_EnglishLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> Privacy Policy - English</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfPrivacyPolicy_PortugueseLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Privacy Policy - Portuguese</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfElderAbusePreventionPosterLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> Elder Abuse Prevention Notice</template>
                                </MenuButton>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Main';
import MenuButton from '@/components/MenuButton';
import pdfCustomerIdentificationProgramNotice_English from '@/assets/pdf/compliance/signs/CustomerIdentificationProgramNoticeEnglish.pdf';
import pdfCustomerIdentificationProgramNotice_Portuguese from '@/assets/pdf/compliance/signs/CustomerIdentificationProgramNoticePortuguese.pdf';
import pdfCustomerAssistancePolicy_English from '@/assets/pdf/compliance/signs/CustomerAssistancePolicyEnglish.pdf';
import pdfCustomerAssistancePolicy_Portuguese from '@/assets/pdf/compliance/signs/CustomerAssistancePolicyPortuguese.pdf';
import pdfPrivacyPolicy_Portuguese from '@/assets/pdf/compliance/signs/PrivacyPolicyPortuguese.pdf';
import pdfPrivacyPolicy_English from '@/assets/pdf/compliance/signs/PrivacyPolicyEnglish.pdf';
import pdfElderAbusePreventionPoster from '@/assets/pdf/compliance/signs/ElderAbusePreventionPoster.pdf';

export default {
    name: 'FormsMenu',
    components: {
        Layout,
        MenuButton,
    },
    data() {
        return {
            pdfCustomerIdentificationProgramNotice_EnglishLink: pdfCustomerIdentificationProgramNotice_English,
            pdfCustomerIdentificationProgramNotice_PortugueseLink: pdfCustomerIdentificationProgramNotice_Portuguese,
            pdfCustomerAssistancePolicy_EnglishLink: pdfCustomerAssistancePolicy_English,
            pdfCustomerAssistancePolicy_PortugueseLink: pdfCustomerAssistancePolicy_Portuguese,
            pdfPrivacyPolicy_EnglishLink: pdfPrivacyPolicy_English,
            pdfPrivacyPolicy_PortugueseLink: pdfPrivacyPolicy_Portuguese,
            pdfElderAbusePreventionPosterLink: pdfElderAbusePreventionPoster,
        };
    },
};
</script>

<style scoped></style>
