<template>
    <button class="text-primary" @click="onClick">
        <slot name="icon"></slot>
        <span class="text-uppercase"><slot name="label"></slot></span>
    </button>
</template>

<script>
export default {
    name: "MenuButton",
    methods: {
        onClick() {
            this.$emit('click');
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    border: 0;
    width: 100%;
    padding: 20px 10px;
    height: 100%;
    border-radius: 10px;
    font-weight: 600;
    font-size: 2rem;
    background: linear-gradient(135deg, #E6E6E6 0%, #FEFEFE 100%);
    box-shadow: -5px 5px 10px rgba(218, 218, 218, 0.2), 5px -5px 10px rgba(218, 218, 218, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 13px rgba(218, 218, 218, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(218, 218, 218, 0.5);

    &:hover {
        background: linear-gradient(135deg, #c4c4c4 0%, #e3e3e3 100%);
    }

    span {
        display: block;
        font-size: 1rem;
    }
}
</style>