<template>
    <div>
        <input type="text" v-model="displayValue" @keydown="currencyFieldMask" @blur="onBlur" @focus="onFocus" @keypress="onKeypress" @keyup="onKeyup" :class="_class" :disabled="disabled" />
    </div>
</template>

<script>
export default {
    name: 'InputCurrency',
    props: ['value', '_class',  'disabled'],
    data: function () {
        return {
            isInputActive: false,
        };
    },
    methods: {
        onBlur() {
            this.isInputActive = false;
            this.$emit('blur');
        },
        onFocus() {
            this.isInputActive = true;
            this.$emit('focus');
        },
        onKeypress() {
            this.$emit('keypress');
        },
        onKeyup() {
            this.$emit('keyup');
        },
        currencyFieldMask(event) {
            const key = event.key;
            const value = event.target.value;

            if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(key)) { 
                return;
            }

            const allowedCommandKeys = ['c', 'v', 'a', 'x'];
            const isCtrlOrCmd = event.ctrlKey || event.metaKey;
            if (isCtrlOrCmd && (allowedCommandKeys.includes(key.toLowerCase()))) {  
                return;
            }

            if (key === '.' && value.includes('.')) {
                event.preventDefault();
                return;
            }

            const parts = value.split('.');
            if (parts.length === 2 && parts[1].length >= 2 && key !== 'Backspace' && key !== 'Delete') {
                event.preventDefault();
            }

            if (!/[0-9.]/.test(key)) {
                event.preventDefault();
                return;
            }
        },
    },
    computed: {
        displayValue: {
            get: function () {
                if (this.value) {
                    if (this.isInputActive) {
                        // Cursor is inside the input field. unformat display value for user
                        return this.value.toString();
                    } else {
                        // User is not modifying now. Format display value for user interface
                        return this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
                    }
                }
                return 0;
            },
            set: function (modifiedValue) {
                // Recalculate value after ignoring "$" and "," in user input
                let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ''));
                // Ensure that it is not NaN
                if (isNaN(newValue)) {
                    newValue = 0;
                }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', newValue);
            },
        },
    },
};
</script>

<style scoped>
.form-control-sm {
    font-size: 0.75em !important;
}
</style>
