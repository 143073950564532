<template>
    <div class="alert alert-danger text-center border-top-0 border-start-0 border-end-0 rounded-0 fw-bold d-print-none d-flex align-items-center justify-content-center" v-if="showAlertEnvironment">
        {{ enviroment }}
        <div class="close-button" @click="hideAlert"><i class="fas fa-times-circle" /></div>
    </div>
</template>

<script>
export default {
    name: 'AlertEnvironment',
    data() {
        return {
            showAlert: true,
        };
    },
    methods: {
        hideAlert() {
            this.showAlert = false;
        },
    },
    computed: {
        enviroment() {
            if (this.$store.getters.ENV === 'TEST') return 'TEST ENVIRONMENT';
            else if (this.$store.getters.ENV === 'HOMO') return 'HOMOLOGATION ENVIRONMENT';
            else if (this.$store.getters.ENV === 'DEVE') return 'DEVELOPMENT ENVIRONMENT';
            else return '';
        },
        showAlertEnvironment() {
            return this.showAlert && (this.$store.getters.ENV === 'TEST' || this.$store.getters.ENV === 'HOMO' || this.$store.getters.ENV === 'DEVE');
        },
    },
};
</script>

<style lang="scss" scoped>
.close-button {
    position: absolute;
    right: 15px;
    cursor: pointer;
    font-size: 1.5rem;
}
</style>
