<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Compliance Forms</h1>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="mb-4 col-3">
                            <a :href="pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19Link" target="_blank">
                                 <MenuButton>
                                    <template v-slot:label>	FinCEN Advisory on Medical Scams Related to COVID-19</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19PortugueseLink" target="_blank">
                                 <MenuButton>
                                    <template v-slot:label>	FinCEN Advisory on Medical Scams Related to COVID-19 (Portuguese)</template>
                                </MenuButton>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </layout>
</template>

<script>
import Layout from "@/views/layouts/Main"
import MenuButton from "@/components/MenuButton"
import pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19 from "@/assets/pdf/compliance/resource/FinCENAdvisoryonMedicalScamsRelatedtoCOVID-19.pdf"
import pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19Portuguese from "@/assets/pdf/compliance/resource/FinCENAdvisoryonMedicalScamsRelatedtoCOVID-19Portuguese.pdf"

export default {
    name: "FormsMenu",
    components: {
        Layout,
        MenuButton,
    },
    data() {
        return {
            pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19Link: pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19,
            pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19PortugueseLink: pdfFinCENAdvisoryonMedicalScamsRelatedtoCOVID_19Portuguese
        }
    }
}
</script>

<style scoped>

</style>