<template>
    <Layout>
        <div class="d-flex flex-column order-layout">
            <div class="container pt-4">
                <div class="row justify-content-center">
                    <div class="col-md-10 col-lg-8 col-12">
                        <div class="mt-3">
                            <div class="progress" style="height: 2px; width: 80%; margin: auto;">
                                <div class="progress-bar bg-primary" :style="{ width: progressWidth}"></div>
                            </div>
                            <ul class="order-steps justify-content-between text-center d-flex">
                                <li class="list-inline-item text-center">
                                    <div class="order-step-dot" :class="[step >= 1 ? 'bg-primary' : 'bg-light']"></div>
                                    <p class="mt-3">Sender</p>
                                </li>
                                <li class="list-inline-item">
                                    <div class="order-step-dot" :class="[step >= 2 ? 'bg-primary' : 'bg-light']"></div>
                                    <p class="mt-3">Recipient</p>
                                </li>
                                <li class="list-inline-item">
                                    <div class="order-step-dot" :class="[step >= 3 ? 'bg-primary' : 'bg-light']"></div>
                                    <p class="mt-3">Payment & Amount</p>
                                </li>
                                <li class="list-inline-item">
                                    <div class="order-step-dot" :class="[step >= 4 ? 'bg-primary' : 'bg-light']"></div>
                                    <p class="mt-3">Additional Information</p>
                                </li>
                                <li class="list-inline-item">
                                    <div class="order-step-dot" :class="[step >= 5 ? 'bg-primary' : 'bg-light']"></div>
                                    <p class="mt-3">Summary</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-2" v-if="editOrder">
                    <h2 class="badge bg-dark fs-6">Editing Order #{{ order.orderId }}</h2>
                </div>
                <slot name="content"></slot>
            </div>
            <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <p class="mb-0 text-uppercase text-primary">{{ navigationLabel }}</p>
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item" :class="{ active: navigationLabel === 'Sender' }"><span class="dot"></span></li>
                                <li class="list-inline-item" :class="{ active: navigationLabel === 'Recipient' }"><span class="dot"></span></li>
                                <li class="list-inline-item" :class="{ active: navigationLabel === 'Payment & Amount' }"><span class="dot"></span></li>
                                <li class="list-inline-item" :class="{ active: navigationLabel === 'Additional Information' }"><span class="dot"></span></li>
                                <li class="list-inline-item" :class="{ active: navigationLabel === 'Summary' }"><span class="dot"></span></li>
                            </ul>
                        </div>
                        <div class="col text-end">
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/views/layouts/Main";
export default {
    name: "CreateOrder",
    props: {
        navigationLabel: String
    },
    components: {
        Layout,
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        editOrder() {
            return this.$store.getters['order/isEdit'];
        },
        step() {
            switch (this.navigationLabel)
            {
                case 'Sender':
                    return 1;
                case 'Recipient':
                    return 2;
                case 'Payment & Amount':
                    return 3;
                case 'Additional Information':
                    return 4;
                case 'Summary':
                    return 5;
                default:
                    return 0;
            }
        },
        progressWidth() {
            return `${25 * (this.step - 1)}%`;
        }
    }

}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.order-layout {
    padding-bottom: 100px;
}

.slick-dots {
  > li {
    display: inline-block;
  }
  > li:only-child {
    display: none;
  }
}

.slick-dotted.slick-slider {
  padding-bottom: 15px;
}

ul.order-steps {
    margin: auto;
    margin-top: -16px;
  padding: 0;
  li {
    width: 25%;
    .order-step-dot {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin: auto;
    }
  }
}


footer {
    p {
        font-size: 12px;
        font-weight: 700;
    }
    .list-inline {
        .list-inline-item {
            color: #BDBDBD;
            font-size: 10px;
            .dot {
                width: 10px;
                height: 10px;
                display: block;
                line-height: 10px;
                background-color: #BDBDBD;
                border-radius: 50%;

            }
        }
        .active {
            .dot {
                background-color: $primary;
                width: 16px;
                border-radius: 5px;
            }
        }

    }
}
</style>