<template>
  <div class="modal" id="modal-cancelations" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header bg-light border-bottom-0 p-4">
          <h5 class="modal-title">Cancelation Request Form</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body px-4">
          <div v-if="error" class="alert alert-danger">
            <!-- {{ error.data.Message }} -->
          </div>
          <div v-if="success" class="alert alert-success">
            Cancelation Request sent successfully!
          </div>
          <div class="d-flex justify-content-center">
            <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div v-if="data">
            <div class="row">
              <div class="col-md-3">
                <h2 class="text-primary fs-6 fw-bold text-uppercase">ORDER</h2>
                <div class="mb-2 mb-lg-0">
                  <div>#{{ data.invoiceId }}</div>
                  <span class="badge" :style="{ 'background-color': data.statusColor }">{{ data.status }}</span>
                </div>
              </div>
              <div class="col-md-3">
                <h2 class="text-primary fs-6 fw-bold text-uppercase">AGENT</h2>
                <div class="mb-2 mb-lg-0">
                  {{ data.agentName }}
                </div>
              </div>
              <div class="col-md-3">
                <h2 class="text-primary fs-6 fw-bold text-uppercase">CORRESPONDENT</h2>
                <div class="mb-2 mb-lg-0">
                  {{ data.correspondentName }}
                </div>
              </div>
              <div class="col-md-3">
                <h2 class="text-primary fs-6 fw-bold text-uppercase text-end">DATE & TIME</h2>
                <div class="mb-2 mb-lg-0 text-lg-end">
                  {{ dateTimeFormatted }}
                </div>
              </div>
            </div>
            <hr>
            <div class="row">
              <div class="col-md-6">
                <h2 class="text-primary fs-6 fw-bold text-uppercase">SENDER #{{ data.senderId }}</h2>
                <div class="mb-3">
                  <div class="mb-1">{{ data.senderName }}</div>
                  <div class="mb-1">{{ data.senderCountry }}</div>
                </div>
              </div>
              <div class="col-md-6">
                <h2 class="text-primary fs-6 fw-bold text-uppercase">RECIPIENT #{{ data.recipientId }}</h2>
                <div class="mb-3">
                  <div class="mb-1">{{ data.recipientName }}</div>
                  <div class="mb-1">{{ data.recipientCountry }}</div>
                </div>
              </div>
            </div>
            <div class="card my-3 border-0">
              <div class="card-body bg-light rounded-3 px-4">
                <div class="row">
                  <div class="col-md-4">
                    <div class="d-flex">
                      <label class="fw-bold">Net Amount:</label>
                      <span class="ms-auto">{{ data.amountSent }}</span>
                    </div>
                    <div class="d-flex">
                      <label class="fw-bold">Total Collected:</label>
                      <span class="ms-auto">{{ data.totalCollected }}</span>
                    </div>
                  </div>
                  <div class="col-md-4 offset-md-4 justify-content-end">
                    <div class="d-flex">
                      <label class="fw-bold">Amount to be Paid:</label>
                      <span class="ms-auto">{{ data.amountReceived }}</span>
                    </div>
                    <div class="d-flex">
                      <label class="fw-bold">Payment Currency:</label>
                      <span class="ms-auto">{{ data.currencyDescription }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fst-italic mb-3 fs-6">{{ data.confirmationMessage }}</div>
            <textarea cols="3" v-model="reason" class="form-control"></textarea>
            <div class="alert alert-warning mt-3 mb-0">{{ data.additionalMessage }}</div>
          </div>
        </div>
        <div class="modal-footer d-flex" v-if="data">
          <button type="button" class="btn btn-primary me-auto text-uppercase" @click="save" :disabled="saving">Confirm</button>
          <button type="button" class="btn btn-outline-primary text-uppercase" @click="close" :disabled="saving">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Modal } from "bootstrap";

export default {
    name: "Cancelations",
    props: {
      defaultNote: Number
    },
    data() {
        return {
            orderId: 0,
            modal: null,
            data: null,
            reason: null,
            reasonId: 0,
            loading: false,
            saving: false,
            success: false,
            error: null,
            confirmationMessage : ''
        }
    },
    mounted () {
        this.modal = new Modal(document.getElementById('modal-cancelations'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`/niloagent/order/${this.orderId}/cancelationrequest`)
                    .then(response => {
                        this.data = response.data;
                    })
                    .catch(error => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    }).finally(() => {
                        this.loading = false;
                    });
            }
        },
        save() {
            this.saving = true;
            axios
                .post(`/niloagent/order/${this.orderId}/cancelationrequest/send`, {
                    orderId: this.orderId,
                    reason: this.reason,
                })
                .then(() => {
                    this.success = true;
                    this.error = null;
                    this.data = null;
                    this.$emit('saved');
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                }).finally(() => {
                    this.saving = false;
                });
        },
        show(orderId) {
            this.orderId = orderId;
            this.success = false;
            this.error = null;
            this.data = null;
            this.reason = null;
            this.modal.show();
            this.load();
        },
        close() {
            this.modal.hide();
        }
    },
    computed: {
        dateTimeFormatted() {
            return this.data ? this.data.createTime.replace(',', '\n') : '';
        }
    }
}
</script>

<style lang="scss" scoped>
textarea {
    height: 100px;
}

</style>