<template>
    <layout navigation-label="Payment & Amount">
        <template v-slot:content>
            <form autocomplete="off">
                <h1 class="page-title text-primary mb-4 text-uppercase" id="pageTitle">Payment & amount</h1>
                <div class="row justify-content-center">
                    <div class="col-sm-6 col-md-3 mb-2">
                        <label for="services" class="form-label">Services</label><label class="mandatory-mark">*</label>
                        <select class="form-select" v-model="payment.paymentMethodId" @change="onChangeService" id="services">
                            <option value="0" selected>- Select -</option>
                            <option v-for="item in services" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-sm-6 col-md-3 mb-2">
                        <label for="currencyOfPayment" class="form-label">Currency of payment</label><label class="mandatory-mark">*</label>
                        <select class="form-select" v-model="payment.currencyId" @change="onChangeCurrency" id="currencyOfPayment">
                            <option value="0" selected>- Select -</option>
                            <option v-for="item in currencies" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="row">
                            <div class="col d-flex flex-column justify-content-end pe-0">
                                <label for="amountSentUSD" class="form-label">Amount Sent (USD)</label>
                                <input-currency v-model="payment.amountSent" id="amountSentUSD" @keyup="onChangeAmountSent" @blur="onChangeAmountSent" _class="form-control"></input-currency>
                            </div>
                            <div class="col-2 d-flex flex-column justify-content-end text-center">
                                <i class="fas fa-exchange-alt mx-auto"></i>
                            </div>
                            <div class="col ps-0">
                                <label for="amountReceivedBRL" class="form-label">Amount Received ({{ currencyOfPaymentName }})</label>
                                <input-currency v-model="payment.amountReceived" id="amountReceivedBRL" @blur="onChangeAmountReceived" @keyup="onChangeAmountReceived" _class="form-control"></input-currency>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 mb-2 mt-3 d-flex flex-column">
                        <button type="button" @click="loadRates" id="getRate" class="btn btn-primary text-uppercase w-100 mt-auto" :disabled="disableGetRatesFees || rateInformationLoading">{{ rateInformationLoading ? 'Wait...' : 'Get Rates & Fees' }}</button>
                    </div>
                </div>
                <div class="divider"></div>

                <div v-show="showRateInformation">
                    <h2 class="page-title text-primary mb-4 text-uppercase">Rate Information</h2>
                    <div class="card border-0">
                        <div class="card-body bg-light rounded-3">
                            <div class="row justify-content-center">
                                <div class="col-md-6 mb-3 mb-md-0">
                                    <div class="row">
                                        <div class="col-6 text-end"><label class="fw-bolder mb-1 fs-5">Exchange Rate:</label></div>
                                        <div class="col-6 text-start">
                                            <label class="fw-bolder mb-1 fs-5">
                                                {{ parseFloat(rateInformation.exchangeRate).toFixed(4) }} <button type="button" @click="openUpdateRateFees" class="btn-sm fs-6 ms-2 border-0 text-uppercase"><i class="far fa-edit"></i> Edit</button>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 text-end"><label class="form-label mb-0">Total Fees:</label></div>
                                        <div class="col-6 col-md-3 text-start">{{ parseFloat(rateInformation.totalFees).toFixed(2) }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6 mb-3 mb-md-0">
                                    <div class="row">
                                        <div class="col-6 col-md-7 text-end">
                                            <label class="form-label mb-1">Amount to pay ({{ rateInformation.currencySymbol }}):</label>
                                        </div>
                                        <div class="col-6 col-md-5 text-start">{{ amountToPay }}</div>
                                    </div>
                                    <div class="row" v-show="showDebitCardSurcharge">
                                        <div class="col-6 col-md-7 text-end"><label class="form-label mb-0">Debit Card Surcharge (US$):</label></div>
                                        <div class="col-6 col-md-5 text-start">{{ parseFloat(payment.debitCardSurcharge).toFixed(2) }}</div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 col-md-7 text-end"><label class="form-label mb-0">Total collected (US$):</label></div>
                                        <div class="col-6 col-md-5 text-start">{{ totalCollected }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="divider"></div>
                </div>

                <div v-show="fieldsVisibility.bankDepositInfo.display">
                    <h2 class="page-title text-primary mb-4 text-uppercase">Bank Information: {{ order.recipient.fullName }}</h2>
                    <div class="mb-3 pb-3">
                        <button type="button" id="findBankAccount" @click="openBankAccounts" class="btn btn-sm btn-primary text-uppercase" v-show="fieldsVisibility.bankDepositInfo.findBankDisplay"><i class="fas fa-address-book me-1"></i>{{ order.recipient.fullName }} - Bank Accounts</button>
                    </div>

                    <div class="d-flex mb-3">
                        <span class="me-auto text-gray new-bank-account">New Bank Account</span>
                        <div class="border-bottom my-2 w-100"></div>
                    </div>

                    <div v-show="true">
                        <div class="row">
                            <div class="col-4 mb-3" v-if="fieldsVisibility.bankDepositInfo.bankNameSelectSearchDisplay">
                                <label for="bankName" class="form-label">Bank name</label><label class="mandatory-mark">*</label>
                                <v-select :options="banks" inputId="bankName" id="bankName" label="value" :clearable="false" :reduce="(item) => item.id" v-model="bankInformation.abaOrNo" @close="onChangeBankAndBranch"></v-select>
                            </div>
                            <div class="col-3 mb-3" v-show="fieldsVisibility.bankDepositInfo.bankAbaNumberLabelDisplay">
                                <label for="bankNumber" class="form-label">Bank number</label>
                                <input type="text" v-model="bankInformation.abaOrNo" class="form-control" id="bankNumber" placeholder="" :disabled="fieldsVisibility.bankDepositInfo.bankAbaNumberTextDisabled" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col mb-3" v-show="fieldsVisibility.bankDepositInfo.branchNumberInputDisplay">
                                <label for="branchNumber" class="form-label">Branch number</label><label class="mandatory-mark">*</label>
                                <input type="text" v-model="bankInformation.branchNo" @focusout="onChangeBankAndBranch" :maxlength="fieldsVisibility.bankDepositInfo.branchIdMaxLength" class="form-control" id="branchNumber" placeholder="" :disabled="fieldsVisibility.bankDepositInfo.branchNumberInputDisable" />
                            </div>
                            <div class="col mb-3" v-show="fieldsVisibility.bankDepositInfo.accountNumberInputDisplay">
                                <label for="accountNumber" class="form-label">Account number</label><label class="mandatory-mark">*</label>
                                <input type="text" v-model="bankInformation.accountNumber" class="form-control" id="accountNumber" placeholder="" :disabled="fieldsVisibility.bankDepositInfo.accountNumberInputDisabled" />
                            </div>
                            <div class="col-4 mb-3" v-show="fieldsVisibility.bankDepositInfo.branchLocationDisplay">
                                <label for="branchLocation" class="form-label">Branch location</label>
                                <input type="text" v-model="payment.branchLocation" class="form-control" id="branchLocation" :disabled="fieldsVisibility.bankDepositInfo.branchLocationDisable" />
                            </div>
                            <div class="col mb-3" v-show="fieldsVisibility.bankDepositInfo.accountTypeSelectDisplay">
                                <label for="accountType" class="form-label">Account type</label><label class="mandatory-mark">*</label>
                                <select class="form-select" v-model="bankInformation.accountType" id="accountType">
                                    <option value="" selected>- Select -</option>
                                    <option v-for="item in accountTypes" :value="item" :key="item">{{ item }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-show="fieldsVisibility.pixInfo.display">
                    <h2 class="page-title text-primary mb-4 text-uppercase">PIX Information</h2>
                    <div class="row align-items-end">
                        <div class="col-md-2 mb-3">
                            <label for="accountType" class="form-label">Pix type</label><label class="mandatory-mark">*</label>
                            <select class="form-select" v-model="payment.pixTypeId" id="pixTypeId">
                                <option value="" selected>- Select -</option>
                                <option v-for="item in pixTypes" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                        <div class="col-md mb-3">
                            <button type="button" id="btnOpenModalPixKeys" @click="openPixKeys" class="btn btn-primary text-uppercase"><i class="fas fa-search"></i> Find Beneficiary Recent Pix</button>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6">
                            <label for="branchLocation" class="form-label">Pix ID</label><label class="mandatory-mark">*</label>
                            <input type="text" v-model="payment.pixId" class="form-control" id="pixId" />
                        </div>
                    </div>
                </div>

                <div v-show="fieldsVisibility.boletoInfo.display">
                    <h2 class="page-title text-primary mb-4 text-uppercase">Boleto or Utility Bill Information</h2>
                    <div class="row">
                        <div class="col mb-3">
                            <label for="branchLocation" class="form-label">Boleto Number</label><label class="mandatory-mark">*</label>
                            <input type="text" v-model="payment.boletoNumber" class="form-control" id="boletoNumber" />
                        </div>
                    </div>
                </div>

                <div v-show="fieldsVisibility.cashPickupInfo.display">
                    <h2 class="page-title text-primary mb-4 text-uppercase">Cash Pickup Information</h2>
                    <label for="locations" class="form-label">Payment Location</label><span class="mandatory-mark">*</span>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <select class="form-select" v-model="payment.payLocationId" @change="onChangeLocation" id="locations" v-show="fieldsVisibility.cashPickupInfo.paymentLocationSelectDisplay">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in locations" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                            <div v-show="fieldsVisibility.cashPickupInfo.findLocationComponentDisplay" class="badge bg-light text-black form-control fs-6 fw-normal text-start py-2 border-light me-3" style="height: 38px">
                                <span>{{ payment.payLocationName }}</span>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <button type="button" @click="openCashPickupLocations" class="btn btn-primary text-uppercase" id="btnFindLocation" v-show="fieldsVisibility.cashPickupInfo.findLocationComponentDisplay"><i class="fas fa-search"></i> Find Location</button>
                        </div>
                    </div>
                </div>

                <div v-show="fieldsVisibility.recipientInfo.display">
                    <h2 class="page-title text-primary mb-4 mt-3 text-uppercase">Recipient Documents</h2>
                    <div class="row">
                        <div class="col-md-3 mb-3" v-show="fieldsVisibility.recipientInfo.documentNumberDisplay">
                            <label for="documentNumberRecipient" class="form-label">Document number</label><span class="mandatory-mark">*</span>
                            <input type="text" v-model="payment.recipientDocumentNumber" class="form-control" id="documentNumberRecipient" />
                        </div>
                        <div class="col-md-3 mb-3" v-show="fieldsVisibility.recipientInfo.documentTypeDisplay">
                            <label for="documentTypeRecipient" class="form-label">Type</label><span class="mandatory-mark">*</span>
                            <select class="form-select" v-model="payment.recipientDocumentType" id="documentTypeRecipient">
                                <option value="" selected>- Select -</option>
                                <option v-for="item in recipientDocumentTypes" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                        <div class="col-md-3 mb-3" v-show="fieldsVisibility.recipientInfo.documentExpirationDisplay">
                            <label for="documentExpirationRecipient" class="form-label">Expiration</label><span class="mandatory-mark">*</span>
                            <div class="input-group mb-3">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" v-model="payment.recipientDocumentExpiration" class="form-control datePicker datePicker-input" id="documentExpirationRecipient" placeholder="mm/dd/yyyy" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-show="fieldsVisibility.recipientInfo.birthday">
                            <label for="documentExpirationRecipient" class="form-label">Birthday</label><span class="mandatory-mark">*</span>
                            <div class="input-group mb-3">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" v-model="payment.recipientBirthday" class="form-control datePicker datePicker-input" id="recipientBirthday" placeholder="mm/dd/yyyy" />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <button type="button" @click="back" class="btn btn-outline-primary text-uppercase mt-4 me-3"><i class="fas fa-arrow-left me-2"></i> Back</button>
            <button type="button" @click="next" class="btn btn-primary text-uppercase mt-4" name="btnPaymentNext" id="btnPaymentNext" :disabled="validating">{{ validating || loading ? 'Wait...' : !hasSummary ? 'Next' : 'Save & Go Summary' }} <i class="fas fa-arrow-right ms-2"></i></button>

            <modal-pix-keys ref="modalPixKeys" @selected="selectedPix" :recipient-id="parseInt(order.recipient.clientId)"></modal-pix-keys>
            <modal-bank-accounts ref="modalBankAccounts" @selected="selectedBank" :recipient-id="parseInt(order.recipient.clientId)"></modal-bank-accounts>
            <modal-update-rate ref="modalUpdateRateFees" @updated="updateRateFees" :amount-sent="parseFloat(payment.amountSent)" :currency-of-payment="parseInt(payment.currencyId)" :type-of-payment-id="parseInt(payment.paymentMethodId)" :rate="parseFloat(rateInformation.exchangeRate)" :fees="parseFloat(rateInformation.totalFees)"></modal-update-rate>
            <modal-cash-pickup-locations ref="modalCashPickupLocations" @selected="selectedCashPickupLocation" :pay-method-id="parseInt(payment.paymentMethodId)" :currency-option-id="parseInt(payment.currencyId)"></modal-cash-pickup-locations>
        </template>
    </layout>
</template>

<script>
import axios from 'axios';
import layout from '@/views/layouts/CreateOrder';
import InputCurrency from '@/components/InputCurrency';
import ModalPixKeys from '@/views/order/modals/PixKeys';
import ModalUpdateRate from '@/views/order/modals/UpdateRate';
import ModalBankAccounts from '@/views/order/modals/BankAccounts';
import ModalCashPickupLocations from '@/views/order/modals/CashPickupLocations';

export default {
    name: 'Payment',
    components: {
        layout,
        ModalPixKeys,
        InputCurrency,
        ModalUpdateRate,
        ModalBankAccounts,
        ModalCashPickupLocations,
    },
    data() {
        return {
            payment: {
                paymentMethod: '',
                paymentMethodName: '',
                paymentMethodId: 0,
                currencyId: 0,
                currencySymbol: '',
                payLocationId: 0,
                payLocationName: '',
                amountSent: 0,
                bankId: 0,
                bankName: '',
                abaOrNo: '',
                branchNo: '',
                accountNumber: '',
                accountType: '',
                branchLocation: '',
                pixTypeId: 0,
                pixId: '',
                exchangeRate: '',
                amountReceived: 0,
                totalFees: 0,
                totalCollected: 0,
                creditCardChecked: false,
                boletoNumber: '',
                debitCardSurcharge: 0,
                recipientDocumentNumber: '',
                recipientDocumentType: '',
                recipientDocumentExpiration: '',
                recipientBirthday: '',
            },
            services: [],
            currencies: [],
            locations: [],
            banks: [],
            bankSelected: null,
            accountTypes: ['CHECKING', 'SAVINGS'],
            pixTypes: [
                {
                    id: 'CPF',
                    value: 'CPF or CNPJ',
                },
                {
                    id: 'PHONE',
                    value: 'Phone number',
                },
                {
                    id: 'EMAIL',
                    value: 'Email',
                },
                {
                    id: 'RANDOMKEY',
                    value: 'Random Key',
                },
            ],
            rateInformation: {},
            bankInformation: {
                abaOrNo: '',
                branchNo: null,
                bankName: null,
            },
            loading: false,
            validating: false,
            rateInformationLoading: false,
            fieldsVisibility: {
                bankDepositInfo: {
                    display: false,
                    accountNumberInputDisabled: false,
                    accountNumberInputDisplay: false,
                    accountTypeSelectDisplay: false,
                    bankAbaNumberLabelDisplay: false,
                    bankAbaNumberTextDisabled: false,
                    bankNameLabelDisplay: false,
                    bankNameLabelValue: null,
                    bankNameSelectSearchDisplay: false,
                    bankNameTextDisabled: false,
                    branchLocationDisable: false,
                    branchLocationDisplay: false,
                    branchNameLabelDisplay: false,
                    branchNumberInputDisable: false,
                    branchNumberInputDisplay: false,
                    branchNumberTextDisplay: false,
                    findBankDisplay: false,
                    findRecipientAccountDisplay: false,
                    paymentLocationSelectDisplay: false,
                },
                pixInfo: {
                    display: false,
                },
                boletoInfo: {
                    display: false,
                },
                cashPickupInfo: {
                    display: false,
                    findLocationComponentDisplay: false,
                    paymentLocationSelectDisplay: false,
                },
                recipientInfo: {
                    display: false,
                    documentExpirationDisplay: false,
                    documentNumberDisplay: false,
                    documentTypeDisplay: false,
                },
            },
            recipientDocumentTypes: [],
        };
    },
    mounted() {
        this.loadServices();
        this.loadBanks();
        this.loadPaymentObjects();
    },
    methods: {
        checkBankAccounts() {
            if (this.payment.paymentMethodId == 31 && this.order.recipient.bankAccounts.length === 1) {
                this.selectedBank(this.order.recipient.bankAccounts[0]);
            }
        },
        loadPaymentObjects() {
            if (this.order.paymentInformation !== null) {
                this.payment = {
                    paymentMethodId: this.order.paymentInformation.paymentMethodId,
                    paymentMethod: this.order.paymentInformation.paymentMethod,
                    paymentMethodName: this.order.paymentInformation.paymentMethodName,
                    currencyId: this.order.paymentInformation.currencyId,
                    payLocationId: this.order.paymentInformation.payLocationId,
                    payLocationName: this.order.paymentInformation.payLocationName,
                    amountSent: this.order.paymentInformation.amountSent,
                    amountReceived: this.order.paymentInformation.amountReceived,
                    boletoNumber: this.order.paymentInformation.boletoNumber,
                    pixTypeId: this.order.paymentInformation.pixTypeId,
                    pixId: this.order.paymentInformation.pixId,
                    debitCardSurcharge: this.order.paymentInformation.debitCardSurcharge,
                    recipientDocumentNumber: this.order.paymentInformation.recipientDocumentNumber,
                    recipientDocumentType: this.order.paymentInformation.recipientDocumentType,
                    recipientDocumentExpiration: this.order.paymentInformation.recipientDocumentExpiration,
                    recipientBirthday: this.order.paymentInformation.recipientBirthday,
                };

                this.rateInformation = {
                    exchangeRate: this.order.paymentInformation.exchangeRate,
                    totalFees: this.order.paymentInformation.totalFees,
                    currencySymbol: this.order.paymentInformation.currencySymbol,
                };

                this.bankInformation = {
                    abaOrNo: this.order.paymentInformation.abaOrNo,
                    bankName: this.order.paymentInformation.bankName,
                    branchNo: this.order.paymentInformation.branchNo,
                    accountNumber: this.order.paymentInformation.accountNumber,
                    branchLocation: this.order.paymentInformation.branchLocation,
                    accountType: this.order.paymentInformation.accountType,
                };

                if (this.payment.paymentMethodId !== 0) this.loadCurrencies();

                if (this.payment.currencyId !== 0) {
                    this.loadLocations();
                    this.loadBanks();
                }
            }
        },
        loadServices() {
            axios
                .get('/niloagent/createorder/filter/payment/services', {
                    params: {
                        agentId: this.order.recipient.clientId,
                        countryId: this.order.recipient.countryId,
                    },
                })
                .then((response) => {
                    this.services = response.data;
                })
                .catch((error) => {
                    this.services = null;
                    this.$toast.error(`Error to find the payment services: ${error.response.data.Message}`);
                });
        },
        loadCurrencies() {
            axios
                .get('/niloagent/createorder/filter/payment/currenciesandpayment', {
                    params: {
                        countryId: this.order.recipient.countryId,
                        typeOfPaymentId: parseInt(this.payment.paymentMethodId),
                    },
                })
                .then((response) => {
                    this.currencies = response.data.currenciesAvailable;
                })
                .catch((error) => {
                    this.currencies = null;
                    this.$toast.error(`Error to find the currencies: ${error.response.data.Message}`);
                });
        },
        loadLocations() {
            if (this.fieldsVisibility.cashPickupInfo.paymentLocationSelectDisplay) {
                axios
                    .get('/niloagent/createorder/payment/select/locations', {
                        params: {
                            countryId: this.order.recipient.countryId,
                            payMethodId: this.payment.paymentMethodId,
                            currencyOptionId: this.payment.currencyId,
                        },
                    })
                    .then((response) => {
                        this.locations = response.data;
                    })
                    .catch((error) => {
                        this.locations = null;
                        this.$toast.error(`Error to find the locations ${error.response.data.Message}`);
                    });
            } else {
                this.locations = null;
            }
        },
        loadBanks() {
            axios
                .get('/niloagent/createorder/filter/payment/loadbanklist', {
                    params: {
                        countryId: this.order.recipient.countryId,
                        payMethodId: this.payment.paymentMethodId,
                        currencyOptionId: this.payment.currencyId,
                    },
                })
                .then((response) => {
                    this.banks = response.data;
                })
                .catch((error) => {
                    this.banks = null;
                    this.$toast.error(`Error to find the brazilian banks: ${error.response.data.Message}`);
                });
        },
        async loadRates() {
            this.rateInformationLoading = true;
            this.validating = true;
            return axios
                .get('/niloagent/createorder/filter/ratesandcosts', {
                    params: {
                        SenderCountryId: this.order.sender.countryId,
                        RecipientCountryId: this.order.recipient.countryId,
                        NetAmountSent: this.payment.amountSent,
                        AmountReceived: this.payment.amountReceived ?? 0,
                        CurrencySentId: 1,
                        CurrencyOfPayment: this.payment.currencyId,
                        TypeOfPaymentID: this.payment.paymentMethodId,
                    },
                })
                .then((response) => {
                    if (this.hasRates) {
                        this.rateInformation.totalFees = response.data.totalFees;
                    } else {
                        this.rateInformation = {
                            exchangeRate: response.data.exchangeRate,
                            totalFees: response.data.totalFees,
                            currencyIsoCode: response.data.currencyIsoCode,
                            currencySymbol: response.data.currencySymbol,
                            maxDiscountAllowed: response.data.maxDiscountAllowed,
                        };
                        this.payment.amountReceived = response.data.amountPaid;
                        this.payment.amountSent = response.data.amountSent;
                    }
                    return true;
                })
                .catch((error) => {
                    this.rateInformation = {};
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.rateInformationLoading = false;
                    this.validating = false;
                });
        },
        loadBranchLocation() {
            axios
                .get('/niloagent/createorder/filter/payment/branch', {
                    params: {
                        payMethodId: this.payment.paymentMethodId,
                        bankId: this.bankInformation.abaOrNo,
                        branchId: this.bankInformation.branchNo,
                    },
                })
                .then((response) => {
                    if (response.data) this.payment.branchLocation = response.data.branchName.trim();
                })
                .catch((error) => {
                    this.bankInformation.branchLocation = '';
                    this.$toast.error(`Error to find the branches: ${error.response.data.Message}`);
                });
        },
        loadFieldsVisibility() {
            axios
                .get('/niloagent/createorder/filter/payment/fieldsvisibility', {
                    params: {
                        payMethodId: this.payment.paymentMethodId,
                        countryId: this.order.recipient.countryId,
                        currencyId: this.payment.currencyId,
                    },
                })
                .then((response) => {
                    this.fieldsVisibility = response.data;
                    this.loadLocations();
                    this.loadBanks();
                })
                .catch((error) => {
                    this.$toast.error(`Error to find the currencies: ${error.response.data.Message}`);
                });
        },
        loadRecipientDocumentTypes() {
            axios
                .get('/niloagent/createorder/filter/recipientdocuments/typedoc', {
                    params: {
                        payMethodId: this.payment.paymentMethodId,
                        countryId: this.order.recipient.countryId,
                        currencyId: this.payment.currencyId,
                    },
                })
                .then((response) => {
                    this.recipientDocumentTypes = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Error to get recipient documents types: ${error.response.data.Message}`);
                });
        },
        updateRateFees(rate, fees) {
            this.rateInformation.exchangeRate = rate;
            this.rateInformation.totalFees = Number(parseFloat(fees).toFixed(2));
            this.calculateAmountReceived();
        },
        calculateAmountReceived() {
            this.payment.amountReceived = this.payment.amountSent > 0 && this.showRateInformation ? Number(parseFloat(parseFloat(this.payment.amountSent) * this.rateInformation.exchangeRate).toFixed(2)) : 0;
        },
        onChangeService() {
            this.payment.paymentMethod = this.payment.paymentMethodId > 0 ? this.services.find((item) => item.id === this.payment.paymentMethodId).type : '';
            this.payment.paymentMethodName = this.payment.paymentMethodId > 0 ? this.services.find((item) => item.id === this.payment.paymentMethodId).value : '';
            this.clearPayments();
            this.loadCurrencies();
            this.checkBankAccounts();
        },
        onChangeCurrency() {
            this.loadLocations();
            this.loadBanks();
        },
        onChangeLocation() {
            this.payment.payLocationName = this.locations.find((item) => item.id === this.payment.payLocationId).value;
        },
        onChangeAmountSent() {
            this.calculateAmountReceived();
        },
        onChangeAmountReceived() {
            this.payment.amountSent = this.payment.amountReceived && this.showRateInformation ? Number(parseFloat(this.payment.amountReceived / this.rateInformation.exchangeRate).toFixed(2)) : this.payment.amountSent;
        },
        onChangeBankAndBranch() {
            if (this.bankInformation.abaOrNo && this.bankInformation.abaOrNo.length > 0 && this.bankInformation.branchNo && this.bankInformation.branchNo.length > 0) this.loadBranchLocation();

            if (this.bankInformation.abaOrNo && this.bankInformation.abaOrNo.length > 0 && this.banks.length > 0)
                this.bankInformation.bankName = this.banks.find((item) => {
                    return item.id === this.bankInformation.abaOrNo;
                }).value;
        },
        openBankAccounts() {
            this.$refs.modalBankAccounts.show();
        },
        openPixKeys() {
            this.$refs.modalPixKeys.show();
        },
        openUpdateRateFees() {
            this.$refs.modalUpdateRateFees.show();
        },
        openCashPickupLocations() {
            this.$refs.modalCashPickupLocations.show();
        },
        selectedPix(pixInformation) {
            this.payment.pixTypeId = pixInformation.pixTypeId.toUpperCase();
            this.payment.pixId = pixInformation.pixId;
        },
        selectedBank(bankInformation) {
            this.bankInformation = { ...bankInformation };
        },
        selectedCashPickupLocation(cashPickupLocation) {
            this.payment.payLocationId = cashPickupLocation.id;
            this.payment.payLocationName = cashPickupLocation.name;
        },
        async validate() {
            this.validating = true;
            return axios
                .post('/niloagent/createorder/validate/payment', {
                    orderId: 0,
                    sender: this.order.sender,
                    recipient: this.order.recipient,
                    paymentInformation: this.payment,
                    additionalInformation: {},
                })
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message ?? error.response.data.title);
                    return false;
                })
                .finally(() => {
                    this.validating = false;
                });
        },
        async next() {
            //if (!this.hasRates)
            await this.loadRates();

            this.payment = {
                ...this.payment,
                ...this.bankInformation,
                ...this.rateInformation,
                totalCollected: this.totalCollected,
                currencyOfPayment: this.currencyOfPaymentName,
            };

            if (await this.validate()) {
                this.$store.commit('order/setPaymentInformation', this.payment);
                await this.$router.push(this.redirectOnSave);
            }
        },
        back() {
            this.payment = {
                ...this.payment,
                ...this.bankInformation,
                ...this.rateInformation,
                totalCollected: this.totalCollected,
                currencyOfPayment: this.currencyOfPaymentName,
            };
            this.$store.commit('order/setPaymentInformation', this.payment);
            this.$router.push({ name: 'Recipient', params: { recipientId: this.order.recipient.clientId > 0 ? parseInt(this.order.recipient.clientId) : null } });
        },
        clearPayments() {
            this.payment.payLocationId = 0;
            this.payment.payLocationName = '';
            this.payment.boletoNumber = '';
            this.payment.pixTypeId = '';
            this.payment.pixId = '';

            this.bankInformation.abaOrNo = '';
            this.bankInformation.branchNo = null;
            this.bankInformation.bankName = null;
            this.bankInformation.accountNumber = '';
            this.bankInformation.accountType = '';

            this.rateInformation = {};
        },
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        currencyOfPaymentName() {
            return this.payment.currencyId > 0 && this.currencies.length > 0 ? this.currencies.find((item) => item.id === this.payment.currencyId).value : '';
        },
        showRateInformation() {
            return this.hasRates;
        },
        amountToPay() {
            if (this.payment.amountReceived > 0 && this.rateInformation.exchangeRate) return this.payment.amountReceived.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
            return '0.00';
        },
        totalCollected() {
            if (this.payment.amountSent > 0) {
                let value = parseFloat(this.payment.amountSent) + this.rateInformation.totalFees + this.payment.debitCardSurcharge;
                return value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
            }
            return '0.00';
        },
        disableGetRatesFees() {
            return !(this.payment.paymentMethod !== '' && (this.payment.currencyId !== 0 || this.payment.currencyId !== '') && (this.payment.amountSent > 0 || this.payment.amountReceived > 0));
        },
        hasRates() {
            return Object.keys(this.rateInformation).length > 0 && this.rateInformation.exchangeRate > 0;
        },
        showDebitCardSurcharge() {
            return this.payment.debitCardSurcharge != null && this.payment.debitCardSurcharge > 0;
        },
        serviceAndCurrency() {
            return this.payment.paymentMethodId + this.payment.currencyId;
        },
        recipientDocumentTypesDisplay() {
            return this.fieldsVisibility.recipientInfo.documentTypeDisplay;
        },
        redirectOnSave() {
            return this.hasSummary ? { name: 'Order Summary' } : { name: 'Additional Information' };
        },
        hasSummary() {
            return this.$store.getters['order/hasSummary'];
        },
        paymentLocationSelectDisplay() {
            return this.fieldsVisibility.cashPickupInfo.paymentLocationSelectDisplay;
        },
        bankListSelectDisplay() {
            return this.fieldsVisibility.cashPickupInfo.bankNameSelectSearchDisplay;
        },
        amountToReceived() {
            return this.payment.amountSent > 0 && this.showRateInformation ? Number(parseFloat(parseFloat(this.payment.amountSent) * this.rateInformation.exchangeRate).toFixed(2)) : 0;
        },
    },
    watch: {
        serviceAndCurrency() {
            if (this.payment.paymentMethodId > 0 && this.payment.currencyId > 0) {
                this.loadFieldsVisibility();
            } else {
                this.fieldsVisibility.bankDepositInfo.display = false;
                this.fieldsVisibility.boletoInfo.display = false;
                this.fieldsVisibility.cashPickupInfo.display = false;
                this.fieldsVisibility.pixInfo.display = false;
                this.fieldsVisibility.recipientInfo.display = false;
            }
        },
        currencies(value) {
            if (value.length === 1) {
                this.payment.currencyId = value[0].id;
            }
        },
        recipientDocumentTypesDisplay(value) {
            if (value && this.recipientDocumentTypes.length === 0) this.loadRecipientDocumentTypes();
        },
        paymentLocationSelectDisplay(value) {
            if (value) this.loadLocations();
        },
        bankListSelectDisplay(value) {
            if (value) this.loadBanks();
        },
    },
};
</script>

<style lang="scss" scoped>
.fa-exchange-alt {
    font-size: 18px;
    margin-bottom: 10px;
}
span.new-bank-account {
    min-width: 160px;
}
</style>
