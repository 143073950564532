<template>
    <div class="modal" id="modal-handler" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-3">
                    <h5 class="modal-title">Order Handler</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-if="handlers">
                        <h1 class="text-center fs-6 my-2">Order ID: {{ ordersIdFormated }}</h1>
                        <label for="orderHandler" class="form-label">Order Handler</label>
                        <select class="form-select" v-model="handlerId" id="orderHandler">
                            <option value="0" selected>- Select -</option>
                            <option v-for="item in handlers" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer d-flex" v-if="handlers">
                    <button type="button" class="btn btn-primary me-auto text-uppercase btn-sm" @click="save" :disabled="saving">Save</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase btn-sm" @click="modal.hide" :disabled="saving">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {Modal} from "bootstrap";

export default {
    name: "ModalHandler",
    props: {
        urlLoad: String,
        urlSave: String
    },
    data() {
        return {
            ordersId: 0,
            modal: null,
            handlers: null,
            handlerId: 0,
            loading: false,
            saving: false,
        }
    },
    mounted () {
        this.modal = new Modal(document.getElementById('modal-handler'));
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(this.urlLoad)
                .then(response => {
                    this.handlers = response.data;
                })
                .catch(error => {
                    this.handlers = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        save() {
            this.saving = true;
            axios
                .put(this.urlSave, {
                    orderId: this.ordersIdParseInt,
                    agentHandlerId: parseInt(this.handlerId)
                })
                .then(response => {
                    this.data = null;
                    this.$toast.success(response.data.message);
                    this.modal.hide();
                    this.$emit('onChange');
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        show(ordersId, handlerId) {
            this.ordersId = ordersId;
            this.handlerId = handlerId;
            this.data = null;
            this.remark = null;
            this.remarkType = 0;
            this.reasonId = 0;
            this.modal.show();
            this.load();
        },
    },
    computed: {
        ordersIdFormated() {
            return this.ordersId.map((orderId) => {
                return `#${orderId}`;
            }).join(', ');
        },
        ordersIdParseInt() {
            return this.ordersId.map((orderId) => {
                return parseInt(orderId);
            });
        }
    }
}
</script>

<style scoped>

</style>