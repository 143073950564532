<template>
    <layout>
        <div class="d-flex flex-column content">
            <div class="container">
                <h1 class="page-title text-primary mb-3 text-uppercase">Top Up</h1>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-header d-flex fs-5 py-3">
                                Sender
                                <div class="ms-auto">
                                    <button type="button" @click="openFindSenderModal" class="btn btn-sm btn-primary text-uppercase me-1">Find Client</button>
                                    <button type="button" @click="clearSender" class="btn btn-sm btn-outline-primary text-uppercase ms-1">Clear</button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row justify-content-center">
                                    <div class="col-md-6 mb-2">
                                        <label for="senderTelephone" class="form-label">Sender Telephone</label>
                                        <input type="text" v-model="sender.phone" class="form-control" id="senderTelephone" name="senderTelephone" placeholder="" />
                                    </div>
                                    <div class="col-md-6 mb-2">
                                        <label for="fullName" class="form-label">Full Name (First and Last Name)</label>
                                        <input type="text" v-model="sender.fullName" class="form-control" id="fullName" placeholder="" />
                                    </div>
                                    <div class="col-md-6 mb-2 mb-lg-0">
                                        <label for="companyName" class="form-label">Company Name</label>
                                        <input type="text" v-model="sender.company" class="form-control" id="companyName" placeholder="" />
                                    </div>
                                    <div class="col-md-6 mb-2 mb-lg-0">
                                        <label for="costumerNumber" class="form-label">Customer Number</label>
                                        <input type="text" v-model="sender.clientId" class="form-control" id="costumerNumber" disabled="true"/>
                                    </div>
                                </div>
                            </div>
                            <hr class="p-0 m-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-4 mb-2">
                                        <label for="senderCountry" class="form-label">Country</label><label class="mandatory-mark">*</label>
                                        <select class="form-select" v-model="sender.countryId" @change="onChangeSenderCountry" id="senderCountry" :disabled="senderCountries.loading">
                                            <option :value="senderCountries.loading && sender ? sender.countryId : 0" selected>{{ senderCountries.loading ? 'Loading...' : '- Select -' }}</option>
                                            <option v-for="(item, index) in senderCountries.data" :value="item.id" :key="index">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="col-8 mb-2">
                                        <label for="streetAddress" class="form-label">Street Address</label>
                                        <input type="text" class="form-control" v-model="sender.address" id="streetAddress" placeholder="">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5 mb-2">
                                        <label for="city" class="form-label">City</label><label class="mandatory-mark">*</label>
                                        <input type="text" class="form-control" v-model="sender.city" id="city" placeholder="">
                                    </div>
                                    <div class="col-6 col-lg-4 mb-2">
                                        <label for="senderState" class="form-label">State</label><label class="mandatory-mark">*</label>
                                        <input type="text" class="form-control" v-model="sender.stateName" placeholder="" v-show="!showAmericanStates">
                                        <select class="form-select" id="senderState" v-model="sender.stateIsoCode" v-show="showAmericanStates" :disabled="senderStates.loading">
                                            <option :value="senderStates.loading && sender ? sender.stateIsoCode : 0" selected>{{ senderStates.loading ? 'Loading...' : '- Select -' }}</option>
                                            <option v-for="(item, index) in senderStates.data" :value="item.id" :key="index">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="col-3 mb-2">
                                        <label for="senderZipCode" class="form-label">Zip code</label><label class="mandatory-mark">*</label>
                                        <input type="text" class="form-control" v-model="sender.zip" id="senderZipCode">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col mb-2">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="text" class="form-control" v-model="sender.email" id="email" placeholder="">
                                    </div>
                                    <div class="col-3 mb-2">
                                        <label for="birthday" class="form-label">Birthday</label><label class="mandatory-mark">*</label>
                                        <input type="text" class="form-control" v-model="sender.birth" id="birthday" placeholder="mm/dd/yyyy">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-header d-flex fs-5 py-3">
                                Recipient
                                <div class="ms-auto">
                                    <button type="button" @click="openFindRecipientModal" class="btn btn-sm btn-primary text-uppercase me-1">Find Client</button>
                                    <button type="button" @click="clearRecipient" class="btn btn-sm btn-outline-primary text-uppercase ms-1">Clear</button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row justify-content-center">
                                    <div class="col-md-6 mb-2">
                                        <label for="recipientId" class="form-label">Customer Number</label>
                                        <input type="text" v-model="recipient.clientId" class="form-control" id="recipientId" disabled="true"/>
                                    </div>
                                    <div class="col-md-6 mb-2">
                                        <label for="recipientFullName" class="form-label">Full Name (First and Last Name)</label>
                                        <input type="text" v-model="recipient.fullName" class="form-control" id="recipientFullName" />
                                    </div>
                                    <div class="col-md-6 mb-2 mb-lg-0">
                                        <label for="recipientCellphone" class="form-label">Recipient Cellphone</label>
                                        <input type="text" v-model="recipient.phone" class="form-control" id="recipientCellphone" />
                                    </div>
                                    <div class="col-md-6 mb-2 mb-lg-0">
                                        <label for="recipientCellphone" class="form-label">Confirm Cellphone</label>
                                        <input type="text" v-model="recipient.confirmPhone" class="form-control" id="recipientConfirmCellphone" />
                                    </div>
                                </div>
                            </div>
                            <hr class="m-0 p-0">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-6 mb-2 mb-sm-0">
                                        <label for="recipientCountry" class="form-label">Country</label><label class="mandatory-mark">*</label>
                                        <select class="form-select" id="recipientCountry" @change="onChangeRecipientCountry(recipient.countryId)" v-model="recipient.countryId" :disabled="recipientCountries.loading">
                                            <option :value="recipientCountries.loading && recipient ? recipient.countryId : 0" selected>{{ recipientCountries.loading ? 'Loading...' : '- Select -' }}</option>
                                            <option v-for="(item, index) in recipientCountries.data" :value="item.id" :key="index">{{ item.value }}</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-6 mb-2 mb-sm-0">
                                        <label for="recipientCountryOfPayment" class="form-label">Country of payment</label>
                                        <select class="form-select" id="recipientCountryOfPayment" @change="onChangeRecipientCountry(recipient.countryOfPaymentId)" v-model="recipient.countryOfPaymentId" :disabled="recipientCountries.loading">
                                            <option :value="recipientCountries.loading && recipient ? recipient.countryOfPaymentId : 0" selected>{{ recipientCountries.loading ? 'Loading...' : '- Select -' }}</option>
                                            <option v-for="(item, index) in recipientCountries.data" :value="item.id" :key="index">{{ item.value }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-header d-flex fs-5 py-3">
                                Payment Options
                                <div class="ms-auto">
                                    <button type="button" @click="loadOperators" class="btn btn-sm btn-primary text-uppercase me-1">Update</button>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="mb-3">
                                    <label class="form-label">Operators:</label>
                                    <select class="form-select" v-model="operatorId" :disabled="operators.loading" @change="loadPacks">
                                        <option value="0" selected>{{ operators.loading ? 'Loading...' : '- Select -' }}</option>
                                        <option v-for="(item, index) in operators.data" :value="item.operatorId" :key="index">{{ item.name }}</option>
                                    </select>
                                </div>
                                <div>
                                    <label class="form-label">Packs:</label>
                                    <select class="form-select" v-model="payment.topupProductId" :disabled="packs.loading"  @change="loadPackDetail">
                                        <option value="0" selected>{{ packs.loading ? 'Loading...' : '- Select -' }}</option>
                                        <option v-for="(item, index) in packs.data" :value="item.productId" :key="index">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card mb-4">
                            <div class="card-header d-flex fs-5 py-3">
                                Info
                            </div>
                            <div class="card-body fw-bold">
                                <div class="mb-2 d-flex">Pack Value: <span class="ms-auto fw-normal">{{ payment.amountReceived }}</span></div>
                                <div class="mb-2 d-flex">Amount to be sent (USD): <span class="ms-auto fw-normal">{{ payment.amountSent }}</span></div>
                                <div class="mb-2 d-flex">Exchange Rate: <span class="ms-auto fw-normal">{{ payment.exchangeRate }}</span></div>
                                <div class="mb-2 d-flex">Fee: <span class="ms-auto fw-normal">{{ payment.totalFees }}</span></div>
                                <div class="d-flex">Total Collected: <span class="ms-auto fw-normal">{{ payment.totalCollected }}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                <div class="container text-end">
                    <button type="button" @click="openConfirmOrder" class="btn btn-primary btn-sm ms-auto" :disabled="disableSave">{{ sending ? 'Wait...' : 'Save Top Up' }}</button>
                </div>
            </footer>
        </div>
        <modal-find-sender ref="modalFindSender" @selected="selectedSender" :customer-number="sender.clientId" :company-name="sender.company" :full-name="sender.fullName" :telephone="sender.phone"></modal-find-sender>
        <modal-find-recipient ref="modalFindRecipient" @selected="selectedRecipient" :sender-id="sender.clientId" :customer-number="recipient.clientId" :full-name="recipient.fullName" :telephone="recipient.phone"></modal-find-recipient>
        <modal-confirm-order ref="modalConfirmOrder" @confirmed="saveTopUp"></modal-confirm-order>
    </layout>
</template>

<script>
import axios from "axios"
import Layout from "@/views/layouts/Main"
import ModalFindSender from "@/views/order/modals/FindSender"
import ModalConfirmOrder from "@/views/order/modals/ConfirmOrder"
import ModalFindRecipient from "@/views/order/modals/FindRecipient"

export default {
    name: "CreateTopUp",
    components: {
        Layout,
        ModalFindSender,
        ModalConfirmOrder,
        ModalFindRecipient,
    },
    data() {
        return {
            sender: {
                clientId: '',
                fullName: '',
                phone: '',
                stateIsoCode: 0,
                company: '',
                countryId: 0,
            },
            recipient: {
                clientId: '',
                fullName: '',
                countryId: 0,
                countryOfPaymentId: 0,
                phone: '',
                confirmPhone: ''
            },
            payment: {
                topupProductId: 0,
                exchangeRate: 0,
                amountReceived: 0,
                totalFees: 0,
                totalCollected: 0,
                amountSent: 0,
            },
            senderStates: {
                data: [],
                loading: false
            },
            senderCountries: {
                data: [],
                loading: false
            },
            recipientCountries: {
                data: [],
                loading: false
            },
            operators: {
                data: [],
                loading: false
            },
            packs: {
                data: [],
                loading: false
            },
            operatorId: 0,
            sending: false,
            loading: false
        }
    },
    mounted() {
        this.loadSenderCountries();
        this.loadAmericanStates();
        this.loadRecipientCountries();
    },
    methods: {
        saveTopUp() {
            this.sending = true;
            axios
                .post('/niloagent/topup/save', {
                    orderReferenceNumber: (new Date()).valueOf(),
                    sender: this.sender,
                    recipient: this.recipient,
                    paymentInformation: this.payment,
                    popUpProductId: this.payment.topupProductId
                })
                .then((response) => {
                    this.$router.push({ name: 'Order Detail', params: { orderId: response.data.orderId } });
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        openConfirmOrder() {
            this.$refs.modalConfirmOrder.show();
        },
        loadSenderCountries() {
            this.senderCountries.loading = true;
            axios
                .get('/niloagent/createorder/filter/country')
                .then(response => {
                    this.senderCountries.data = response.data;
                })
                .catch(error => {
                    this.$toast.error(`country: ${error.Message}`);
                })
                .finally(() => {
                    this.senderCountries.loading = false;
                });
        },
        loadRecipientCountries() {
            this.recipientCountries.loading = true;
            axios
                .get('/niloagent/createorder/filter/destinycountries')
                .then(response => {
                    this.recipientCountries.data = response.data;
                })
                .catch(error => {
                    this.$toast.error(`country: ${error.Message}`);
                })
                .finally(() => {
                    this.recipientCountries.loading = false;
                });
        },
        loadAmericanStates() {
            this.senderStates.loading = true;
            axios
                .get('/niloagent/createorder/filter/sender/state')
                .then(response => {
                    this.senderStates.data = response.data;
                })
                .catch(error => {
                    this.$toast.error(error.Message);
                })
                .finally(() => {
                    this.senderStates.loading = false;
                });
        },
        onChangeSenderCountry() {
            this.sender.stateIsoCode = '';
        },
        onChangeRecipientCountry(value) {
            this.recipient.countryId = value;
            this.recipient.countryOfPaymentId = value;
        },
        loadOperators() {
            this.operators.loading = true;
            this.packs.data = null;
            axios
                .get(`/lookup/${this.recipient.phone}`)
                .then(response => {
                    this.operators.data = response.data;
                })
                .catch(error => {
                    console.log(error.response)
                    this.$toast.error(error.data.Message);
                })
                .finally(() => {
                    this.operators.loading = false;
                });
        },
        loadPacks() {
            if (this.operatorId > 0) {
                this.packs.loading = true;
                axios
                    .get(`/products/${this.countryIsoCode}/${this.operatorId}`)
                    .then(response => {
                        this.packs.data = response.data;
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.$toast.error(error.data.Message);
                    })
                    .finally(() => {
                        this.packs.loading = false;
                    });
            } else {
                this.packs.data = [];
            }

        },
        loadPackDetail() {
            if (this.payment.topupProductId > 0) {
                this.packs.loading = true;
                axios
                    .get(`/product/${this.payment.topupProductId}`)
                    .then(response => {
                        this.payment.amountSent = response.data.amountSent;
                        this.payment.exchangeRate = response.data.exchangeRate;
                        this.payment.amountReceived = response.data.amountReceived;
                        this.payment.totalFees = response.data.totalFees;
                        this.payment.totalCollected = response.data.totalCollected;
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.$toast.error(error.data.Message);
                    })
                    .finally(() => {
                        this.packs.loading = false;
                    });
            } else {
                this.packs.data = [];
            }
        },
        openFindSenderModal() {
            this.$refs.modalFindSender.show();
        },
        openFindRecipientModal() {
            this.$refs.modalFindRecipient.show();
        },
        selectedSender(sender) {
            this.sender = {
                fullName: sender.fullName,
                phone: sender.phone,
                clientId: sender.clientId,
                company: sender.company,
                city: sender.city,
                countryId: sender.countryId,
                address: sender.address,
                email: sender.email,
                birth: sender.birth,
                zip: sender.zip,
                stateIsoCode: sender.stateIsoCode,
                stateName: sender.stateName
            };
            this.$refs.modalFindSender.close();
        },
        selectedRecipient(recipient) {
            this.recipient = {
                fullName: recipient.fullName,
                phone: recipient.phone,
                clientId: recipient.clientId,
                countryId: recipient.countryId,
                countryOfPaymentId: recipient.countryId
            };
            this.$refs.modalFindRecipient.close();
        },
        clearSender() {
            this.sender.phone = '';
            this.sender.fullName = '';
            this.sender.company = '';
            this.sender.clientId = '';
        },
        clearRecipient() {
            this.recipient.clientId = '';
            this.recipient.fullName = '';
            this.recipient.phone = '';
            this.recipient.confirmPhone = '';
        }
    },
    computed: {
        showAmericanStates() {
            return (this.sender && parseInt(this.sender.countryId) === 1)
        },
        operatorsLength() {
            return this.operators.data.length;
        },
        countryIsoCode() {
            return this.operatorsLength > 0 ? this.operators.data.find(item => {
                return item.operatorId = this.operatorId
            }).countryIsoCode : '';
        },
        disableSave() {
            return this.payment.topupProductId === 0 || this.packs.loading || this.sending;
        },
    },
    watch: {
        operatorsLength(value) {
            if (value === 1) {
                this.operatorId = this.operators.data[0].operatorId;
                this.loadPacks();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.content {
    padding-bottom: 80px;
}
</style>