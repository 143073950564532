<template>
    <nav class="mt-4" v-show="totalPages > 0">
        <ul class="pagination justify-content-center">
            <li class="page-item">
                <button v-show="actualPage != 1" type="button" class="page-link" @click="changePage(actualPage - 1)" :disabled="actualPage === 1">
                    <span aria-hidden="true">&laquo; Previous Page</span>
                </button>
            </li>
            <select v-model="selectedOption" class="page-link" @change="handleOptionChange">
                <option class="page-item" v-for="pageNumber in pagesToShow" :key="pageNumber" :value="pageNumber">Page {{ pageNumber }}</option>
            </select>
            <li class="page-item">
                <button v-show="actualPage != totalPages" type="button" class="page-link" @click="changePage(actualPage + 1)" :disabled="actualPage === totalPages">
                    <span aria-hidden="true">&raquo; Next Page </span>
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'Pagination',
    props: {
        actualPage: Number,
        totalPages: Number,
    },
    methods: {
        changePage(page) {
            this.selectedOption = page;
            this.$emit('change', page);
        },
        handleOptionChange() {
            // Your logic here
            this.changePage(this.selectedOption);
        },
    },
    computed: {
        selectedOption: {
            get: function () {
                return this.actualPage;
            },
            set: function (modifiedValue) {
                this.actualPage = modifiedValue;
            },
        },
        pagesToShow() {
            return this.totalPages;
        },
    },
};
</script>

<style lang="scss" scoped>
@import './src/assets/scss/variables';
.active {
    background-color: $primary;
}
</style>
