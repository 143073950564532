<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4">
                <h1 id="pageTitle" class="page-title text-primary text-uppercase">Tasks: Paid After 7 Days</h1>
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-3 mb-3 mb-md-0">
                                <label for="fromDate" class="form-label">From</label>
                                <div class="input-group">
                                    <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                    <input type="text" class="form-control datePicker datePicker-input" id="fromDate" placeholder="" />
                                </div>
                            </div>
                            <div class="col-md-3">
                                <label for="toDate" class="form-label">To</label>
                                <div class="input-group">
                                    <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                    <input type="text" class="form-control datePicker datePicker-input" id="toDate" placeholder="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div>
                        <button type="button" @click="displayData" class="btn btn-primary text-uppercase" :disabled="loading"><i class="fas fa-search"></i> {{ loading ? 'Wait...' : 'Display' }}</button>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="data !== null && data.length > 0" class="mb-5">
                    <div class="table-responsive">
                        <table class="table table-borderless caption-top">
                            <caption>
                                <span id="ordersFound" class="fw-bold">{{ data.length }}</span>
                                orders found
                            </caption>
                            <thead class="bg-light">
                                <tr>
                                    <th># Order</th>
                                    <th>Company Name</th>
                                    <th>Creation Date</th>
                                    <th>Payment Date</th>
                                    <th class="text-center rounded-end">Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.orderId">
                                    <td>
                                        <router-link :to="{ name: 'Order Detail', params: { orderId: item.orderId } }" class="fw-bold text-decoration-none">{{ item.orderId }}</router-link>
                                    </td>
                                    <td>
                                        {{ item.companyName }}
                                    </td>
                                    <td>
                                        {{ item.creationDate }}
                                    </td>
                                    <td>
                                        {{ item.paymentDate }}
                                    </td>
                                    <td class="text-center">
                                        <button type="button" @click="openNotes(item.orderId)" class="btn btn-outline-primary btn-sm">Notes</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div v-else>
                    <div class="alert alert-danger" v-if="error !== null">
                        {{ error.data.Message ? error.data.Message : error.data.title }} <span class="fw-bold">({{ error.status }})</span>
                    </div>
                    <div id="noOrderFound" class="alert alert-warning" v-show="data !== null && data.length === 0">No order found.</div>
                </div>
            </div>
        </div>
        <modal-notes ref="modalNotes"></modal-notes>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Main';
import ModalNotes from '@/views/order/modals/Notes';
import axios from 'axios';
import { Datepicker } from 'vanillajs-datepicker';

export default {
    name: 'PaidAfterSevenDaysOrders',
    components: {
        Layout,
        ModalNotes,
    },
    data() {
        return {
            data: null,
            error: null,
            loading: false,
            saving: false,
            fromDate: null,
            toDate: null,
        };
    },
    mounted() {
        this.fromDate = new Datepicker(document.getElementById('fromDate'));
        this.toDate = new Datepicker(document.getElementById('toDate'));
    },
    methods: {
        displayData() {
            this.loading = true;
            axios
                .get('/niloagent/CustomerServiceTask/PaidAfterSevenDaysList', {
                    params: {
                        startDate: this.fromDate.getDate('yyyy-mm-dd'),
                        endDate: this.toDate.getDate('yyyy-mm-dd'),
                    },
                })
                .then((response) => {
                    this.data = response.data.items;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openNotes(orderId) {
            this.$refs.modalNotes.show(orderId);
        },
        openHandlers(orderId) {
            this.$refs.modalHandler.show(orderId);
        },
    },
};
</script>

<style lang="scss" scoped>
footer {
    z-index: 100;
}
table tr td {
    white-space: pre-line;
}
</style>
