<template>
    <layout>
        <div class="container">
            <alert show="true" code="404" status="404">Page not found!</alert>
        </div>
    </layout>
</template>

<script>
import Alert from "@/components/Alert"
import Layout from "@/views/layouts/Main";

export default {
    name: "NotFound",
    components: {
        Alert,
        Layout,
    }
}
</script>

<style scoped>

</style>