import axios from 'axios';
import { formatDateToUs } from '@/helpers/formatDate';

export default {
    namespaced: true,
    state: {
        orderId: 0,
        sender: null,
        recipient: null,
        paymentInformation: null,
        additionalInformation: null,
        orderReferenceNumber: 0,
        loading: false,
        modelErrors: null,
        errors: {
            payment: {
                exchangeRate: false,
                totalFees: false,
                amountSent: false,
                amountReceived: false,
                currency: false,
                services: false,
            },
        },
    },
    actions: {
        new({ state, commit }) {
            state.orderId = 0;
            commit('newSender');
            commit('newRecipient');
            commit('newPayment');
            commit('newInformation');
            state.orderReferenceNumber = new Date().valueOf();
        },
        checkIfRecipientCountryChanged({ state, commit }, value) {
            if (state.recipient != null && value != null && parseInt(state.recipient.countryId, 10) !== parseInt(value.countryId, 10)) {
                commit('setPaymentInformation', null);
            }
        },
        async create({ state }) {
            const order = state;
            state.error = null;
            state.modelErrors = null;
            try {
                const response = await axios.post('/niloagent/createordersimple', order);

                if (response.status === 200) {
                    state.orderId = response.data.orderId;
                    return true;
                }
            } catch (error) {
                state.error = error;
                state.modelErrors = error.response.data.ModelErrors;
                return false;
            }
        },
    },
    mutations: {
        setSender(state, values) {
            state.sender = {
                ...values,
                birth: formatDateToUs(values?.birth),
            };
        },
        setRecipient(state, values) {
            state.recipient = {
                ...values,
            };
        },
        setPaymentInformation(state, values) {
            state.paymentInformation = {
                ...values,
            };
        },
        setAdditionalInformation(state, values) {
            state.additionalInformation = {
                ...values,
            };
        },
        setAdditionalInformationKeepingOccupation(state, values) {
            state.additionalInformation = {
                ...values,
                payMethod: state.additionalInformation.payMethod,
                senderOccupation: state.additionalInformation.senderOccupation,
            };
        },
        setReferenceNumber(state, value) {
            state.orderReferenceNumber = value;
        },
        setOrderId(state, value) {
            state.orderId = value;
        },
        newSender(state) {
            state.sender = {
                clientId: '',
                senderId: '',
                city: '',
                countryId: 1,
                stateName: '',
                stateIsoCode: 0,
                document1Type: 0,
                document1Issueby: 0,
                document2Type: 0,
                document2Issueby: 0,
                document1Object: null,
                document2Object: null,
                occupation: '',
            };
        },
        newRecipient(state) {
            state.recipient = {
                clientId: '',
                countryId: state?.recipient?.countryId ?? 0,
                countryIsoCode: '',
                countryOfPaymentId: state?.recipient?.countryOfPaymentId ?? 0,
                stateIsoCode: 0,
                relationshipToSender: '',
            };
        },
        newPayment(state) {
            state.paymentInformation = {
                paymentMethod: '',
                paymentMethodName: '',
                paymentMethodId: 0,
                currencyId: 0,
                currencySymbol: '',
                currencyDescription: '',
                currencyIsoCode: '',
                payLocationId: 0,
                payLocationName: '',
                amountSent: 0,
                bankId: 0,
                bankName: '',
                abaOrNo: '',
                branchNo: '',
                accountNumber: '',
                accountType: '',
                branchLocation: '',
                pixTypeId: 0,
                pixId: '',
                exchangeRate: 0,
                amountReceived: 0,
                flatFee: 0,
                percentFee: 0,
                otherFees: 0,
                totalFees: 0,
                totalCollected: 0,
                creditCardChecked: false,
                boletoNumber: '',
                debitCardSurcharge: 0,
                recipientDocumentNumber: '',
                recipientDocumentType: '',
                recipientDocumentExpiration: '',
                recipientBirthday: '',
                amountSentIsChanging: false,
            };
        },
        newInformation(state) {
            state.additionalInformation = {
                payMethod: 'CASH',
                sourceOfFunds: '',
                senderOccupation: '',
                messageToRecipient: '',
                purposeOfTransaction: '',
                senderRelationshipToRecipient: '',
            };
        },
        setLoading(state, value) {
            state.loading = value;
        },
    },
    getters: {
        disableCreateOrderButton(state) {
            return state.paymentInformation.amountSentIsChanging;
        },
        isEdit(state) {
            return state.orderId && state.orderId > 0;
        },
    },
};
