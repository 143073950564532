<template>
    <div class="vh-100">
        <navbar :show-nav-bar-links="showNavbarLinks"></navbar>
        <alert-environment></alert-environment>
        <slot></slot>
    </div>
</template>

<script>
import Navbar from '@/components/Navbar';
import AlertEnvironment from '@/components/AlertEnvironment';

export default {
    name: 'Main',
    props: {
        showNavbarLinks: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Navbar,
        AlertEnvironment,
    },
};
</script>

<style scoped></style>
