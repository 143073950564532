<template>
    <div class="modal" id="modal-notes" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Order Actions & Remarks</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <div v-if="error" class="alert alert-danger">
                        {{ error.data.Message }}
                    </div>
                    <div v-if="success" class="alert alert-success">Remark sent successfully!</div>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-if="data">
                        <div class="row">
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">ORDER</h2>
                                <div class="mb-2 mb-lg-0">
                                    <div>#{{ data.invoiceId }}</div>
                                    <span class="badge" :style="{ 'background-color': data.statusColor }">{{ data.status }}</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">AGENT</h2>
                                <div class="mb-2 mb-lg-0">
                                    {{ data.agentName }}
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">CORRESPONDENT</h2>
                                <div class="mb-2 mb-lg-0">
                                    {{ data.correspondentName }}
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase text-end">DATE & TIME</h2>
                                <div class="mb-2 mb-lg-0 text-lg-end">
                                    {{ dateTimeFormatted }}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-md-6">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">SENDER #{{ data.senderId }}</h2>
                                <div class="mb-3">
                                    <div class="mb-1">{{ data.senderName }}</div>
                                    <div class="mb-1">{{ data.senderCountry }}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">RECIPIENT #{{ data.recipientId }}</h2>
                                <div class="mb-3">
                                    <div class="mb-1">{{ data.recipientName }}</div>
                                    <div class="mb-1">{{ data.recipientCountry }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-3 border-0">
                            <div class="card-body bg-light rounded-3 px-4">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="d-flex">
                                            <label class="fw-bold">Net Amount:</label>
                                            <span class="ms-auto">{{ data.amountSent }}</span>
                                        </div>
                                        <div class="d-flex">
                                            <label class="fw-bold">Total Collected:</label>
                                            <span class="ms-auto">{{ data.totalCollected }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 offset-md-4 justify-content-end">
                                        <div class="d-flex">
                                            <label class="fw-bold">Amount to be Paid:</label>
                                            <span class="ms-auto">{{ data.amountReceived }}</span>
                                        </div>
                                        <div class="d-flex">
                                            <label class="fw-bold">Payment Currency:</label>
                                            <span class="ms-auto">{{ data.currencyDescription }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 class="text-primary fs-6 fw-bold text-uppercase">NOTES REMARKS</h2>
                        <textarea cols="3" v-model="remark" class="form-control"></textarea>
                        <h2 class="text-primary fs-6 fw-bold text-uppercase mt-3">TYPE OF REMARK</h2>
                        <select class="form-select" v-model="remarkType">
                            <option value="0" selected>- Select -</option>
                            <option v-for="item in data.remarks" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                        <div v-if="data.reasons">
                            <h2 class="text-primary fs-6 fw-bold text-uppercase mt-3">REASON OF REMARK</h2>
                            <select class="form-select" v-model="reasonId">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in data.reasons" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex" v-if="data">
                    <button type="button" class="btn btn-primary me-auto text-uppercase" @click="save" :disabled="saving">Save</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase" @click="close" :disabled="saving">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import DOMPurify from 'dompurify';

export default {
    name: 'Notes',
    props: {
        defaultNote: Number,
    },
    data() {
        return {
            orderId: 0,
            modal: null,
            data: null,
            remark: null,
            remarkType: 0,
            reasonId: 0,
            loading: false,
            saving: false,
            success: false,
            error: null,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-notes'));
        this.remarkType = this.defaultNote;
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`/niloagent/order/${this.orderId}/note`)
                    .then((response) => {
                        this.data = response.data;
                        this.data.remarks.sort((a, b) => {
                            if (a.value < b.value) return -1;
                            if (a.value > b.value) return 1;
                            return 0;
                        });
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        save() {
            this.saving = true;
            axios
                .post(`/niloagent/order/${this.orderId}/note/save`, {
                    orderId: this.orderId,
                    remarkText: DOMPurify.sanitize(this.remark),
                    remarkTypeId: this.remarkType,
                    reasonId: this.reasonId,
                })
                .then(() => {
                    this.success = true;
                    this.error = null;
                    this.data = null;
                    this.$emit('saved');
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        show(orderId) {
            this.orderId = orderId;
            this.success = false;
            this.error = null;
            this.data = null;
            this.remark = null;
            this.reasonId = 0;
            this.modal.show();
            this.load();
        },
        close() {
            this.modal.hide();
        },
    },
    computed: {
        dateTimeFormatted() {
            return this.data ? this.data.createTime.replace(',', '\n') : '';
        },
    },
    watch: {
        defaultNote(newValue) {
            this.remarkType = newValue;
        },
    },
};
</script>

<style lang="scss" scoped>
textarea {
    height: 100px;
}
</style>
