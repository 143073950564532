import { render, staticRenderFns } from "./AdminReportsMenu.vue?vue&type=template&id=23010264&scoped=true&"
import script from "./AdminReportsMenu.vue?vue&type=script&lang=js&"
export * from "./AdminReportsMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23010264",
  null
  
)

export default component.exports