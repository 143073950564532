<template>
    <layout>
        <div class="loading-container" v-show="loading">
            <loading class="loading-mark" />
        </div>
        <div class="content container">
            <div class="row small-row justify-content-center mt-2">
                <div class="col-lg-6">
                    <new-order-sender-form></new-order-sender-form>
                    <new-order-recipient-form></new-order-recipient-form>
                </div>
                <div class="col-lg-6">
                    <new-order-payment-form></new-order-payment-form>
                    <new-order-information-form></new-order-information-form>

                    <div class="container d-flex align-items-center justify-content-end p-0">
                        <h2 v-if="editOrder && !confirmingOrder" class="badge bg-dark fs-6 m-0 mx-2">Editing Order #{{ order.orderId }}</h2>
                        <button type="button" class="btn btn-sm btn-primary px-4" v-show="!isDebitCardPayment" :disabled="sending || disableCreateOrderButton || confirmingOrder" @click="openConfirmOrder">{{ sending || confirmingOrder ? 'Wait...' : editOrder ? 'Save' : 'Create Order' }}</button>
                        <button type="button" class="btn btn-sm btn-primary px-4" v-show="isDebitCardPayment" :disabled="sending || disableCreateOrderButton" @click="createOrder">{{ sending ? 'Wait...' : editOrder ? 'Save' : 'Create Order' }}</button>
                    </div>
                </div>
            </div>

            <modal-confirm-order ref="modalConfirmOrder" @confirm="confirmOrder" @save="createOrder"></modal-confirm-order>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import Layout from '@/views/layouts/Main';
import NewOrderSenderForm from '@/views/order/simpleOrder/_sender';
import NewOrderPaymentForm from '@/views/order/simpleOrder/_payment';
import NewOrderRecipientForm from '@/views/order/simpleOrder/_recipient';
import NewOrderInformationForm from '@/views/order/simpleOrder/_information';

import ModalConfirmOrder from '@/views/order/modals/ConfirmOrderModal';

export default {
    name: 'NewOrder',
    components: {
        Loading,
        Layout,
        NewOrderSenderForm,
        NewOrderPaymentForm,
        NewOrderRecipientForm,
        NewOrderInformationForm,
        ModalConfirmOrder,
    },
    data() {
        return {
            sending: false,
            confirmingOrder: false,
        };
    },
    created() {
        !this.$route.params.blockCreated && this.$store.dispatch('simpleOrder/new');
    },
    methods: {
        async createOrder() {
            this.sending = true;
            if (await this.$store.dispatch('simpleOrder/create')) {
                this.$router.push({ name: 'Order Detail', params: { orderId: this.order.orderId } });
            } else {
                this.$toast.error(this.order.error.response.data.Message, { timeout: false });
            }

            this.closeConfirmOrder();
            this.sending = false;
        },
        async confirmOrder() {
            this.sending = true;
            if (await this.$store.dispatch('simpleOrder/create')) {
                this.confirmingOrder = true;
                axios
                    .post('/niloagent/createorder/confirm', { orderId: this.order.orderId })
                    .then((response) => {
                        this.$router.push({ name: 'Order Confirmation', params: { orderId: response.data.orderId } });
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.$router.push({ name: 'Order Detail', params: { orderId: this.order.orderId } });
                    })
                    .finally(() => {
                        this.closeConfirmOrder();
                        this.confirmingOrder = false;
                    });
            } else {
                this.closeConfirmOrder();
                this.$toast.error(this.order.error.response.data.Message, { timeout: false });
            }
            this.sending = false;
        },
        openConfirmOrder() {
            this.$refs.modalConfirmOrder.show();
        },
        closeConfirmOrder() {
            this.$refs.modalConfirmOrder.close();
        },
    },
    computed: {
        order() {
            return this.$store.state.simpleOrder;
        },
        isDebitCardPayment() {
            return this.order.additionalInformation.payMethod === 'DEBIT_CARD';
        },
        disableCreateOrderButton() {
            return this.$store.getters['simpleOrder/disableCreateOrderButton'];
        },
        loading() {
            return this.$store.state.simpleOrder.loading;
        },
        editOrder() {
            return this.$store.getters['simpleOrder/isEdit'];
        },
    },
};
</script>

<style lang="scss" scoped>
.loading-container {
    background: #ffffffbd;
    position: absolute;
    width: 100%;
    height: 96%;
    z-index: 9;
}

.loading-mark {
    position: absolute;
    top: 50%;
    left: 50%;
}
</style>
