<template>
    <div class="">
        <div class="container vh-100">
            <div class="row align-items-center justify-content-center flex-column h-100 gap-2">
                <h5 class="text-center text-danger mb-0">Use a Debit Card for this transaction</h5>
                <h6 class="text-center text-danger mb-0 fst-italic">Using a Credit Card may result in fees from your bank</h6>
                <div class="col-lg-4 col-md-8">
                    <loading :show="loading" />
                    <div class="alert alert-danger text-center mb-3" v-show="error.length > 0">{{ error }}</div>
                    <div class="card my-auto shadow" v-if="data">
                        <div class="card-header bg-white text-center py-4">
                            <h5 class="text-center mb-2">Payment Information</h5>
                            <h6 class="text-black-50 mb-0">Invoice: {{ orderId }}</h6>
                            <h6 class="text-black-50 mb-0">Sender: {{ senderFullname }}</h6>
                            <h6 class="text-black-50 mb-0">Recipient: {{ recipientFullname }}</h6>
                            <h6 v-show="success" class="text-black-50 mb-0">Amount Sent: ${{ amountSent }}</h6>
                        </div>
                        <div class="card-body">
                            <div class="alert alert-success mb-0 text-center" v-show="success">Payment submitted</div>
                            <div v-show="!success">
                                <div class="alert alert-danger mb-3 d-none fw-bolder py-2 fs-6">Please check fields down below:</div>
                                <div class="mb-3">
                                    <div class="input-group">
                                        <span class="input-group-text"><i class="fas fa-credit-card"></i></span>
                                        <input type="number" v-model="payment.number" name="cardNumber" class="form-control" placeholder="Card Number*" />
                                    </div>
                                    <span v-if="errors.number.error" class="text-danger">{{ errors.number.message }}</span>
                                </div>
                                <div class="mb-3">
                                    <input type="text" v-model="payment.name" class="form-control" name="cardName" placeholder="Card Holder's Name*" />
                                    <span v-if="errors.name.error" class="text-danger">{{ errors.name.message }}</span>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-8">
                                        <div class="input-group">
                                            <input type="tel" v-model="payment.expDate" v-mask="'##/##'" class="form-control" name="cardExpiration" placeholder="Exp. Date*" maxlength="7" />
                                            <span class="input-group-text">mm/yy</span>
                                            <span v-if="errors.expDate.error" class="text-danger">{{ errors.expDate.message }}</span>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" v-model="payment.cardValidationNum" class="form-control" name="cardCVV" placeholder="CVV*" />
                                        <span v-if="errors.cardValidationNum.error" class="text-danger">{{ errors.cardValidationNum.message }}</span>
                                    </div>
                                </div>
                                <hr />
                                <div class="mb-3">
                                    <input type="text" v-model="payment.addressLine1" class="form-control" name="address1" placeholder="Address 1*" />
                                    <span v-if="errors.addressLine1.error" class="text-danger">{{ errors.addressLine1.message }}</span>
                                </div>
                                <div class="mb-3">
                                    <input type="text" v-model="payment.addressLine2" class="form-control" name="address2" placeholder="Address 2" />
                                </div>
                                <div class="mb-3">
                                    <input type="text" v-model="payment.city" class="form-control" name="city" placeholder="City*" />
                                    <span v-if="errors.city.error" class="text-danger">{{ errors.city.message }}</span>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <select v-model="payment.state" name="state" class="form-select">
                                            <option value="0" disabled selected>State*</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                        <span v-if="errors.state.error" class="text-danger">{{ errors.state.message }}</span>
                                    </div>
                                    <div class="col">
                                        <input type="number" v-model="payment.zip" class="form-control" name="zipCode" placeholder="Zip Code*" />
                                        <span v-if="errors.zip.error" class="text-danger">{{ errors.zip.message }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-white" v-show="!success">
                            <button type="button" id="btnPayNow" @click="send" class="btn btn-primary w-100" :disabled="sending">{{ sending ? 'Sending...' : `Pay Now - $${data.amountSent}` }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mask } from 'vue-the-mask';
import Loading from '@/components/Loading';

export default {
    name: 'CreditDebitCardPayment',
    components: {
        Loading,
    },
    directives: { mask },
    props: {
        token: {
            type: String,
        },
    },
    data() {
        return {
            data: null,
            payment: {
                name: '',
                number: '',
                expDate: '',
                cardValidationNum: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                state: 0,
                zip: '',
                type: '',
                amountSent: '',
            },
            error: '',
            loading: false,
            sending: false,
            success: false,
            errors: {
                addressLine1: { error: false, message: '' },
                cardValidationNum: { error: false, message: '' },
                companySelect: { error: false, message: '' },
                city: { error: false, message: '' },
                expDate: { error: false, message: '' },
                name: { error: false, message: '' },
                number: { error: false, message: '' },
                state: { error: false, message: '' },
                zip: { error: false, message: '' },
            },
        };
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(`/niloagent/debitcard/${this.token}`)
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.error = error.response.data.Message;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        resetFormValidation() {
            this.errors.addressLine1.error = false;
            this.errors.addressLine1.message = '';
            this.errors.cardValidationNum.error = false;
            this.errors.cardValidationNum.message = '';
            this.errors.companySelect.error = false;
            this.errors.companySelect.message = '';
            this.errors.city.error = false;
            this.errors.city.message = '';
            this.errors.expDate.error = false;
            this.errors.expDate.message = '';
            this.errors.name.error = false;
            this.errors.name.message = '';
            this.errors.number.error = false;
            this.errors.number.message = '';
            this.errors.state.error = false;
            this.errors.state.message = '';
            this.errors.zip.error = false;
            this.errors.zip.message = '';
        },
        isNotFilled(data) {
            return data == null || data == '';
        },
        isValidExpDate(expDate) {
            const [month, year] = expDate.split('/').map(Number);
            const currentDate = new Date();
            const currentMonth = currentDate.getMonth() + 1;
            const currentYear = currentDate.getFullYear() % 100;

            if (year > currentYear || (year === currentYear && month >= currentMonth)) {
                return true;
            }
            return false;
        },
        validateForm() {
            this.resetFormValidation();
            let isValid = true;
            let { number, name, expDate, cardValidationNum, addressLine1, city, state, zip } = this.payment;

            if (number.length == 0) {
                this.errors.number.message = 'Enter a valid card number';
                this.errors.number.error = true;
                isValid = false;
            } else if (number.length < 13 || number.length > 16) {
                this.errors.number.message = 'Card number must be between 13 and 16 digits';
                this.errors.number.error = true;
                isValid = false;
            }
            if (this.isNotFilled(name)) {
                this.errors.name.message = 'Enter the card holder name';
                this.errors.name.error = true;
                isValid = false;
            }
            if (this.isNotFilled(expDate)) {
                this.errors.expDate.message = 'Enter the expiration date';
                this.errors.expDate.error = true;
                isValid = false;
            } else if (!this.isValidExpDate(expDate)) {
                this.errors.expDate.message = 'Enter a valid expiration date';
                this.errors.expDate.error = true;
                isValid = false;
            }
            if (this.isNotFilled(cardValidationNum)) {
                this.errors.cardValidationNum.message = 'Enter the CVV';
                this.errors.cardValidationNum.error = true;
                isValid = false;
            }
            if (this.isNotFilled(addressLine1)) {
                this.errors.addressLine1.message = 'Enter the address line';
                this.errors.addressLine1.error = true;
                isValid = false;
            }
            if (this.isNotFilled(city)) {
                this.errors.city.message = 'Enter the city';
                this.errors.city.error = true;
                isValid = false;
            }
            if (this.isNotFilled(state)) {
                this.errors.state.message = 'Select the state';
                this.errors.state.error = true;
                isValid = false;
            }
            if (this.isNotFilled(zip)) {
                this.errors.zip.message = 'Enter the zip code';
                this.errors.zip.error = true;
                isValid = false;
            }

            return isValid;
        },
        send() {
            if (!this.validateForm()) {
                return;
            }
            this.sending = true;
            this.success = false;
            this.payment.amountSent = this.data.amountSent;
            axios
                .post(`/niloagent/debitcard/${this.token}`, this.payment)
                .then(() => {
                    this.success = true;
                    this.error = '';
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
    },
    computed: {
        senderFullname() {
            return `${this.data.senderFirstName} ${this.data.senderLastName}`;
        },
        orderId() {
            return this.data.orderId;
        },
        recipientFullname() {
            if (this.data.recipientFirstName == null || this.data.recipientFirstName == '') {
                return this.data.recipientCompany;
            }
            return `${this.data.recipientFirstName} ${this.data.recipientLastName ?? ''}`;
        },
        amountSent() {
            return this.data.amountSent;
        },
    },
};
</script>

<style lang="scss" scoped>
.bg-light {
    height: 100%;
}
</style>
