<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4">
                <h1 id="pageTitle" class="page-title text-primary text-uppercase">Tasks: Usend Aging Process Orders</h1>
                <div class="row justify-content-end">
                    <div class="col-3">
                        <label for="countryFilter" class="form-label">Payee Filter</label>
                        <select class="form-select" v-model="payeeFilter" @change="load" id="countryFilter">
                            <option value="0" selected>- Select -</option>
                            <option v-for="item in payees" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="data !== null && data.length > 0" class="mb-5">
                    <div class="table-responsive">
                        <table class="table table-borderless caption-top">
                            <caption><span id="ordersFound" class="fw-bold">{{ data.length }}</span> orders found</caption>
                            <thead class="bg-light">
                            <tr>
                                <th># Order</th>
                                <th>Date</th>
                                <th>Ageing Days</th>
                                <th>Payee Company</th>
                                <th>Service</th>
                                <th>Sender</th>
                                <th>Recipient</th>
                                <th>Amount</th>
                                <th>Agent</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in data" :key="item.orderId">
                                <td><router-link :to="{ name: 'Order Detail', params: { orderId: item.orderId } }" class="fw-bold text-decoration-none">{{ item.orderId }}</router-link></td>
                                <td>
                                    {{ item.date }}
                                </td>
                                <td>
                                    {{ item.ageingDays }}
                                </td>
                                <td>
                                    {{ item.payeeCompany }}
                                </td>
                                <td>
                                    {{ item.service }}
                                </td>
                                <td>
                                    {{ item.sender }}
                                </td>
                                <td>
                                    {{ item.recipient }}
                                </td>
                                <td>
                                    {{ item.amount }}
                                </td>
                                <td>
                                    {{ item.agent }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "@/views/layouts/Main";
import axios from "axios";

export default {
    name: "UsendAgingProcessOrders",
    components: {
        Layout,
    },
    data() {
        return {
            data: null,
            error: null,
            loading: false,
            payees: null,
            payeeFilter: null
        }
    },
    mounted() {
        this.loadFilter();
        this.load();
    },
    methods: {
        load() {
            this.data = null;
            this.loading = true;
            axios
                .get('/niloagent/UsendCustomerServiceTask/AgingProcessOrdersList', {
                    params: {
                        agentFilter: this.payeeFilter,
                    }
                })
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadFilter() {
            axios
                .get('/niloagent/UsendCustomerServiceTask/UsendAgingProcessOrdersFilter')
                .then(response => {
                    this.payees = response.data;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>

<style lang="scss" scoped>
table tr td {
    white-space: pre-line;
}
</style>