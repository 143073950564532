import FormsMenu from "@/views/forms/FormsMenu";
import ComplianceMenu from "@/views/forms/ComplianceMenu";
import ComplianceSigns from "@/views/forms/ComplianceSigns";
import ComplianceSignsMenu from "@/views/forms/ComplianceSignsMenu";
import ComplianceDocumentsMenu from "@/views/forms/ComplianceDocumentsMenu";
import ComplianceResourcesMenu from "@/views/forms/ComplianceResourcesMenu";

export default [
    {
        path: '/forms',
        name: 'Forms',
        component: FormsMenu,
    },
    {
        path: '/compliance',
        name: 'Compliance',
        component: ComplianceMenu,
    },
    {
        path: '/compliance/documents',
        name: 'ComplianceDocuments',
        component: ComplianceDocumentsMenu,
    },
    {
        path: '/compliance/signs-menu',
        name: 'ComplianceSignsMenu',
        component: ComplianceSignsMenu,
    },
    {
        path: '/compliance/resurces',
        name: 'ComplianceResources',
        component: ComplianceResourcesMenu,
    },
    {
        path: '/compliance/signs',
        name: 'ComplianceSigns',
        component: ComplianceSigns,
    },
]