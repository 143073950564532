import Vue from 'vue';
import axios from 'axios';
import VueI18n from 'vue-i18n';
import vSelect from 'vue-select';
import Toast from 'vue-toastification';
import VueFlags from '@growthbunker/vueflags';
import { TrackData } from '@interco/track';
import Toggle from '@vueform/toggle/dist/toggle.vue2.js';

import router from './router';
import store from './store';
import App from './App.vue';
import messages from './locales/index.json';

import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all';
import 'vue-toastification/dist/index.css';
import 'vue-select/dist/vue-select.css';
import '@vueform/toggle/themes/default.css';

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

window.hash = process.env.VUE_APP_HASH;
window.hashDate = process.env.VUE_APP_HASH_DATE;
window.ENV = process.env.VUE_APP_ENV;
window.captchaSiteKey = process.env.VUE_APP_CAPTCHA;

Vue.use(Toast);
Vue.use(VueI18n);
Vue.use(VueFlags, {
    iconPath: '/flags/',
});
Vue.component('v-select', vSelect);
Vue.component('toggle-button', Toggle);

const i18n = new VueI18n({
    locale: 'en',
    messages,
});

Vue.prototype.$t = function (key, locale, values) {
    const result = this.$i18n.t(key, locale, values);

    if (result === key) {
        return '';
    }
    if (result === '') {
        return key;
    }
    return result;
};

TrackData.initOrUpdate({
    runtimeEnv: process.env.VUE_APP_ENV === 'PRD' ? 'production' : 'staging',
    projectName: 'frontend-nilo-agent',
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        let errorMessage = 'An error occurred!';
        if (error.response?.data) {
            errorMessage = error.response.data.Message;
        } else if (error.response?.status === 401 || error.response?.status === 403) {
            errorMessage = 'Session expired! Please login again';
        }

        TrackData.error({
            transactionSubType: 'REQUEST_ERROR',
            INFO: error.toString(),
            TYPE_ERROR: errorMessage,
        });

        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                await store.dispatch('auth/logout');
                return Promise.reject({
                    response: {
                        data: {
                            Message: 'Session expired! Please login again',
                        },
                    },
                });
            } else if (error.response.status === 404) {
                return Promise.reject({
                    response: {
                        data: {
                            Message: 'Error 404 Not Found!',
                        },
                    },
                });
            } else return Promise.reject(error);
        } else if (error.request) {
            return Promise.reject({
                response: {
                    data: {
                        Message: 'A connection error occurred!',
                    },
                },
            });
        } else {
            // Something happened in setting up the request that triggered an Error
            return Promise.reject({
                response: {
                    data: {
                        Message: 'A error occurred!',
                    },
                },
            });
        }
    }
);

new Vue({
    store,
    router,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
