import axios from 'axios';
import Vue from 'vue';

export const findSenders = async (params) => {
    const toast = Vue.prototype.$toast;
    try {
        const senders = await axios.get('/niloagent/createordersimple/sender/find', { params });
        return senders.data;
    } catch (error) {
        toast.error(`Fail to get senders: ${error.response.data.Message}`);
    }
};
