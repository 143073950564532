<template>
    <div class="alert mt-5 text-center" :class="alertType" v-show="show">
        <h2>Error: <span class="fw-bold">{{ status }}</span></h2>
        <span class="fw-bold">{{ code }}:</span> <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Alert",
    props: {
        status: String,
        code: String,
        show: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {

        }
    },
    computed: {
        alertType() {
            switch (this.status) {
                case 500:
                    return 'alert-danger';
                case 400:
                    return 'alert-warning';
                case 200:
                    return 'alert-success';
                default:
                    return 'alert-primary'
            }

        }
    }

}
</script>

<style scoped>

</style>