<template>
    <layout>
        <div class="container pt-4 d-print-none pb-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Balance Statement</h1>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 mb-3 mb-md-0">
                            <label for="dateRange" class="form-label">Date range</label>
                            <select class="form-select" v-model="dateRangeType" id="dateRange">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in dateRange" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                        <div class="col-md-3 mb-3 mb-md-0">
                            <label for="fromDate" class="form-label">From</label>
                            <div class="input-group">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" class="form-control datePicker datePicker-input" id="fromDate" placeholder="" />
                            </div>
                            <span v-if="errors.fromDate.error" class="text-danger">{{ errors.fromDate.message }} </span>
                        </div>
                        <div class="col-md-3">
                            <label for="toDate" class="form-label">To</label>
                            <div class="input-group">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" class="form-control datePicker datePicker-input" id="toDate" placeholder="" />
                            </div>
                            <span v-if="errors.toDate.error" class="text-danger">{{ errors.toDate.message }} </span>
                        </div>
                    </div>
                    <div class="row" v-if="!isAgent">
                        <div class="col-md-6 mb-2 mt-3">
                            <label for="company" class="form-label">Company</label>
                            <v-select :options="companies" :clearable="false" :reduce="(item) => item.id" label="value" v-model="companyId"></v-select>
                        </div>
                        <span v-if="errors.companySelect.error" class="text-danger">{{ errors.companySelect.message }} </span>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div>
                    <button type="button" @click="displayData" class="btn btn-sm btn-primary text-uppercase" :disabled="loading"><i class="fas fa-search"></i> {{ loading ? 'Wait...' : 'Display' }}</button>
                    <button type="button" @click="exportData" class="btn btn-sm btn-primary text-uppercase ms-3" :disabled="exporting"><i class="fas fa-download"></i> <span v-show="!exporting">Export</span><span v-show="exporting">Wait...</span></button>
                </div>
                <button type="button" class="btn btn-sm btn-outline-primary text-uppercase ms-auto" @click="print" :disabled="!data"><i class="fas fa-print"></i> Print</button>
            </div>
        </div>
        <loading :show="loading" />
        <div v-if="data && !loading">
            <div class="container mb-4">
                <div class="row d-none d-print-flex">
                    <div class="col-6 text-start">
                        <img src="@/assets/img/banco-inter.svg" class="logo" />
                    </div>
                    <div class="col-6 text-end d-flex">
                        <h1 class="fs-4 mb-0 mt-auto ms-auto">Balance Statement</h1>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
                <div class="row">
                    <div class="col-6">
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Date of Statement:</label></div>
                            <div class="col-6">{{ data.dateOfStatement }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Parent Company:</label></div>
                            <div class="col-6">{{ data.parentCompany }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Agent:</label></div>
                            <div class="col-6">{{ data.agent }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Account Number:</label></div>
                            <div class="col-6">{{ data.accountNumber }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Statement Period</label></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold">From:</label></div>
                            <div class="col-6">{{ data.statetementPeriodFrom }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold">To:</label></div>
                            <div class="col-6">{{ data.statetementPeriodTo }}</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div v-if="data.items.length > 0">
                            <div class="row mb-2">
                                <div class="col-6 text-end"><label class="fw-bold text-uppercase">Beginning Balance:</label></div>
                                <div class="col-6">{{ data.beginningBalance }}</div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-6 text-end"><label class="fw-bold text-uppercase">Ending Balance:</label></div>
                                <div class="col-6">{{ data.endingBalance }}</div>
                            </div>
                        </div>
                        <div class="row mb-2" v-else>
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Current Balance:</label></div>
                            <div class="col-6">{{ data.currentBalance }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Account Credit:</label></div>
                            <div class="col-6">{{ data.accountCredit }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end">
                                <label class="fw-bold text-uppercase">
                                    Available Credit:<br />
                                    (<span class="text-capitalize small">On Balance Closing</span>)
                                </label>
                            </div>
                            <div class="col-6">{{ data.availableCredit }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Currency:</label></div>
                            <div class="col-6">{{ data.currency }}</div>
                        </div>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
            </div>
            <div class="container-fluid">
                <div class="table-responsive" v-if="data.items.length > 0">
                    <table class="table table-borderless">
                        <thead class="bg-light">
                            <tr>
                                <th class="rounded-start">Date</th>
                                <th>Reference</th>
                                <th>Agent Reference #</th>
                                <th>Type</th>
                                <th>Description</th>
                                <th>Debits</th>
                                <th>Credits</th>
                                <th class="rounded-end">Balance</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data.items" :key="index">
                                <td>
                                    {{ item.date }}
                                </td>
                                <td>
                                    {{ item.reference }}
                                </td>
                                <td>
                                    {{ item.agentReference }}
                                </td>
                                <td>
                                    {{ item.type }}
                                </td>
                                <td>
                                    {{ item.description }}
                                </td>
                                <td>
                                    {{ item.decrease }}
                                </td>
                                <td>
                                    {{ item.increase }}
                                </td>
                                <td>
                                    {{ item.balance }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="bg-light">
                            <tr>
                                <th>TOTAL:</th>
                                <th></th>
                                <th>{{ data.numberOfTransactions }} Transactions</th>
                                <th></th>
                                <th></th>
                                <th>{{ data.totalDecrease }}</th>
                                <th>{{ data.totalIncrease }}</th>
                                <th>{{ data.totalBalance }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="alert alert-warning text-center container" v-else>
                    <h4 class="fw-semibold">There are no transactions in this report</h4>
                    <span class="fst-italic">(Please modify your search criteria and try again)</span>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';
import { Datepicker } from 'vanillajs-datepicker';

const HTTP_NO_CONTENT = 204;

export default {
    name: 'BalanceStatementFilter',
    components: {
        Layout,
        Loading,
    },
    data() {
        return {
            dateRangeType: 0,
            fromDate: null,
            toDate: null,
            country: 0,
            company: 0,
            dateRange: [],
            countries: [],
            companies: [],
            loading: false,
            exporting: false,
            data: null,
            companyId: null,
            errors: {
                fromDate: { error: false, message: '' },
                toDate: { error: false, message: '' },
                companySelect: { error: false, message: '' },
            },
        };
    },
    mounted() {
        this.fromDate = new Datepicker(document.getElementById('fromDate'));
        this.toDate = new Datepicker(document.getElementById('toDate'));
        this.loadDateRange();
        this.loadCompanies();
        this.dateRangeType = 1;
    },
    methods: {
        loadDateRange() {
            this.dateRange = [
                {
                    id: 1,
                    value: 'Today',
                    range: {
                        from: moment().format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 2,
                    value: 'Yesterday',
                    range: {
                        from: moment().subtract(1, 'days').format('MM/DD/YYYY'),
                        to: moment().subtract(1, 'days').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 3,
                    value: 'This Week',
                    range: {
                        from: moment().day('0').format('MM/DD/YYYY'),
                        to: moment().day('6').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 4,
                    value: 'Last Week',
                    range: {
                        from: moment().day('0').subtract(7, 'days').format('MM/DD/YYYY'),
                        to: moment().day('6').subtract(7, 'days').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 5,
                    value: 'This Month',
                    range: {
                        from: moment().startOf('month').format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 6,
                    value: 'Last Month',
                    range: {
                        from: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
                        to: moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 7,
                    value: 'Past 30 Days',
                    range: {
                        from: moment().subtract(30, 'days').format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
            ];
        },
        loadCompanies() {
            axios
                .get('/niloagent/accounting/companieschildrenaccounting')
                .then((response) => {
                    this.companies = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        getParams() {
            return {
                startDate: this.fromDate.getDate('yyyy-mm-dd'),
                endDate: this.toDate.getDate('yyyy-mm-dd'),
                company: this.isAgent ? 1 : this.companyId,
                productOrigin: 'US',
            };
        },
        resetFormValidation() {
            this.errors.fromDate.error = false;
            this.errors.fromDate.message = '';
            this.errors.toDate.error = false;
            this.errors.toDate.message = '';
            this.errors.companySelect.error = false;
            this.errors.companySelect.message = '';
        },
        validateForm() {
            let isValid = true;
            const params = this.getParams();
            this.resetFormValidation();

            if (params.startDate == null) {
                this.errors.fromDate.error = true;
                this.errors.fromDate.message = 'From date is required';
                isValid = false;
            }
            if (params.endDate == null) {
                this.errors.toDate.error = true;
                this.errors.toDate.message = 'To date is required';
                isValid = false;
            }
            if (params.company == null) {
                this.errors.companySelect.error = true;
                this.errors.companySelect.message = 'Company is required';
                isValid = false;
            }
            if (params.startDate != null && params.endDate != null) {
                const endDateTime = new Date(params.endDate);
                const startDateTime = new Date(params.startDate);
                let diff = Math.abs(endDateTime - startDateTime);
                let days = diff / (1000 * 60 * 60 * 24);
                if (days > 90) {
                    this.errors.fromDate.error = true;
                    this.errors.fromDate.message = 'The difference between the dates must be less than 90 days';
                    this.errors.toDate.error = true;
                    this.errors.toDate.message = 'The difference between the dates must be less than 90 days';
                    isValid = false;
                }
                if (startDateTime.getTime() > endDateTime.getTime()) {
                    this.errors.fromDate.error = true;
                    this.errors.fromDate.message = 'From date must be before end date';
                    this.errors.toDate.error = true;
                    this.errors.toDate.message = 'To date must be after start date';
                    isValid = false;
                }
            }
            return isValid;
        },
        displayData() {
            if (!this.validateForm()) {
                return;
            }
            let params = this.getParams();
            this.loading = true;
            axios
                .get(`/niloagent/accounting/balancestatement`, {
                    params: {
                        startDate: params.startDate,
                        endDate: params.endDate,
                        companyId: params.company,
                    },
                })
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        exportData() {
            if (!this.validateForm()) {
                return;
            }
            this.exporting = true;
            let params = this.getParams();
            axios
                .post(
                    '/niloagent/accounting/balancestatementexcel',
                    {
                        startDate: new Date(params.startDate).toISOString().replace('Z', ''),
                        endDate: new Date(params.endDate).toISOString().replace('00:00:00.000', '23:59:59.999').replace('Z', ''),
                        company: params.company,
                        productOrigin: params.productOrigin,
                    },
                    {
                        responseType: 'blob',
                    }
                )
                .then((response) => {
                    if (response.status == HTTP_NO_CONTENT) {
                        this.$toast.error('There are no records available for the selected filter.');
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'balance-statement.xlsx');
                        document.body.appendChild(link);
                        link.click();
                    }
                })
                .catch(async (error) => {
                    let responseError = JSON.parse(await error.response.data.text());
                    this.$toast.error(responseError.Message);
                })
                .finally(() => {
                    this.exporting = false;
                });
        },
        print() {
            window.print();
        },
    },
    computed: {
        isAgent() {
            return this.$store.getters['agent/isAgent'];
        },
    },
    watch: {
        dateRangeType(value) {
            if (value > 0) {
                const selected = this.dateRange.find((item) => {
                    return item.id === value;
                });
                this.fromDate.setDate(selected.range.from);
                this.toDate.setDate(selected.range.to);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    max-width: 100px;
}
@media print {
    html,
    body,
    p,
    label,
    div {
        font-size: 8pt !important;
    }
    h1 {
        font-size: 14pt !important;
    }
}
</style>
