<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Admin Reports</h1>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'Accounting Custom Report' }">
                                <MenuButton>
                                    <template v-slot:label>Custom Report</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'Accounting Daily Activity' }">
                                <MenuButton>
                                    <template v-slot:label>Daily Activity</template>
                                </MenuButton>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Main';
import MenuButton from '@/components/MenuButton';

export default {
    name: 'AdminReportsMenu',
    components: {
        Layout,
        MenuButton,
    },
    methods: {
        notImplemented() {
            this.$toast.error('This functionality was not implemented yet');
        },
    },
};
</script>

<style scoped></style>
