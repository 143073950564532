<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Compliance Forms</h1>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="mb-4 col-3">
                             <router-link :to="{ name: 'ComplianceDocuments' }">
                                <MenuButton>
                                        <template v-slot:label>Documents</template>
                                </MenuButton>
                             </router-link>
                        </div>
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'ComplianceSignsMenu' }">
                                <MenuButton>
                                        <template v-slot:label>Signs</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'ComplianceResources' }">
                                <MenuButton>
                                        <template v-slot:label>Resources</template>
                                </MenuButton>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </layout>
</template>

<script>
import Layout from "@/views/layouts/Main"
import MenuButton from "@/components/MenuButton"

export default {
    name: "FormsMenu",
    components: {
        Layout,
        MenuButton,
    },
    methods: {
        notImplemented() {
            this.$toast.error("This functionality was not implemented yet");
        },
    }
}
</script>

<style scoped>

</style>