import Create from '@/views/order/topup/Create'
import TopUpReceipt from '@/views/order/topup/Receipt'

export default [
  {
    path: '/order/top-up',
    name: 'Top Up',
    component: Create,
  },
  {
    path: '/order/receipt/top-up/:orderId',
    name: 'Top Up Receipt',
    component: TopUpReceipt,
    props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
  },
]
