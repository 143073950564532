<template>
    <div class="d-flex justify-content-center">
        <div class="spinner-grow text-primary mt-2 mb-4" v-show="show">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        show: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped></style>
