<template>
    <div class="modal" id="modal-confirm-release-order" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Confirm Release Order</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <h3 class="fs-6 fw-bold mb-3">Are you sure you want to release this order?</h3>
                    <ul class="m-0 list-unstyled">
                        <li class="mb-2">Press Confirm to approve the Order.</li>
                    </ul>
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" id="confirmReleaseOrder" class="btn btn-primary me-auto text-uppercase" @click="confirm">Confirm</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase" @click="close">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    name: "ConfirmReleaseOrder",
    data() {
        return {
            modal: null,
        }
    },
    mounted () {
        this.modal = new Modal(document.getElementById('modal-confirm-release-order'));
    },
    methods: {
        confirm() {
            this.$emit('confirmed');
            this.modal.hide();
        },
        show() {
            this.modal.show();
        },
        close() {
            this.modal.hide();
        }
    }
}
</script>

<style scoped>

</style>