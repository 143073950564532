<template>
    <div class="modal" id="modal-announcements" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 px-md-5 py-md-4">
                    <h5 class="modal-title">Announcements & messages</h5>
                    <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-md-5">
                    <loading :show="loading"></loading>
                    <ul class="list-unstyled mt-4">
                        <li class="border-start ps-3 border-secondary border-2" v-for="item in data" :key="item.id">
                            <h5 class="text-primary">
                                <span :class="{ 'px-2 py-1 bg-secondary': item.isHighlighted }">{{ item.title }}</span>
                            </h5>
                            <p>{{ item.announcement }}</p>
                        </li>
                    </ul>
                    <div class="divider border-light"></div>
                    <div class="d-md-flex">
                        <div class="form-check text-primary d-flex justify-content-end mb-3 mb-md-0">
                            <input class="form-check-input border-primary" type="radio" value="true" v-model="checked" @change="onChangeCheck" id="confirmAnnoucements" :checked="checked" />
                            <label class="form-check-label" for="confirmAnnoucements"> I have read and understood the announcements and messages. </label>
                        </div>
                        <button id="btnAnnouncement" type="button" class="btn text-uppercase ms-md-auto" @click="modal.hide()" :class="{ 'btn-primary': checked, 'btn-light': !checked }" :disabled="!checked">Continue <i class="fas fa-arrow-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import Loading from './Loading.vue';

export default {
    components: { Loading },
    name: 'Announcements',
    data() {
        return {
            checked: true,
            modal: null,
            data: [],
            haveReadDate: false,
            haveRead: false,
            loading: false,
        };
    },
    async mounted() {
        this.modal = new Modal(document.getElementById('modal-announcements'));

        if (this.$store.state.announcements.data.length === 0) {
            await this.getAnnouncements();
            this.checkIfExistNewMessages();
        }
    },
    methods: {
        async getAnnouncements() {
            this.loading = true;
            try {
                const response = await axios.get('/niloagent/home/announcements');

                if (response.status === 200) {
                    this.data = response.data;
                    this.$store.commit('setAnnouncements', this.data);
                }
            } catch (error) {
                console.error(error);
            }
            this.loading = false;
        },
        onChangeCheck() {
            this.haveRead = !!this.checked;
            this.haveReadDate = Date.now();
            this.$store.commit('haveReadAnnouncements', !!this.checked);
        },
        checkIfExistNewMessages() {
            if (localStorage.getItem('announcements.haveReadDate') !== null) {
                this.haveReadDate = localStorage.getItem('announcements.haveReadDate');
                this.haveRead = true;
            }

            if (this.haveReadDate) {
                this.data.forEach((item) => {
                    if (new Date(item.date) > this.haveReadDate) {
                        this.haveRead = false;
                    }
                });
            } else this.checked = false;

            if (!this.haveRead) {
                this.modal.show();
            }
        },
        async openModal() {
            this.getAnnouncements();
            this.modal.show();
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-header {
    h5 {
        font-size: 1rem;
    }
}
.modal-body {
    h5 {
        font-weight: 600;
        font-size: 0.9rem;
    }
}
</style>
