<template>
    <div class="modal" id="modal-cancel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Order Cancel Request</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <div v-if="error" class="alert alert-danger">
                        {{ error.data.Message }}
                    </div>
                    <div v-if="success" class="alert alert-success">Order was cancel successfully!</div>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div v-if="data">
                        <div class="row">
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">ORDER</h2>
                                <div class="mb-2 mb-lg-0">
                                    #{{ data.invoiceId }} <span class="badge" :style="{ 'background-color': data.statusColor }">{{ data.status }}</span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">AGENT</h2>
                                <div class="mb-2 mb-lg-0">
                                    {{ data.agentName }}
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">CORRESPONDENT</h2>
                                <div class="mb-2 mb-lg-0">
                                    {{ data.correspondentName }}
                                </div>
                            </div>
                            <div class="col-md-3">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase text-end">DATE & TIME</h2>
                                <div class="mb-2 mb-lg-0 text-lg-end">
                                    {{ data.createTime }}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-md-6">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">SENDER #{{ data.senderId }}</h2>
                                <div class="mb-3">
                                    <div class="mb-1">{{ data.senderName }}</div>
                                    <div class="mb-1">{{ data.senderCountry }}</div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <h2 class="text-primary fs-6 fw-bold text-uppercase">RECIPIENT #{{ data.recipientId }}</h2>
                                <div class="mb-3">
                                    <div class="mb-1">{{ data.recipientName }}</div>
                                    <div class="mb-1">{{ data.recipientCountry }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="card my-3 border-0">
                            <div class="card-body bg-light rounded-3 px-4">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="d-flex">
                                            <label class="fw-bold">Net Amount:</label>
                                            <span class="ms-auto">{{ data.amountSent }}</span>
                                        </div>
                                        <div class="d-flex">
                                            <label class="fw-bold">Total Collected:</label>
                                            <span class="ms-auto">{{ data.totalCollected }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 offset-md-4 justify-content-end">
                                        <div class="d-flex">
                                            <label class="fw-bold">Amount to be Paid:</label>
                                            <span class="ms-auto">{{ data.amountReceived }}</span>
                                        </div>
                                        <div class="d-flex">
                                            <label class="fw-bold">Payment Currency:</label>
                                            <span class="ms-auto">{{ data.currencyDescription }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="alert alert-warning text-center fs-6">Please select the reason for cancelling the order</div>
                        <div class="row" v-show="showCancelationRequestFields">
                            <div class="col-md-6">
                                <label for="dateCanceledRequestElem" class="form-label">Date Canceled Request:</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <div class="input-group">
                                    <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                    <input type="text" class="form-control datePicker datePicker-input" id="dateCanceledRequestElem" placeholder="" :disabled="saving" />
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Cancelation Request Type:</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <select class="form-select mb-3" :disabled="saving" v-model="cancelationRequestTypeId">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in data.requestTypes" :value="item.id" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row" v-show="!showCancelationRequestFields">
                            <div class="col-md-6">
                                <label class="form-label mb-1">Reason:</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <select class="form-select mb-3" v-model="cancelationCodeId" :disabled="saving">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in data.reasons" :value="item.id" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label">Cancelation Request Type:</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <select class="form-select mb-3" :disabled="saving" v-model="cancelationRequestTypeId">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in data.requestTypes" :value="item.id" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="row" v-show="showCancelationRequestFields">
                            <div class="col-md">
                                <label class="form-label mb-1">Reason:</label><span class="mandatory-mark tiny-mandatory-mark">*</span>
                                <select class="form-select mb-3" v-model="cancelationCodeId" :disabled="saving">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in data.reasons" :value="item.id" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                        </div>

                        <label class="form-label mb-1">Message:</label>
                        <textarea cols="3" v-model="cancelationMessage" class="form-control" :disabled="saving"></textarea>

                        <div class="row" v-show="!showCancelationRequestFields">
                            <div class="alert alert-warning text-center fs-6" style="margin-top: 10px"><input class="form-check-input" type="checkbox" v-model="cancelationApprovalAgent" value="true" /> By selecting the “Cancel” button pertaining to Invoice {{ data.invoiceId }}, I am confirming the client’s agreement to the predefined terms and conditions. This communication also affirms the reimbursement to the client of the entire amount including fee: US$ {{ data.amountSent }}.</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer d-flex" v-if="data">
                    <button type="button" class="btn btn-primary me-auto text-uppercase" @click="save" :disabled="saving || !canCancel || !needAgentApproval">{{ saving ? 'Wait..' : 'Cancel Order' }}</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase" @click="close" :disabled="saving">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import { Datepicker } from 'vanillajs-datepicker';

export default {
    name: 'Cancel',
    props: {
        orderId: {
            type: Number,
        },
    },
    data() {
        return {
            modal: null,
            data: null,
            cancelationCodeId: 0,
            cancelationMessage: '',
            loading: false,
            saving: false,
            success: false,
            error: null,
            cancelationRequestTypeId: 0,
            dateCanceledRequest: null,
            dateCanceledRequestElem: null,
            cancelationApprovalAgent: false,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-cancel'));
    },
    updated() {
        if (this.showCancelationRequestFields) {
            const elemCanceledDate = document.getElementById('dateCanceledRequestElem');

            if (elemCanceledDate) {
                this.dateCanceledRequestElem = new Datepicker(elemCanceledDate);
                elemCanceledDate.addEventListener('changeDate', () => {
                    this.dateCanceledRequest = this.dateCanceledRequestElem.getDate('mm/dd/yyyy');
                });
            }
        }
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`/niloagent/order/${this.orderId}/cancel`)
                    .then((response) => {
                        console.log('aqui', response.data);
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        save() {
            this.saving = true;
            axios
                .post(`/niloagent/order/${this.orderId}/cancel/save`, {
                    orderId: this.orderId,
                    cancelationCodeId: this.cancelationCodeId,
                    cancelationMessage: this.cancelationMessage,
                    cancellationRequestTypeId: this.cancelationRequestTypeId,
                    DateCancelledRequest: this.dateCanceledRequest,
                })
                .then((response) => {
                    this.success = true;
                    this.error = null;
                    this.data = null;
                    console.log(response);
                    this.$emit('canceled');
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        show() {
            this.success = false;
            this.error = null;
            this.data = null;
            this.cancelationCodeId = 0;
            this.cancelationMessage = '';
            this.cancelationRequestTypeId = 0;
            this.dateCanceledRequest = null;
            this.modal.show();
            this.load();
        },
        close() {
            this.modal.hide();
        },
        // onCancelationRequestTypeChange(e) {
        //     console.log(e.target.value, this.data.reasons.find((reason) => reason.id === e.target.value).value);
        //     // this.cancelationRequestTypeId = e.target.value;
        //     this.cancelationRequestTypeDescription = this.data.reasons.find((reason) => reason.id === e.target.value).value;
        // },
    },
    computed: {
        showCancelationRequestFields() {
            return this.$store.state.agent.headquarter;
        },
        canCancel() {
            return !this.showCancelationRequestFields || (+this.cancelationRequestTypeId !== 0 && this.dateCanceledRequest !== null && this.dateCanceledRequest !== undefined);
        },
        needAgentApproval() {
            if (this.$store.state.agent.headquarter) return true;
            else return this.cancelationApprovalAgent;
        },
    },
};
</script>

<style scoped></style>
