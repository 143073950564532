<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4">
                <h1 id="pageTitle" class="page-title text-primary text-uppercase">Tasks: Pending Refund Orders</h1>
                <div class="row justify-content-end">
                    <div class="col-3">
                        <label for="handlerFilter" class="form-label">Handler Filter</label>
                        <select class="form-select" v-model="handlerFilter" id="handlerFilter">
                            <option selected>- Select -</option>
                            <option v-for="item in handlers" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-3">
                        <label for="countryFilter" class="form-label">Country Filter</label>
                        <select class="form-select" v-model="countryFilter" id="countryFilter">
                            <option value="0" selected>- Select -</option>
                            <option v-for="item in countries" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-if="data !== null && data.length > 0" class="mb-5">
                    <div class="table-responsive">
                        <table class="table table-borderless caption-top">
                            <caption><span id="ordersFound" class="fw-bold">{{ data.length }}</span> orders found</caption>
                            <thead class="bg-light">
                            <tr>
                                <th class="text-center rounded-start"><input type="checkbox" class="form-check-input m-0" v-model="checkAll" @click="toggleCheckbox" value="true"></th>
                                <th># Order</th>
                                <th>Date</th>
                                <th>Agent</th>
                                <th>Sender</th>
                                <th>Recipient</th>
                                <th>Amount</th>
                                <th>Client ACH Payment</th>
                                <th>Last Note</th>
                                <th class="text-center rounded-end">Notes</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in data" :key="item.orderId">
                                <td class="text-center"><input class="form-check-input m-0" type="checkbox" v-model="selected" name="check" :value="item.orderId"></td>
                                <td><router-link :to="{ name: 'Order Detail', params: { orderId: item.orderId } }" class="fw-bold text-decoration-none">{{ item.orderId }}</router-link></td>
                                <td>
                                    {{ item.date }}
                                </td>
                                <td>
                                    {{ item.agent }}
                                </td>
                                <td>
                                    {{ item.sender }}
                                </td>
                                <td>
                                    {{ item.recipient }}
                                </td>
                                <td>
                                    {{ item.amount }}
                                </td>
                                <td>
                                    {{ item.achPayment }}
                                </td>
                                <td>
                                    <div>{{ item.lastNote }}</div>
                                    <span class="badge bg-danger rounded-3">{{ item.lastNoteStatusText }}</span>
                                </td>
                                <td class="text-center">
                                    <button type="button" @click="openNotes(item.orderId)" class="btn btn-outline-primary btn-sm">Notes</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="noOrderFound" class="alert alert-warning" v-show="data !== null && data.length === 0">No Results Was Found using the criteria specified.</div>
            </div>
            <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                <div class="container text-end d-flex">

                </div>
            </footer>
        </div>
    </layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/Main";

export default {
    name: "PendingRefundOrders",
    components: {
        Layout
    },
    data() {
        return {
            data: null,
            error: null,
            loading: false,
            handlers: [],
            countries: [],
            handlerFilter: null,
            countryFilter: null,
            selected: [],
            remarkId: 0,
            remarks: [],
            checkAll: false,
            saving: false
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            this.data = null;
            axios
                .get('/niloagent/CustomerServiceTask/PendingRefundOrdersList', {
                    params: {
                        handler: this.handlerFilter,
                        countryId: this.countryFilter
                    }
                })
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.data = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        toggleCheckbox() {
            if (!this.checkAll)
                this.selected = this.data.map(el => el.orderId);
            else
                this.selected = [];
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    z-index: 100;
}
table tr td {
    white-space: pre-line;
}
</style>