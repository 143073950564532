<template>
    <layout navigation-label="Sender">
        <template v-slot:content>
            <loading :show="loading" />
            <div id="sender-information" v-if="!loading && sender">
                <form autocomplete="off">
                    <h1 class="page-title text-primary mb-4 text-uppercase">Sender Information</h1>
                    <div class="row">
                        <div class="col mb-2">
                            <label for="fullName" class="form-label">Full name</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('fullName') }" id="fullName" v-model="sender.fullName" placeholder="" :disabled="!isNewSender" />
                            <span class="text-danger" v-show="showError('fullName')">{{ showError('fullName') ? showError('fullName').Message : '' }}</span>
                        </div>
                        <div class="col-md-3 col-6 mb-2">
                            <label for="telephone" class="form-label">Telephone</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('phone') }" v-model="sender.phone" id="telephone" placeholder="" />
                            <span class="text-danger" v-show="showError('phone')">{{ showError('phone') ? showError('phone').Message : '' }}</span>
                        </div>
                        <div class="col-md-3 col-6 mb-2" v-if="!isNewSender">
                            <label class="form-label">Customer number</label>
                            <p>
                                <span class="badge bg-light text-black form-control fs-5 py-2 border-light">{{ sender.clientId }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-2">
                            <label for="companyName" class="form-label">Company name</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('company') }" v-model="sender.company" id="companyName" placeholder="" :disabled="!isNewSender" />
                            <span class="text-danger" v-show="showError('company')">{{ showError('company') ? showError('company').Message : '' }}</span>
                        </div>
                        <div class="col mb-2">
                            <label for="email" class="form-label">Email</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('email') }" v-model="sender.email" id="email" placeholder="" />
                            <span class="text-danger" v-show="showError('email')">{{ showError('email') ? showError('email').Message : '' }}</span>
                        </div>
                        <div class="col-3 mb-2">
                            <label for="birthday" class="form-label">Birthday</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('birth') }" :value="sender.birth" @change="onChangeBirth" id="birthday" placeholder="mm/dd/yyyy" />
                            <span class="text-danger" v-show="showError('birth')">{{ showError('birth') ? showError('birth').Message : '' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 mb-2">
                            <label for="senderCountry" class="form-label">Country</label><label class="mandatory-mark">*</label>
                            <select-country :data="countries.data" v-model="sender.countryId" :value="sender.countryId" @change="onChangeSenderCountry" id="senderCountry"></select-country>
                        </div>
                        <div class="col-8 mb-2">
                            <label for="streetAddress" class="form-label">Street Address</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('address') }" v-model="sender.address" id="street" placeholder="" />
                            <span class="text-danger" v-show="showError('address')">{{ showError('address') ? showError('address').Message : '' }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 mb-2">
                            <label for="city" class="form-label">City</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('city') }" v-model="sender.city" id="city" placeholder="" />
                            <span class="text-danger" v-show="showError('city')">{{ showError('city') ? showError('city').Message : '' }}</span>
                        </div>
                        <div class="col-6 col-lg-3 mb-2">
                            <label for="state" class="form-label">State</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('stateIsoCode') }" v-model="sender.stateName" placeholder="" v-show="!showAmericanStates" />
                            <select class="form-select mb-1" :class="{ 'border-danger': showError('stateIsoCode') }" v-model="sender.stateIsoCode" id="state" @change="onChangeSenderState" v-show="showAmericanStates" :disabled="states.loading">
                                <option :value="states.loading && sender ? sender.stateIsoCode : 0" selected>{{ states.loading ? 'Loading...' : '- Select -' }}</option>
                                <option v-for="(item, index) in states.data" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('stateIsoCode')">{{ showError('stateIsoCode') ? showError('stateIsoCode').Message : '' }}</span>
                        </div>
                        <div class="col-3 mb-2">
                            <label for="zipCode" class="form-label">Zip code</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" class="form-control mb-1" :class="{ 'border-danger': showError('zip') }" v-model="sender.zip" @focusout="loadCityStateFromZipCode" id="zipCode" placeholder="" />
                            <span class="text-danger" v-show="showError('zip')">{{ showError('zip') ? showError('zip').Message : '' }}</span>
                        </div>
                    </div>
                    <h1 class="page-title text-primary my-4 text-uppercase">Sender Documents</h1>
                    <div class="row justify-content-center mb-3">
                        <div class="col-6 col-md-3 mb-2">
                            <label for="documentNumber-1" class="form-label">Document number</label>
                            <input autocomplete="none" type="text" class="form-control" :class="{ 'border-danger': showError('document1Number') }" v-model="sender.document1Number" id="documentNumber-1" placeholder="" />
                            <span class="text-danger" v-show="showError('document1Number')">{{ showError('document1Number') ? showError('document1Number').Message : '' }}</span>
                        </div>
                        <div class="col-6 col-md-3 mb-2">
                            <label for="documentType-1" class="form-label">Type</label>
                            <select class="form-select" v-model="sender.document1Type" @change="onChangeDoc1Type" id="documentType-1" :class="{ 'border-danger': showError('document1Type') }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc1Types" :value="item.value" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document1Type')">{{ showError('document1Type') ? showError('document1Type').Message : '' }}</span>
                        </div>
                        <div class="col-6 col-md-2 mb-2">
                            <label for="expirationDate-1" class="form-label">Expiration date</label>
                            <input autocomplete="none" type="text" class="form-control" :value="sender.document1Expiration" id="expirationDate-1" placeholder="mm/dd/yyyy" :class="{ 'border-danger': showError('document1Expiration') }" />
                            <span class="text-danger" v-show="showError('document1Expiration')">{{ showError('document1Expiration') ? showError('document1Expiration').Message : '' }}</span>
                        </div>
                        <div class="col-6 col-md-2 mb-2">
                            <label for="issueBy-1" class="form-label">Issue by</label>
                            <select class="form-select" v-model="sender.document1Issueby" id="issueBy-1" :class="{ 'border-danger': showError('document1Issueby') }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc1IssuedBy" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document1Issueby')">{{ showError('document1Issueby') ? showError('document1Issueby').Message : '' }}</span>
                        </div>
                        <div class="col-4 col-md-2 mb-2 mt-2 mt-md-0 d-flex flex-column justify-content-end">
                            <div class="btn-group">
                                <button type="button" @click="openUploadFile('doc1FileUpload')" class="btn btn-outline-primary rounded" v-show="!sender.document1Object"><i class="fas fa-upload"></i> Upload File</button>
                                <a :href="sender.document1Object ? sender.document1Object.imageUrl : '#'" target="_blank" class="btn btn-outline-primary me-1 rounded" v-show="sender.document1Object"><i class="far fa-file-alt"></i> File</a>
                                <button type="button" @click="deleteDoc1FileUpload" class="btn btn-outline-primary ms-1 rounded" v-show="sender.document1Object"><i class="far fa-trash-alt m-0"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-6 col-md-3 mb-2">
                            <label for="documentNumber-2" class="form-label">Document number</label>
                            <input autocomplete="none" type="text" class="form-control" v-model="sender.document2Number" id="documentNumber-2" placeholder="" :class="{ 'border-danger': showError('document2Number') }" />
                            <span class="text-danger" v-show="showError('document2Number')">{{ showError('document2Number') ? showError('document2Number').Message : '' }}</span>
                        </div>
                        <div class="col-6 col-md-3 mb-2">
                            <label for="documentType-2" class="form-label">Type</label>
                            <select class="form-select" v-model="sender.document2Type" @change="onChangeDoc2Type" id="documentType-2" :class="{ 'border-danger': showError('document2Type') }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc2Types" :value="item.value" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document2Type')">{{ showError('document2Type') ? showError('document2Type').Message : '' }}</span>
                        </div>
                        <div class="col-6 col-md-2 mb-2">
                            <label for="expirationDate-2" class="form-label">Expiration date</label>
                            <input autocomplete="none" type="text" class="form-control" :value="sender.document2Expiration" id="expirationDate-2" placeholder="mm/dd/yyyy" :disabled="disabledDocument2ExpirationAndIssueBy" :class="{ 'border-danger': showError('document2Expiration') }" />
                            <span class="text-danger" v-show="showError('document2Expiration')">{{ showError('document2Expiration') ? showError('document2Expiration').Message : '' }}</span>
                        </div>
                        <div class="col-6 col-md-2 mb-2">
                            <label for="issueBy-2" class="form-label">Issue by</label>
                            <select class="form-select" v-model="sender.document2Issueby" id="issueBy-2" :disabled="disabledDocument2ExpirationAndIssueBy" :class="{ 'border-danger': showError('document2Issueby') }">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in doc2IssuedBy" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                            <span class="text-danger" v-show="showError('document2Issueby')">{{ showError('document2Issueby') ? showError('document2Issueby').Message : '' }}</span>
                        </div>
                        <div class="col-4 col-md-2 mb-2 mt-2 mt-md-0 d-flex flex-column justify-content-end">
                            <div class="btn-group">
                                <button type="button" @click="openUploadFile('doc2FileUpload')" class="btn btn-outline-primary rounded" v-show="!sender.document2Object"><i class="fas fa-upload"></i> Upload File</button>
                                <a :href="sender.document2Object ? sender.document2Object.imageUrl : '#'" target="_blank" class="btn btn-outline-primary me-1 rounded" v-show="sender.document2Object"><i class="far fa-file-alt"></i> File</a>
                                <button type="button" @click="deleteDoc2FileUpload" class="btn btn-outline-primary ms-1 rounded" v-show="sender.document2Object"><i class="far fa-trash-alt m-0"></i></button>
                            </div>
                        </div>
                    </div>
                </form>
                <router-link to="/order/create/find-sender" class="btn btn-outline-primary text-uppercase mt-4 me-3"><i class="fas fa-arrow-left me-2"></i> Back</router-link>
                <button name="btnSenderNext" id="next" type="button" @click="next" class="btn btn-primary text-uppercase mt-4" :disabled="validating">{{ validating || loading ? 'Wait...' : !hasSummary ? 'Next' : 'Save & Go Summary' }} <i class="fas fa-arrow-right ms-2"></i></button>
            </div>
            <modal-file-upload ref="modalUploadFile" upload-url="/niloagent/createorder/savefile" @uploaded="saveUploadedFile"></modal-file-upload>
            <ModalClientChangedConfirmation ref="modalClientChangedConfirmation" confirmationText="" @confirmed="updateClientChangesConfirmation"></ModalClientChangedConfirmation>
            <ModalExistingSimiliarClient ref="modalExistingSimiliarClient" confirmationText="" @confirmed="clientExistsConfirmation"></ModalExistingSimiliarClient>
        </template>
    </layout>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import Layout from '@/views/layouts/CreateOrder';
import { Datepicker } from 'vanillajs-datepicker';
import SelectCountry from '@/components/SelectCountry';
import ModalFileUpload from '@/views/order/modals/FileUpload';
import ModalExistingSimiliarClient from '@/views/order/modals/ExistingSimiliarClient';
import ModalClientChangedConfirmation from '@/views/order/modals/ClientChangedConfirmation';

export default {
    name: 'Sender',
    components: {
        Layout,
        Loading,
        SelectCountry,
        ModalFileUpload,
        ModalClientChangedConfirmation,
        ModalExistingSimiliarClient,
    },
    props: {
        senderId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            sender: {
                city: '',
                countryId: 1,
                stateName: '',
                document1Object: null,
                document2Object: null,
                updateInformation: false,
                clientExists: null,
            },
            states: {
                data: [],
                loading: false,
            },
            countries: {
                data: [],
                loading: false,
            },
            doc1Types: [],
            doc2Types: [],
            doc1IssuedBy: [],
            doc2IssuedBy: [],
            docFileUpload: '',
            birthDatePicker: null,
            document1DatePicker: null,
            document2DatePicker: null,
            loading: false,
            validating: false,
            errors: [],
        };
    },
    async mounted() {
        if (this.$store.state.order.sender != null) {
            this.sender = this.$store.state.order.sender;
            this.loadDoc2Types();
            this.loadDoc1IssuedBy();
            this.loadDoc2IssuedBy();

            if (this.sender.document1Object != null) {
                this.doc1FileUpload = this.sender.document1Object;
            }

            if (this.sender.document2Object != null) {
                this.doc2FileUpload = this.sender.document2Object;
            }
        } else if (this.senderId !== null && this.senderId > 0) {
            await this.loadSender();
        }

        this.loadCountries();
        this.loadDoc1Types();
        this.loadAmericanStates();
        this.setDatePickers();
    },
    methods: {
        async loadSender() {
            this.loading = true;
            try {
                const response = await axios.get('/niloagent/createorder/find/sender', {
                    params: {
                        ClientId: this.senderId,
                        pageNumber: 1,
                    },
                });

                if (response.status === 200 && response.data.result.length > 0) {
                    this.sender = response.data.result[0];
                    this.sender.fullName = `${this.sender.fullName}`;

                    if (this.sender.document1Object != null) this.doc1FileUpload = this.sender.document1Object;

                    if (this.sender.document2Object != null) this.doc2FileUpload = this.sender.document2Object;

                    this.loadDoc2Types();
                    this.loadDoc1IssuedBy();
                    this.loadDoc2IssuedBy();
                }
            } catch (error) {
                this.sender = null;
                this.$toast.error(error.response.data.Message);
            } finally {
                this.loading = false;
            }
        },
        async loadCountries() {
            try {
                this.countries.loading = true;
                const response = await axios.get('/niloagent/createorder/filter/country');

                if (response.status === 200) {
                    this.countries.data = response.data;
                    return true;
                }

                return false;
            } catch (error) {
                this.$toast.error(`country: ${error.Message}`);
                return false;
            } finally {
                this.countries.loading = false;
            }
        },
        loadAmericanStates() {
            this.states.loading = true;
            axios
                .get('/niloagent/createorder/filter/sender/state')
                .then((response) => {
                    this.states.data = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`American States: ${error.Message}`);
                })
                .finally(() => {
                    this.states.loading = false;
                });
        },
        loadDoc1Types() {
            axios
                .get('/niloagent/createorder/filter/sender/fromtypedoc')
                .then((response) => {
                    this.doc1Types = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Files: ${error.Message}`);
                });
        },
        loadDoc2Types() {
            axios
                .get('/niloagent/createorder/filter/sender/typedoc', {
                    params: {
                        fromDocumentType: this.sender ? this.sender.document1Type : '',
                    },
                })
                .then((response) => {
                    this.doc2Types = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Files: ${error.Message}`);
                });
        },
        loadDoc1IssuedBy() {
            axios
                .get('/niloagent/createorder/filter/sender/issuedbyfilter', {
                    params: {
                        fromDocumentType: this.sender ? this.sender.document1Type : '',
                    },
                })
                .then((response) => {
                    this.doc1IssuedBy = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Issue by: ${error.Message}`);
                });
        },
        loadDoc2IssuedBy() {
            axios
                .get('/niloagent/createorder/filter/sender/issuedbyfilter', {
                    params: {
                        fromDocumentType: this.sender ? this.sender.document2Type : '',
                    },
                })
                .then((response) => {
                    this.doc2IssuedBy = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Sender Doc Issue by: ${error.Message}`);
                });
        },
        loadCityStateFromZipCode() {
            if (this.countryAndZipCodeAreFilled) {
                axios
                    .get('/niloagent/createorder/filter/sender/citystate', {
                        params: {
                            countryId: this.sender.countryId,
                            zipCode: this.sender.zip,
                        },
                    })
                    .then((response) => {
                        this.sender.city = response.data.city;
                        this.sender.stateName = response.data.state;
                    })
                    .catch((error) => {
                        this.$toast.error(`State: ${error.Message}`);
                    });
            }
        },
        onChangeDoc1Type() {
            this.loadDoc2Types();
            this.loadDoc1IssuedBy();
        },
        onChangeDoc2Type() {
            this.loadDoc2IssuedBy();
        },
        onChangeSenderState() {
            this.sender.stateIsoCode = this.selectedSenderState.id;
            this.sender.stateName = this.selectedSenderState.value;
        },
        onChangeSenderCountry() {
            this.sender.stateIsoCode = '';
            this.sender.stateName = '';
            this.loadCityStateFromZipCode();
        },
        openUploadFile(docFileUpload) {
            this.docFileUpload = docFileUpload;
            this.$refs.modalUploadFile.show();
        },
        saveUploadedFile(file) {
            if (this.docFileUpload === 'doc1FileUpload') {
                this.sender.document1Object = file;
                this.sender.document1File = file.fileKey;
            }

            if (this.docFileUpload === 'doc2FileUpload') {
                this.sender.document2Object = file;
                this.sender.document2File = file.fileKey;
            }
        },
        deleteDoc1FileUpload() {
            this.sender.document1Object = null;
            this.sender.document1File = null;
        },
        deleteDoc2FileUpload() {
            this.sender.document2Object = null;
            this.sender.document2File = null;
        },
        updateClientChangesConfirmation(confirmation) {
            this.sender.updateInformation = confirmation;
            this.$store.commit('order/setSender', this.sender);
            this.$router.push(this.redirectOnSave);
        },
        clientExistsConfirmation(confirmation, clientId) {
            this.sender.clientExists = confirmation;
            this.sender.clientId = clientId;

            this.$store.commit('order/setSender', this.sender);
            this.$router.push(this.redirectOnSave);
        },
        setDatePickers() {
            const elemBirthDatePicker = document.getElementById('birthday');
            this.birthDatePicker = new Datepicker(elemBirthDatePicker);
            this.onChangeBirth(elemBirthDatePicker);
            if (this.sender.birth) this.birthDatePicker.setDate(this.sender.birth);

            const elemExpirationDate1 = document.getElementById('expirationDate-1');
            this.document1DatePicker = new Datepicker(elemExpirationDate1);
            this.onChangeDocument1Expiration(elemExpirationDate1);
            if (this.sender.document1Expiration) this.document1DatePicker.setDate(this.sender.document1Expiration);

            const elemExpirationDate2 = document.getElementById('expirationDate-2');
            this.document2DatePicker = new Datepicker(elemExpirationDate2);
            this.onChangeDocument2Expiration(elemExpirationDate2);
            if (this.sender.document2Expiration) this.document2DatePicker.setDate(this.sender.document2Expiration);
        },
        onChangeBirth(elem) {
            elem.addEventListener('changeDate', () => {
                this.sender.birth = this.birthDatePicker.getDate('mm/dd/yyyy');
            });
        },
        onChangeDocument1Expiration(elem) {
            elem.addEventListener('changeDate', () => {
                this.sender.document1Expiration = this.document1DatePicker.getDate('mm/dd/yyyy');
            });
        },
        onChangeDocument2Expiration(elem) {
            elem.addEventListener('changeDate', () => {
                this.sender.document2Expiration = this.document2DatePicker.getDate('mm/dd/yyyy');
            });
        },
        showError(field) {
            return (
                this.errors.length > 0 &&
                this.errors.find((value) => {
                    return value.Field == field;
                })
            );
        },
        scrollUp() {
            document.getElementById('sender-information').scrollIntoView();
        },
        async changeConfirmation() {
            this.validating = true;
            try {
                const response = await axios.post('/niloagent/createorder/changeconfirmation/sender', this.sender);

                if (response.status === 200 && response.data.clientChanged) {
                    this.$refs.modalClientChangedConfirmation.show(response.data.confirmationDialogMessage, response.data.fieldsChanged);
                    return true;
                }
                return false;
            } catch (error) {
                this.$toast.error(error.response.data.Message);
                this.validating = false;
                return false;
            } finally {
                this.validating = false;
            }
        },
        async checkClientExisting() {
            this.validating = true;
            try {
                const response = await axios.post('/niloagent/createorder/clientexists/sender', this.sender);

                if (response.status === 200 && response.data.clientExists) {
                    this.$refs.modalExistingSimiliarClient.show(response.data);
                    return true;
                }

                return false;
            } catch (error) {
                this.$toast.error(error.response.data.Message);
                return false;
            } finally {
                this.validating = false;
            }
        },
        async validate() {
            this.validating = true;
            try {
                const response = await axios.post('/niloagent/createorder/validate/sender', this.sender);
                return response.status === 200;
            } catch (error) {
                this.$toast.error(error.response.data.Message);
                this.errors = error.response.data.ModelErrors;
                this.scrollUp();
                return false;
            } finally {
                this.validating = false;
            }
        },
        async next() {
            if ((await this.validate()) && !(await this.changeConfirmation()) && !(await this.checkClientExisting())) {
                this.$store.commit('order/setSender', this.sender);
                await this.$router.push(this.redirectOnSave);
            }
        },
    },
    computed: {
        isNewSender() {
            return !this.sender.clientId > 0;
        },
        showAmericanStates() {
            return this.sender && parseInt(this.sender.countryId) === 1;
        },
        disabledDocument2ExpirationAndIssueBy() {
            return Object.keys(this.sender).length > 0 && this.sender.document2Type ? this.sender.document2Type.includes('CPF') || this.sender.document2Type.includes('Social Security Number') : false;
        },
        selectedSenderState() {
            return Object.keys(this.sender).length > 0 && this.states.length > 0 && this.sender.stateIsoCode != '' ? this.states.find((item) => item.id === this.sender.stateIsoCode) : { id: this.sender.stateIsoCode, value: this.sender.stateName };
        },
        document2Type() {
            return this.sender.document2Type;
        },
        countryAndZipCodeAreFilled() {
            return parseInt(this.sender.countryId) > 0 && this.sender.zip && this.sender.zip.length > 0;
        },
        hasSummary() {
            return this.$store.getters['order/hasSummary'];
        },
        redirectOnSave() {
            return this.hasSummary ? { name: 'Order Summary' } : { name: 'Find Recipient', params: { senderId: this.sender.clientId } };
        },
    },
    watch: {
        document2Type(value) {
            if (value.includes('Social Security Number')) this.sender.document2Issueby = 'USA';
        },
        doc2IssuedBy(value) {
            if (value.length === 1) this.sender.document2Issueby = value[0].id;
        },
        senderstateIsoCode() {
            this.sender.stateIsoCode = this.selectedSenderState.id;
            this.sender.stateName = this.selectedSenderState.value;
        },
    },
};
</script>

<style lang="scss" scoped>
.btn {
    svg {
        font-size: 1rem;
    }
}
</style>
