<template>
    <div class="card">
        <div class="card-header fs-6 fw-bold text-black-50">Customer Service Usend</div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnProblemOrdersList'" to="/tasks/usend-problem-orders-list" class="text-decoration-none">Problem Orders</router-link></div>
            <button :name="'btnProblemOrdersCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getProblemOrderCount"><i class="far fa-eye m-0"></i><span v-show="showProblemOrderCount" class="ms-2">{{ problemOrderCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex">
            <div class="my-2"><router-link :name="'btnAgingProcessOrdersList'" to="/tasks/usend-aging-process-orders-list" class="text-decoration-none">Aging Process Orders</router-link></div>
            <button :name="'btnAgingProcessOrdersCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getAgingProcessOrdersCount"><i class="far fa-eye m-0"></i><span v-show="showAgingProcessOrdersCount" class="ms-2">{{ agingProcessOrdersCount }}</span></button>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "CustomerServiceUsendCard",
    data() {
        return {
            problemOrderCount: 0,
            showProblemOrderCount: false,

            agingProcessOrdersCount:0,
            showAgingProcessOrdersCount: false,
        }
    },
    methods: {
        getProblemOrderCount() {
            axios
                .get('/niloagent/UsendCustomerServiceTask/UsendProblemOrderscount')
                .then(response => {
                    this.problemOrderCount = response.data;
                    this.showProblemOrderCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showProblemOrderCount = false;
                });
        },
        getAgingProcessOrdersCount() {
            axios
                .get('/niloagent/UsendCustomerServiceTask/UsendAgingProcessOrdersCount')
                .then(response => {
                    this.agingProcessOrdersCount = response.data;
                    this.showAgingProcessOrdersCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showAgingProcessOrdersCount = false;
                });
        },
    }
}
</script>

<style scoped>

</style>