<template>
    <layout>
        <div class="container pt-4 d-print-none mb-5">
            <h1 class="page-title text-primary mb-5 text-uppercase">User Detail Report</h1>
            <div class="card mb-4">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3 mb-3 mb-md-0">
                            <label for="dateRange" class="form-label">Date range</label>
                            <select class="form-select" v-model="dateRangeType" id="dateRange">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in dateRange" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                        <div class="col-md-3 mb-3 mb-md-0">
                            <label for="fromDate" class="form-label">From</label>
                            <div class="input-group">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" class="form-control datePicker datePicker-input" id="fromDate" placeholder="" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <label for="toDate" class="form-label">To</label>
                            <div class="input-group">
                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                <input type="text" class="form-control datePicker datePicker-input" id="toDate" placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex">
                <div>
                    <button type="button" @click="displayData" class="btn btn-primary text-uppercase" :disabled="loading"><i class="fas fa-search"></i> {{ loading ? 'Wait...' : 'Display' }}</button>
                    <button type="button" @click="exportData" class="btn btn-primary text-uppercase ms-3" :disabled="loading" v-show="false"><i class="fas fa-download"></i> <span v-show="!loading">Export</span><span v-show="loading">Waiting...</span></button>
                </div>
                <button type="button" class="btn btn-outline-primary text-uppercase ms-auto" @click="print" :disabled="!data"><i class="fas fa-print"></i> Print</button>
            </div>
        </div>
        <loading :show="loading" />
        <div v-if="data && !loading">
            <div class="container mb-4">
                <div class="row d-none d-print-flex">
                    <div class="col-6 text-start">
                        <img src="@/assets/img/banco-inter.svg" class="logo" />
                    </div>
                    <div class="col-6 text-end d-flex">
                        <h1 class="fs-4 mb-0 mt-auto ms-auto">Balance Statement</h1>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
                <div class="row">
                    <div class="col-6">
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Statement Period</label></div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold">From:</label></div>
                            <div class="col-6">{{ data.statetementPeriodFrom }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold">To:</label></div>
                            <div class="col-6">{{ data.statetementPeriodTo }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Date of Statement:</label></div>
                            <div class="col-6">{{ data.dateOfStatement }}</div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Agent:</label></div>
                            <div class="col-6">{{ data.agent }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-6 text-end"><label class="fw-bold text-uppercase">Name:</label></div>
                            <div class="col-6">{{ name }}</div>
                        </div>
                    </div>
                </div>
                <hr class="d-none d-print-block" />
            </div>
            <div class="container-fluid">
                <div class="table-responsive d-print-table" v-if="data.items.length > 0">
                    <table class="table table-borderless">
                        <thead class="bg-light">
                            <tr>
                                <th class="rounded-start">Date</th>
                                <th>#Order</th>
                                <th>Amount Sent</th>
                                <th>Amount Paid to Recipient</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data.items" :key="index">
                                <td>
                                    {{ item.dateTime }}
                                </td>
                                <td>
                                    {{ item.order }}
                                </td>
                                <td>
                                    {{ item.amountSent }}
                                </td>
                                <td>
                                    {{ item.amountPaidToRecipient }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot class="bg-light">
                            <tr>
                                <th colspan="2">
                                    TOTAL: <span class="ps-3">{{ data.numberOfTransactions }} Transactions</span>
                                </th>
                                <th>{{ data.totalAmountSent }}</th>
                                <th>{{ data.totalAmountPaidToRecipient }}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div class="alert alert-warning text-center container" v-else>
                    <h4 class="fw-semibold">There are no transactions in this report</h4>
                    <span class="fst-italic">(Please modify your search criteria and try again)</span>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';
import { Datepicker } from 'vanillajs-datepicker';

export default {
    name: 'UserDetailReport',
    components: {
        Layout,
        Loading,
    },
    data() {
        return {
            dateRangeType: 0,
            fromDate: null,
            toDate: null,
            country: 0,
            company: 0,
            agent: 0,
            dateRange: [],
            countries: [],
            companies: [],
            agents: [],
            loading: false,
            data: null,
            showProfit: true,
        };
    },
    mounted() {
        this.fromDate = new Datepicker(document.getElementById('fromDate'));
        this.toDate = new Datepicker(document.getElementById('toDate'));
        this.loadAgents();
        this.loadDateRange();
        this.dateRangeType = 1;
    },
    methods: {
        loadAgents() {
            axios
                .get('/niloagent/accounting/agentsbycompany')
                .then((response) => {
                    this.agents = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Error to find agents: ${error.response.data.Message}`);
                });
        },
        loadDateRange() {
            this.dateRange = [
                {
                    id: 1,
                    value: 'Today',
                    range: {
                        from: moment().format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 2,
                    value: 'Yesterday',
                    range: {
                        from: moment().subtract(1, 'days').format('MM/DD/YYYY'),
                        to: moment().subtract(1, 'days').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 3,
                    value: 'This Week',
                    range: {
                        from: moment().day('0').format('MM/DD/YYYY'),
                        to: moment().day('6').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 4,
                    value: 'Last Week',
                    range: {
                        from: moment().day('0').subtract(7, 'days').format('MM/DD/YYYY'),
                        to: moment().day('6').subtract(7, 'days').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 5,
                    value: 'This Month',
                    range: {
                        from: moment().startOf('month').format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 6,
                    value: 'Last Month',
                    range: {
                        from: moment().subtract(1, 'months').startOf('month').format('MM/DD/YYYY'),
                        to: moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY'),
                    },
                },
                {
                    id: 7,
                    value: 'Past 30 Days',
                    range: {
                        from: moment().subtract(30, 'days').format('MM/DD/YYYY'),
                        to: moment().format('MM/DD/YYYY'),
                    },
                },
            ];
        },
        displayData() {
            this.loading = true;
            axios
                .get(`/niloagent/accounting/userdetailreport`, {
                    params: {
                        startDate: this.fromDate.getDate('yyyy-mm-dd'),
                        endDate: this.toDate.getDate('yyyy-mm-dd'),
                        agentId: this.agent,
                    },
                })
                .then((response) => {
                    this.checkAgent(this.agent);
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        exportData() {},
        print() {
            window.print();
        },
        checkAgent(value) {
            this.showProfit = value <= 0;
        },
    },
    watch: {
        dateRangeType(value) {
            if (value > 0) {
                const selected = this.dateRange.find((item) => {
                    return item.id === value;
                });
                this.fromDate.setDate(selected.range.from);
                this.toDate.setDate(selected.range.to);
            }
        },
    },
    computed: {
        name() {
            return this.$store.state.agent ? this.$store.state.agent.fullName : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    max-width: 100px;
}
</style>
