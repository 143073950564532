<template>
    <div class="modal fade" id="modal-find-sender" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-lg-down">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Find Sender</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <loading :show="loading" />
                    <div class="alert alert-danger" v-if="error">
                        {{ error.Message }}
                    </div>
                    <div class="table-responsive" v-show="!loading" v-else>
                        <table class="table table-borderless">
                            <thead class="bg-light">
                                <tr>
                                    <th class="rounded-start">Client</th>
                                    <th>Doc number</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th class="rounded-end">Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.clientId" @click="selectSender(item)">
                                    <td>{{ item.clientId }}</td>
                                    <td>{{ item.document1Number }}</td>
                                    <td>
                                        {{ item.fullName }}
                                        <br />
                                        <strong>{{ item.company }}</strong>
                                    </td>

                                    <td>
                                        {{ item.address }} - {{ item.city }}/{{ item.stateName }} -
                                        {{ item.countryIsoCode }}
                                    </td>
                                    <td>{{ item.phone }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import Loading from '@/components/Loading';

export default {
    name: 'FindSender',
    components: {
        Loading,
    },
    props: {
        customerNumber: String,
        companyName: String,
        fullName: String,
        telephone: String,
    },
    data() {
        return {
            modal: null,
            data: null,
            loading: false,
            error: null,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-find-sender'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get('/niloagent/createordersimple/sender/find/v2', {
                        params: {
                            ClientId: this.customerNumber,
                            CompanyName: this.companyName,
                            FullName: this.fullName,
                            SdPhone1: this.telephone,
                            pageNumber: 1,
                        },
                    })
                    .then((response) => {
                        this.data = response.data.result;
                    })
                    .catch((error) => {
                        this.error = error.response.data;
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        selectSender(sender) {
            this.$emit('selected', sender);
        },
        show() {
            this.data = null;
            this.error = null;
            this.modal.show();
            this.load();
        },
        close() {
            this.modal.hide();
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

tbody {
    tr:hover {
        background-color: lighten($primary, 45);
        cursor: pointer;
    }
}
</style>
