<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4 position-relative">
                <h1 class="page-title text-primary mb-3 text-uppercase">Find Order</h1>
                <div class="card mb-3">
                    <div class="accordion" id="find-order-inputs">
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button page-title text-black-50 fw-bolder" type="button" data-bs-toggle="collapse" data-bs-target="#order-number-and-date">Order number and date</button>
                            </h2>
                            <div id="order-number-and-date" class="accordion-collapse collapse show" data-bs-parent="#find-order-inputs">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-md-3 mb-3">
                                            <label for="orderNumber" class="form-label">Order number</label>
                                            <input type="number" v-model="order.number" @keyup.enter="load" class="form-control" id="orderNumber" placeholder="" />
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="customAgentOrderNumber" class="form-label">Custom agent order number</label>
                                            <input type="text" v-model="order.customAgentOrderNumber" class="form-control" @keyup.enter="load" id="customAgentOrderNumber" placeholder="" />
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="fromDate" class="form-label">From</label>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                                <input type="text" @keyup.enter="load" class="form-control datePicker datePicker-input" id="fromDate" placeholder="" />
                                            </div>
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="toDate" class="form-label">To</label>
                                            <div class="input-group mb-3">
                                                <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                                <input type="text" @keyup.enter="load" class="form-control datePicker datePicker-input" id="toDate" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 mb-3">
                                            <label for="correspondentOrderNumber" class="form-label">Correspondent Order Number</label>
                                            <input type="text" v-model="order.correspondentOrderNumber" @keyup.enter="load" class="form-control" id="correspondentOrderNumber" placeholder="" />
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="routedOrderNumber" class="form-label">Routed order number</label>
                                            <input type="text" v-model="order.routedOrderNumber" @keyup.enter="load" class="form-control" id="routedOrderNumber" placeholder="" />
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="orderStatus" class="form-label">Order status</label>
                                            <select class="form-select" v-model="order.status" id="orderStatus">
                                                <option value="0" selected>- Select -</option>
                                                <option v-for="item in filters.status" :value="item.id" :key="item.id">{{ item.value }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button page-title text-black-50 fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sender-information">Sender information</button>
                            </h2>
                            <div id="sender-information" class="accordion-collapse collapse" data-bs-parent="#find-order-inputs">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-md-4 mb-3">
                                            <label for="senderCountry" class="form-label">Country</label>
                                            <select class="form-select" v-model="sender.country" id="senderCountry">
                                                <option value="0" selected>- Select -</option>
                                                <option v-for="item in filters.countries" :value="item.id" :key="item.id">{{ item.value }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label for="senderLastName" class="form-label">Sender last name</label>
                                            <input type="text" v-model="sender.lastName" @keyup.enter="load" class="form-control" id="senderLastName" placeholder="" />
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label for="senderFirstName" class="form-label">Sender first name</label>
                                            <input type="text" v-model="sender.firstName" @keyup.enter="load" class="form-control" id="senderFirstName" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-4 mb-3">
                                            <label for="senderNumber" class="form-label">Sender number</label>
                                            <input type="text" v-model="sender.number" @keyup.enter="load" class="form-control" id="senderNumber" placeholder="" />
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label for="senderPhone" class="form-label">Sender phone</label>
                                            <input type="text" v-model="sender.phone" @keyup.enter="load" class="form-control" id="senderPhone" placeholder="" />
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label for="senderCompany" class="form-label">Sender company</label>
                                            <input type="text" v-model="sender.company" @keyup.enter="load" class="form-control" id="senderCompany" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item border-0">
                            <h2 class="accordion-header">
                                <button class="accordion-button page-title text-black-50 fw-bolder collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#recipient-information">Recipient information</button>
                            </h2>
                            <div id="recipient-information" class="accordion-collapse collapse" data-bs-parent="#find-order-inputs">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-md-4 mb-3">
                                            <label for="recipientCountry" class="form-label">Country</label>
                                            <select class="form-select" v-model="recipient.country" id="recipientCountry">
                                                <option value="0" selected>- Select -</option>
                                                <option v-for="item in filters.countries" :value="item.id" :key="item.id">{{ item.value }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label for="recipientLastName" class="form-label">Recipient last name</label>
                                            <input type="text" v-model="recipient.lastName" @keyup.enter="load" class="form-control" id="recipientLastName" placeholder="" />
                                        </div>
                                        <div class="col-md-4 mb-3">
                                            <label for="recipientFirstName" class="form-label">Recipient first name</label>
                                            <input type="text" v-model="recipient.firstName" @keyup.enter="load" class="form-control" id="recipientFirstName" placeholder="" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 mb-3">
                                            <label for="recipientNumber" class="form-label">Recipient CPF</label>
                                            <input type="text" v-model="recipient.cpf" @keyup.enter="load" class="form-control" id="recipientCpf" placeholder="" />
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="recipientNumber" class="form-label">Recipient number</label>
                                            <input type="text" v-model="recipient.number" @keyup.enter="load" class="form-control" id="recipientNumber" placeholder="" />
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="recipientPhone" class="form-label">Recipient phone</label>
                                            <input type="text" v-model="recipient.phone" @keyup.enter="load" class="form-control" id="recipientPhone" placeholder="" />
                                        </div>
                                        <div class="col-md-3 mb-3">
                                            <label for="recipientCompany" class="form-label">Recipient company</label>
                                            <input type="text" v-model="recipient.company" @keyup.enter="load" class="form-control" id="recipientCompany" placeholder="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <loading :show="loading" />
                <div class="alert alert-danger" v-if="error !== null">
                    {{ error.data.Message ? error.data.Message : error.data.title }} <span class="fw-bold">({{ error.status }})</span>
                </div>
                <div class="alert alert-warning" v-show="data !== null && data.length === 0">No Order Was Found using the criteria specified.</div>
                <div id="resultsFound" v-show="data !== null && data.length > 0">
                    <h1 class="page-title text-primary py-4 text-uppercase">Results Found ({{ pagination.totalRows }} transactions)</h1>
                    <div class="table-responsive">
                        <table class="table table-borderless">
                            <thead class="bg-light">
                                <tr>
                                    <th class="rounded-start"># Order</th>
                                    <th>Date/status</th>
                                    <th>USD</th>
                                    <th>($) Local</th>
                                    <th width="25%">Sender</th>
                                    <th width="30%" :class="[isAgent ? '' : 'rounded-end']">Recipient</th>
                                    <th v-if="isAgent" :class="{ isAgent: 'rounded-end' }"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <router-link v-for="item in data" :key="item.orderID" :to="{ name: 'Order Detail', params: { orderId: item.orderID } }" v-slot="{ navigate }" custom>
                                    <tr>
                                        <td @click="navigate">
                                            <router-link :to="{ name: 'Order Detail', params: { orderId: item.orderID } }" class="fw-bold">{{ item.orderID }}</router-link>
                                        </td>
                                        <td @click="navigate" class="text-center">
                                            <div>{{ item.orCreateTime }}</div>
                                            <span class="badge rounded-3" :style="{ 'background-color': item.statusColor }">{{ item.status }}</span>
                                        </td>
                                        <td @click="navigate">{{ item.orAmountSentFormated }}</td>
                                        <td @click="navigate">{{ item.orAmountRcvdFormated }} {{ item.orRcvdCurrency }}</td>
                                        <td @click="navigate" width="25%">
                                            <span class="fw-bold"> {{ item.sdFullNameOrCompany }} </span><br />
                                            (No. {{ item.senderID }})<br />
                                            {{ item.sdAddress1 }}, {{ item.sdCity }}<br />
                                            {{ item.sdCountryName }}
                                        </td>
                                        <td @click="navigate" width="30%">
                                            <span class="fw-bold">{{ item.rcFullNameOrCompany }}</span
                                            ><br />
                                            (No. {{ item.recipientID }})<br />
                                            {{ item.rcAddress1 }} - {{ item.rcCity }} / {{ item.rcCountryName }}<br />
                                            Bank: {{ item.rcBankName }}, <br />
                                            Agency {{ item.rcBranchID }}, Account {{ item.rcBankAccNo }}
                                        </td>
                                        <td v-if="isAgent">
                                            <button type="button" @click="repeatTransaction(item.orderID)" class="btn btn-outline-primary btn-sm text-uppercase"><i class="far fa-clone"></i> Repeat</button>
                                        </td>
                                    </tr>
                                </router-link>
                            </tbody>
                        </table>
                    </div>
                    <loading :show="loading" />
                    <pagination v-show="!loading" :actual-page="pagination.pageNumber" :total-pages="pagination.totalPages" v-on:change="changePage"></pagination>
                </div>
            </div>
            <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                <div class="container text-end">
                    <button type="button" @click="clearAll" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3">Clear all</button>
                    <button type="button" @click="loadButton" class="btn btn-primary btn-sm text-uppercase" :disabled="loading"><i class="fas fa-search"></i> <span v-show="!loading">Find Order</span><span v-show="loading">Waiting...</span></button>
                </div>
            </footer>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';
import Pagination from '@/components/Pagination';
import { Datepicker } from 'vanillajs-datepicker';

export default {
    name: 'OrderFind',
    components: {
        Layout,
        Loading,
        Pagination,
    },
    data() {
        return {
            order: {
                number: null,
                customAgentOrderNumber: null,
                fromDate: null,
                toDate: null,
                correspondentOrderNumber: null,
                routedOrderNumber: null,
                status: 0,
            },
            filters: {
                status: null,
                countries: null,
            },
            sender: {
                country: 0,
                lastName: '',
                firstName: '',
                number: '',
                phone: '',
                company: '',
            },
            recipient: {
                country: 0,
                lastName: '',
                firstName: '',
                cpf: '',
                number: '',
                phone: '',
                company: '',
            },
            data: null,
            pagination: {
                pageNumber: 1,
            },
            loading: false,
            error: null,
            params: null,
        };
    },
    beforeMount() {
        this.getFilterStatus();
        this.getFilterCountry();
    },
    mounted() {
        this.order.fromDate = new Datepicker(document.getElementById('fromDate'));
        this.order.toDate = new Datepicker(document.getElementById('toDate'));
        this.setVariablesFromParams();

        if (Object.keys(this.params).length > 0) this.load();
    },
    methods: {
        loadButton() {
            this.pagination.pageNumber = 1;
            this.load();
        },
        load() {
            this.loading = true;
            this.error = null;

            this.params = {
                pageNumber: this.pagination.pageNumber,
                OrderId: this.order.number,
                DateBefore: this.order.fromDate.getDate('mm/dd/yyyy'),
                DateAfter: this.order.toDate.getDate('mm/dd/yyyy'),
                OrGivenID: this.order.customAgentOrderNumber,
                OrGivenIDC: this.order.correspondentOrderNumber,
                ReroutedID: this.order.routedOrderNumber,
                orStatus: this.order.status === 0 ? null : this.order.status,
                SdCountry: this.sender.country === 0 ? null : this.sender.country,
                SdLastName: this.sender.lastName,
                SdFirstName: this.sender.firstName,
                SdClientID: this.sender.number,
                SdPhone1: this.sender.phone,
                SdCompany: this.sender.company,
                RcCountry: this.recipient.country === 0 ? null : this.recipient.country,
                RcLastName: this.recipient.lastName,
                RcFirstName: this.recipient.firstName,
                RcCPF: this.recipient.cpf,
                RcClientID: this.recipient.number,
                RcPhone1: this.recipient.phone,
                RcCompany: this.recipient.company,
            };
            this.$router.push({ query: this.params });

            axios
                .get('/niloagent/order/find', { params: this.params })
                .then((response) => {
                    this.data = response.data.result;
                    this.pagination = (({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }) => ({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }))(response.data);
                    this.loading = false;
                    this.scrollUp();
                })
                .catch((error) => {
                    this.error = error.response;
                    this.loading = false;
                    this.data = null;
                });
        },
        changePage(page) {
            this.pagination.pageNumber = page;
            this.load();
        },
        scrollUp() {
            document.getElementById('resultsFound').scrollIntoView();
        },
        getFilterCountry() {
            axios
                .get('/niloagent/order/find/filter/country')
                .then((response) => {
                    this.filters.countries = response.data;
                })
                .catch((error) => {
                    this.error = error.response.data;
                    this.$toast.error(this.error.Message);
                });
        },
        getFilterStatus() {
            axios
                .get('/niloagent/order/find/filter/status')
                .then((response) => {
                    this.filters.status = response.data;
                })
                .catch((error) => {
                    this.error = error.response.data;
                    this.$toast.error(this.error.Message);
                });
        },
        setVariablesFromParams() {
            this.params = this.$route.query;

            if (Object.keys(this.params).length > 0) {
                this.pagination.pageNumber = this.params.pageNumber;
                this.order.number = this.params.OrderId;
                this.order.fromDate.setDate(this.params.DateBefore);
                this.order.toDate.setDate(this.params.DateAfter);
                this.order.customAgentOrderNumber = this.params.OrGivenID;
                this.order.correspondentOrderNumber = this.params.OrGivenIDC;
                this.order.routedOrderNumber = this.params.ReroutedID;
                this.order.status = this.params.orStatus;
                this.sender.country = this.params.SdCountry;
                this.sender.lastName = this.params.SdLastName;
                this.sender.firstName = this.params.SdFirstName;
                this.sender.number = this.params.SdClientID;
                this.sender.phone = this.params.SdPhone1;
                this.sender.company = this.params.SdCompany;
                this.recipient.country = this.params.RcCountry;
                this.recipient.lastName = this.params.RcLastName;
                this.recipient.firstName = this.params.RcFirstName;
                this.recipient.cpf = this.params.RcCPF;
                this.recipient.number = this.params.RcClientID;
                this.recipient.phone = this.params.RcPhone1;
                this.recipient.company = this.params.RcCompany;
            }
        },
        clearAll() {
            this.order = {
                number: null,
                customAgentOrderNumber: null,
                fromDate: new Datepicker(document.getElementById('fromDate')),
                toDate: new Datepicker(document.getElementById('toDate')),
                correspondentOrderNumber: null,
                routedOrderNumber: null,
                status: 0,
            };
            this.sender = {
                country: 0,
                lastName: '',
                firstName: '',
                number: '',
                phone: '',
                company: '',
            };
            this.recipient = {
                country: 0,
                lastName: '',
                firstName: '',
                cpf: '',
                number: '',
                phone: '',
                company: '',
            };

            this.order.fromDate.setDate({ clear: true });
            this.order.toDate.setDate({ clear: true });
        },
        repeatTransaction(orderId) {
            axios
                .get(`/niloagent/createorder/clone/${orderId}`)
                .then((response) => {
                    // this.$store.dispatch('order/create');
                    // this.$store.commit('order/setSender', response.data.sender);
                    // this.$store.commit('order/setRecipient', response.data.recipient);
                    // this.$store.commit('order/setPaymentInformation', response.data.paymentInformation);
                    // this.$store.commit('order/setAdditionalInformation', response.data.additionalInformation);

                    // this.$router.push({ name: 'Payment Type And Amount' });

                    this.$store.dispatch('simpleOrder/new');
                    this.$store.commit('simpleOrder/setSender', response.data.sender);
                    this.$store.commit('simpleOrder/setRecipient', response.data.recipient);
                    this.$store.commit('simpleOrder/setPaymentInformation', response.data.paymentInformation);
                    this.$store.commit('simpleOrder/setAdditionalInformation', response.data.additionalInformation);

                    this.$router.push({ name: 'Simple Order', params: { blockCreated: true } });
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
    },
    computed: {
        isAgent() {
            return this.$store.getters['agent/isAgent'];
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/variables';

footer {
    z-index: 100;
}
a {
    color: inherit;
    text-decoration: none;
}
.content {
    padding-bottom: 100px;
}

.accordion-button:not(.collapsed) {
    background-color: #f1f1f1;
}

.accordion-button:focus {
    box-shadow: none !important;
}
tbody {
    tr:hover {
        background-color: lighten($primary, 45);
        cursor: pointer;
    }
}
</style>
