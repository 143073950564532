<template>
    <layout navigation-label="Recipient">
        <template v-slot:content>
            <loading :show="loading" />
            <div v-if="!loading && recipient">
                <form autocomplete="off">
                    <h1 class="page-title text-primary mb-4 text-uppercase">Recipient Information</h1>
                    <div class="row">
                        <div class="col mb-2">
                            <label for="fullName" class="form-label">Full name</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" v-model="recipient.fullName" class="form-control" id="fullName" placeholder="First and last name" :disabled="!isNewRecipient" />
                        </div>
                        <div class="col-md-3 mb-2" v-if="!isNewRecipient">
                            <label class="form-label">Customer number</label>
                            <p>
                                <span class="badge bg-light text-black form-control fs-5 py-2 border-light">{{ recipient.clientId }}</span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <label for="company" class="form-label">Company name</label>
                            <input autocomplete="none" type="text" v-model="recipient.company" class="form-control" id="company" placeholder="" :disabled="!isNewRecipient" />
                        </div>
                        <div class="col-sm-6 col-md-3 mb-2" v-show="showCpfCnpj">
                            <label for="cpfCnpj" class="form-label">CPF or CNPJ</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" v-model="recipient.cpfOrCnpj" class="form-control" id="cpfCnpj" placeholder="" />
                        </div>
                        <div class="col-sm-6 col-md-3 mb-2">
                            <label for="telephone" class="form-label">Telephone</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" v-model="recipient.phone" class="form-control" id="telephone" placeholder="" />
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="row">
                        <div class="col-sm-4 mb-2">
                            <label for="country" class="form-label">Country</label><label class="mandatory-mark">*</label>
                            <select-country :data="countries" v-model="recipient.countryId" :value="recipient.countryId" @change="changeCountry(recipient.countryId)" id="country"></select-country>
                        </div>
                        <div class="col-sm-4 mb-2">
                            <label for="zip" class="form-label">Zip code</label>
                            <input autocomplete="none" type="text" v-model="recipient.zip" class="form-control" id="zip" placeholder="" />
                        </div>
                        <div class="col-sm-4 mb-2">
                            <label for="countryOfPayment" class="form-label">Country of payment</label>
                            <select-country :data="countries" v-model="recipient.countryOfPaymentId" :value="recipient.countryOfPaymentId" @change="changeCountry(recipient.countryOfPaymentId)" id="countryOfPayment"></select-country>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 mb-2">
                            <label for="street" class="form-label">Street Address</label>
                            <input autocomplete="none" type="text" v-model="recipient.address" class="form-control" id="street" placeholder="" />
                        </div>
                        <div class="col-sm-6 col-md-3 mb-2">
                            <label for="city" class="form-label">City</label>
                            <input autocomplete="none" type="text" v-model="recipient.city" class="form-control" id="city" placeholder="" />
                        </div>
                        <div class="col-sm-6 col-md-3 mb-2">
                            <label for="state" class="form-label">State / Province</label><label class="mandatory-mark">*</label>
                            <input autocomplete="none" type="text" v-model="recipient.stateName" class="form-control" id="state" placeholder="" v-show="showStateInputText" />
                            <select id="states" class="form-select" v-model="recipient.stateIsoCode" @change="changeState" v-show="!showStateInputText">
                                <option value="0" selected>- Select -</option>
                                <option v-for="(item, index) in states" :value="item.id" :key="index">{{ item.value }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input autocomplete="none" type="text" v-model="recipient.email" class="form-control" id="email" placeholder="" />
                        </div>
                    </div>
                    <div class="d-flex">
                        <router-link to="/order/create/find-recipient" class="btn btn-outline-primary text-uppercase mt-4 me-3"><i class="fas fa-arrow-left me-2"></i> Back</router-link>
                        <button name="btnRecipientNext" id="btnNext" type="button" @click="next" class="btn btn-primary text-uppercase mt-4" :disabled="validating">{{ validating || loading ? 'Wait...' : !hasSummary ? 'Next' : 'Save & Go Summary' }} <i class="fas fa-arrow-right ms-2"></i></button>
                        <button type="button" @click="clearFields" class="btn btn-outline-primary text-uppercase mt-4 ms-auto">Clear</button>
                    </div>
                </form>
            </div>
            <ModalClientChangedConfirmation ref="modalClientChangedConfirmation" confirmationText="" @confirmed="updateClientChangesConfirmation"></ModalClientChangedConfirmation>
            <ModalExistingSimiliarClient ref="modalExistingSimiliarClient" confirmationText="" @confirmed="clientExistsConfirmation"></ModalExistingSimiliarClient>
        </template>
    </layout>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import SelectCountry from '@/components/SelectCountry';
import Layout from '@/views/layouts/CreateOrder';
import ModalClientChangedConfirmation from '@/views/order/modals/ClientChangedConfirmation';
import ModalExistingSimiliarClient from '@/views/order/modals/ExistingSimiliarClient';

export default {
    name: 'Recipient',
    components: {
        Layout,
        Loading,
        SelectCountry,
        ModalExistingSimiliarClient,
        ModalClientChangedConfirmation,
    },
    props: {
        recipientId: {
            type: Number,
            default: null,
        },
    },
    data() {
        return {
            recipient: {
                countryId: 0,
                countryIsoCode: '',
                countryOfPaymentId: 0,
                stateIsoCode: 0,
                updateInformation: false,
                clientExists: null,
            },
            countries: [],
            states: [],
            loading: false,
            validating: false,
        };
    },
    mounted() {
        this.loadCountries();
        this.setDefaultCountryToBrazil();
        this.checkRecipient();
    },
    methods: {
        setDefaultCountryToBrazil() {
            this.recipient.countryId = 42;
            this.recipient.countryOfPaymentId = 42;
        },
        loadRecipient() {
            this.loading = true;
            axios
                .get('/niloagent/createorder/find/recipient', {
                    params: {
                        ClientId: this.recipientId,
                        pageNumber: 1,
                    },
                })
                .then((response) => {
                    if (response.data.result.length > 0) {
                        this.recipient = response.data.result[0];
                        this.checkCountriesSelected();
                    }
                })
                .catch((error) => {
                    this.recipient = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadCountries() {
            axios
                .get('/niloagent/createorder/filter/destinycountries')
                .then((response) => {
                    this.countries = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`country: ${error.Message}`);
                });
        },
        loadStates() {
            axios
                .get('/niloagent/createorder/filter/recipient/state', {
                    params: {
                        countryId: this.recipient.countryId,
                    },
                })
                .then((response) => {
                    this.states = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`country: ${error.Message}`);
                });
        },
        checkCountriesSelected() {
            if (this.recipient.countryId > 0 && this.recipient.countryOfPaymentId <= 0) this.recipient.countryOfPaymentId = this.recipient.countryId;

            if (this.recipient.countryId <= 0 && this.recipient.countryOfPaymentId > 0) this.recipient.countryId = this.recipient.countryOfPaymentId;
        },
        changeCountry(value) {
            this.recipient.countryId = value;
            this.recipient.countryOfPaymentId = value;
        },
        changeState() {
            this.recipient.stateName = this.selectedRecipientState.value;
        },
        clearFields() {
            this.recipient = {
                countryId: 0,
                countryOfPaymentId: 0,
            };
        },
        checkRecipient() {
            if (this.order.recipient != null) {
                /*** if same recipient keep from session ***/
                if (parseInt(this.order.recipient.clientId) === this.recipientId) this.recipient = { ...this.order.recipient };
                /*** if new recipient and was not selected from list, keep from session ***/ else if ((this.order.recipient.clientId <= 0 || this.order.recipient.clientId == null) && this.recipientId == null) this.recipient = { ...this.order.recipient };
                else if (this.recipientId !== null) this.loadRecipient();
            } else if (this.recipientId !== null) this.loadRecipient();
        },
        updateClientChangesConfirmation(confirmation) {
            this.recipient.updateInformation = confirmation;
            this.$store.commit('order/setRecipient', this.recipient);
            this.$router.push(this.redirectOnSave);
        },
        clientExistsConfirmation(confirmation, clientId) {
            this.recipient.clientExists = confirmation;
            this.recipient.clientId = clientId;

            this.$store.commit('order/setRecipient', this.recipient);
            this.$router.push({ name: 'Payment Type And Amount' });
        },
        async validate() {
            this.validating = true;
            return axios
                .post('/niloagent/createorder/validate/recipient', this.recipient)
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    return false;
                })
                .finally(() => {
                    this.validating = false;
                });
        },
        async changeConfirmation() {
            this.validating = true;
            return axios
                .post('/niloagent/createorder/changeconfirmation/recipient', this.recipient)
                .then((response) => {
                    if (response.data.clientChanged === true) {
                        this.$refs.modalClientChangedConfirmation.show(response.data.confirmationDialogMessage, response.data.fieldsChanged);
                        return true;
                    } else return false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    return false;
                })
                .finally(() => {
                    this.validating = false;
                });
        },
        async checkClientExisting() {
            this.validating = true;
            return axios
                .post('/niloagent/createorder/clientexists/recipient', this.recipient)
                .then((response) => {
                    if (response.data.clientExists) {
                        this.$refs.modalExistingSimiliarClient.show(response.data);
                        return true;
                    } else return false;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    return false;
                })
                .finally(() => {
                    this.validating = false;
                });
        },
        async next() {
            if ((await this.validate()) && !(await this.changeConfirmation()) && !(await this.checkClientExisting())) {
                this.$store.commit('order/setRecipient', this.recipient);
                await this.$router.push(this.redirectOnSave);
            }
        },
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        isNewRecipient() {
            return !this.recipient.clientId > 0;
        },
        showCpfCnpj() {
            return parseInt(this.recipient.countryOfPaymentId) === 42;
        },
        showStateInputText() {
            return this.states.length === 0;
        },
        selectedRecipientState() {
            return Object.keys(this.recipient).length > 0 && this.states.length > 0 && this.recipient.stateIsoCode > '' ? this.states.find((item) => item.id === this.recipient.stateIsoCode) : { id: this.recipient.stateIsoCode, value: this.recipient.stateName };
        },
        recipientCountry() {
            return this.recipient.countryId;
        },
        redirectOnSave() {
            return this.hasSummary ? { name: 'Order Summary' } : { name: 'Payment Type And Amount' };
        },
        hasSummary() {
            return this.$store.getters['order/hasSummary'];
        },
    },
    watch: {
        recipientCountry(value) {
            if (value > 0) this.loadStates();
        },
    },
};
</script>

<style scoped></style>
