export const persistOrdersReceiptDetail = (orderReceipt) => {
    if (!localStorage.getItem('ordersReceipt')) {
        localStorage.setItem('ordersReceipt', JSON.stringify([orderReceipt]));
    } else {
        const ordersReceiptToJson = JSON.parse(localStorage.getItem('ordersReceipt'));
        if (ordersReceiptToJson.length == 0) {
            localStorage.setItem('ordersReceipt', JSON.stringify([orderReceipt]));
        } else if (!ordersReceiptToJson.some((orderDetail) => orderDetail.id == orderReceipt.id)) {
            ordersReceiptToJson.push(orderReceipt);
            localStorage.setItem('ordersReceipt', JSON.stringify(ordersReceiptToJson));
        }
    }
};
export const getOrderReceived = (orderId) => {
    if (!localStorage.getItem('ordersReceipt')) {
        return null;
    }
    const ordersReceiptToJson = JSON.parse(localStorage.getItem('ordersReceipt'));
    if (ordersReceiptToJson.length == 0) return null;
    return ordersReceiptToJson.find((value) => value.id == orderId);
};
export const persistLastSender = (sender) => {
    localStorage.setItem('lastSender', JSON.stringify(sender));
};
