import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import moment from 'moment';

import auth from './auth';
import order from './order';
import agent from './agent';
import simpleOrder from './simpleOrder';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        order,
        agent,
        simpleOrder,
    },
    state: {
        announcements: {
            data: [],
            haveRead: false,
            haveReadDate: null,
        },
        balance: {
            loading: false,
            data: null,
        },
        calculator: {
            countries: [],
        },
        incompleteOrders: -1,
        _showComplianceSigns: false,
        _expireComplianceAML: null,
    },
    actions: {
        getAnnouncementsHaveReadDate(context) {
            if (localStorage.getItem('announcements.haveReadDate')) context.commit('haveReadAnnouncementsDate', localStorage.getItem('announcements.haveReadDate'));
        },
        getIncompleteOrdersCount({ state, commit }) {
            if (state.incompleteOrders < 0)
                axios
                    .get('/niloagent/TaskController/IncompleteOrdersCount')
                    .then((response) => {
                        commit('setIncompleteOrders', response.data);
                    })
                    .catch(() => {
                        commit('setIncompleteOrders', -1);
                    });
        },
        getAccountingBalance({ state, getters }) {
            if (getters['agent/isAgent']) {
                state.balance.loading = true;
                axios
                    .get(`/niloagent/accounting/accounting`)
                    .then((response) => {
                        state.balance.data = response.data;
                    })
                    .catch((error) => {
                        state.balance.data = null;
                        this.$toast.error(error.response.data.Message);
                    })
                    .finally(() => {
                        state.balance.loading = false;
                    });
            }
        },
        removeShowComplianceSigns({ state }) {
            state._showComplianceSigns = false;
            localStorage.removeItem('showComplianceSigns');
        },
    },
    getters: {
        ENV() {
            return process.env.VUE_APP_ENV.toUpperCase();
        },
        showComplianceSigns(state) {
            return localStorage.getItem('showComplianceSigns') ? false : state._showComplianceSigns;
        },
        showComplianceAML(state) {
            const expiresAML = state._expireComplianceAML ?? localStorage.getItem('expireComplianceAML');
            if (expiresAML) {
                const expires = parseInt(localStorage.getItem('expireComplianceAML'));
                const today = parseInt(moment().format('yyyyMMDD'));
                return today >= expires;
            } else return true;
        },
    },
    mutations: {
        setIncompleteOrders(state, value) {
            state.incompleteOrders = value;
        },
        setAnnouncements(state, payload) {
            state.announcements.data = payload;
        },
        haveReadAnnouncements(state, payload) {
            state.announcements.haveRead = payload;
            state.announcements.haveReadDate = Date.now();
            localStorage.setItem('announcements.haveReadDate', state.announcements.haveReadDate);
        },
        haveReadAnnouncementsDate(state, payload) {
            state.announcements.haveReadDate = payload;
        },
        setShowComplianceSigns(state, value) {
            state._showComplianceSigns = value;
            localStorage.setItem('showComplianceSigns', value);
        },
        setExpireComplianceAML(state, value) {
            state._expireComplianceAML = value;
            if (value == null) {
                localStorage.removeItem('expireComplianceAML');
            } else {
                localStorage.setItem('expireComplianceAML', value);
            }
        },
        removeAccountingBalance(state) {
            state.balance.data = null;
        },
        setCalculatorCountries(state, payload) {
            state.calculator.countries = payload;
        },
    },
});
