<template>
    <div class="card">
        <div id="pageTitle" class="card-header fs-6 fw-bold text-black-50">Tasks</div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnIncompleteOrdersList'" :to="{ name: 'Task List', params: { taskType: 'incomplete-order' }}" class="text-decoration-none">Incomplete Orders</router-link></div>
            <button :name="'btnIncompleteOrdersCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getIncompleteOrdersCount"><i class="far fa-eye m-0"></i><span v-show="showIncompleteOrdersCount" class="ms-2">{{ incompleteOrdersCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnIncompleteOrdersAppList'" :to="{ name: 'Task List', params: { taskType: 'incomplete-order-app' }}"  class="text-decoration-none">Incomplete Orders app</router-link></div>
            <button :name="'btnIncompleteOrdersAppCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getIncompleteOrdersAppCount"><i class="far fa-eye m-0"></i><span v-show="showIncompleteOrdersAppCount" class="ms-2">{{ incompleteOrdersAppCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnFinancialHoldList'" :to="{ name: 'Task List', params: { taskType: 'financial' }}" class="text-decoration-none">Financial Hold</router-link></div>
            <button :name="'btnFinancialHoldCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getFinancialHoldCount"><i class="far fa-eye m-0"></i><span v-show="showFinancialHoldCount" class="ms-2">{{ financialHoldCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnComplianceApprovalList'" :to="{ name: 'Task List', params: { taskType: 'compliance-approval' }}" class="text-decoration-none">Compliance Approval</router-link></div>
            <button :name="'btnComplianceApprovalCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getComplianceApprovalCount"><i class="far fa-eye m-0"></i><span v-show="showComplianceApprovalCount" class="ms-2">{{ complianceApprovalCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnNewPaymentsList'" :to="{ name: 'Task List', params: { taskType: 'new-payment' }}" class="text-decoration-none">New Payments</router-link></div>
            <button :name="'btnNewPaymentsCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getNewPaymentsCount"><i class="far fa-eye m-0"></i><span v-show="showNewPaymentsCount" class="ms-2">{{ newPaymentsCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnProblemOrderList'" :to="{ name: 'Task List', params: { taskType: 'problem-order' }}" class="text-decoration-none">Problem Orders</router-link></div>
            <button :name="'btnProblemOrderCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getProblemOrderCount"><i class="far fa-eye m-0"></i><span v-show="showProblemOrderCount" class="ms-2">{{ problemOrderCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnPendingRefundOrdersList'" :to="{ name: 'Task List', params: { taskType: 'pending-refund-order' }}" class="text-decoration-none">Pending Refund Orders</router-link></div>
            <button :name="'btnPendingRefundOrdersCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getPendingRefundOrdersCount"><i class="far fa-eye m-0"></i><span v-show="showPendingRefundOrdersCount" class="ms-2">{{ pendingRefundOrdersCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex">
            <div class="my-2"><router-link :name="'btnCardSuccessfulPaymentsList'" :to="{ name: 'Task List', params: { taskType: 'successful-payments' }}" class="text-decoration-none">Card Successful Payments</router-link></div>
            <button :name="'btnCardSuccessfulPaymentsCount'" type="button" class="btn btn-primary ms-auto btn-sm" @click="getCardSuccessfulPaymentsCount"><i class="far fa-eye m-0"></i><span v-show="showCardSuccessfulPaymentsCount" class="ms-2">{{ cardSuccessfulPaymentsCount }}</span></button>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "TasksCard",
    data() {
        return {
            incompleteOrdersCount: 0,
            showIncompleteOrdersCount: false,

            incompleteOrdersAppCount: 0,
            showIncompleteOrdersAppCount: false,

            financialHoldCount: 0,
            showFinancialHoldCount: false,

            complianceApprovalCount: 0,
            showComplianceApprovalCount: false,

            newPaymentsCount: 0,
            showNewPaymentsCount: false,

            problemOrderCount: 0,
            showProblemOrderCount: false,

            pendingRefundOrdersCount: 0,
            showPendingRefundOrdersCount: false,

            cardSuccessfulPaymentsCount: 0,
            showCardSuccessfulPaymentsCount: false
        }
    },
    mounted() {
      if (!this.$store.state.agent.headquarter)
      {
        this.getIncompleteOrdersCount()
        this.getIncompleteOrdersAppCount()
        this.getFinancialHoldCount()
        this.getComplianceApprovalCount()
        this.getNewPaymentsCount()
        this.getProblemOrderCount()
        this.getPendingRefundOrdersCount()
        this.getCardSuccessfulPaymentsCount()
      }
    },
    methods: {
        getIncompleteOrdersCount() {
            axios
                .get('/niloagent/TaskController/IncompleteOrdersCount')
                .then(response => {
                    this.incompleteOrdersCount = response.data;
                    this.showIncompleteOrdersCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showIncompleteOrdersCount = false;
                });
        },
        getIncompleteOrdersAppCount() {
            axios
                .get('/niloagent/TaskController/IncompleteAppOrdersCount')
                .then(response => {
                    this.incompleteOrdersAppCount = response.data;
                    this.showIncompleteOrdersAppCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showIncompleteOrdersAppCount = false;
                });
        },
        getFinancialHoldCount() {
            axios
                .get('/niloagent/TaskController/FinancialHoldCount')
                .then(response => {
                    this.financialHoldCount = response.data;
                    this.showFinancialHoldCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showFinancialHoldCount = false;
                });
        },
        getComplianceApprovalCount() {
            axios
                .get('/niloagent/TaskController/ApprovalOrdersCount')
                .then(response => {
                    this.complianceApprovalCount = response.data;
                    this.showComplianceApprovalCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showComplianceApprovalCount = false;
                });
        },
        getNewPaymentsCount() {
            axios
                .get('/niloagent/TaskController/NewPaymentscount')
                .then(response => {
                    this.newPaymentsCount = response.data;
                    this.showNewPaymentsCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showNewPaymentsCount = false;
                });
        },
        getProblemOrderCount() {
            axios
                .get('/niloagent/TaskController/ProblemOrdersCount')
                .then(response => {
                    this.problemOrderCount = response.data;
                    this.showProblemOrderCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showProblemOrderCount = false;
                });
        },
        getPendingRefundOrdersCount() {
            axios
                .get('/niloagent/TaskController/PendingRefundOrdersCount')
                .then(response => {
                    this.pendingRefundOrdersCount = response.data;
                    this.showPendingRefundOrdersCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showPendingRefundOrdersCount = false;
                });
        },
        getCardSuccessfulPaymentsCount() {
            axios
                .get('/niloagent/TaskController/CardSuccessfulPaymentsount')
                .then(response => {
                    this.cardSuccessfulPaymentsCount = response.data;
                    this.showCardSuccessfulPaymentsCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                    this.showCardSuccessfulPaymentsCount = false;
                });
        }
    }
}
</script>

<style scoped>

</style>