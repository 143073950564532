<template>
    <div class="container-fluid" v-if="data">
        <div class="text-center py-4">
            <img alt="Banco Inter" src="@/assets/img/banco-inter.svg" class="logo-inter" />
        </div>

        <div class="text-center border-top border-primary py-4">
            <h1 class="fs-4">Recebimento de Pagamento</h1>
            <h2 class="fs-5 text-black-50 mb-0">Payment Receipt</h2>
        </div>

        <div class="row mb-3 border-top pt-4">
            <div class="col-6 text-end">
                Remetente <span class="text-black-50">(Sender)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.senderName }}
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-6 text-end">
                Beneficiário no Brasil <span class="text-black-50">(Beneficiary)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.beneficiary }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6 text-end">
                CPF / CNPJ <span class="text-black-50">(Tax ID)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.beneficiaryCpfOrCnpj }}
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-6 text-end">
                Banco <span class="text-black-50">(Bank)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.beneficiaryBankName }}
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-6 text-end">
                Agência <span class="text-black-50">(Branch)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.beneficiaryBranch }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6 text-end">
                Conta <span class="text-black-50">(Account)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.beneficiaryAccount }}
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-6 text-end">
                Valor em Reais <span class="text-black-50">(BRL amount)</span>
            </div>
            <div class="col-6 fw-bold">
                R$ {{ data.amountReceived }}
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-6 text-end">
                Data <span class="text-black-50">(Date)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.datePaid }}
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-6 text-end">
                Horário <span class="text-black-50">(Time)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.timePaid }}
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-6 text-end">
                Identificação do PIX <span class="text-black-50">(PIX ID)</span>
            </div>
            <div class="col-6 fw-bold">
                {{ data.pixId }}
            </div>
        </div>

        <div class="py-4 mt-4 border-top border-bottom text-center">
            <p>Esta transação foi realizada por USEND Tecnologia e Serviços Financeiros Ltda. (CNPJ:31.712.536/0001-63), em nome do Remetente acima indicado, em conformidade com a regras do Banco Central do Brasil.</p>
            <p class="text-black-50 fst-italic mb-0">This transaction was processed by USEND Tecnologia e Serviços Financeiros Ltda. (CNPJ:31.712.536/0001-63), on behalf of the Sender indicated above, in accordance with the rules of the Central Bank of Brazil.</p>
        </div>
        <div class="py-4 text-center">
            <p>Não nos responsabilizamos pela demora ou não cumprimento da transferência acima por erro de preenchimento das informações acima.</p>
            <p class="text-black-50 fst-italic">We will not be liable for delays or non compleation of the transaction above if the information provided to us contain any error.</p>
            <div><a href="https://us.inter.co/" target="_blank" class="text-decoration-none">us.inter.co</a></div>
            <button type="button" @click="print" class="btn btn-primary d-print-none text-uppercase mt-5">Print</button>
        </div>

    </div>
</template>

<script>

export default {
    name: "PaymentReceiptInter",
    props: {
        data: Object
    },
    methods: {
        print() {
            window.print();
        }
    }
}
</script>

<style lang="scss" scoped>
.logo-inter {
    max-width: 200px;
}
</style>