<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Accounting</h1>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="mb-4 col-3" v-if="!isAgent">
                            <router-link :to="{ name: 'Admin Reports Menu' }">
                                <MenuButton>
                                    <template v-slot:label>Admin Reports</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'Accounting Balance Statement' }">
                                <MenuButton>
                                    <template v-slot:label>Balance Statement</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3" v-if="!isAgent">
                            <router-link :to="{ name: 'Accounting Current Balance' }">
                                <MenuButton>
                                    <template v-slot:label>Current Balance</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'Accounting Detail Report' }">
                                <MenuButton>
                                    <template v-slot:label>Detail Report</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'Accounting Payment Statement' }">
                                <MenuButton>
                                    <template v-slot:label>Payment Statement</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3" v-if="!isAgent">
                            <router-link :to="{ name: 'Accounting Payments' }">
                                <MenuButton>
                                    <template v-slot:label>Payments</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3" v-if="!isAgent">
                            <router-link :to="{ name: 'Accounting Summary Balance' }">
                                <MenuButton>
                                    <template v-slot:label>Summary Balance</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3" v-if="!isAgent">
                            <router-link :to="{ name: 'Upload Payments' }">
                                <MenuButton>
                                    <template v-slot:label>Upload Payments</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3">
                            <router-link :to="{ name: 'User Detail Report' }">
                                <MenuButton>
                                    <template v-slot:label>User Detail Report</template>
                                </MenuButton>
                            </router-link>
                        </div>
                        <div class="mb-4 col-3" :class="column">
                            <router-link :to="{ name: 'Agent-Receipt-Upload' }">
                                <MenuButton>
                                    <template v-slot:label>Agent Receipt Upload</template>
                                    <template v-slot:icon><i class="far fa-user-circle"></i></template>
                                </MenuButton>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-3 col-md-5" v-if="isAgent && balance">
                    <div class="card bg-light border-0">
                        <div class="card-body text-center">
                            <div class="mb-3">
                                <h4 class="mb-0 text-danger">{{ balance.coBalanceFormated }}</h4>
                                <label class="text-uppercase">Your balance</label>
                            </div>
                            <hr />
                            <div class="mb-3">
                                <h4 class="mb-0">${{ balance.receivedPaymentsPendingPostingFormated }}</h4>
                                <label class="text-uppercase">Received payments Pending Posting</label>
                            </div>
                            <hr />
                            <div class="mb-3">
                                <h4 class="mb-0">${{ balance.coCreditLimitFormated }}</h4>
                                <label class="text-uppercase">Operational Limit</label>
                            </div>
                            <hr />
                            <div class="">
                                <h4 class="mb-0">${{ balance.coCreditAvailableFormated }}</h4>
                                <label class="text-uppercase">Available Operational Limit</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import MenuButton from '@/components/MenuButton';

export default {
    name: 'AccountingMenu',
    components: {
        Layout,
        MenuButton,
    },
    data() {
        return {
            data: null,
            loading: false,
        };
    },
    mounted() {
        this.getAccountingBalance();
    },
    methods: {
        getAccountingBalance() {
            if (this.isAgent) {
                this.loading = true;
                axios
                    .get(`/niloagent/accounting/accounting`)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.data = null;
                        this.$toast.error(error.response.data.Message);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
    computed: {
        isAgent() {
            return !this.$store.state.agent.headquarter;
        },
        balance() {
            return this.$store.state.balance.data;
        },
    },
};
</script>

<style scoped></style>
