<template>
    <div class="modal" id="modal-comp-exclusive" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-size">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 px-4">
                    <h5 class="modal-title">Client Compliance Exclusive</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body px-4">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow text-primary mb-4" v-show="form.flow.loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div class="w-100 text-center">
                        <h4 class="text-primary fw-bold text-uppercase">CLIENT ID #{{ clientId }}</h4>
                    </div>

                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="d-flex">
                                    <h3 class="text-primary fs-6 fw-bold text-uppercase">Documents</h3>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-1">
                                        <label class="form-label">Active</label>
                                        <div class="text-center mt-1">
                                            <input class="form-check-input" type="checkbox" v-model="client.doc1Active" />
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="form-label">Country</label>
                                        <select-country class="form-select" :data="form.data.document1Countries" v-model="client.doc1CountryId" :value="client.doc1CountryId" @change="onChangeDocument1Country()"></select-country>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="form-label">Type</label>
                                        <select class="form-select" :class="{ 'border-danger': form.validation.document1Type.invalid }" v-model="client.doc1TypeId" id="doc1TypeId">
                                            <option :value="0" selected>- Select -</option>
                                            <option v-for="item in form.data.availableDocument1Types" :value="item.id" :key="item.id">{{ item.type }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="form-label">Expiration Date</label>
                                        <div class="input-group">
                                            <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                            <input type="text" class="form-control datePicker datePicker-input" :class="{ 'border-danger': form.validation.document1ExpirationDate.invalid }" id="document1ExpirationDate" autocomplete="off" v-model="client.doc1ExpirationDate" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4">
                                        <span v-if="form.validation.document1Country.invalid" class="text-danger">{{ form.validation.document1Country.message }}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span v-if="form.validation.document1Type.invalid" class="text-danger">{{ form.validation.document1Type.message }}</span>
                                    </div>
                                    <div class="col-md-3">
                                        <span v-if="form.validation.document1ExpirationDate.invalid" class="text-danger">{{ form.validation.document1ExpirationDate.message }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-3">
                                <div class="row">
                                    <div class="col-md-1 text-center">
                                        <input class="form-check-input" type="checkbox" v-model="client.doc2Active" />
                                    </div>
                                    <div class="col-md-4">
                                        <select-country class="form-select" :disable="true" :data="form.data.document2Countries" v-model="client.doc2CountryId" :value="client.doc2CountryId"></select-country>
                                    </div>
                                    <div class="col-md-4">
                                        <select class="form-select" :class="{ 'border-danger': form.validation.document2Type.invalid }" v-model="client.doc2TypeId" id="doc2TypeId">
                                            <option :value="0" selected>- Select -</option>
                                            <option v-for="item in form.data.availableDocument2Types" :value="item.id" :key="item.id">{{ item.type }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="input-group">
                                            <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                            <input type="text" class="form-control datePicker datePicker-input" :class="{ 'border-danger': form.validation.document2ExpirationDate.invalid }" id="document2ExpirationDate" autocomplete="off" v-model="client.doc2ExpirationDate" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-4">
                                        <span v-if="form.validation.document2Country.invalid" class="text-danger">{{ form.validation.document2Country.message }}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span v-if="form.validation.document2Type.invalid" class="text-danger">{{ form.validation.document2Type.message }}</span>
                                    </div>
                                    <div class="col-md-3">
                                        <span v-if="form.validation.document2ExpirationDate.invalid" class="text-danger">{{ form.validation.document2ExpirationDate.message }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="d-flex">
                                    <h3 class="text-primary fs-6 fw-bold text-uppercase">Source of Funds</h3>
                                </div>
                            </div>
                            <div>
                                <div class="row">
                                    <div class="col-md-1">
                                        <label class="form-label">Active</label>
                                        <div class="text-center mt-1">
                                            <input class="form-check-input" type="checkbox" v-model="client.sofActive" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label class="form-label">Range</label>
                                        <div>
                                            <select class="form-select" :class="{ 'border-danger': form.validation.sourceOfFundsRange.invalid }" v-model="client.sofRangeId" id="sofRange">
                                                <option :value="0" selected>- Select -</option>
                                                <option v-for="item in form.data.sourceOfFundsRanges" :value="item.id" :key="item.id">{{ item.range }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label class="form-label">Type</label>
                                        <div>
                                            <select class="form-select" :class="{ 'border-danger': form.validation.sourceOfFundsType.invalid }" v-model="client.sofTypeId" id="sofType" @change="onChangeSourceOfFundsType">
                                                <option :value="0" selected>- Select -</option>
                                                <option v-for="item in form.data.sourceOfFundsTypes" :value="item.id" :key="item.id">{{ item.type }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <label class="form-label">Amount</label>
                                        <div>
                                            <input type="text" class="form-control" :class="{ 'border-danger': form.validation.sourceOfFundsAmount.invalid }" v-model="client.sofAmount" />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label class="form-label">Expiration Date</label>
                                        <div class="input-group">
                                            <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                            <input type="text" class="form-control datePicker datePicker-input" :class="{ 'border-danger': form.validation.sourceOfFundsExpirationDate.invalid }" id="sourceOfFundsExpirationDate" autocomplete="off" v-model="client.sofExpirationDate" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-2">
                                        <span v-if="form.validation.sourceOfFundsRange.invalid" class="text-danger">{{ form.validation.sourceOfFundsRange.message }}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span v-if="form.validation.sourceOfFundsType.invalid" class="text-danger">{{ form.validation.sourceOfFundsType.message }}</span>
                                    </div>
                                    <div class="col-md-2">
                                        <span v-if="form.validation.sourceOfFundsAmount.invalid" class="text-danger">{{ form.validation.sourceOfFundsAmount.message }}</span>
                                    </div>
                                    <div class="col-md-3">
                                        <span v-if="form.validation.sourceOfFundsExpirationDate.invalid" class="text-danger">{{ form.validation.sourceOfFundsExpirationDate.message }}</span>
                                    </div>
                                </div>
                                <div class="row mt-2" v-show="form.flow.showSourceOfFundsOtherType">
                                    <label class="form-label">Other type</label>
                                    <div>
                                        <input type="text" class="form-control" :class="{ 'border-danger': form.validation.sourceOfFundsOtherType.invalid }" v-model="client.sofOtherType" />
                                    </div>
                                </div>
                                <div class="row">
                                    <span v-if="form.validation.sourceOfFundsOtherType.invalid" class="text-danger">{{ form.validation.sourceOfFundsOtherType.message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="d-flex">
                                    <h3 class="text-primary fs-6 fw-bold text-uppercase">Compliance Form</h3>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-1">
                                    <label class="form-label">Active</label>
                                    <div class="text-center mt-1">
                                        <input class="form-check-input m-0" type="checkbox" v-model="client.complianceActive" />
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <label class="form-label">Type</label>
                                    <div>
                                        <input type="text" class="form-control" :class="{ 'border-danger': form.validation.complianceType.invalid }" v-model="client.complianceType" />
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <label class="form-label">Expiration Date</label>
                                    <div class="input-group">
                                        <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                        <input type="text" class="form-control datePicker datePicker-input" :class="{ 'border-danger': form.validation.complianceExpirationDate.invalid }" id="complianceExpirationDate" autocomplete="off" v-model="client.complianceExpirationDate" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-8">
                                    <span v-if="form.validation.complianceType.invalid" class="text-danger">{{ form.validation.complianceType.message }}</span>
                                </div>
                                <div class="col-md-3">
                                    <span v-if="form.validation.complianceExpirationDate.invalid" class="text-danger">{{ form.validation.complianceExpirationDate.message }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-3">
                        <div class="card-body">
                            <div class="row">
                                <div class="d-flex">
                                    <h3 class="text-primary fs-6 fw-bold text-uppercase">Notes</h3>
                                </div>
                            </div>
                            <div class="row mx-1">
                                <textarea class="form-control" id="notes" v-model="client.note"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer d-flex justify-content-end">
                    <button type="button" class="btn btn-primary text-uppercase" @click="save" :disabled="this.disableButtons()">{{ this.disableButtons() ? 'Wait..' : 'Save' }}</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase" @click="close" :disabled="this.disableButtons()">Cancel</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
import { Datepicker } from 'vanillajs-datepicker';
import axios from 'axios';
import SelectCountry from '@/components/SelectCountry';

export default {
    name: 'ClientComplianceExcluModal',
    components: {
        SelectCountry,
    },
    props: {
        clientId: {
            type: String,
        },
    },
    data() {
        return {
            modal: null,
            form: {
                element: {
                    document1ExpirationDate: null,
                    document2ExpirationDate: null,
                    sourceOfFundsExpirationDate: null,
                    complianceExpirationDate: null,
                },
                data: {
                    document1Countries: [],
                    document2Countries: [],
                    allDocumentTypes: [],
                    availableDocument1Types: [],
                    availableDocument2Types: [],
                    sourceOfFundsRanges: [],
                    sourceOfFundsTypes: [],
                },
                flow: {
                    loading: false,
                    saving: false,
                    showSourceOfFundsOtherType: false,
                },
                validation: {
                    document1Country: {
                        invalid: false,
                        message: '',
                    },
                    document1Type: {
                        invalid: false,
                        message: '',
                    },
                    document1ExpirationDate: {
                        invalid: false,
                        message: '',
                    },
                    document2Country: {
                        invalid: false,
                        message: '',
                    },
                    document2Type: {
                        invalid: false,
                        message: '',
                    },
                    document2ExpirationDate: {
                        invalid: false,
                        message: '',
                    },
                    sourceOfFundsRange: {
                        invalid: false,
                        message: '',
                    },
                    sourceOfFundsType: {
                        invalid: false,
                        message: '',
                    },
                    sourceOfFundsOtherType: {
                        invalid: false,
                        message: '',
                    },
                    sourceOfFundsAmount: {
                        invalid: false,
                        message: '',
                    },
                    sourceOfFundsExpirationDate: {
                        invalid: false,
                        message: '',
                    },
                    complianceType: {
                        invalid: false,
                        message: '',
                    },
                    complianceExpirationDate: {
                        invalid: false,
                        message: '',
                    },
                },
            },
            client: {},
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-comp-exclusive'));

        this.form.element.document1ExpirationDate = new Datepicker(document.getElementById('document1ExpirationDate'));
        this.form.element.document2ExpirationDate = new Datepicker(document.getElementById('document2ExpirationDate'));
        this.form.element.sourceOfFundsExpirationDate = new Datepicker(document.getElementById('sourceOfFundsExpirationDate'));
        this.form.element.complianceExpirationDate = new Datepicker(document.getElementById('complianceExpirationDate'));

        const elemExpirationDate1 = document.getElementById('document1ExpirationDate');
        this.document1DatePicker = new Datepicker(elemExpirationDate1);
        this.onChangeDocument1ExpirationDate(elemExpirationDate1);
        if (this.client.doc1ExpirationDate) this.document1DatePicker.setDate(this.client.doc1ExpirationDate);

        const elemExpirationDate2 = document.getElementById('document2ExpirationDate');
        this.document2DatePicker = new Datepicker(elemExpirationDate2);
        this.onChangeDocument2ExpirationDate(elemExpirationDate2);
        if (this.client.doc2ExpirationDate) this.document2DatePicker.setDate(this.client.doc2ExpirationDate);

        const elemExpirationDate3 = document.getElementById('sourceOfFundsExpirationDate');
        this.document3DatePicker = new Datepicker(elemExpirationDate3);
        this.onChangeSourceOfFundsExpirationDate(elemExpirationDate3);
        if (this.client.sofExpirationDate) this.document3DatePicker.setDate(this.client.sofExpirationDate);

        const elemExpirationDate4 = document.getElementById('complianceExpirationDate');
        this.document4DatePicker = new Datepicker(elemExpirationDate4);
        this.onChangeComplianceExpirationDate(elemExpirationDate4);
        if (this.client.complianceExpirationDate) this.document4DatePicker.setDate(this.client.complianceExpirationDate);
    },
    methods: {
        show() {
            this.loadDocumentCountries();
            this.loadDocumentTypes();
            this.loadSourceOfFundsRanges();
            this.loadSourceOfFundsTypes();

            this.setLoading(true);
            this.client = {};
            axios
                .get(`/niloagent/client-compliance-exclusive/${this.clientId}`)
                .then((response) => {
                    this.initData(response.data);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.setLoading(false);
                });
            this.modal.show();
        },
        initData(client) {
            if (client === null || client == {}) {
                this.client = {
                    clientId: this.clientId,
                    doc1CountryId: 0,
                    doc1TypeId: 0,
                    doc2CountryId: 0,
                    doc2TypeId: 0,
                    sofRangeId: 0,
                    sofTypeId: 0,
                    sofAmount: null,
                };
            } else {
                this.client = client;
                this.client.doc1CountryId = client.doc1CountryId ?? 0;
                this.client.doc1TypeId = client.doc1TypeId ?? 0;
                this.client.doc1ExpirationDate = this.getFormattedDate(this.client.doc1ExpirationDate);
                this.client.doc2CountryId = client.doc2CountryId ?? 0;
                this.client.doc2TypeId = client.doc2TypeId ?? 0;
                this.client.doc2ExpirationDate = this.getFormattedDate(this.client.doc2ExpirationDate);
                this.client.sofRangeId = client.sofRangeId ?? 0;
                this.client.sofTypeId = client.sofTypeId ?? 0;
                this.client.sofExpirationDate = this.getFormattedDate(this.client.sofExpirationDate);
                this.client.complianceExpirationDate = this.getFormattedDate(this.client.complianceExpirationDate);
            }

            this.setDocument1TypeList();
            this.setDocument2TypeList();
        },
        getFormattedDate(expirationDate) {
            if (expirationDate === null || expirationDate === '') {
                return null;
            }

            const date = expirationDate.split('T')[0].split('-');
            const day = date[2];
            const month = date[1];
            const year = date[0];
            return month + '/' + day + '/' + year;
        },
        setDocument1TypeList() {
            this.form.data.availableDocument1Types = [];
            if (this.client.doc1CountryId === 0) {
                return;
            }

            if (this.client.doc1CountryId === 1) {
                var usDriversLicense = this.form.data.allDocumentTypes[0];
                var usId = this.form.data.allDocumentTypes[1];
                var usPassport = this.form.data.allDocumentTypes[2];
                var usMilitaryId = this.form.data.allDocumentTypes[3];
                var usAlienCard = this.form.data.allDocumentTypes[4];

                this.form.data.availableDocument1Types.push(usDriversLicense);
                this.form.data.availableDocument1Types.push(usId);
                this.form.data.availableDocument1Types.push(usPassport);
                this.form.data.availableDocument1Types.push(usMilitaryId);
                this.form.data.availableDocument1Types.push(usAlienCard);
            } else {
                var foreignPassport = this.form.data.allDocumentTypes[6];
                var foreignDriversLicense = this.form.data.allDocumentTypes[7];
                var foreignId = this.form.data.allDocumentTypes[8];
                var foreignMilitaryId = this.form.data.allDocumentTypes[9];
                var foreignVoterId = this.form.data.allDocumentTypes[10];

                this.form.data.availableDocument1Types.push(foreignPassport);
                this.form.data.availableDocument1Types.push(foreignDriversLicense);
                this.form.data.availableDocument1Types.push(foreignId);
                this.form.data.availableDocument1Types.push(foreignMilitaryId);
                this.form.data.availableDocument1Types.push(foreignVoterId);
            }
        },
        setDocument2TypeList() {
            this.form.data.availableDocument2Types = [];
            if (this.client.doc1CountryId === 0) {
                return;
            }

            if (this.client.doc1CountryId === 1) {
                var ssn = this.form.data.allDocumentTypes[5];
                this.form.data.availableDocument2Types.push(ssn);
            } else {
                var foreignPassport = this.form.data.allDocumentTypes[6];
                var foreignDriversLicense = this.form.data.allDocumentTypes[7];
                var foreignId = this.form.data.allDocumentTypes[8];
                var foreignMilitaryId = this.form.data.allDocumentTypes[9];
                var foreignVoterId = this.form.data.allDocumentTypes[10];

                this.form.data.availableDocument2Types.push(foreignPassport);
                this.form.data.availableDocument2Types.push(foreignDriversLicense);
                this.form.data.availableDocument2Types.push(foreignId);
                this.form.data.availableDocument2Types.push(foreignMilitaryId);
                this.form.data.availableDocument2Types.push(foreignVoterId);

                if (this.client.doc1CountryId === 42) {
                    var cpf = this.form.data.allDocumentTypes[11];
                    this.form.data.availableDocument2Types.push(cpf);
                }
            }
        },
        close() {
            this.modal.hide();
        },
        loadDocumentCountries() {
            this.setLoading(true);
            axios
                .get('/niloagent/createorder/filter/country')
                .then((response) => {
                    this.form.data.document1Countries = response.data;
                    this.form.data.document2Countries = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`${error.response.data.Message}`);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        },
        loadDocumentTypes() {
            this.setLoading(true);
            axios
                .get('/niloagent/client-compliance-exclusive/document/type')
                .then((response) => {
                    this.form.data.allDocumentTypes = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`${error.response.data.Message}`);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        },
        loadSourceOfFundsRanges() {
            this.setLoading(true);
            axios
                .get('/niloagent/client-compliance-exclusive/source-of-funds/range')
                .then((response) => {
                    this.form.data.sourceOfFundsRanges = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`${error.response.data.Message}`);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        },
        loadSourceOfFundsTypes() {
            this.setLoading(true);
            axios
                .get('/niloagent/client-compliance-exclusive/source-of-funds/type')
                .then((response) => {
                    this.form.data.sourceOfFundsTypes = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`${error.response.data.Message}`);
                })
                .finally(() => {
                    this.setLoading(false);
                });
        },
        onChangeDocument1Country() {
            this.client.doc1TypeId = 0;
            this.client.doc2CountryId = this.client.doc1CountryId;
            this.onChangeDocument2Country();
            this.setDocument1TypeList();
        },
        onChangeDocument2Country() {
            this.client.doc2TypeId = 0;
            this.setDocument2TypeList();
        },
        onChangeSourceOfFundsType() {
            if (this.client.sofTypeId === 11) {
                this.form.flow.showSourceOfFundsOtherType = true;
            } else {
                this.form.flow.showSourceOfFundsOtherType = false;
                this.client.sofOtherType = null;
            }
        },
        onChangeDocument1ExpirationDate(elem) {
            elem.addEventListener('changeDate', () => {
                this.client.doc1ExpirationDate = this.form.element.document1ExpirationDate.getDate('mm/dd/yyyy');
            });
        },
        onChangeDocument2ExpirationDate(elem) {
            elem.addEventListener('changeDate', () => {
                this.client.doc2ExpirationDate = this.form.element.document2ExpirationDate.getDate('mm/dd/yyyy');
            });
        },
        onChangeSourceOfFundsExpirationDate(elem) {
            elem.addEventListener('changeDate', () => {
                this.client.sofExpirationDate = this.form.element.sourceOfFundsExpirationDate.getDate('mm/dd/yyyy');
            });
        },
        onChangeComplianceExpirationDate(elem) {
            elem.addEventListener('changeDate', () => {
                this.client.complianceExpirationDate = this.form.element.complianceExpirationDate.getDate('mm/dd/yyyy');
            });
        },
        setLoading(isLoading) {
            this.form.flow.loading = isLoading;
        },
        setSaving(isSaving) {
            this.form.flow.saving = isSaving;
        },
        disableButtons() {
            return this.form.flow.saving || this.form.flow.loading;
        },
        save() {
            if (!this.validateForm()) {
                return;
            }

            this.setSaving(true);

            this.client.doc1Active ??= false;
            this.client.doc2Active ??= false;
            this.client.complianceActive ??= false;
            this.client.sofActive ??= false;

            axios
                .post(`/niloagent/client-compliance-exclusive`, this.client)
                .then(() => {
                    this.$toast.success("Client's Compliance Exclusive successfully updated!");
                    this.modal.hide();
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.setSaving(false);
                });
        },
        validateForm() {
            this.resetFormValidation();

            let isFormValid = true;
            if (this.client.doc1Active) {
                if (this.client.doc1CountryId === 0) {
                    this.form.validation.document1Country.invalid = true;
                    this.form.validation.document1Country.message = 'Country is required';
                    isFormValid = false;
                }
                if (this.client.doc1TypeId === 0) {
                    this.form.validation.document1Type.invalid = true;
                    this.form.validation.document1Type.message = 'Type is required';
                    isFormValid = false;
                }
                if (this.client.doc1ExpirationDate === null || this.client.doc1ExpirationDate === '') {
                    this.form.validation.document1ExpirationDate.invalid = true;
                    this.form.validation.document1ExpirationDate.message = 'Date is required';
                    isFormValid = false;
                }
                if (this.client.doc1ExpirationDate !== null) {
                    const doc1ExpirationDate = new Date(this.client.doc1ExpirationDate);
                    if (new Date().getTime() > doc1ExpirationDate.getTime()) {
                        this.form.validation.document1ExpirationDate.invalid = true;
                        this.form.validation.document1ExpirationDate.message = 'Document is expired';
                        isFormValid = false;
                    }
                }
            }

            if (this.client.doc2Active) {
                if (this.client.doc2CountryId === 0) {
                    this.form.validation.document2Country.invalid = true;
                    this.form.validation.document2Country.message = 'Country is required';
                    isFormValid = false;
                }
                if (this.client.doc2TypeId === 0) {
                    this.form.validation.document2Type.invalid = true;
                    this.form.validation.document2Type.message = 'Type is required';
                    isFormValid = false;
                }
                if (this.client.doc2ExpirationDate === null || this.client.doc2ExpirationDate === '') {
                    if (this.client.doc2TypeId !== 6 && this.client.doc2TypeId !== 12) {
                        this.form.validation.document2ExpirationDate.invalid = true;
                        this.form.validation.document2ExpirationDate.message = 'Date is required';
                        isFormValid = false;
                    }
                }
                if (this.client.doc2ExpirationDate !== null) {
                    const doc2ExpirationDate = new Date(this.client.doc2ExpirationDate);
                    if (new Date().getTime() > doc2ExpirationDate.getTime()) {
                        this.form.validation.document2ExpirationDate.invalid = true;
                        this.form.validation.document2ExpirationDate.message = 'Document is expired';
                        isFormValid = false;
                    }
                }
            }

            if (this.client.sofActive) {
                if (this.client.sofRangeId === 0) {
                    this.form.validation.sourceOfFundsRange.invalid = true;
                    this.form.validation.sourceOfFundsRange.message = 'Range is required';
                    isFormValid = false;
                }
                if (this.client.sofTypeId === 0) {
                    this.form.validation.sourceOfFundsType.invalid = true;
                    this.form.validation.sourceOfFundsType.message = 'Type is required';
                    isFormValid = false;
                }
                if ((this.client.sofTypeId === 11 && this.client.sofOtherType === null) || this.client.sofOtherType === '') {
                    this.form.validation.sourceOfFundsOtherType.invalid = true;
                    this.form.validation.sourceOfFundsOtherType.message = 'Type is required';
                    isFormValid = false;
                }
                if (this.client.sofAmount === null || this.client.sofAmount <= 0) {
                    this.form.validation.sourceOfFundsAmount.invalid = true;
                    this.form.validation.sourceOfFundsAmount.message = 'Amount is required';
                    isFormValid = false;
                }
                if (this.client.sofExpirationDate === null || this.client.sofExpirationDate === '') {
                    this.form.validation.sourceOfFundsExpirationDate.invalid = true;
                    this.form.validation.sourceOfFundsExpirationDate.message = 'Date is required';
                    isFormValid = false;
                }
                if (this.client.sofExpirationDate !== null) {
                    const sofExpirationDate = new Date(this.client.sofExpirationDate);
                    if (new Date().getTime() > sofExpirationDate.getTime()) {
                        this.form.validation.sourceOfFundsExpirationDate.invalid = true;
                        this.form.validation.sourceOfFundsExpirationDate.message = 'Document is expired';
                        isFormValid = false;
                    }
                }
            }

            if (this.client.complianceActive) {
                if (this.client.complianceType === null || this.client.complianceType === '') {
                    this.form.validation.complianceType.invalid = true;
                    this.form.validation.complianceType.message = 'Type is required';
                    isFormValid = false;
                }
                if (this.client.complianceExpirationDate === null || this.client.complianceExpirationDate === '') {
                    this.form.validation.complianceExpirationDate.invalid = true;
                    this.form.validation.complianceExpirationDate.message = 'Date is required';
                    isFormValid = false;
                }
                if (this.client.complianceExpirationDate !== null) {
                    const complianceExpirationDate = new Date(this.client.complianceExpirationDate);
                    if (new Date().getTime() > complianceExpirationDate.getTime()) {
                        this.form.validation.complianceExpirationDate.invalid = true;
                        this.form.validation.complianceExpirationDate.message = 'Document is expired';
                        isFormValid = false;
                    }
                }
            }

            return isFormValid;
        },
        resetFormValidation() {
            this.form.validation.document1Country.invalid = false;
            this.form.validation.document1Country.message = '';
            this.form.validation.document1Type.invalid = false;
            this.form.validation.document1Type.message = '';
            this.form.validation.document1ExpirationDate.invalid = false;
            this.form.validation.document1ExpirationDate.message = '';

            this.form.validation.document2Country.invalid = false;
            this.form.validation.document2Country.message = '';
            this.form.validation.document2Type.invalid = false;
            this.form.validation.document2Type.message = '';
            this.form.validation.document2ExpirationDate.invalid = false;
            this.form.validation.document2ExpirationDate.message = '';

            this.form.validation.sourceOfFundsRange.invalid = false;
            this.form.validation.sourceOfFundsRange.message = '';
            this.form.validation.sourceOfFundsType.invalid = false;
            this.form.validation.sourceOfFundsType.message = '';
            this.form.validation.sourceOfFundsOtherType.invalid = false;
            this.form.validation.sourceOfFundsOtherType.message = '';
            this.form.validation.sourceOfFundsAmount.invalid = false;
            this.form.validation.sourceOfFundsAmount.message = '';
            this.form.validation.sourceOfFundsExpirationDate.invalid = false;
            this.form.validation.sourceOfFundsExpirationDate.message = '';

            this.form.validation.complianceType.invalid = false;
            this.form.validation.complianceType.message = '';
            this.form.validation.complianceExpirationDate.invalid = false;
            this.form.validation.complianceExpirationDate.message = '';
        },
    },
};
</script>

<style scoped>
.modal-size {
    max-width: 950px;
}
</style>
