<template>
    <layout>
        <div class="container text-center" v-if="data">
            <h1 class="page-title text-uppercase text-primary mb-4">Order Confirmation</h1>
            <div class="row justify-content-center">
                <div class="col-md-8">
                    <div class="alert alert-success mb-4">
                        The order number <span class="fw-bold fs-5 text-uppercase">{{ data.invoiceId }}</span> has been confirmed.
                        <p class="mb-0">
                            Your digital confirmation number is: <span class="fw-bold fs-5 text-uppercase">{{ data.orderCode }}</span>
                        </p>
                        <br />
                        <div v-if="loadingReceipt" class="d-flex gap-2 justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div v-else class="btn-wrapper">
                            <router-link :to="{ name: 'Order Receipt', params: { orderId: data.invoiceId }, query: { printOnLoad: 'true' } }" class="btn btn-primary text-capitalize" target="_blank"> <i class="fas fa-print me-2"></i>Print Receipt</router-link>
                            <router-link :to="{ name: 'Simple Order', query: { senderId: this.data.senderId } }" class="btn btn-primary text-capitalize"><i class="fas fa-history me-2"></i>Repeat Sender</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <p class="small text-muted">Please contact your central office in case any modication necessary for this order.</p>

            <div v-if="showForApproval" class="row justify-content-center">
                <div class="col-md-8">
                    <div class="alert alert-danger mb-4">
                        <p class="mb-0"><span class="fw-bold fs-6 text-uppercase">This order will require additional documentation for Approval</span></p>
                        <br />
                        <p class="mb-0">These include a compliance form and copies of sender documents that must be faxed or e-mailed to Inter.</p>
                        <br />
                        <p class="mb-0">(FOR TRANSACTIONS OF US$ 3000.00 OR MORE, INCLUDING FEES) Send this signed form to Inter, Inc. by whatsApp # 1877-766-8825, or by mail at compliance@inter.co</p>
                        <br />
                        <p class="mb-0">(PARA TRANSAÇÕES DE US$ 3000.00 OU MAIS, INCLUSO TARIFA) Envie esse formulário para a Inter Inc. por whatsApp # 1877-766-8825, ou por email compliance@inter.co</p>
                        <br />
                        <p class="mb-0">(PARA TRANACCIONES DE US$ 3000.00 OU MÁS, INCLUYENDO LA TARIFA) EnvÍe esee formulArio para a Inter Inc. por whatsApp # 1877-766-8825, ou por email compliance@inter.co</p>
                        <br />
                        <router-link :to="{ name: 'Compliance Form', params: { orderId: data.invoiceId } }" class="btn btn-primary text-uppercase mb-3" target="_blank"><i class="fas fa-print me-2"></i> Print Compliance Form</router-link>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import { getOrderReceiptDetail } from '../../../services/orders';
import { persistOrdersReceiptDetail } from '../../../helpers/orders';

export default {
    name: 'OrderConfirmation',
    components: {
        Layout,
    },
    props: {
        orderId: Number,
    },
    data() {
        return {
            data: null,
            loading: false,
            loadingReceipt: false,
            timmer: 3,
        };
    },
    mounted() {
        this.loadingReceipt = true;
        Promise.all([this.load(), this.loadDetailToPrint()]).then(this.waitForPrintReceipt);
    },
    methods: {
        async load() {
            try {
                const response = await axios.get(`/niloagent/order/${this.orderId}/detail`);
                this.data = response.data;
            } catch (error) {
                this.data = null;
                this.$toast.error(error.response.data.Message);
            }
        },
        async loadDetailToPrint() {
            try {
                const response = await getOrderReceiptDetail(this.orderId);
                const dataToStore = { id: this.orderId, data: response.data };
                persistOrdersReceiptDetail(dataToStore);
            } catch (error) {
                this.$toast.error(error.response.data.Message);
            }
        },
        waitForPrintReceipt() {
            this.loadingReceipt = false;
            const route = this.$router.resolve({ name: 'Order Receipt', params: { orderId: this.data.invoiceId }, query: { printOnLoad: 'true' } });
            window.open(route.href, '_blank');
        },
    },
    computed: {
        order() {
            return {
                id: 12343232,
                confirmationNumber: 'XYUSD',
            };
        },
        showForApproval() {
            return this.data.status === 'For Approval';
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    button,
    a {
        width: 208px;
        margin: 0;
    }
}
</style>
