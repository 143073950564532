export function formatMoney(value) {
    let money = value.replace(/[^\d]/g, '');
    money = money.padStart(4, '0');

    let beforeDecimal = money.slice(0, -2);
    let afterDecimal = money.slice(-2);

    beforeDecimal = beforeDecimal.replace(/^0+/, '');

    if (beforeDecimal === '') beforeDecimal = 0;

    const formattedValue = `${beforeDecimal}.${afterDecimal}`;

    return formattedValue;
}
