<template>
    <div class="modal" id="modal-confirm-client-changes" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Client Information Changed</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <h3 class="fs-6 fw-bold mb-3"></h3>
                    <ul class="m-0 list-unstyled" >
                        <li class="mb-2"  v-for="item in confirmationText" :key="item" >{{item}}</li>
                         <li class="mb-2"><a href="javascript:void()"  @click="showChanges">see changes</a></li>
                    </ul>
                     <ul class="m-0 list-unstyled" v-show="showChangeFields">
                        <li class="mb-2"  v-for="item in changes" :key="item" >{{item}}</li>
                    </ul>
                </div>
                <div class="modal-footer d-flex">
                    <button type="button" class="btn btn-primary me-auto text-uppercase" @click="confirm">YES</button>
                    <button type="button" class="btn btn-outline-primary text-uppercase" @click="cancel">NO</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
    name: "ClientChangedConfirmation",
    data() {
        return {
            modal: null,
            confirmationText: [],
            changes: [],
            showChangeFields: false,
        }
    },
    mounted () {
        this.modal = new Modal(document.getElementById('modal-confirm-client-changes'));
    },
    methods: {
        confirm() {
            this.$emit('confirmed', true);
            this.modal.hide();
        },
        show(text, fieldChanges) {
            this.changes = fieldChanges;
            this.confirmationText = text;
            this.modal.show();
        },
        cancel() {
            this.$emit('confirmed', false);
            this.modal.hide();
        },
        showChanges(){
            this.showChangeFields= !this.showChangeFields
        }
    }
}
</script>

<style scoped>

</style>