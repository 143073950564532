import { render, staticRenderFns } from "./DetailOrderDebitCard.vue?vue&type=template&id=ada1a408&scoped=true&"
import script from "./DetailOrderDebitCard.vue?vue&type=script&lang=js&"
export * from "./DetailOrderDebitCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ada1a408",
  null
  
)

export default component.exports