<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Compliance Forms</h1>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="mb-4 col-3">
                            <a :href="pdfCancellationFormLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Cancellation Form</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfPaymentRefundReceiptLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Payment Refund Receipt</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfAcknowledgementOfEmployeeTrainingLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Acknowledgement of Employee Training</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfInterComplianceFormLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Inter Compliance Form</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfPontualBusinessRegistrationFormLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Inter Business Registration Form</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfVoluntaryDisqualificationFormRequestLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Voluntary Disqualification Request Form</template>
                                </MenuButton>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Main';
import MenuButton from '@/components/MenuButton';

import pdfCancellationForm from '@/assets/pdf/forms/CancellationForm.pdf';
import pdfAcknowledgementOfEmployeeTraining from '@/assets/pdf/forms/AcknowledgementOfEmployeeTraining.pdf';
import pdfInterComplianceForm from '@/assets/pdf/forms/InterComplianceForm.pdf';
import pdfPontualBusinessRegistrationForm from '@/assets/pdf/forms/InterBusinessRegistrationForm.pdf';
import pdfPaymentRefundReceipt from '@/assets/pdf/forms/PaymentRefundReceipt.pdf';
import pdfVoluntaryDisqualificationFormRequest from '@/assets/pdf/forms/VoluntaryDisqualificationFormRequest.pdf';

export default {
    name: 'FormsMenu',
    components: {
        Layout,
        MenuButton,
    },
    data() {
        return {
            pdfCancellationFormLink: pdfCancellationForm,
            pdfPaymentRefundReceiptLink: pdfPaymentRefundReceipt,
            pdfAcknowledgementOfEmployeeTrainingLink: pdfAcknowledgementOfEmployeeTraining,
            pdfInterComplianceFormLink: pdfInterComplianceForm,
            pdfPontualBusinessRegistrationFormLink: pdfPontualBusinessRegistrationForm,
            pdfVoluntaryDisqualificationFormRequestLink: pdfVoluntaryDisqualificationFormRequest,
        };
    },
};
</script>

<style scoped></style>
