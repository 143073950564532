import router from '@/router/index';
import OrderFind from '@/views/order/Find';
import OrderDetail from '@/views/order/Detail';
import Sender from '@/views/order/create/Sender';
import OrderReceipt from '@/views/order/Receipt';
import Payment from '@/views/order/create/Payment';
import Recipient from '@/views/order/create/Recipient';
import OrderSummary from '@/views/order/create/Summary';
import FindSender from '@/views/order/create/FindSender';
import ComplianceForm from '@/views/order/ComplianceForm';
import PreReceipt from '@/views/order/receipts/PreReceipt';
import FindRecipient from '@/views/order/create/FindRecipient';
import OrderConfirmation from '@/views/order/create/Confirmation';
import PreReceiptPrint from '@/views/order/receipts/PreReceiptPrint';
import OrderPaymentReceipt from '@/views/order/receipts/PaymentReceipt';
import AdditionalInformation from '@/views/order/create/AdditionalInformation';
import CreditDebitCardPayment from '@/views/order/create/CreditDebitCardPayment';
import NewOrder from '@/views/order/simpleOrder/NewOrder';

export default [
    {
        path: '/new-order',
        name: 'Simple Order',
        component: NewOrder,
    },
    {
        path: '/order/create/new',
        name: 'New Order',
        beforeEnter: () => {
            router.app.$store.dispatch('order/create');
            router.push({ name: 'Find Sender' });
        },
    },
    {
        path: '/order/create/find-sender',
        name: 'Find Sender',
        component: FindSender,
        beforeEnter: (to, from, next) => {
            if (!router.app.$store.state.order.orderReferenceNumber > 0) next({ name: 'New Order' });
            else next();
        },
    },
    {
        path: '/order/create/sender/:senderId?',
        name: 'Sender',
        component: Sender,
        props: (route) => ({ senderId: parseInt(route.params.senderId, 10) }),
    },
    {
        path: '/order/create/find-recipient',
        name: 'Find Recipient',
        component: FindRecipient,
        beforeEnter: (to, from, next) => {
            if (!router.app.$store.state.order.sender) router.push({ name: 'Find Sender' });
            else next();
        },
    },
    {
        path: '/order/create/recipient/:recipientId?',
        name: 'Recipient',
        component: Recipient,
        props: (route) => ({
            recipientId: route.params.recipientId ? parseInt(route.params.recipientId, 10) : null,
        }),
        beforeEnter: (to, from, next) => {
            if (!router.app.$store.state.order.sender) router.push({ name: 'Find Sender' });
            else next();
        },
    },
    {
        path: '/order/create/payment',
        name: 'Payment Type And Amount',
        component: Payment,
        beforeEnter: (to, from, next) => {
            if (!router.app.$store.state.order.sender) router.push({ name: 'Find Sender' });
            else if (!router.app.$store.state.order.recipient) router.push({ name: 'Find Recipient' });
            else next();
        },
    },
    {
        path: '/order/create/additional-information',
        name: 'Additional Information',
        component: AdditionalInformation,
    },
    {
        path: '/order/create/summary',
        name: 'Order Summary',
        component: OrderSummary,
        beforeEnter: (to, from, next) => {
            if (!router.app.$store.state.order.orderReferenceNumber > 0) router.push({ name: 'New Order' });
            else next();
        },
    },
    {
        path: '/order/create/confirmation/:orderId',
        name: 'Order Confirmation',
        component: OrderConfirmation,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/find',
        name: 'Order Find',
        component: OrderFind,
        props: true,
    },
    {
        path: '/order/detail/:orderId',
        name: 'Order Detail',
        component: OrderDetail,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/receipt/:orderId',
        name: 'Order Receipt',
        component: OrderReceipt,
        props: (route) => ({ 
            orderId: parseInt(route.params.orderId, 10),
            printOnLoad: route.query.printOnLoad === 'true' 
        }),
    },
    {
        path: '/order/payment-receipt/:orderId',
        name: 'Order Payment Receipt',
        component: OrderPaymentReceipt,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/detail/complianceForm/:orderId',
        name: 'Compliance Form',
        component: ComplianceForm,
        props: (route) => ({ orderId: parseInt(route.params.orderId, 10) }),
    },
    {
        path: '/order/card-payment/:token',
        name: 'Credit Debit Card Payment',
        component: CreditDebitCardPayment,
        props: true,
    },
    {
        path: '/order/pre-receipt',
        name: 'Pre-Receipt',
        component: PreReceipt,
    },
    {
        path: '/order/pre-receipt-print',
        name: 'Pre-Receipt Print',
        component: PreReceiptPrint,
    },
];
