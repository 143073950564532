<template>
    <div class="modal" id="modal-nilo-online-documents">
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Nilo Online Documents</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <loading :show="loading" />
                    <div class="alert alert-warning" v-show="data !== null && data.length === 0">No documents were found for Sender.</div>
                    <div class="table-responsive" v-show="!loading && data !== null && data.length > 0">
                        <table class="table table-borderless">
                            <thead class="bg-light">
                                <tr>
                                    <th class="rounded-start">Document</th>
                                    <th>By</th>
                                    <th class="rounded-end">From</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data" :key="item.id">
                                    <td class="text-truncate document-name">
                                        <a :href="item.documentUrl" target="_blank">{{ item.documentName }}</a>
                                    </td>
                                    <td>
                                        {{ item.uploadedBy }}
                                    </td>
                                    <td>
                                        {{ item.uploadedFrom }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import Loading from '@/components/Loading';

export default {
    name: 'NiloOnlineDocuments',
    components: {
        Loading,
    },
    props: {
        orderId: Number,
    },
    data() {
        return {
            modal: null,
            data: null,
            loading: false,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-nilo-online-documents'));
    },
    methods: {
        load() {
            if (!this.data) {
                this.loading = true;
                axios
                    .get(`/niloagent/order/${this.orderId}/detail/senderNiloOnlineDocuments`)
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((error) => {
                        this.$toast.error(`load: ${error.response.data.Message}`);
                        this.data = null;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        show() {
            this.data = null;
            this.modal.show();
            this.load();
        },
    },
};
</script>

<style lang="scss" scoped>
.document-name {
    max-width: 300px;
}
</style>
