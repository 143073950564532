<template>
    <layout :show-navbar-links="false">
        <div class="container py-4">
            <h1 class="page-title text-primary mb-4 text-uppercase">Notification</h1>

            <p class="fw-bold fst-italic">Dear Agent/ Employee</p>
            <p>Here is our company’s <span class="text-decoration-underline">Regulation E</span> and <span class="text-decoration-underline">UDAAP</span> presentation (training materials) that lay the grounds to comply with Federal consumer financial laws and prevent associated risks that potentially could harm consumers.</p>
            <p>It’s crucial that you as agent (and its employees) and our employees are informed and able to implement and follow established procedures to comply with the law.</p>
            <p>These two presentations will be available in our system under:</p>
            <ul>
                <li>Compliance Documents</li>
                <li>Documents</li>
            </ul>
            <p><span class="fw-bold">Link (Reg E Presentation):</span> <a :href="pdfRegulation_E_training2022eLink" target="_blank">Regulation E Training</a></p>
            <p><span class="fw-bold">Link (UDDAP Presentation):</span> <a :href="pdfUDDAP_Training_2022Link" target="_blank">UDAAP Training</a></p>

            <div class="row my-5 justify-content-center">
                <div class="col-lg-8">
                    <div class="card shadow">
                        <div class="card-body">
                            <img src="@/assets/img/compliance-documents.png" class="mx-auto w-100">
                        </div>
                    </div>
                </div>
            </div>

            <p class="fw-bold fst-italic">Prezado Agente/Funcionário</p>
            <p>Aqui segue as apresentações do <u>Regulamento E</u> e <u>UDAAP</u> da nossa empresa (materiais de treinamento) que estabelecem os fundamentos para cumprir com as leis financeiras federais do consumidor e evitar riscos associados que possam prejudicar os consumidores.</p>
            <p>É crucial que você com nosso agente (e seus funcionários) e nossos  funcionários estejam informados e sejam capazes de implementar e seguir os procedimentos estabelecidos para cumprir com a lei.</p>
            <p>Essas duas apresentações estarão disponíveis em nosso sistema sob:</p>
            <ul>
                <li>Compliance Documents</li>
                <li>Documents</li>
            </ul>
            <p><span class="fw-bold">Link (Reg E Presentation):</span> <a :href="pdfRegulation_E_training2022eLink" target="_blank">Regulation E Training</a></p>
            <p><span class="fw-bold">Link (UDDAP Presentation):</span> <a :href="pdfUDDAP_Training_2022Link" target="_blank">UDAAP Training</a></p>

            <div class="row border-top pt-4 justify-content-center">
                <div class="col-md-2">
                    <button type="button" id="btnComplianceSignsOk" @click="ok" class="btn btn-sm btn-primary w-100">OK</button>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "@/views/layouts/Main"
import pdfUDDAP_Training_2022 from "@/assets/pdf/compliance/documents/UDDAP-Training-2022.pdf";
import pdfRegulation_E_training2022 from "@/assets/pdf/compliance/documents/Regulation-E-training-2022.pdf";

export default {
    name: "ComplianceSigns",
    components: {
        Layout,
    },
    data() {
        return {
            pdfRegulation_E_training2022eLink: pdfRegulation_E_training2022,
            pdfUDDAP_Training_2022Link: pdfUDDAP_Training_2022
        }
    },
    methods: {
        async ok() {
            await this.$store.commit('setShowComplianceSigns', false);
            this.$router.push({ name: 'Home' });
        }
    }
}
</script>

<style scoped>

</style>