<template>
    <div>
        <loading :show="loading"></loading>

        <payment-receipt-inter v-if="showInterReceipt" :data="data"></payment-receipt-inter>
        <payment-receipt-rendimento v-if="showRendimentoReceipt" :data="data"></payment-receipt-rendimento>
    </div>
</template>

<script>
import axios from "axios"
import Loading from "@/components/Loading"
import PaymentReceiptInter from "./PaymentReceiptInter"
import PaymentReceiptRendimento from "./PaymentReceiptRendimento";

export default {
    name: "PaymentReceipt",
    props: {
        orderId: Number
    },
    components: {
        Loading,
        PaymentReceiptInter,
        PaymentReceiptRendimento
    },
    data() {
        return {
            data: null,
            loading: false,
            linkPdf: ''
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            axios
                .get(`/niloagent/order/${this.orderId}/detail/paymentreceipt`)
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        downloadPdf() {
            const linkSource = `data:application/pdf;base64,${this.data.fileBytes}`;
            const downloadLink = document.createElement("a");
            const fileName = `${this.orderId}-payment-receipt.pdf`;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            setTimeout(function() {
                window.close()
            }, 1000);
        }
    },
    computed: {
        showInterReceipt() {
            return this.data ? this.data.paymentReceiptType.toUpperCase() === 'INTER' : false;
        },
        showRendimentoReceipt() {
            return this.data ? this.data.paymentReceiptType.toUpperCase() === 'RENDIMENTO' : false;
        },
        showDaycovalReceipt() {
            return this.data ? this.data.paymentReceiptType.toUpperCase() === 'DAYCOVAL' : false;
        },
        showBTReceipt() {
            return this.data ? this.data.paymentReceiptType.toUpperCase() === 'BT' : false;
        }
    },
    watch: {
        showDaycovalReceipt(value) {
            if (value)
                this.downloadPdf();
        },
        showBTReceipt(value) {
            if (value)
                this.downloadPdf();
        }
    }
}
</script>

<style scoped>

</style>