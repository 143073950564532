<template>
    <div id="app">
    <error-boundary>
      <router-view />
    </error-boundary>
  </div>
</template>

<script>
import ErrorBoundary from "@/components/ErrorBoundary";

export default {
    name: 'App',
    components: {
        'error-boundary': ErrorBoundary    
    },
}
</script>

<style lang="scss">
@import "./assets/scss/variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/vanillajs-datepicker/dist/css/datepicker";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import "./assets/scss/custom";
</style>

