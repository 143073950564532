<template>
    <div>
        <div class="container-fluid" v-if="data.items.length > 0">
            <div v-for="(item, index) in data.items" :key="index">
                <div class="bg-dark text-white p-2">{{ item.typeOfPayment }}</div>
                <div class="table-responsive d-print-table">
                    <table class="table table-borderless">
                        <thead class="bg-light">
                            <tr>
                                <th class="">DATE</th>
                                <th>PAYMENT No.</th>
                                <th>COMPANY</th>
                                <th class="text-center">IMG</th>
                                <th>TYPE</th>
                                <th>ACCOUNT</th>
                                <th>REFERENCE/DESCRIPTION</th>
                                <th class="text-end">AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-gray fw-bold" v-show="item.itemsDetailsPaymentReceived.length > 0">
                                <td colspan="8" class="text-light">Payments Received</td>
                            </tr>
                            <tr v-for="(itemPayment, index) in item.itemsDetailsPaymentReceived" :key="index">
                                <td>
                                    {{ itemPayment.date.replace('-', ' ') }} <br />
                                    By: {{ itemPayment.userId }}
                                </td>
                                <td>
                                    {{ itemPayment.paymentNumber }}
                                </td>
                                <td>
                                    {{ itemPayment.companyName }}
                                </td>
                                <td class="text-center">
                                    <a :href="itemPayment.img" target="_blank" class="btn btn-sm btn-outline-gray" v-show="itemPayment.img"> <i class="far fa-file-alt"></i> Open </a>
                                </td>
                                <td>
                                    {{ itemPayment.typeDescription }} <br />
                                    {{ itemPayment.payType }}
                                </td>
                                <td>
                                    {{ itemPayment.account }}
                                </td>
                                <td>
                                    {{ itemPayment.referenceDescription }}
                                </td>
                                <td class="text-end">
                                    {{ itemPayment.amountFormated }}
                                </td>
                            </tr>
                            <tr class="bg-gray text-light fw-bold" v-show="item.itemsDetailsPaymentReceived.length > 0">
                                <td colspan="7" class="text-light">Total Payments Received</td>
                                <td class="text-light text-end">{{ item.totalPaymentsReceived }}</td>
                            </tr>
                            <tr class="bg-gray fw-bold" v-show="item.itemsDetailsPaymentMade.length > 0">
                                <td colspan="8" class="text-light">Payments Made</td>
                            </tr>
                            <tr v-for="(itemPayment, index) in item.itemsDetailsPaymentMade" :key="index">
                                <td>
                                    {{ itemPayment.date.replace('-', ' ') }} <br />
                                    By: {{ itemPayment.userId }}
                                </td>
                                <td>
                                    {{ itemPayment.paymentNumber }}
                                </td>
                                <td>
                                    {{ itemPayment.companyName }}
                                </td>
                                <td class="text-center">
                                    <a :href="itemPayment.img" target="_blank" class="btn btn-sm btn-outline-gray" v-show="itemPayment.img"> <i class="far fa-file-alt"></i> Open </a>
                                </td>
                                <td>
                                    {{ itemPayment.typeDescription }} <br />
                                    {{ itemPayment.payType }}
                                </td>
                                <td>
                                    {{ itemPayment.account }}
                                </td>
                                <td>
                                    {{ itemPayment.referenceDescription }}
                                </td>
                                <td class="text-end">
                                    {{ itemPayment.amountFormated }}
                                </td>
                            </tr>
                            <tr class="bg-gray text-light fw-bold" v-show="item.itemsDetailsPaymentMade.length > 0">
                                <td colspan="7" class="text-light">Total Payments Made</td>
                                <td class="text-light text-end">{{ item.totalPaymentsMade }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="border-top border-bottom p-2 d-flex bg-light mb-2">
                    <div>{{ item.typeOfPayment }} Cash Flow (Payments Received - Payments Made)</div>
                    <div class="ms-auto">{{ item.totalCompanyCashFlow }}</div>
                </div>
            </div>
            <div class="p-2 d-flex text-primary fw-bold">
                <div>OVERALL CASH FLOW:</div>
                <div class="ms-auto">{{ data.totalOverallCashFlow }}</div>
            </div>
        </div>
        <div class="container" v-else>
            <div class="alert alert-warning text-center container">
                <h4 class="fw-semibold">There are no transactions in this report</h4>
                <span class="fst-italic">(Please modify your search criteria and try again)</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PaymentStatementByTypeOfPaymentGrid',
    props: {
        data: Object,
    },
};
</script>
