<template>
    <Layout>
        <div class="content container">
            <h1 class="page-title text-primary mb-3 text-uppercase">CONTACT US</h1>
            <div class="row justify-content-center mt-5">
                <div class="col-lg-6 d-flex flex-column align-items-center">
                    <h5>Contact by Whatsapp</h5>

                    <h5><a href="https://wa.me/18777668825">Click here</a></h5>

                    <h5>or scan the QR Code</h5>
                    <!-- 
                    <img src="@/assets/img/qrcodecontact.jpg" class="mx-auto w-50" /> -->
                    <div class="mx-auto w-50">
                        <qrcode-vue :value="paymentLink" size="300"></qrcode-vue>
                    </div>
                </div>
                <div class="col-lg-6 d-flex flex-column justify-content-between">
                    <div>
                        <h5><b>Contact by Phone:</b> +1 (877) 766-8825</h5>
                        <br />
                        <h5><b>Contact by E-mail:</b><br />agentsupport@inter.co<br />compliance@inter.co</h5>
                    </div>
                    <div>
                        <h5><b>Business Hours:</b></h5>
                        <h5><b>Monday to Friday</b> 7:00am to 10:00pm EST</h5>
                        <h5><b>Saturday</b> 9:00am to 5:00pm EST</h5>
                        <br />
                        <label>Verify the holiday schedule within the <a href="#" @click="openModalAnnouncements">announcements and messages</a></label>
                    </div>
                </div>
            </div>
            <modal-announcements ref="announcements"></modal-announcements>
        </div>
    </Layout>
</template>

<script>
import Layout from '@/views/layouts/Main';
import ModalAnnouncements from '@/components/ModalAnnouncements';
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'Contact',
    components: {
        Layout,
        ModalAnnouncements,
        QrcodeVue,
    },
    data() {
        return {
            paymentLink: 'https://wa.me/18777668825',
        };
    },
    methods: {
        openModalAnnouncements() {
            this.$refs.announcements.openModal();
        },
    },
};
</script>
