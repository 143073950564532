<template>
    <layout :show-navbar-links="false">
        <div class="container py-4">
            <div class="row my-1 justify-content-center">
                <div class="col-lg-8">
                    <div class="card shadow">
                        <div class="card-body">
                            <img :src="imageUrl" class="mx-auto w-100" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-4 justify-content-center">
                <div class="col-md-4" style="text-align: center">
                    <a href="javascript:void();" @click="abrirEmNovaAba"> Clique aqui para imprimir</a>
                </div>
            </div>
            <div class="row border-top pt-4 justify-content-center">
                <div class="col-md-2">
                    <button type="button" id="btnComplianceSignsOk" @click="ok" class="btn btn-sm btn-primary w-100">OK</button>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Main';

export default {
    name: 'RemessaPremiada',
    components: {
        Layout,
    },
    data() {
        return {
            imageUrl: 'promocaocarroeletrico.png',
        };
    },
    methods: {
        abrirEmNovaAba() {
            window.open(this.imageUrl, '_blank');
        },
        async ok() {
            localStorage.setItem('showRemessaPremiada', 'false');
            localStorage.setItem('dateRemessaPremiada', new Date());
            this.$router.push({ name: 'Home' });
        },
    },
};
</script>

<style scoped></style>
