export default {
  namespaced: true,
  state: {
    orderId: 0,
    sender: null,
    recipient: null,
    paymentInformation: null,
    additionalInformation: null,
    orderReferenceNumber: 0,
  },
  actions: {
    create({ commit }) {
      commit('setOrderId', null)
      commit('setSender', null)
      commit('setRecipient', null)
      commit('setPaymentInformation', null)
      commit('setAdditionalInformation', null)
      commit('setReferenceNumber', new Date().valueOf())
    },
    checkIfRecipientCountryChanged({ state, commit }, value) {
      if (
        state.recipient != null &&
        value != null &&
        parseInt(state.recipient.countryId, 10) !== parseInt(value.countryId, 10)
      ) {
        commit('setPaymentInformation', null)
      }
    },
  },
  getters: {
    existIncompleteOrders(state) {
      return state.incompleteOrders > 0
    },
    hasSummary(state) {
      return (
        state.sender != null &&
        state.recipient != null &&
        state.paymentInformation != null &&
        state.additionalInformation != null
      )
    },
    isEdit(state) {
      return state.orderId && state.orderId > 0
    },
  },
  mutations: {
    setReferenceNumber(state, value) {
      state.orderReferenceNumber = value
    },
    setOrderId(state, value) {
      state.orderId = value
    },
    setSender(state, value) {
      state.sender = value
    },
    setRecipient(state, value) {
      this.dispatch('order/checkIfRecipientCountryChanged', value)
      state.recipient = value
    },
    setPaymentInformation(state, value) {
      state.paymentInformation = value
    },
    setAdditionalInformation(state, value) {
      state.additionalInformation = value
    },
    setDebitCardSurcharge(state, value) {
      state.paymentInformation.debitCardSurcharge = value
    },
    setTotalCollected(state, value) {
      state.paymentInformation.totalCollected = value
    },
  },
}
