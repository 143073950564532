<template>
    <layout navigation-label="Summary">
        <template v-slot:content>
            <loading :show="loading" />
            <div class="container mt-2" v-if="fieldsVisibility">
                <div class="text-center">
                    <h1 class="text-primary fs-5 fw-bold text-uppercase" id="pageTitle">Order Summary</h1>
                    <p class="text-primary">Now it is time to check all informations before send it.</p>
                </div>
                <div class="alert alert-warning" v-if="aggregationMessage" v-html="aggregationMessage"></div>
                <div class="row mb-4">
                    <div class="col-lg-6 mb-4 mb-lg-0">
                        <div class="card h-100">
                            <div class="card-body">
                                <div class="d-flex">
                                    <h3 class="fs-6 fw-bold">
                                        <span class="text-primary">SENDER:</span> <span v-show="order.sender.company">{{ order.sender.company }} <br> </span> {{ order.sender.fullName }} <span class="text-muted">({{ order.sender.clientId }})</span>
                                    </h3>
                                    <router-link to="/order/create/sender" class="btn btn-sm btn-outline-primary text-uppercase ms-auto">Edit</router-link>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="fw-bold mb-1">Address:</label>
                                        <div class="mb-2">
                                            <div>{{ order.sender.address }}</div>
                                            <div>{{ order.sender.city }}</div>
                                            <div>{{ order.sender.stateName }}, {{ order.sender.stateIsoCode }} - {{ order.sender.countryIsoCode }}</div>
                                            <div>{{ order.sender.zip }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="fw-bold mb-1">Phone:</label>
                                        <div class="mb-2">
                                            {{ order.sender.phone }}
                                        </div>
                                        <label class="fw-bold mb-1">Birthday:</label>
                                        <div class="mb-2">
                                            {{ order.sender.birth }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Company:</label>
                                            {{ order.sender.company }}
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mb-2">
                                            <label class="fw-bold mb-1">Email:</label>
                                            {{ order.sender.email }}
                                        </div>
                                    </div>
                                </div>
                                <label class="fw-bold mb-1">Documents:</label>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Number</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Expiration</th>
                                            <th scope="col">Issued By</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>{{ order.sender.document1Number }}</td>
                                            <td>{{ order.sender.document1Type }}</td>
                                            <td>{{ order.sender.document1Expiration }}</td>
                                            <td>{{ order.sender.document1Issueby }}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>{{ order.sender.document2Number }}</td>
                                            <td>{{ order.sender.document2Type }}</td>
                                            <td>{{ order.sender.document2Expiration }}</td>
                                            <td>{{ order.sender.document2Issueby }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="d-flex mt-5 align-content-center">
                                    <h3 class="fs-6 fw-bold">
                                        <span class="text-primary">RECIPIENT:</span> <span v-show="order.recipient.company">{{ order.recipient.company }} <br> </span> {{ order.recipient.fullName }} <span class="text-muted">({{ order.recipient.clientId }})</span>
                                    </h3>
                                    <router-link :to="{ name: 'Recipient', params: { recipientId: order.recipient.clientId } }" par class="btn btn-sm btn-outline-primary text-uppercase ms-auto">Edit</router-link>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="fw-bold mb-1">Address:</label>
                                        <div class="mb-1">
                                            <div>{{ order.recipient.address }}</div>
                                            <div>{{ order.recipient.city }}</div>
                                            <div>{{ order.recipient.stateName }} - {{ order.recipient.countryIsoCode }}</div>
                                            <div>{{ order.recipient.zip }}</div>
                                        </div>
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Company:</label>
                                            {{ order.recipient.company }}
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label class="fw-bold mb-1">Phone:</label>
                                        <div class="mb-2">
                                            {{ order.recipient.phone }}
                                        </div>
                                        <label class="fw-bold mb-1">Birthday:</label>
                                        <div class="mb-2">
                                            {{ order.recipient.birth }}
                                        </div>
                                        <div class="" v-if="order.recipient.cpfOrCnpj">
                                            <label class="fw-bold mb-1">CPF/CNPJ:</label>
                                            {{ order.recipient.cpfOrCnpj }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-1">
                                    <label class="fw-bold mb-1">Email:</label>
                                    {{ order.recipient.email }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="card h-100">
                            <div class="card-body">
                                <div class="d-flex">
                                    <h3 class="fs-6 fw-bold text-primary">PAYMENT INFORMATION</h3>
                                    <router-link to="/order/create/payment" class="btn btn-sm btn-outline-primary text-uppercase ms-auto">Edit</router-link>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Service:</label>
                                            <div>{{ order.paymentInformation.paymentMethodName }}</div>
                                        </div>
                                        <div class="mb-1" v-show="showCashPickupLocation">
                                            <label class="fw-bold mb-1">Cash Pickup Location:</label>
                                            <div>{{ order.paymentInformation.payLocationName }}</div>
                                        </div>
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Currency of payment:</label>
                                            {{ order.paymentInformation.currencyOfPayment }}
                                        </div>
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Amount Sent (USD):</label>
                                            {{ order.paymentInformation.amountSent }}
                                        </div>
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Amount to be paid:</label>
                                            {{ order.paymentInformation.amountReceived }}
                                        </div>

                                        <div class="mb-1 fs-5">
                                            <label class="mb-1 fw-bold text-decoration-underline">Exchange Rate:</label>
                                            {{ order.paymentInformation.exchangeRate }}
                                        </div>
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Total Fees:</label>
                                            {{ parseFloat(order.paymentInformation.totalFees).toFixed(2) }}
                                        </div>
                                        <div class="mb-1" v-show="showDebitCardSurcharge">
                                            <label class="fw-bold mb-1">Debit Card Surcharge:</label>
                                            {{ parseFloat(order.paymentInformation.debitCardSurcharge).toFixed(2) }}
                                        </div>
                                        <div class="mb-1 fs-5">
                                            <label class="fw-bold mb-1 text-decoration-underline">Total collected:</label>
                                            {{ parseFloat(totalCollected).toFixed(2) }}
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="showBankInformation">
                                        <div class="mb-1" v-show="fieldsVisibility.bankDepositInfo.bankAbaNumberLabelDisplay">
                                            <label class="fw-bold mb-1">Bank:</label>
                                            {{ order.paymentInformation.bankName }} ({{ order.paymentInformation.abaOrNo }})
                                        </div>
                                        <div class="mb-1" v-show="fieldsVisibility.bankDepositInfo.branchNumberInputDisplay">
                                            <label class="fw-bold mb-1">Branch number:</label>
                                            {{ order.paymentInformation.branchNo }}
                                        </div>
                                        <div class="mb-1" v-show="fieldsVisibility.bankDepositInfo.accountNumberInputDisplay">
                                            <label class="fw-bold mb-1">Account number:</label>
                                            {{ order.paymentInformation.accountNumber }}
                                        </div>
                                        <div class="mb-1" v-show="fieldsVisibility.bankDepositInfo.accountTypeSelectDisplay">
                                            <label class="fw-bold mb-1">Account type:</label>
                                            {{ order.paymentInformation.accountType }}
                                        </div>
                                        <div class="mb-1" v-show="fieldsVisibility.bankDepositInfo.branchLocationDisplay">
                                            <label class="fw-bold mb-1">Branch location:</label>
                                            <div>{{ order.paymentInformation.branchLocation }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="showPIXInformation">
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Pix Type:</label>
                                            {{ order.paymentInformation.pixTypeId }}
                                        </div>
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Pix Id:</label>
                                            {{ order.paymentInformation.pixId }}
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="showBillPaymentInformation">
                                        <div class="mb-1">
                                            <label class="fw-bold mb-1">Boleto:</label>
                                            {{ order.paymentInformation.boletoNumber }}
                                        </div>
                                    </div>
                                    <div class="col-md-6" v-if="showRecipientDocuments">
                                        <div class="fw-semibold fs-6 text-secondary mb-2">Recipient Document</div>
                                        <div class="mb-1" v-show="fieldsVisibility.recipientInfo.documentNumberDisplay">
                                            <label class="fw-bold mb-1">Document Number:</label>
                                            {{ order.paymentInformation.recipientDocumentNumber }}
                                        </div>
                                        <div class="mb-1" v-show="fieldsVisibility.recipientInfo.documentTypeDisplay">
                                            <label class="fw-bold mb-1">Document Type:</label>
                                            {{ order.paymentInformation.recipientDocumentType }}
                                        </div>
                                        <div class="mb-1" v-show="fieldsVisibility.recipientInfo.documentExpirationDisplay">
                                            <label class="fw-bold mb-1">Document Expiration:</label>
                                            {{ order.paymentInformation.recipientDocumentExpiration }}
                                        </div>
                                        <div class="mb-1" v-show="fieldsVisibility.recipientInfo.birthday">
                                            <label class="fw-bold mb-1">Birthday:</label>
                                            {{ order.paymentInformation.recipientBirthday }}
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex mt-5">
                                    <h3 class="fs-6 fw-bold text-primary">ADDITIONAL INFORMATION</h3>
                                    <router-link to="/order/create/additional-information" class="btn btn-sm btn-outline-primary text-uppercase ms-auto">Edit</router-link>
                                </div>
                                <hr />
                                <div class="mb-1">
                                    <label class="fw-bold mb-1">Pay method <span class="text-muted ms-auto">(received from sender)</span>:</label>
                                    {{ order.additionalInformation.payMethod }}
                                </div>
                                <div class="mb-1">
                                    <label class="fw-bold mb-1">Sender occupation:</label>
                                    {{ order.additionalInformation.senderOccupation }}
                                </div>
                                <div class="mb-1">
                                    <label class="fw-bold mb-1">Sender relationship to recipient:</label>
                                    {{ order.additionalInformation.senderRelationshipToRecipient }}
                                </div>
                                <div class="mb-1">
                                    <label class="fw-bold mb-1">Purpose of transaction:</label>
                                    {{ order.additionalInformation.purposeOfTransaction }}
                                </div>
                                <div class="mb-1">
                                    <label class="fw-bold mb-1">Source of funds:</label>
                                    {{ order.additionalInformation.sourceOfFunds }}
                                </div>
                                <div class="mb-1">
                                    <label class="fw-bold mb-1">Message to recipient:</label>
                                    {{ order.additionalInformation.messageToRecipient }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <router-link to="/order/create/additional-information" class="btn btn-outline-primary text-uppercase"><i class="fas fa-arrow-left"></i> Back</router-link>
            </div>
        </template>
        <template v-slot:footer>
            <button :name="'btnSaveSummary'" id="btnSaveSummary" type="button" @click="confirm" class="btn btn-primary text-uppercase px-3" :disabled="sending">{{ sending ? 'Wait...' : 'Save' }}</button>
        </template>
    </layout>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import Layout from '@/views/layouts/CreateOrder';

export default {
    name: 'OrderSummary',
    components: {
        Layout,
        Loading,
    },
    data() {
        return {
            sending: false,
            loading: false,
            fieldsVisibility: null,
            aggregationMessage: null,
        };
    },
    mounted() {
        this.loadFieldsVisibility();
        this.AggregationValidation();
    },
    methods: {
        AggregationValidation() {
            //this.aggregationMessage = 'Your sent aggregation to this beneficiary for 365 days is over $24,000';
            axios
                .post('/niloagent/createorder/validate/aggregation', this.order)
                .then(() => {
                    this.aggregationMessage = null;
                })
                .catch((error) => {
                    this.aggregationMessage = error.response.data.Message.replace(/\n/g, '<br />');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        loadFieldsVisibility() {
            this.loading = true;
            axios
                .get('/niloagent/createorder/filter/payment/fieldsvisibility', {
                    params: {
                        payMethodId: this.order.paymentInformation.paymentMethodId,
                        countryId: this.order.recipient.countryId,
                        currencyId: this.order.paymentInformation.currencyId,
                    },
                })
                .then((response) => {
                    this.fieldsVisibility = response.data;
                })
                .catch((error) => {
                    this.$toast.error(`Error to find the currencies: ${error.response.data.Message}`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        confirm() {
            this.$store.commit('order/setTotalCollected', this.totalCollected);
            this.sending = true;
            axios
                .post('/niloagent/createorder', this.order)
                .then((response) => {
                    this.$router.push({ name: 'Order Detail', params: { orderId: response.data.orderId } });
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        showBankInformation() {
            return this.fieldsVisibility.bankDepositInfo.display ?? false;
        },
        showPIXInformation() {
            return this.fieldsVisibility.pixInfo.display ?? false;
        },
        showBillPaymentInformation() {
            return this.fieldsVisibility.boletoInfo.display ?? false;
        },
        showDebitCardSurcharge() {
            return this.order.additionalInformation.payMethod === 'DEBIT_CARD';
        },
        showRecipientDocuments() {
            return this.fieldsVisibility.recipientInfo.display ?? false;
        },
        showCashPickupLocation() {
            return this.fieldsVisibility.cashPickupInfo.display ?? false;
        },
        totalCollected() {
            return parseFloat(this.order.paymentInformation.debitCardSurcharge) + parseFloat(this.order.paymentInformation.amountSent) + parseFloat(this.order.paymentInformation.totalFees);
        },
    },
};
</script>

<style scoped></style>
