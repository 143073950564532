<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4">
                <h1 id="pageTitle" class="page-title text-primary text-uppercase">Tasks: Usend Hold</h1>
                <div class="row mt-4">
                    <div class="col-3">
                        <label for="handlerFilter" class="form-label">Handler Filter</label>
                        <select class="form-select" v-model="handlerFilter" id="handlerFilter">
                            <option selected>- Select -</option>
                            <option v-for="item in handlers" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-3">
                        <label for="countryFilter" class="form-label">Country Filter</label>
                        <select class="form-select" v-model="countryFilter" id="countryFilter">
                            <option value="0" selected>- Select -</option>
                            <option v-for="item in countries" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-2 d-flex flex-row">
                        <button type="button" @click="load" class="btn btn-outline-primary mt-auto" :disabled="loading"><i class="fas fa-search"></i><span v-show="!loading">Find</span><span v-show="loading">Waiting...</span></button>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <loading :show="loading"></loading>
                </div>
                <div id="noOrderFound" class="alert alert-warning" v-show="data !== null && data.length === 0">No Results Was Found using the criteria specified.</div>
            </div>
            <div v-if="data !== null && data.length > 0" class="mb-5 container-fluid">
                <div class="table-responsive">
                    <table class="table table-borderless caption-top">
                        <caption><span id="ordersFound" class="fw-bold">{{ data.length }}</span> orders found</caption>
                        <thead class="bg-light">
                        <tr>
                            <th class="text-center rounded-start"><input type="checkbox" class="form-check-input m-0" v-model="checkAll" @click="toggleCheckbox" value="true"></th>
                            <th># Order</th>
                            <th>Date</th>
                            <th>Agent</th>
                            <th>Sender</th>
                            <th>Recipient</th>
                            <th>Amount</th>
                            <th>Client ACH Payment</th>
                            <th>Last Note</th>
                            <th class="text-center">Handler</th>
                            <th class="text-center rounded-end">Notes</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in data" :key="item.orderId" :style="{'background-color': item.rowColor}">
                            <td class="text-center"><input class="form-check-input m-0" type="checkbox" v-model="selected" name="check" :value="item.orderId"></td>
                            <td><router-link :to="{ name: 'Order Detail', params: { orderId: item.orderId } }" class="fw-bold text-decoration-none">{{ item.orderId }}</router-link></td>
                            <td>
                                {{ item.date }}
                            </td>
                            <td>
                                {{ item.agent }}
                            </td>
                            <td>
                                {{ item.sender }}
                            </td>
                            <td>
                                {{ item.recipient }}
                            </td>
                            <td>
                                {{ item.amount }}
                            </td>
                            <td>
                                {{ item.achPayment }}
                            </td>
                            <td>
                                <div>{{ item.lastNote }}</div>
                                <span class="badge bg-danger rounded-3">{{ item.lastNoteStatusText }}</span>
                            </td>
                            <td class="text-center">
                                <button type="button" @click="openHandlers(item.orderId, item.handlerId)" class="text-decoration-none border-0 badge" :class="[item.handler === 'Unassigned' ? 'bg-danger' : 'bg-light text-dark']">{{ item.handler }}</button>
                            </td>
                            <td class="text-center">
                                <button type="button" @click="openNotes(item.orderId)" class="btn btn-outline-primary btn-sm">Notes</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else id="noOrderFound" class="alert alert-warning" v-show="data !== null && data.length === 0">
                No order found.
            </div>
            <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                <div class="container text-end d-flex">
                    <div class="me-auto d-inline-flex">
                        <button type="button" @click="approve" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3 " :disabled="saving">Approve</button>
                        <button type="button" @click="assign" class="btn btn-outline-primary btn-sm bg-white text-uppercase " :disabled="saving">Assign</button>
                    </div>
                    <div class="ms-auto">
                        <select class="form-select form-select me-3" v-model="remarkId">
                            <option value="0" selected>- Default Note -</option>
                            <option v-for="item in remarks" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>
            </footer>
        </div>
        <modal-notes :default-note="remarkId" ref="modalNotes"></modal-notes>
        <modal-handler ref="modalHandler" url-load="/niloagent/ComplianceTask/HandlerFilter" url-save="/niloagent/ComplianceTask/changeHandler" @onChange="load"></modal-handler>
    </layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/Main";
import Loading from "@/components/Loading";
import ModalNotes from "@/views/order/modals/Notes";
import ModalHandler from "@/views/task/ModalHandler";

export default {
    name: "UsendHold",
    components: {
        Layout,
        Loading,
        ModalNotes,
        ModalHandler,
    },
    data() {
        return {
            data: null,
            loading: false,
            handlers: [],
            countries: [],
            handlerFilter: null,
            countryFilter: null,
            selected: [],
            remarkId: 0,
            remarks: [],
            checkAll: false,
            saving: false
        }
    },
    mounted() {
        this.loadHandlerFilter();
        this.loadCountryFilter();
        this.loadRemarks();
        this.load();
    },
    methods: {
        load() {
            this.loading = true;
            this.data = null;
            this.selected = [];
            axios
                .get('/niloagent/ComplianceTask/UsendHoldList', {
                    params: {
                        handler: this.handlerFilter,
                        countryId: this.countryFilter
                    }
                })
                .then(response => {
                    this.data = response.data;
                })
                .catch(error => {
                    this.data = null;
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        approve() {
            this.saving = true;
            axios
                .post('/niloagent/ComplianceTask/approvefinancialtask', {
                    ordersToBeApproved: this.selected,
                    remarkId: this.remarkId
                })
                .then(response => {
                    this.success = true;
                    this.error = null;
                    this.selected = [];
                    this.load();
                    this.$toast.success(`${response.data.message}`);
                    console.log(response);
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                });
        },
        assign() {
            this.$refs.modalHandler.show(this.selected);
        },
        loadHandlerFilter() {
            axios
                .get('/niloagent/ComplianceTask/HandlerFilter')
                .then(response => {
                    this.handlers = response.data;
                })
                .catch(error => {
                    this.handlers = null;
                    this.$toast.error(error.response.data.Message);
                });
        },
        loadCountryFilter() {
            axios
                .get('/niloagent/ComplianceTask/CountryFilter')
                .then(response => {
                    this.countries = response.data;
                })
                .catch(error => {
                    this.countries = null;
                    this.$toast.error(error.response.data.Message);
                });
        },
        loadRemarks() {
            axios
                .get('/niloagent/ComplianceTask/NotesRemarkTypes')
                .then(response => {
                    this.remarks = response.data;
                    this.remarkId = 4;
                })
                .catch(error => {
                    this.remarks = null;
                    this.$toast.error(error.response.data.Message);
                });
        },
        toggleCheckbox() {
            if (!this.checkAll)
                this.selected = this.data.map(el => el.orderId);
            else
                this.selected = [];
        },
        openNotes(orderId) {
            this.$refs.modalNotes.show(orderId);
        },
        openHandlers(orderId) {
            this.$refs.modalHandler.show(orderId);
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    z-index: 100;
}
table tr td {
    white-space: pre-line;
}
.badge {
    font-size: .8rem;
}
</style>