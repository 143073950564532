import axios from 'axios';

export default {
    namespaced: true,
    state: {
        token: null,
        refreshToken: null,
        tokenExpiration: null,
        changePassword: false,
        timer: null,
    },
    actions: {
        async logout({ commit, state }) {
            clearTimeout(state.timer);

            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tokenExpiration');
            localStorage.removeItem('announcements.haveReadDate');
            localStorage.removeItem('ordersReceipt');

            if (state.token != null) {
                const response = await axios.post(
                    '/Auth/logout',
                    {
                        refreshToken: state.refreshToken,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${state.token}`,
                        },
                    }
                );

                if (response.status === 200) {
                    commit('setLogout');
                    commit('removeAccountingBalance', null, { root: true });
                }
            }
        },
        async authenticate({ getters, dispatch }) {
            if (getters.isAuthenticated) {
                return true;
            }

            if (getters.token && getters.token.length > 0) {
                return await dispatch('loginRefreshToken');
            } else {
                return false;
            }
        },
        async loginRefreshToken({ state, commit, getters, dispatch }) {
            if (getters.token != null || state.token != null) {
                try {
                    const response = await axios.post(
                        '/Auth/loginrefreshtoken',
                        {
                            refreshToken: state.refreshToken ?? getters.refreshToken,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${state.token ?? getters.token}`,
                            },
                        }
                    );

                    if (response.status === 200) {
                        commit('setLogin', response.data);
                        commit('agent/setAgent', response.data.agentInformation, { root: true });
                        dispatch('setRefreshTokenTimer');
                        return true;
                    }
                } catch (error) {
                    return false;
                }
            }
            return false;
        },
        setRefreshTokenTimer({ state, getters, dispatch }) {
            state.timer = setTimeout(() => {
                dispatch('loginRefreshToken');
            }, getters.expiresIn);
        },
    },
    getters: {
        isAuthenticated(state) {
            return !!state.token;
        },
        token() {
            return localStorage.getItem('token');
        },
        refreshToken() {
            return localStorage.getItem('refreshToken');
        },
        tokenExpiration() {
            return localStorage.getItem('tokenExpiration');
        },
        expiresIn(state) {
            return state.tokenExpiration - new Date().getTime();
        },
        changePassword(state) {
            return state.changePassword;
        },
    },
    mutations: {
        setLogin(state, payload) {
            const expiresIn = payload.expiresIn * 1000;
            const expirationDate = new Date().getTime() + expiresIn;

            state.token = payload.token;
            state.refreshToken = payload.refreshToken;
            state.tokenExpiration = expirationDate;
            state.changePassword = payload.changePassword;

            localStorage.setItem('token', state.token);
            localStorage.setItem('refreshToken', state.refreshToken);
            localStorage.setItem('tokenExpiration', expirationDate.toString());

            axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
        },
        setLogout(state) {
            state.token = null;
            state.refreshToken = null;
            state.tokenExpiration = null;
        },
        setChangePassword(state, value) {
            state.changePassword = value;
        },
    },
};
