import Payments from '@/views/accounting/Payments';
import DetailReport from '../views/accounting/DetailReport';
import DailyActivity from '../views/accounting/DailyActivity';
import CustomReport from '../views/accounting/CustomReport';
import UserDetailReport from '../views/accounting/UserDetailReport';
import AccountingMenu from '../views/accounting/AccountingMenu';
import BalanceStatement from '../views/accounting/BalanceStatement';
import PaymentStatement from '../views/accounting/PaymentStatement';
import UploadPayments from '@/views/accounting/UploadPayments';
import CurrentBalance from '../views/accounting/CurrentBalance';
import SummaryBalance from '../views/accounting/SummaryBalance';
import AdminReportsMenu from '../views/accounting/AdminReportsMenu';
import AgentReceiptUpload from '../views/accounting/payment-receipt-upload/AgentReceiptUpload';
import ReceiptQrCodeUpload from '@/views/accounting/payment-receipt-upload/ReceiptQrCodeUpload';
import router from '@/router/index';

export default [
    {
        path: '/accounting',
        name: 'Accounting',
        component: AccountingMenu,
    },
    {
        path: '/accounting/balance-statement',
        name: 'Accounting Balance Statement',
        component: BalanceStatement,
    },
    {
        path: '/accounting/detail-report',
        name: 'Accounting Detail Report',
        component: DetailReport,
    },
    {
        path: '/accounting/daily-activity',
        name: 'Accounting Daily Activity',
        component: DailyActivity,
    },
    {
        path: '/accounting/user-detail-report',
        name: 'User Detail Report',
        component: UserDetailReport,
    },
    {
        path: '/accounting/agent-receipt-upload',
        name: 'Agent-Receipt-Upload',
        component: AgentReceiptUpload,
    },
    {
        path: '/accounting/payment-statement',
        name: 'Accounting Payment Statement',
        component: PaymentStatement,
    },
    {
        path: '/accounting/payments',
        name: 'Accounting Payments',
        component: Payments,
        beforeEnter: (to, from, next) => {
            if (router.app.$store.getters['agent/isAgent']) router.push({ name: 'Accounting' });
            else next();
        },
    },
    {
        path: '/accounting/uploadpayments',
        name: 'Upload Payments',
        component: UploadPayments,
        beforeEnter: (to, from, next) => {
            if (router.app.$store.getters['agent/isAgent']) router.push({ name: 'Accounting' });
            else next();
        },
    },
    {
        path: '/accounting/current-balance',
        name: 'Accounting Current Balance',
        component: CurrentBalance,
        beforeEnter: (to, from, next) => {
            if (router.app.$store.getters['agent/isAgent']) router.push({ name: 'Accounting' });
            else next();
        },
    },
    {
        path: '/accounting/summary-balance',
        name: 'Accounting Summary Balance',
        component: SummaryBalance,
        beforeEnter: (to, from, next) => {
            if (router.app.$store.getters['agent/isAgent']) router.push({ name: 'Accounting' });
            else next();
        },
    },
    {
        path: '/accounting/admin-reports',
        name: 'Admin Reports Menu',
        component: AdminReportsMenu,
        beforeEnter: (to, from, next) => {
            if (router.app.$store.getters['agent/isAgent']) router.push({ name: 'Accounting' });
            else next();
        },
    },
    {
        path: '/accounting/custom-report',
        name: 'Accounting Custom Report',
        component: CustomReport,
        beforeEnter: (to, from, next) => {
            if (router.app.$store.getters['agent/isAgent']) router.push({ name: 'Accounting' });
            else next();
        },
    },
    {
        path: '/accounting/upload/:token',
        name: 'Receipt-Qr-Code-Upload',
        component: ReceiptQrCodeUpload,
        props: true,
    },
];
