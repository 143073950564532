<template>
    <div class="modal fade" id="qr-code-modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered modal-sm modal-fullscreen-lg-down">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h6 class="modal-title">Scan the QR Code to upload</h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <div class="row">
                        <qrcode-vue :value="receiptImageUploadURL" size="250"></qrcode-vue>
                    </div>
                    <br />
                    <div class="row">
                        <span>Point your cell phone camera to take a photo or choose the payment receipt from your device's gallery. Then come back here and confirm the upload.</span>
                    </div>
                    <div class="row mt-3">
                        <button class="btn btn-sm btn-primary text-uppercase" @click="confirmReceiptUpload">Confirm upload</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'FindSender',
    components: {
        QrcodeVue,
    },
    props: {
        uploadedReceiptImageId: Number,
    },
    data() {
        return {
            modal: null,
            receiptImageUploadURL: '',
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('qr-code-modal'));
    },
    methods: {
        show() {
            this.modal.show();
            this.receiptImageUploadURL = `${window.location.origin}/accounting/upload/${this.uploadedReceiptImageId}`;
        },
        close() {
            this.modal.hide();
        },
        confirmReceiptUpload() {
            this.$emit('onReceiptUploadConfirmation');
            this.close();
        },
    },
};
</script>

<style lang="scss" scoped></style>
