<template>
    <div class="card">
        <div class="card-header fs-6 fw-bold text-black-50">
            Customer Service
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnCsProblemOrdersList'" to="/tasks/problem-orders-list" class="text-decoration-none">Problem Orders</router-link></div>
            <button type="button" :name="'btnCsProblemOrdersCount'" class="btn btn-primary ms-auto btn-sm" @click="getProblemOrderCount"><i class="far fa-eye m-0"></i><span v-show="showProblemOrderCount" class="ms-2">{{ problemOrderCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnCsPendingRefundOrdersList'" to="/tasks/pending-refund-orders-list" class="text-decoration-none">Pending Refund Orders</router-link></div>
            <button type="button" :name="'btnCsPendingRefundOrdersCount'" class="btn btn-primary ms-auto btn-sm" @click="getPendingRefundOrdersCount"><i class="far fa-eye m-0"></i><span v-show="showPendingRefundOrdersCount" class="ms-2">{{ pendingRefundOrdersCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex border-bottom">
            <div class="my-2"><router-link :name="'btnCsAgingProcessOrdersList'" to="/tasks/aging-process-orders-list" class="text-decoration-none">Aging Process Orders</router-link></div>
            <button type="button" :name="'btnCsAgingProcessOrdersCount'" class="btn btn-primary ms-auto btn-sm" @click="getAgingProcessOrdersCount"><i class="far fa-eye m-0"></i><span v-show="showAgingProcessOrdersCount" class="ms-2">{{ agingProcessOrdersCount }}</span></button>
        </div>
        <div class="card-body d-inline-flex">
            <div class="my-2"><router-link :name="'btnPaidAfterSevenDaysList'" to="/tasks/paid-after-seven-days-orders-list" class="text-decoration-none">Paid after 7 days (MA)</router-link></div>
           </div>
    </div>
</template>

<script>

import axios from "axios";

export default {
    name: "CustomerServiceCard",
    data() {
        return {
            problemOrderCount: 0,
            showProblemOrderCount: false,

            pendingRefundOrdersCount:0,
            showPendingRefundOrdersCount: false,

            agingProcessOrdersCount:0,
            showAgingProcessOrdersCount: false,

            paidAfterSevenDaysCount:0,
            showPaidAfterSevenDaysCount: false,
        }
    },
    methods: {
        getProblemOrderCount() {
            axios
                .get('/niloagent/CustomerServiceTask/ProblemOrderscount')
                .then(response => {
                    this.problemOrderCount = response.data;
                    this.showProblemOrderCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        getPendingRefundOrdersCount() {
            axios
                .get('/niloagent/CustomerServiceTask/PendingRefundOrdersCount')
                .then(response => {
                    this.pendingRefundOrdersCount = response.data;
                    this.showPendingRefundOrdersCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        getAgingProcessOrdersCount() {
            axios
                .get('/niloagent/CustomerServiceTask/AgingProcessOrdersCount')
                .then(response => {
                    this.agingProcessOrdersCount = response.data;
                    this.showAgingProcessOrdersCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        getPaidAfterSevenDaysCount() {
            axios
                .get('/niloagent/CustomerServiceTask/PaidAfterSevenDaysCount')
                .then(response => {
                    this.paidAfterSevenDaysCount = response.data;
                    this.showPaidAfterSevenDaysCount = true;
                })
                .catch(error => {
                    this.$toast.error(error.response.data.Message);
                });
        },
    }
}
</script>

<style scoped>

</style>