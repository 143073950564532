<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Administration</h1>
        </div>
    </layout>
</template>

<script>
import Layout from "@/views/layouts/Main";
export default {
    name: "AdministrationMenu",
    components: {
        Layout,
    }
}
</script>

<style scoped>

</style>