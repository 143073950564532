<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Compliance Documents</h1>
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="mb-4 col-3">
                            <a :href="pdfReferenceGuideForRemittanceEnglishLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Reference Guide for Remittance</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfReferenceGuideForRemittancePortugueseLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>Guia de Referência para Remessas</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfFinCEN_BSARequirementsLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> FinCEN - BSA Requirements - Quick Guide for MSBs</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfFinCEN_MSBPreventionGuideeLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> FinCEN - MSB Prevention Guide</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfSummaryOfReqUnderPatriotActLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> Summary of Requirements Under Patriot Act (for MA agents only)</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfAML_AgentGuideLink" target="_blank">
                                <MenuButton>
                                    <template v-slot:label>AML Agent Manual</template>
                                </MenuButton>
                            </a>
                        </div>
                        <div class="mb-4 col-3">
                            <a :href="pdfCFPB_RemittanceRule_Reg_E_v2020_07_0Link" target="_blank">
                                <MenuButton>
                                    <template v-slot:label> CFPB - Remittance Rule (Reg E)</template>
                                </MenuButton>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Main';
import MenuButton from '@/components/MenuButton';
import pdfReferenceGuideForRemittanceEnglish from '@/assets/pdf/compliance/documents/ReferenceGuideForRemittanceEnglish.pdf';
import pdfReferenceGuideForRemittancePortuguese from '@/assets/pdf/compliance/documents/ReferenceGuideForRemittancePortuguese.pdf';
import pdfFinCEN_BSARequirements from '@/assets/pdf/compliance/documents/FinCEN-BSARequirements.pdf';
import pdfFinCEN_MSBPreventionGuide from '@/assets/pdf/compliance/documents/FinCEN-MSBPreventionGuide.pdf';
import pdfSummaryOfReqUnderPatriotAct from '@/assets/pdf/compliance/documents/SummaryOfReqUnderPatriotAct.pdf';
import pdfAML_AgentGuide from '@/assets/pdf/compliance/documents/AMLAgentGuide.pdf';
import pdfCFPB_RemittanceRule_Reg_E_v2020_07_0 from '@/assets/pdf/compliance/documents/CFPBRemittanceRuleRegE.pdf';

export default {
    name: 'FormsMenu',
    components: {
        Layout,
        MenuButton,
    },
    data() {
        return {
            pdfReferenceGuideForRemittanceEnglishLink: pdfReferenceGuideForRemittanceEnglish,
            pdfReferenceGuideForRemittancePortugueseLink: pdfReferenceGuideForRemittancePortuguese,
            pdfFinCEN_BSARequirementsLink: pdfFinCEN_BSARequirements,
            pdfFinCEN_MSBPreventionGuideeLink: pdfFinCEN_MSBPreventionGuide,
            pdfSummaryOfReqUnderPatriotActLink: pdfSummaryOfReqUnderPatriotAct,
            pdfAML_AgentGuideLink: pdfAML_AgentGuide,
            pdfCFPB_RemittanceRule_Reg_E_v2020_07_0Link: pdfCFPB_RemittanceRule_Reg_E_v2020_07_0,
        };
    },
};
</script>

<style scoped></style>
