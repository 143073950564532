export default {
  namespaced: true,
  state: {
    canSendTransaction: false,
    companyCode: '',
    companyId: 0,
    companyName: '',
    companyType: '',
    fullName: '',
    headquarter: false,
    permissions: [],
  },
  getters: {
        showDisclosure(state) {
            return state.permissions.includes('TRAINING');
        },
        isAgent(state) {
            return !state.headquarter;
        }
  },
  mutations: {
    setAgent(state, payload) {
      state.canSendTransaction = payload.canSendTransaction
      state.companyCode = payload.companyCode
      state.companyId = payload.companyId
      state.companyName = payload.companyName
      state.companyType = payload.companyType
      state.fullName = payload.fullName
      state.headquarter = payload.headquarter
      state.permissions = payload.permissions
    },
  },
}
