<template>
    <layout navigation-label="Sender">
        <template v-slot:content>
            <h1 class="page-title text-primary mb-4 text-uppercase">Find Sender</h1>
            <form autocomplete="off">
                <div class="row justify-content-center mb-3">
                    <div class="col-lg-2 col-md-6 mb-2">
                        <label for="senderTelephone" class="form-label">Sender Telephone</label>
                        <input autocomplete="none" type="text" v-model="telephone" @keyup.enter="load" class="form-control" placeholder="" />
                    </div>
                    <div class="col-lg-4 col-md-6 mb-2">
                        <label for="fullName" class="form-label">Full Name (First and Last Name)</label>
                        <input autocomplete="none" type="text" v-model="fullName" @keyup.enter="load" class="form-control" placeholder="" />
                    </div>
                    <div class="col-lg-4 col-md-6 mb-2">
                        <label for="companyName" class="form-label">Company Name</label>
                        <input autocomplete="none" type="text" v-model="companyName" @keyup.enter="load" class="form-control" placeholder="" />
                    </div>
                    <div class="col-lg-2 col-md-6 mb-2">
                        <label for="costumerNumber" class="form-label">Customer Number</label>
                        <input autocomplete="none" type="text" v-model="costumerNumber" @keyup.enter="load" class="form-control" placeholder="" />
                    </div>
                </div>
            </form>
            <div class="d-flex">
                <button type="button" @click="load" class="btn btn-primary btn-sm text-uppercase" :disabled="loading" id="findSender" name="findSender"><i class="fas fa-search"></i><span v-show="!loading">Find Sender</span><span v-show="loading">Waiting...</span></button>
                <button type="button" @click="clearFields" class="btn btn-outline-primary btn-sm bg-white text-uppercase ms-3">Clear all</button>
            </div>

            <div class="mt-4">
                <loading :show="loading" />
                <div class="alert alert-danger" v-if="error !== null">
                    {{ error.Message ? error.Message : error.title }}
                    <span class="fw-bold" v-show="error.status">({{ error.status }})</span>
                </div>
                <div class="alert alert-warning" v-show="data !== null && data.length === 0">No Sender Was Found using the criteria specified.</div>
            </div>
            <div v-if="data !== null && data.length > 0">
                <div class="divider"></div>
                <h1 class="page-title text-primary mb-4 text-uppercase" id="resultsFound">Results Found</h1>
                <div class="table-responsive">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">Client</th>
                                <th scope="col">Doc number</th>
                                <th scope="col">Name</th>
                                <th scope="col">Address</th>
                                <th scope="col">Phone</th>
                                <th scope="col" class="text-center">30 days</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in data" :key="item.clientId">
                                <td><input autocomplete="none" class="form-check-input border-primary" name="radio" v-model="selectedId" type="radio" :value="item.clientId" id="foundItem" /></td>
                                <td>{{ item.clientId }}</td>
                                <td>{{ item.document1Number }}</td>
                                <td>
                                    {{ item.fullName }}
                                    <br />
                                    <b>{{ item.company }}</b>
                                </td>
                                <td>
                                    {{ item.address }} - {{ item.city }}/{{ item.stateName }} -
                                    {{ item.countryIsoCode }}
                                </td>
                                <td>{{ item.phone }}</td>
                                <td class="text-center">
                                    <button type="button" class="border-0 bg-transparent text-primary" @click="get30DaysAggregation(item.clientId, index)" v-show="!item.loading && !item.aggregation">
                                        <i class="fas fa-sync-alt"></i>
                                    </button>
                                    <div class="spinner-border spinner-border-sm text-primary" role="status" v-show="item.loading">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                    <span>{{ item.aggregation }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <button type="button" id="next" class="btn btn-primary text-uppercase mt-4" @click="next" :disabled="!senderSelected">Next <i class="fas fa-arrow-right ms-2"></i></button>
            </div>
        </template>
        <template v-slot:footer>
            <button type="button" @click="newSender" name="btnNewSender" class="btn btn-primary btn-sm ms-auto"><i class="fas fa-plus-circle"></i> New Sender</button>
        </template>
    </layout>
</template>

<script>
import axios from 'axios';
import Loading from '@/components/Loading';
import Layout from '@/views/layouts/CreateOrder';

export default {
    name: 'FindSender',
    components: {
        Loading,
        Layout,
    },
    data() {
        return {
            deg: 360,
            data: null,
            fullName: '',
            costumerNumber: '',
            telephone: '',
            companyName: '',
            selectedId: null,
            loading: false,
            error: null,
            pagination: {
                pageNumber: 1,
            },
        };
    },
    mounted() {
        if (this.existIncompleteOrders && this.newOrder) this.$toast.info('You have incomplete orders, please verify them.');
    },
    methods: {
        load() {
            this.loading = true;
            this.error = null;
            this.data = null;

            axios
                .get('/niloagent/createorder/find/sender', {
                    params: {
                        ClientId: this.costumerNumber,
                        CompanyName: this.companyName,
                        FullName: this.fullName,
                        SdPhone1: this.telephone,
                        pageNumber: this.pagination.pageNumber,
                    },
                })
                .then((response) => {
                    this.data = response.data.result;
                    this.pagination = (({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }) => ({ pageNumber, pageSize, rowFrom, rowTo, totalPages, totalRows }))(response.data);
                })
                .catch((error) => {
                    this.data = null;
                    this.error = error.response.data;
                })
                .finally(() => {
                    this.loading = false;
                    this.scrollUp();
                });
        },
        next() {
            let sender = this.data[this.selectedIndex];
            this.$store.commit('order/setSender', sender);
            this.$router.push({ name: 'Sender', params: { senderId: this.selectedId, selectedSender: sender } });
        },
        scrollUp() {
            document.getElementById('resultsFound').scrollIntoView();
        },
        clearFields() {
            this.fullName = '';
            this.costumerNumber = '';
            this.telephone = '';
            this.companyName = '';
            this.senderId = 0;
        },
        newSender() {
            this.$store.commit('order/setSender', null);
            this.$router.push('/order/create/sender');
        },
        get30DaysAggregation(clientId, index) {
            const row = this.data.find((el) => el.clientId === clientId);
            row.loading = true;
            this.$set(this.data, index, row);
            this.loadingAggregation = true;
            axios
                .get(`/niloagent/client/sender/${clientId}/senderaggregationmonthly`)
                .then((response) => {
                    const value = response.data.aggregation30DaysFormated;
                    row.aggregation = `$${value}`;
                    this.$set(this.data, index, row);
                })
                .catch((error) => {
                    this.error = error.response.data;
                })
                .finally(() => {
                    row.loading = false;
                    this.$set(this.data, index, row);
                });
        },
    },
    computed: {
        newOrder() {
            return !this.$store.state.order.sender;
        },
        existIncompleteOrders() {
            return this.$store.getters['order/existIncompleteOrders'];
        },
        senderSelected() {
            return this.selectedId > 0;
        },
    },
};
</script>

<style lang="scss" scoped>
.transition {
    transition: transform 0.5s ease-in-out;
}
</style>
