<template>
    <div class="modal" id="modal-update-rate" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Change Exchange Rate</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body px-4">
                    <div class="d-flex justify-content-center">
                        <div class="spinner-grow text-primary mt-2 mb-4" v-show="loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <label for="rate" class="form-label">Rate</label>
                    <input type="number" v-model="changedRate" id="rate" class="form-control" />
                </div>
                <div class="modal-footer d-flex px-5">
                    <button type="button" @click="validate" class="btn btn-primary me-auto text-uppercase w-100">Update</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
    name: 'UpdateRate',
    props: {
        amountSent: Number,
        currencyOfPayment: Number,
        typeOfPaymentId: Number,
        rate: Number,
        fees: Number,
    },
    data() {
        return {
            data: null,
            modal: null,
            loading: false,
            changedRate: 0,
            changedFees: 0,
        };
    },
    mounted() {
        this.modal = new Modal(document.getElementById('modal-update-rate'));
    },
    methods: {
        validate() {
            this.loading = true;
            axios
                .post('/niloagent/createorder/filter/validateratesandcosts', {
                    requestInfo: {
                        senderCountryId: this.order.sender.countryId,
                        recipientCountryId: this.order.recipient.countryOfPaymentId,
                        netAmountSent: this.amountSent,
                        currencySentId: 1,
                        currencyOfPayment: this.currencyOfPayment,
                        typeOfPaymentID: this.typeOfPaymentId,
                    },
                    exchangeRate: parseFloat(this.changedRate),
                    totalFee: parseFloat(this.changedFees),
                })
                .then((response) => {
                    if (response.data === 'ok') {
                        this.$emit('updated', this.changedRate, this.changedFees);
                        this.modal.hide();
                    }
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        show() {
            this.changedRate = this.rate;
            this.changedFees = this.fees;
            this.modal.show();
        },
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
    },
};
</script>

<style scoped></style>
