<template>
    <layout>
        <div class="container pt-4 d-print-none mb-5">
            <h1 class="page-title text-primary mb-5 text-uppercase text-center">Pre-Receipt</h1>
            <div class="row justify-content-center">
                <div class="col-md-5">
                    <div class="card mb-4">
                        <div class="card-body p-4">
                            <div class="mb-4">
                                <label for="paymentAmount" class="form-label">Payment Amount</label>
                                <input type="number" v-model="paymentAmount" class="form-control" id="paymentAmount">
                            </div>
                            <label for="paymentCountrySelect" class="form-label">Payment Country</label>
                            <select class="form-select" v-model="country" id="paymentCountrySelect" @change="changeCountry(country)">
                                <option value="0" selected>- Select -</option>
                                <option v-for="item in countries" :value="item.id" :key="item.id">{{ item.value }}</option>
                            </select>
                        </div>
                        <div class="card-footer p-4">
                            <label for="exchangeRate" class="form-label">Exchange Rate</label>
                            <input type="number" v-model="exchangeRate" class="form-control" id="exchangeRate" disabled>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center">
                <router-link :to="{ name: 'Pre-Receipt Print', query: { country: country, paymentAmount: paymentAmount }}" target="_blank" class="btn btn-primary text-uppercase" :class="{ 'disabled': disableDisplayToPrint }">{{ loading ? 'Wait...' : 'Display to Print' }}</router-link>
            </div>
        </div>
        <loading :show="loading"></loading>
    </layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/Main"
import Loading from "@/components/Loading"

export default {
    name: "PreReceipt",
    components: {
        Layout,
        Loading
    },
    data() {
        return {
            country: 0,
            paymentAmount: 1,
            exchangeRate: 0,
            countries: [],
            loading: false,
        }
    },
    mounted() {
        this.loadCountries();
    },
    methods: {
        loadCountries() {
            axios
                .get('/niloagent/createorder/filter/destinycountries')
                .then(response => {
                    this.countries = response.data;
                })
                .catch(error => {
                    this.$toast.error(`country: ${error.response.data.Message}`);
                });
        },
        changeCountry(value) {
             axios
                .get(`/niloagent/prereceipt/getrates?country=${value}&amount=1`)
                .then((response) => {
                    this.exchangeRate = response.data.exchangeRate;
                    return true
                })
                .catch(error => {
                    this.exchangeRate = 0
                    this.$toast.error(error.response.data.Message);
                });
        }
    },
    computed: {
        disableDisplayToPrint() {
            return this.loading || this.country === 0 || this.exchangeRate === 0;
        }
    }
}
</script>

<style scoped>

</style>