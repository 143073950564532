<template>
    <layout>
        <div class="content d-flex flex-column">
            <div class="container pt-4">
                <h1 class="page-title text-primary text-uppercase">Tasks: Compliance Hold</h1>
                <div class="row mt-4">
                    <div class="col-6 col-md-3 mb-3 mb-md-0">
                        <label for="agentFilter" class="form-label">Agent Filter</label>
                        <select class="form-select" v-model="agentFilter" id="agentFilter">
                            <option value="" selected></option>
                            <option v-for="item in agents" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-6 col-md-3 mb-3 mb-md-0">
                        <label for="senderFilter" class="form-label">Sender Filter</label>
                        <select class="form-select" v-model="senderFilter" id="senderFilter">
                            <option value="" selected></option>
                            <option v-for="(item, index) in senders" :value="item.id" :key="index">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-6 col-md-2 mb-3 mb-md-0">
                        <label for="handlerFilter" class="form-label">Handler Filter</label>
                        <select class="form-select" v-model="handlerFilter" id="handlerFilter">
                            <option value="" selected></option>
                            <option v-for="item in handlers" :value="item.value" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-6 col-md-2 mb-3 mb-md-0">
                        <label for="countryFilter" class="form-label">Country Filter</label>
                        <select class="form-select" v-model="countryFilter" id="countryFilter">
                            <option value="" selected></option>
                            <option v-for="item in countries" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                    <div class="col-md-2 d-flex flex-row">
                        <button type="button" @click="refresh" class="btn btn-outline-primary mt-auto">Refresh</button>
                    </div>
                </div>
                <div class="form-check mt-3">
                    <input class="form-check-input" v-model="customerSupportNoteOnly" type="checkbox" value="" id="checkCustomerSupportNote" />
                    <label class="form-check-label" for="checkCustomerSupportNote">Customer Support Note Only</label>
                </div>
                <div class="d-flex justify-content-center mt-4">
                    <loading :show="loading"></loading>
                </div>
                <div class="alert alert-warning" v-show="dataFiltered !== null && dataFiltered.length === 0">No Results Was Found using the criteria specified.</div>
            </div>
            <div v-if="dataFiltered !== null && dataFiltered.length > 0" class="mb-5 container-fluid">
                <div class="table-responsive">
                    <table class="table table-borderless caption-top">
                        <caption>
                            <span class="fw-bold">{{ dataFiltered.length }}</span>
                            orders found
                        </caption>
                        <thead class="bg-light">
                            <tr>
                                <th class="text-center rounded-start"><input type="checkbox" class="form-check-input m-0" v-model="checkAll" @click="toggleCheckbox" value="true" /></th>
                                <th># Order</th>
                                <th style="width: 120px">Date</th>
                                <th>Agent</th>
                                <th>Sender</th>
                                <th>Recipient</th>
                                <th>Amount</th>
                                <th>Occupation</th>
                                <th>Purpose</th>
                                <th>Relationship to Sender</th>
                                <th style="width: 140px">OFAC</th>
                                <th>Last Note</th>
                                <th>Customer Support Note</th>
                                <th class="text-center">Handler</th>
                                <th class="text-center rounded-end">Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in dataFiltered" :key="item.orderId" :style="{ 'background-color': item.rowColor }">
                                <td class="text-center"><input class="form-check-input m-0" type="checkbox" v-model="selected" name="check" :value="item.orderId" /></td>
                                <td>
                                    <router-link :to="{ name: 'Order Detail', params: { orderId: item.orderId } }" class="fw-bold text-decoration-none">{{ item.orderId }}</router-link>
                                </td>
                                <td>
                                    <div class="d-flex gap-2">
                                        {{ item.date }}
                                        <spam v-if="item.date" type="button" @click="copyToClipboard(item.date)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.agentName">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3"> {{ item.agentName }} </span>
                                        <spam v-if="item.agentName" type="button" @click="copyToClipboard(item.agentName)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.senderName + ' ' + item.senderId">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3"> {{ item.senderName + ' ' + item.senderId }} </span>

                                        <spam v-if="item.senderName" type="button" @click="copyToClipboard(item.senderName + ' ' + item.senderId)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.recipient">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3"> {{ item.recipient }} </span>
                                        <spam v-if="item.recipient" type="button" @click="copyToClipboard(item.recipient)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.amount">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3"> {{ item.amount }} </span>
                                        <spam v-if="item.amount" type="button" @click="copyToClipboard(item.amount)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.occupation">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3"> {{ item.occupation }} </span>
                                        <spam v-if="item.occupation" type="button" @click="copyToClipboard(item.occupation)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.purpose">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3"> {{ item.purpose }} </span>
                                        <spam v-if="item.purpose" type="button" @click="copyToClipboard(item.purpose)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.relationshipToSender">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3"> {{ item.relationshipToSender }} </span>
                                        <spam v-if="item.relationshipToSender" type="button" @click="copyToClipboard(item.relationshipToSender)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.ofacText">
                                    <div class="d-flex gap-2">
                                        <span v-if="item.ofacHit" class="item-truncate-2">{{ item.ofacText }}</span>
                                        <span v-else class="item-truncate-3">{{ item.ofacText }}</span>
                                        <spam v-if="item.ofacText" type="button" @click="copyToClipboard(item.ofacText)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                    <span v-if="item.ofacHit" class="badge bg-danger">HIT</span>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.lastNote">
                                    <div class="d-flex gap-2">
                                        <span v-if="item.lastNotePending" class="item-truncate-2">{{ item.lastNote }}</span>
                                        <span v-else class="item-truncate-3">{{ item.lastNote }}</span>
                                        <spam v-if="item.lastNote" type="button" @click="copyToClipboard(item.lastNote)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                    <span v-if="item.lastNotePending" class="badge bg-danger">PENDING</span>
                                </td>
                                <td data-toggle="tooltip" data-placement="top" :title="item.lastCustomerSupportNote">
                                    <div class="d-flex gap-2">
                                        <span class="item-truncate-3">{{ item.lastCustomerSupportNote }}</span>
                                        <spam v-if="item.lastCustomerSupportNote" type="button" @click="copyToClipboard(item.lastCustomerSupportNote)" title="Copy"><i class="far fa-clone"></i></spam>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <button type="button" @click="openHandlers(item.orderId, item.handlerId)" class="text-decoration-none border-0 badge" :class="[item.handler === 'Unassigned' ? 'bg-danger' : 'bg-light text-dark']">{{ item.handler }}</button>
                                </td>
                                <td class="text-center">
                                    <button type="button" @click="openNotes(item.orderId)" class="btn btn-outline-primary btn-sm">Notes</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-else>
                <div class="alert alert-danger" v-if="error !== null">
                    {{ error.data.Message ? error.data.Message : error.data.title }} <span class="fw-bold">({{ error.status }})</span>
                </div>
                <div class="alert alert-warning" v-show="data !== null && data.length === 0">No records found.</div>
            </div>
            <footer class="bg-light position-fixed bottom-0 w-100 p-3">
                <div class="container text-end d-flex">
                    <div class="me-auto d-inline-flex">
                        <button type="button" @click="approve" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3" :disabled="saving">{{ loading ? 'Wait...' : 'Approve' }}</button>
                        <button type="button" @click="assign" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3" :disabled="saving">Assign</button>
                        <button type="button" @click="sethighlight" class="btn btn-outline-primary btn-sm bg-white text-uppercase me-3" :disabled="saving">Highlight</button>
                        <button type="button" @click="removehighlight" class="btn btn-outline-primary btn-sm bg-white text-uppercase" :disabled="saving">Remove Highlight</button>
                    </div>
                    <div class="ms-auto">
                        <select class="form-select form-select" v-model="remarkId">
                            <option value="0" selected>- Default Note -</option>
                            <option v-for="item in remarks" :value="item.id" :key="item.id">{{ item.value }}</option>
                        </select>
                    </div>
                </div>
            </footer>
        </div>
        <modal-notes :default-note="remarkId" ref="modalNotes"></modal-notes>
        <modal-handler ref="modalHandler" url-load="/niloagent/ComplianceTask/ComplianceHandlerFilter" url-save="/niloagent/ComplianceTask/changeComplianceHandler" @onChange="load"></modal-handler>
    </layout>
</template>

<script>
import axios from 'axios';
import Layout from '@/views/layouts/Main';
import Loading from '@/components/Loading';
import ModalNotes from '@/views/order/modals/Notes';
import ModalHandler from '@/views/task/ModalHandler';

export default {
    name: 'Compliance',
    components: {
        Layout,
        ModalNotes,
        ModalHandler,
        Loading,
    },
    data() {
        return {
            data: null,
            error: null,
            loading: false,
            handlers: [],
            countries: [],
            agents: [],
            senders: [],
            agentFilter: null,
            senderFilter: null,
            handlerFilter: null,
            countryFilter: null,
            customerSupportNoteOnly: false,
            selected: [],
            remarkId: 0,
            remarks: [],
            checkAll: false,
            saving: false,
        };
    },
    mounted() {
        this.loadHandlerFilter();
        this.loadCountryFilter();
        this.loadRemarks();
        this.load();
        document.querySelector('[data-toggle="tooltip"]').tooltip();
    },
    methods: {
        load() {
            this.loading = true;
            this.data = null;
            this.selected = [];
            axios
                .get('/niloagent/ComplianceTask/ComplianceHoldList')
                .then((response) => {
                    this.data = response.data.responseList;
                    this.agents = response.data.agentList;
                    this.senders = response.data.senderList;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    this.data = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        approve() {
            this.saving = true;
            this.loading = true;
            axios
                .post('/niloagent/ComplianceTask/approvecompliancetask', {
                    ordersToBeApproved: this.selected,
                    remarkId: this.remarkId,
                })
                .then((response) => {
                    this.success = true;
                    this.error = null;
                    this.selected = [];
                    this.load();
                    this.$toast.success(`${response.data.message}`);
                    console.log(response);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                    this.loading = false;
                });
        },
        assign() {
            this.$refs.modalHandler.show(this.selected);
        },
        sethighlight() {
            this.saving = true;
            this.loading = true;
            axios
                .post('/niloagent/ComplianceTask/sethighlight', {
                    ordersToBeApproved: this.selected,
                })
                .then((response) => {
                    this.success = true;
                    this.error = null;
                    this.selected = [];
                    this.load();
                    this.$toast.success(`${response.data.message}`);
                    console.log(response);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                    this.loading = false;
                });
        },
        removehighlight() {
            this.saving = true;
            this.loading = true;
            axios
                .post('/niloagent/ComplianceTask/removehighlight', {
                    ordersToBeApproved: this.selected,
                })
                .then((response) => {
                    this.success = true;
                    this.error = null;
                    this.selected = [];
                    this.load();
                    this.$toast.success(`${response.data.message}`);
                    console.log(response);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.saving = false;
                    this.loading = false;
                });
        },
        loadHandlerFilter() {
            axios
                .get('/niloagent/ComplianceTask/HandlerFilter')
                .then((response) => {
                    this.handlers = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    this.handlers = null;
                });
        },
        loadCountryFilter() {
            axios
                .get('/niloagent/ComplianceTask/CountryFilter')
                .then((response) => {
                    this.countries = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    this.countries = null;
                });
        },
        loadRemarks() {
            axios
                .get('/niloagent/ComplianceTask/NotesRemarkTypes')
                .then((response) => {
                    this.remarks = response.data;
                    this.remarkId = 1;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                    this.remarks = null;
                });
        },
        toggleCheckbox() {
            if (!this.checkAll) this.selected = this.dataFiltered.map((el) => el.orderId);
            else this.selected = [];
        },
        openNotes(orderId) {
            this.$refs.modalNotes.show(orderId);
        },
        openHandlers(orderId, handlerId) {
            this.$refs.modalHandler.show([orderId], handlerId);
        },
        refresh() {
            this.load();
            this.agentFilter = null;
            this.senderFilter = null;
            this.handlerFilter = null;
            this.countryFilter = null;
            this.customerSupportNoteOnly = false;
        },
        copyToClipboard(str) {
            navigator.clipboard.writeText(str);
            this.$toast.success('Text copied to clipboard.');
        },
    },
    computed: {
        dataFiltered() {
            let data = this.data;

            if (this.agentFilter && this.agentFilter !== '') data = data.filter((item) => item.agentId === this.agentFilter);

            if (this.senderFilter && this.senderFilter !== '') data = data.filter((item) => item.senderId === this.senderFilter);

            if (this.handlerFilter && this.handlerFilter !== '') data = data.filter((item) => item.handler === this.handlerFilter);

            if (this.countryFilter && this.countryFilter !== '') data = data.filter((item) => parseInt(item.countryId) === parseInt(this.countryFilter));

            if (this.customerSupportNoteOnly) data = data.filter((item) => item.lastCustomerSupportNote !== null);

            return data;
        },
    },
};
</script>

<style lang="scss" scoped>
footer {
    z-index: 100;
}

.item-truncate-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
}

.item-truncate-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
