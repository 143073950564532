<template>
    <layout>
        <div class="container pb-4">
            <h1 class="page-title text-primary mb-4 text-uppercase">Enter a Payment</h1>
            <div v-show="sent" class="text-center">
                <div class="alert alert-primary">Your credit/debit entry has been <span class="fw-bold">successfully</span> posted.</div>
                <button type="button" @click="newPayment" class="btn btn-sm btn-outline-primary mt-4">New Payment</button>
            </div>
            <div v-show="!sent">
                <div class="card mb-4">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 mb-2" v-if="data">
                                <label for="company" class="form-label">Company</label>
                                <v-select :options="data.companies" :clearable="false" :reduce="(item) => item.id" label="value" v-model="companyId"></v-select>
                            </div>
                            <div class="col-md-6 pt-2">
                                <div class="form-check mb-2">
                                    <input class="form-check-input" type="radio" v-model="paymentTypeId" name="typeOfPayment" id="paymentReceived" value="3" />
                                    <label class="form-check-label" for="paymentReceived"> Payment Received <span class="text-primary fw-bold">FROM</span> Agent <small class="text-gray">(CREDIT into Agent Account)</small> </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" v-model="paymentTypeId" name="typeOfPayment" id="paymentMade" value="2" />
                                    <label class="form-check-label" for="paymentMade"> Payment Made <span class="text-primary fw-bold">TO</span> Agent <small class="text-gray">(DEBIT from Agent Account)</small> </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body border-top">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <label for="paymentAmount" class="form-label">Payment Amount</label>
                                <input type="number" v-model="paymentAmount" class="form-control" id="paymentAmount" />
                            </div>
                            <div class="col-md-4">
                                <label for="paymentDate" class="form-label">Payment Date</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                    <input type="text" class="form-control datePicker datePicker-input" id="paymentDate" />
                                </div>
                            </div>
                            <div class="col-md-4">
                                <label for="invoiceDate" class="form-label">Invoice Date</label>
                                <div class="input-group mb-3">
                                    <span class="input-group-text text-black-50 calendar-icon"><i class="fas fa-calendar-day"></i></span>
                                    <input type="text" class="form-control datePicker datePicker-input" id="invoiceDate" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3" v-if="data">
                                <label for="typeOfTransaction" class="form-label">Type of Transaction</label>
                                <select class="form-select" v-model="typeOfTransactionId" id="typeOfTransaction">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in data.typeOfTransactions" :value="item.id" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3" v-if="data">
                                <label for="formOfPayment" class="form-label">Form of Payment</label>
                                <select class="form-select" v-model="formOfPaymentId" id="formOfPayment">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in data.formOfPayments" :value="item.id" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="invoice" class="form-label">Invoice</label>
                                <input type="text" v-model="invoice" class="form-control" id="invoice" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 mb-3" v-if="data">
                                <label for="companyAccount" class="form-label">Company Account</label>
                                <select class="form-select" v-model="companyAccountId" id="companyAccount">
                                    <option value="0" selected>- Select -</option>
                                    <option v-for="item in data.companyAccounts" :value="item.id" :key="item.id">{{ item.value }}</option>
                                </select>
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="agentAccount" class="form-label">Agent Account</label>
                                <input type="text" v-model="agentAccount" class="form-control" id="agentAccount" />
                            </div>
                            <div class="col-md-4 mb-3">
                                <label for="reference" class="form-label">Reference or Check #</label>
                                <input type="text" v-model="referenceOrCheckNumber" class="form-control" id="reference" />
                            </div>
                        </div>
                        <div>
                            <label for="remarks" class="form-label">Remarks</label>
                            <textarea v-model="remarks" class="form-control" id="remarks"></textarea>
                        </div>
                    </div>
                </div>
                <button type="button" @click="send" class="btn btn-sm btn-primary text-uppercase" :disabled="sending">{{ sending ? 'Wait...' : 'Send Payment' }}</button>
            </div>
        </div>
    </layout>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Layout from '@/views/layouts/Main';
import { Datepicker } from 'vanillajs-datepicker';

export default {
    name: 'Payments',
    components: {
        Layout,
    },
    data() {
        return {
            paymentTypeId: 3,
            companyId: null,
            paymentAmount: '',
            paymentDate: null,
            invoiceDate: null,
            typeOfTransactionId: 0,
            formOfPaymentId: 0,
            invoice: '',
            companyAccountId: 0,
            agentAccount: '',
            referenceOrCheckNumber: '',
            remarks: '',
            data: null,
            sending: false,
            sent: false,
        };
    },
    mounted() {
        this.paymentDate = new Datepicker(document.getElementById('paymentDate'));
        this.invoiceDate = new Datepicker(document.getElementById('invoiceDate'));

        this.paymentDate.setDate(moment().toDate());
        this.load();
    },
    methods: {
        load() {
            axios
                .get('/niloagent/accounting/paymentsoptions')
                .then((response) => {
                    this.data = response.data;
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                });
        },
        send() {
            if (confirm('Are you sure you want to post this payment?')) {
                this.sending = true;
                axios
                    .post('/niloagent/accounting/payment', {
                        paymentTypeId: this.paymentTypeId,
                        companyId: this.companyId,
                        amount: this.paymentAmount,
                        paymentDate: this.paymentDate.getDate('mm/dd/yyyy'),
                        invoiceDate: this.invoiceDate.getDate('mm/dd/yyyy'),
                        typeOfTransactionId: this.typeOfTransactionId,
                        invoice: this.invoice,
                        formOfPaymentId: this.formOfPaymentId,
                        companyAccountId: this.companyAccountId,
                        referenceOrCheckNumber: this.referenceOrCheckNumber,
                        agentAccount: this.agentAccount,
                        remarks: this.remarks,
                    })
                    .then(() => {
                        this.sent = true;
                    })
                    .catch((error) => {
                        this.$toast.error(error.response.data.Message);
                    })
                    .finally(() => {
                        this.sending = false;
                    });
            }
        },
        newPayment() {
            this.sent = false;
            this.cleanFields();
        },
        cleanFields() {
            this.paymentTypeId = 3;
            this.companyId = null;
            this.paymentAmount = '';

            this.paymentDate.destroy();
            this.paymentDate = new Datepicker(document.getElementById('paymentDate'));
            this.paymentDate.setDate(moment().toDate());

            this.invoiceDate.destroy();
            this.invoiceDate = new Datepicker(document.getElementById('invoiceDate'));
            this.invoiceDate.setDate([], { clear: true });

            this.typeOfTransactionId = 0;
            this.formOfPaymentId = 0;
            this.invoice = '';
            this.companyAccountId = 0;
            this.agentAccount = '';
            this.referenceOrCheckNumber = '';
            this.remarks = '';
        },
    },
};
</script>

<style scoped></style>
