<template>
    <div class="modal" id="modal-detail-order-debit-card" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light border-bottom-0 p-4">
                    <h5 class="modal-title">Debit Card Payment</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body text-center">
                    <p class="fs-6">
                        The Text Message was sent to the number <span class="fw-bold text-primary">{{ phone }}</span> <br />
                        In case the sender did not received it you can:
                    </p>
                    <hr />
                    <div class="mb-3">
                        <h3 class="fs-6 fw-bold">1- Ask the client to scan the QR Code</h3>
                        <a :href="paymentLink" target="_blank">
                            <qrcode-vue :value="paymentLink" size="150"></qrcode-vue>
                        </a>
                    </div>

                    <div class="mb-3">
                        <h3 class="fs-6 mb-3 fw-bold">2- Open or copy the link</h3>
                        <div class="input-group mt-3">
                            <input type="text" class="form-control cursor-pointer text-decoration-underline text-primary" :value="paymentLink" @click="openLink" readonly/>
                            <span class="input-group-text">
                                <button type="button" @click="copyLink" class="border-0 bg-transparent" id="copy-link">
                                    <i class="far fa-copy"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div v-if="canResendTextMessage">
                        <h3 class="fs-6 mb-3 fw-bold">3- Resend the Text Message</h3>
                        <button type="button" v-on:click="resendTextMessage" class="btn btn-outline-primary btn-sm bg-white text-uppercase" :disabled="sendingTextMessage">{{ sendingTextMessage ? 'Sending...' : 'Re-send Text Message' }}</button>
                    </div>
                    <hr />
                    <div class="alert alert-warning mb-0">
                        <span class="badge bg-light fs-6 text-dark me-2">{{ counter }}</span> Waiting the payment to be done by the client
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { Modal, Tooltip } from 'bootstrap';
import QrcodeVue from 'qrcode.vue';

export default {
    name: 'DetailOrderDebitCard',
    components: {
        QrcodeVue,
    },
    props: {
        orderId: Number,
        phone: String,
        paymentLink: String,
        canResendTextMessage: Boolean,
    },
    data() {
        return {
            modal: null,
            timer: null,
            counter: 30,
            tooltipButton: null,
            sendingTextMessage: false,
        };
    },
    mounted() {
        const el = document.getElementById('modal-detail-order-debit-card');
        this.modal = new Modal(el);

        this.tooltipButton = new Tooltip(document.getElementById('copy-link'), {
            title: 'Copy link',
        });
    },
    methods: {
        resendTextMessage() {
            this.sendingTextMessage = true;
            axios
                .post(`/niloagent/order/${this.orderId}/detail/resendtextmessagedebitcard`)
                .then((response) => {
                    this.$toast.info(response.data);
                })
                .catch((error) => {
                    this.$toast.error(error.response.data.Message);
                })
                .finally(() => {
                    this.sendingTextMessage = false;
                });
        },
        copyLink() {
            navigator.clipboard.writeText(this.paymentLink);
            this.tooltipButton.setContent({ '.tooltip-inner': 'Link copied!' });
        },
        openLink() {
            window.open(this.paymentLink, '_blank').focus();
        },
        show() {
            this.modal.show();
            this.timer = setInterval(() => {
                if (this.counter <= 0) {
                    this.$emit('reload');
                    this.counter = 30;
                }
                this.counter--;
            }, 1000);
        },
        hide() {
            clearTimeout(this.timer);
            this.modal.hide();
        },
    },
};
</script>


<style scoped></style>