<template>
    <layout>
        <div class="container pt-4">
            <h1 class="page-title text-primary mb-5 text-uppercase">Tasks</h1>
            <div class="row justify-content-center">
                <div class="col-lg-8" v-show="showMoreTasks">
                    <div class="row">
                        <div class="col-lg-6">
                            <compliance-card class="mb-4"></compliance-card>
                            <financial-card></financial-card>
                        </div>
                        <div class="col-lg-6">
                            <customer-service-card class="mb-4"></customer-service-card>
                            <customer-service-usend-card></customer-service-usend-card>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <tasks-card></tasks-card>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "@/views/layouts/Main";
import TasksCard from "@/views/task/cards/TasksCard";
import FinancialCard from "@/views/task/cards/FinancialCard";
import ComplianceCard from "@/views/task/cards/ComplianceCard";
import CustomerServiceCard from "@/views/task/cards/CustomerServiceCard";
import CustomerServiceUsendCard from "@/views/task/cards/CustomerServiceUsendCard";

export default {
    name: "Tasks",
    components: {
        Layout,
        ComplianceCard,
        FinancialCard,
        CustomerServiceCard,
        CustomerServiceUsendCard,
        TasksCard
    },
    methods: {

    },
    computed: {
        showMoreTasks() {
            return (this.$store.state.agent.headquarter);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>